import CourseService from 'http/CourseService';
import { getErrorMessage } from 'http/utils';
import React, { useEffect, useState } from 'react';
import {
  Button,
  CloseButton,
  Col,
  Form,
  Modal,
  Row,
  Spinner
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import ConfirmModal from 'components/common/ConfirmModal';
import Flex from 'components/common/Flex';

const ChapterModal = ({ chapter, course, close, refetchCourse }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n?.language;
  const [chapterDetails, setChapterDetails] = useState(chapter);
  const [errors, setErrors] = useState(null);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const {
    mutate: saveChapter,
    isError: saveChapterError,
    isLoading: saveChapterLoading
  } = useMutation({
    mutationFn: CourseService.updateChapter,
    onSuccess: data => onSaveChapterSucces(data)
  });

  const onSaveChapterSucces = () => {
    toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
    refetchCourse && refetchCourse(course?.id);
    close && close();
  };

  const handleSubmitChapter = () => {
    let errorsObj = {};
    if (!chapterDetails?.title?.ar) {
      if (!errorsObj['title']) {
        errorsObj['title'] = {
          ar: {
            error: true,
            message: t('common:validation.fieldIsRequired')
          }
        };
      } else {
        errorsObj['title']['ar'] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
      }
    }
    if (!chapterDetails?.title?.en) {
      if (!errorsObj['title']) {
        errorsObj['title'] = {
          en: { error: true, message: t('common:validation.fieldIsRequired') }
        };
      } else {
        errorsObj['title']['en'] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
      }
    }
    if (!!chapterDetails?.title?.ar) {
      if (chapterDetails?.title?.ar.length > 70) {
        if (!errorsObj['title']) {
          errorsObj['title'] = {
            ar: {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 70
              })
            }
          };
        } else {
          errorsObj['title']['ar'] = {
            error: true,
            message: t('course:validation.message.maxAllowedCharacters', {
              count: 70
            })
          };
        }
      } else {
        if (!!errorsObj['title']) {
          errorsObj['title']['ar'] = null;
        }
      }
    }
    if (!!chapterDetails?.title?.en) {
      if (chapterDetails?.title?.en.length > 70) {
        if (!errorsObj['title']) {
          errorsObj['title'] = {
            en: {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 70
              })
            }
          };
        } else {
          errorsObj['title']['en'] = {
            error: true,
            message: t('course:validation.message.maxAllowedCharacters', {
              count: 70
            })
          };
        }
      } else {
        if (!!errorsObj['title']) {
          errorsObj['title']['en'] = null;
        }
      }
    }

    setErrors(errorsObj);
    if (errorsObj?.title?.en?.error || errorsObj?.title?.ar?.error) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    saveChapter({
      id: chapterDetails?.id,
      title: chapterDetails?.title,
      courseId: course.id
    });
  };

  const checkUnsavedChanged = () => {
    let hasChanges = false;
    if (!chapter?.id) {
      if (!!chapterDetails?.title?.ar) {
        hasChanges = true;
      }
      if (!!chapterDetails?.title?.en) {
        hasChanges = true;
      }
    } else {
      if (chapter?.title?.ar !== chapterDetails?.title?.ar) {
        hasChanges = true;
      }
      if (chapter?.title?.en !== chapterDetails?.title?.en) {
        hasChanges = true;
      }
    }

    setHasUnsavedChanges(hasChanges);
  };

  const handleCancel = () => {
    if (!hasUnsavedChanges) {
      close(false);
      return;
    }
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => close(false),
      message: t('common:message.confirmDiscardMessage'),
      header: t('common:message.confirmDiscardTitle'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.discard')
    });
  };

  useEffect(() => {
    checkUnsavedChanged();
  }, [chapterDetails]);

  useEffect(() => {
    if (saveChapterError) {
      toast.error(getErrorMessage(t, saveChapterError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [saveChapterError]);

  return (
    <Modal
      show={!!chapter?.id}
      onHide={close}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('course:labels.chapterModalTitle', {
            name: chapter?.title?.[currentLanguage]
          })}
        </Modal.Title>
        <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => close(false)}
        />
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="newChapter" className="position-relative">
          <Flex alignItems="center" justifyContent="start" gap="1">
            <Row className="w-100 px-3">
              <Col md={6} className="mt-1 g-1">
                <Form.Label>
                  {`${t('common:labels.title')} (${t(
                    'common:language.ar.name'
                  )})`}
                  <span className="text-danger">*</span>
                  <small>
                    {`(${t('common:labels.maxCharacters', { count: 70 })})`}
                  </small>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="titleAr"
                  required
                  value={chapterDetails?.title?.ar}
                  className="pe-4"
                  onChange={e =>
                    setChapterDetails({
                      ...chapterDetails,
                      title: { ...chapterDetails?.title, ar: e.target.value }
                    })
                  }
                  placeholder={t('common:placeholder.titleAr')}
                  isInvalid={!!errors?.title?.ar?.error}
                />
                <Form.Text className="text-danger">
                  {!!errors?.title?.ar?.error && errors?.title?.ar?.message}
                </Form.Text>
              </Col>
              <Col md={6} className="mt-1 g-1">
                <Form.Label>
                  {`${t('common:labels.title')} (${t(
                    'common:language.en.name'
                  )})`}
                  <span className="text-danger">*</span>
                  <small>
                    {`(${t('common:labels.maxCharacters', { count: 70 })})`}
                  </small>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="titleEn"
                  required
                  value={chapterDetails?.title?.en}
                  className="pe-4"
                  onChange={e =>
                    setChapterDetails({
                      ...chapterDetails,
                      title: { ...chapterDetails?.title, en: e.target.value }
                    })
                  }
                  placeholder={t('common:placeholder.titleEn')}
                  isInvalid={!!errors?.title?.en?.error}
                />
                <Form.Text className="text-danger">
                  {!!errors?.title?.en?.error && errors?.title?.en?.message}
                </Form.Text>
              </Col>
            </Row>
          </Flex>
        </Form.Group>
        <ConfirmModal {...confirmModalProps} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={saveChapterLoading}
          onClick={handleSubmitChapter}
          className="mt-1"
        >
          {saveChapterLoading && <Spinner size="sm" />}
          {!saveChapterLoading && t('common:button.save')}
        </Button>
        <Button onClick={handleCancel} variant="secondary">
          {t('common:button.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ChapterModal;
