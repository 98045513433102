import { faCode, faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const CurriculumPracticeCodeItem = ({
  t,
  chapter,
  isReadOnly,
  breakpoints,
  setPracticeCodeToEdit,
  handleClickDeletePracticeCode
}) => {
  return (
    <Row>
      <div className="d-flex align-items-center">
        <Flex
          key={`practiceCode-content-${chapter?.practiceCode?.id}`}
          gap="2"
          className={`w-100 ${
            breakpoints.down('md') ? 'mx-1' : 'ms-2'
          } p-2 border-grey border-1 border rounded`}
          alignItems="center"
          justifyContent="between"
        >
          <Flex
            className={`w-75 ${isReadOnly ? 'ms-2' : 'ms-4'}`}
            alignItems="center"
            justifyContent="start"
            gap="2"
          >
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip
                  id={'tooltip-practiceCode-' + chapter?.practiceCode?.id}
                >
                  {t('course:labels.practiceCode')}
                </Tooltip>
              }
            >
              <span className="mx-1 fs-0">
                <FontAwesomeIcon icon={faCode} style={{ width: '16px' }} />
              </span>
            </OverlayTrigger>
            <p className="mx-2 mb-0">{t('course:labels.practiceCode')}</p>
          </Flex>
          {!isReadOnly && (
            <Flex
              gap="2"
              className="w-100"
              alignItems="center"
              justifyContent="end"
            >
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id={
                      'tooltip-edit-practiceCode-' + chapter?.practiceCode?.id
                    }
                  >
                    {t('common:button.edit')}
                  </Tooltip>
                }
              >
                <span className="ms-2 text-primary fs-0">
                  <FontAwesomeIcon
                    icon={faEdit}
                    className="cursor-pointer"
                    onClick={() =>
                      setPracticeCodeToEdit({
                        practiceCode: chapter?.practiceCode,
                        chapter
                      })
                    }
                  />
                </span>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip
                    id={
                      'tooltip-delete-practiceCode-' + chapter?.practiceCode?.id
                    }
                  >
                    {t('common:button.delete')}
                  </Tooltip>
                }
              >
                <span className="ms-2 text-danger fs-0">
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="cursor-pointer"
                    onClick={() =>
                      handleClickDeletePracticeCode({
                        id: chapter?.practiceCode?.id,
                        chapterId: chapter.id
                      })
                    }
                  />
                </span>
              </OverlayTrigger>
            </Flex>
          )}
        </Flex>
      </div>
    </Row>
  );
};

export default CurriculumPracticeCodeItem;
