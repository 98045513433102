import React from 'react';
import IconButton from 'components/common/IconButton';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import { Col, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const defaultPaging = {
  pageNumber: 1,
  pageSize: 10
};

const ExamsListTableHeader = ({ t, filters, setFilters }) => {
  const navigate = useNavigate();
  const handleSearch = term => {
    //console.log('handleSearch term', term);
    let searchPayload = {
      query: {
        term: term,
        conjunction: 'or',
        properties: [
          'title.ar',
          'title.en',
          'tags',
          'description.ar',
          'description.en',
          'course.title.ar',
          'course.title.en',
          'category.title.ar',
          'category.title.en'
        ]
      }
    };
    if (!term) {
      searchPayload = {
        query: null
      };
    }
    setFilters({
      ...filters,
      ...defaultPaging,
      ...searchPayload
    });
  };

  return (
    <div className="d-lg-flex justify-content-between">
      <Row className="flex-between-center gy-2 px-x1 col-12 col-md-6 flex-md-nowrap">
        <Col xs="auto" className="pe-0">
          <h6 className="mb-0">{t('plans:labels.allPlans')}</h6>
        </Col>
        <Col xs="12">
          <AdvanceTableSearchBox
            //className="input-search-width"
            globalFilter={''}
            setGlobalFilter={handleSearch}
            placeholder={t('plans:labels.searchPlans')}
          />
        </Col>
      </Row>
      <div className="d-flex align-items-center justify-content-between justify-content-lg-end px-x1">
        <div id="orders-actions">
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="user-newPlan-tooltip">
                {t('plans:button.newPlan')}
              </Tooltip>
            }
          >
            <span>
              <IconButton
                variant="spark-default"
                size="sm"
                icon="plus"
                transform="shrink-3"
                iconAlign="middle"
                onClick={() => navigate('/plans/create')}
              >
                <span className="d-none d-sm-inline-block d-xl-none d-xxl-inline-block ms-1">
                  {t('common:button.new')}
                </span>
              </IconButton>
            </span>
          </OverlayTrigger>
        </div>
      </div>
    </div>
  );
};

ExamsListTableHeader.propTypes = {
  selectedRowIds: PropTypes.object,
  globalFilter: PropTypes.string,
  setGlobalFilter: PropTypes.func,
  handleShow: PropTypes.func,
  layout: PropTypes.string,
  selectedItems: PropTypes.array,
  handleTicketsSearch: PropTypes.func
};

export default ExamsListTableHeader;
