import useVimeo from 'hooks/useVimeo';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ReactVimeoPlayer from 'react-player/vimeo';
import FileDropZone from 'components/common/FileDropZone';
import { toast } from 'react-toastify';
import { formatFileSize, getErrorMessage } from 'http/utils';
import { ProgressBar } from 'react-bootstrap';
import LinearIndeterminateProgressBar from 'components/common/LinearIndeterminateProgressBar';
import Flex from 'components/common/Flex';
import { formatDuration } from 'helpers/utils';
import MultiLanguageRTE from 'components/common/MultiLanguageRTE';

const LessonContent = ({ lesson, onChange, disableAll, errors }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n?.language;
  const handleVimeoUpload = files => {
    if (files?.length) {
      handleUpload(files[0]);
    }
    console.log('handleVimeoUpload files', { files });
  };

  const onVimeoSuccessUpload = responseData => {
    console.log('onVimeoSuccessUpload responseData', { responseData });
    onChange &&
      onChange({
        type: 'video',
        data: responseData
      });
  };

  const onVimeoErrorUpload = error => {
    toast.error(getErrorMessage(t, error), {
      theme: 'colored',
      autoClose: false
    });
    console.log('onVimeoErrorUpload error', { error });
  };

  const { status, isLoading, progress, handleUpload } = useVimeo({
    onFinish: onVimeoSuccessUpload,
    onError: onVimeoErrorUpload
  });

  const handleChange = ({ type, data }) => {
    //console.log('handleChange', { type, data });
    switch (type) {
      case 'text':
        onChange({ type, data });
        break;
      case 'video':
        handleVimeoUpload(data.files);
        break;
      case 'html5':
        handleHtmlUpload(data.files);
        break;
      default:
        break;
    }
  };

  const handleHtmlUpload = files => {
    console.log('handleHtmlUpload files', { files });
  };

  useEffect(() => {
    if ((progress && progress < 100) || isLoading) {
      disableAll && disableAll(true);
    } else {
      disableAll && disableAll(false);
    }
  }, [progress, isLoading]);

  useEffect(() => {
    //console.log('vimeo status', status);
    if (!!status) {
      toast.success(status, {
        theme: 'colored',
        autoClose: 5000
      });
    }
  }, [status]);

  if (!lesson?.type) {
    return '';
  }

  if (lesson?.type === 'video') {
    if (!lesson?.content?.video?.url) {
      return (
        <div>
          <FileDropZone
            onFilesChange={files =>
              handleChange({ type: 'video', data: { files } })
            }
            multiple={false}
            displayUploadedFiles={false}
            disabled={isLoading || (progress && progress < 100)}
            error={errors?.content}
          />
          {!!progress && progress < 100 && (
            <ProgressBar now={progress} label={`${progress}%`} />
          )}
          {isLoading && <LinearIndeterminateProgressBar />}
        </div>
      );
    }
    return (
      <div>
        <Flex>
          <ul>
            <li>
              {' '}
              {`${t('lesson:video.name')} : ${
                lesson?.content?.video?.name || 'Untitled'
              }`}
            </li>
            <li>
              {' '}
              {`${t('lesson:video.duration')} : ${formatDuration(
                lesson?.content?.video?.duration,
                t
              )}`}
            </li>
            <li>
              {' '}
              {`${t('lesson:video.size')} : ${formatFileSize(
                lesson?.content?.video?.size || 0,
                t
              )}`}
            </li>
            <li>
              {' '}
              {`${t('lesson:video.type')} : ${
                lesson?.content?.video?.type || 'Unknown'
              }`}
            </li>
          </ul>
        </Flex>
        <ReactVimeoPlayer
          url={lesson?.content?.video?.url}
          controls={true}
          className="react-player"
        />
      </div>
    );
  }

  if (lesson?.type === 'html5') {
    return (
      <div>
        <FileDropZone
          onFilesChange={files =>
            handleChange({ type: 'html5', data: { files } })
          }
          multiple={false}
          accept=".zip,application/zip"
          files={
            lesson?.content?.interactiveContentPath
              ? [lesson?.content?.interactiveContentPath]
              : []
          }
          filesTitle={t(`lesson:type.${lesson?.type}`)}
          dropPlaceholder={
            lesson?.content?.video?.url || lesson?.content?.video?.url
              ? t('common:placeholder.replaceFiles')
              : undefined
          }
          error={errors?.content}
        />
      </div>
    );
  }

  return (
    <div>
      <br />
      <div className={`tinymce-editor-container`}>
        <MultiLanguageRTE
          t={t}
          enableFullscreen
          activeLanguage={currentLanguage}
          valueEn={lesson?.content?.text?.en || ''}
          valueAr={lesson?.content?.text?.ar || ''}
          errorEn={!!errors?.content?.en}
          errorAr={!!errors?.content?.ar}
          errorArMessage={t('common:validation.fieldIsRequired')}
          errorEnMessage={t('common:validation.fieldIsRequired')}
          placeholderEn={t('course:placeholders.addNewItem')}
          placeholderAr={t('course:placeholders.addNewItem')}
          handleEnChange={newValue => {
            handleChange({
              type: 'text',
              data: { ...lesson?.content?.text, en: newValue }
            });
          }}
          handleArChange={newValue => {
            handleChange({
              type: 'text',
              data: { ...lesson?.content?.text, ar: newValue }
            });
          }}
        />
      </div>
    </div>
  );
};

export default LessonContent;
