import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Collapse } from 'react-bootstrap';
import { truncateString } from 'helpers/utils';

const ProfileIntro = ({ user, t }) => {
  const [collapsed, setCollapsed] = useState(false);
  return (
    <Card className="mb-3">
      <Card.Header className="bg-light">
        <h5 className="mb-0">{t('user:labels.intro')}</h5>
      </Card.Header>

      <Card.Body className="text-justify text-1000">
        <p>
          {truncateString(user?.bio || t('common:labels.noDataAvailable'), 200)}
        </p>
        <Collapse in={collapsed}>
          <div>
            <p>{user?.bio}</p>
          </div>
        </Collapse>
      </Card.Body>

      <Card.Footer className="bg-light p-0 border-top d-grid">
        {user?.bio?.length > 200 && (
          <Button
            variant="link"
            // className="me-2 mb-1"
            onClick={() => setCollapsed(!collapsed)}
          >
            {t(`common:labels.${collapsed ? 'less' : 'more'}`)}
            <FontAwesomeIcon
              icon="chevron-down"
              className="ms-2 fs--2"
              transform={collapsed ? 'rotate-180' : ''}
            />
          </Button>
        )}
      </Card.Footer>
    </Card>
  );
};

export default ProfileIntro;
