import React, { useContext, useState } from 'react';
import {
  Button,
  Card,
  Form,
  OverlayTrigger,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import CourseService from 'http/CourseService';
import { toast } from 'react-toastify';
import { useEffect } from 'react';
import { getErrorMessage } from 'http/utils';
import { CourseContext } from 'context/Context';
import MultiLanguageRTE from 'components/common/MultiLanguageRTE';

const CourseRequirements = () => {
  const { t, i18n } = useTranslation();
  const { course, isCreate, isReadOnly, fetchCourse } =
    useContext(CourseContext);
  const currentLanguage = i18n.language;
  const [newLearn, setNewLearn] = useState(null);
  const [newTarget, setNewTarget] = useState(null);
  const [newRequirement, setNewRequirement] = useState(null);
  const [newItemType, setNewItemType] = useState(null);
  const [openNewLearn, setOpenNewLearn] = useState(false);
  const [openNewTarget, setOpenNewTarget] = useState(false);
  const [openNewRequirement, setOpenNewRequirement] = useState(false);
  const [infoItems, setInfoItems] = useState({
    learn: null,
    target: null,
    requirements: null
  });
  const [errors, setErrors] = useState({
    learn: null,
    target: null,
    requirement: null
  });

  useEffect(() => {
    if (course?.info?.length) {
      setInfoItems({
        learn: course?.info.find(i => i.type === 'learn') || null,
        target: course?.info.find(i => i.type === 'target') || null,
        requirement: course?.info.find(i => i.type === 'requirement') || null
      });
    }
  }, [course]);

  const {
    mutate: updateInfo,
    error: infoUpdateError,
    isLoading: infoUpdateLoading
  } = useMutation({
    mutationFn: CourseService.updateInfo,
    onSuccess: data => onSuccesInfoUpdate(data)
  });

  const { mutate: deleteInfoItem, error: infoItemDeleteError } = useMutation({
    mutationFn: CourseService.deleteInfoItem,
    onSuccess: data => onSuccesInfoDelete(data)
  });

  const onSuccesInfoUpdate = () => {
    setNewItemType(null);
    fetchCourse(course?.id);

    setNewLearn(null);
    setNewTarget(null);
    setNewRequirement(null);
    setOpenNewLearn(false);
    setOpenNewTarget(false);
    setOpenNewRequirement(false);
    toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
  };

  const onSuccesInfoDelete = () => {
    fetchCourse(course?.id);
    toast.success(t(`common:message.deleteSuccess`), { theme: 'colored' });
  };

  const handleSave = type => {
    let item = null;
    switch (type) {
      case 'learn':
        item = newLearn;
        break;
      case 'target':
        item = newTarget;
        break;
      case 'requirement':
        item = newRequirement;
        break;
      default:
        break;
    }
    let errorsObj = { ...errors };
    if (!item?.content?.ar) {
      if (!errorsObj[type]) {
        errorsObj[type] = { ar: true };
      } else {
        errorsObj[type]['ar'] = true;
      }
    }
    if (!item?.content?.en) {
      if (!errorsObj[type]) {
        errorsObj[type] = { en: true };
      } else {
        errorsObj[type]['en'] = true;
      }
    }
    if (item?.content?.en && item?.content?.ar) {
      errorsObj[type] = null;
    }
    setErrors(errorsObj);
    const hasErrors = Object.values(errorsObj)?.some(x => {
      if (!x) {
        return false;
      }
      if (!x?.ar || !x?.en) {
        return true;
      }
      return false;
    });
    console.log('errorsObj', errorsObj);
    if (hasErrors) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    if (item && item?.content) {
      setNewItemType(type);
      updateInfo({
        courseId: course?.id,
        infoItemId: item?.id || null,
        content: item?.content,
        type
      });
    }
  };

  const handleDeleteItem = item => {
    deleteInfoItem({ courseId: course?.id, infoItemId: item?.id });
  };

  const handleEditClick = (item, type) => {
    switch (type) {
      case 'learn':
        setOpenNewLearn(true);
        setNewLearn(item);
        break;
      case 'target':
        setOpenNewTarget(true);
        setNewTarget(item);
        break;
      case 'requirement':
        setOpenNewRequirement(true);
        setNewRequirement(item);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (infoItemDeleteError) {
      toast.error(getErrorMessage(t, infoItemDeleteError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (infoUpdateError) {
      toast.error(getErrorMessage(t, infoUpdateError), {
        theme: 'colored',
        autoClose: false
      });
      setNewItemType(null);
    }
  }, [infoUpdateError, infoItemDeleteError]);

  if (isCreate) {
    return '';
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h5" className="bg-light">
          {t('course:labels.learnsTitle')}
        </Card.Header>
        <Card.Body className="pt-0 p-all-m-0">
          {!!infoItems['learn'] && (
            <Flex alignItems="start" className={`hover-actions-trigger py-3`}>
              {/* <FontAwesomeIcon
                      icon={faDotCircle}
                      className="fs--2 text-secondary me-3 mt-1"
                    /> */}
              <p
                className="fs--1 mb-0 me-6"
                dangerouslySetInnerHTML={{
                  __html: infoItems['learn'].content[currentLanguage]
                }}
              ></p>
              {!isReadOnly && (
                <div
                  className="hover-actions end-0 translate-middle-y"
                  style={{ top: '10%' }}
                >
                  <OverlayTrigger
                    overlay={
                      <Tooltip id={`editLearnTooltip-${infoItems['learn'].id}`}>
                        {t('common:tooltips.edit')}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="link"
                      type="button"
                      onClick={() =>
                        handleEditClick(infoItems['learn'], 'learn')
                      }
                      className="d-flex align-items-center fs--1 text-700 p-0 me-3"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={
                      <Tooltip
                        id={`removeLearnTooltip-${infoItems['learn'].id}`}
                      >
                        {t('common:tooltips.delete')}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="spark-default"
                      type="button"
                      size="sm"
                      onClick={() => handleDeleteItem(infoItems['learn'])}
                      className="icon-item icon-item-sm rounded-3 fs--2"
                    >
                      <FontAwesomeIcon icon="times" />
                    </Button>
                  </OverlayTrigger>
                </div>
              )}
            </Flex>
          )}
          {(!infoItems['learn'] || openNewLearn) && !isReadOnly && (
            <Form.Group
              controlId="courseLearn"
              className="position-relative mt-2 "
            >
              <MultiLanguageRTE
                t={t}
                enableFullscreen
                activeLanguage={currentLanguage}
                valueEn={newLearn?.content?.en || ''}
                valueAr={newLearn?.content?.ar || ''}
                errorEn={!!errors?.learn?.en}
                errorAr={!!errors?.learn?.ar}
                errorArMessage={t('common:validation.fieldIsRequired')}
                errorEnMessage={t('common:validation.fieldIsRequired')}
                placeholderEn={t('course:placeholders.addNewItem')}
                placeholderAr={t('course:placeholders.addNewItem')}
                handleEnChange={newValue => {
                  setNewLearn({
                    ...newLearn,
                    content: {
                      ...newLearn?.content,
                      en: newValue
                    }
                  });
                }}
                handleArChange={newValue => {
                  setNewLearn({
                    ...newLearn,
                    content: {
                      ...newLearn?.content,
                      ar: newValue
                    }
                  });
                }}
              />
              <Flex className="mt-2 gap-1">
                <Button
                  size="sm"
                  onClick={() => handleSave('learn')}
                  type="button"
                  disabled={!newLearn?.content?.ar && !newLearn?.content?.en}
                >
                  {infoUpdateLoading && newItemType === 'learn' && (
                    <Spinner size="sm" />
                  )}
                  {!infoUpdateLoading &&
                    newItemType !== 'learn' &&
                    t('common:button.save')}
                </Button>
                {(newLearn?.content?.ar || newLearn?.content?.en) && (
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => setNewLearn(null)}
                  >
                    {t('common:button.cancel')}
                  </Button>
                )}
              </Flex>
            </Form.Group>
          )}
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header as="h5" className="bg-light">
          {t('course:labels.targetsTitle')}
        </Card.Header>
        <Card.Body className="pt-0 p-all-m-0">
          {!!infoItems['target'] && (
            <Flex alignItems="start" className={`hover-actions-trigger py-3`}>
              {/* <FontAwesomeIcon
                      icon={faDotCircle}
                      className="fs--2 text-secondary me-3 mt-1"
                    /> */}
              <p
                className="fs--1 mb-0 me-6"
                dangerouslySetInnerHTML={{
                  __html: infoItems['target'].content[currentLanguage]
                }}
              ></p>
              {!isReadOnly && (
                <div
                  className="hover-actions end-0 translate-middle-y"
                  style={{ top: '10%' }}
                >
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="editTargetTooltip">
                        {t('common:tooltips.edit')}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="link"
                      type="button"
                      onClick={() =>
                        handleEditClick(infoItems['target'], 'target')
                      }
                      className="d-flex align-items-center fs--1 text-700 p-0 me-3"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="removeTargetTooltip">
                        {t('common:tooltips.delete')}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="spark-default"
                      type="button"
                      size="sm"
                      onClick={() => handleDeleteItem(infoItems['target'])}
                      className="icon-item icon-item-sm rounded-3 fs--2"
                    >
                      <FontAwesomeIcon icon="times" />
                    </Button>
                  </OverlayTrigger>
                </div>
              )}
            </Flex>
          )}
          {(!infoItems['target'] || openNewTarget) && !isReadOnly && (
            <Form.Group
              controlId="courseTarget"
              className="position-relative mt-2"
            >
              <MultiLanguageRTE
                t={t}
                enableFullscreen
                activeLanguage={currentLanguage}
                valueEn={newTarget?.content?.en || ''}
                valueAr={newTarget?.content?.ar || ''}
                errorEn={!!errors?.target?.en}
                errorAr={!!errors?.target?.ar}
                errorArMessage={t('common:validation.fieldIsRequired')}
                errorEnMessage={t('common:validation.fieldIsRequired')}
                placeholderEn={t('course:placeholders.addNewItem')}
                placeholderAr={t('course:placeholders.addNewItem')}
                handleEnChange={newValue => {
                  setNewTarget({
                    ...newTarget,
                    content: {
                      ...newTarget?.content,
                      en: newValue
                    }
                  });
                }}
                handleArChange={newValue => {
                  setNewTarget({
                    ...newTarget,
                    content: {
                      ...newTarget?.content,
                      ar: newValue
                    }
                  });
                }}
              />
              {!isReadOnly && (
                <Flex className="mt-2 gap-1">
                  <Button
                    size="sm"
                    onClick={() => handleSave('target')}
                    type="button"
                    disabled={
                      !newTarget?.content?.ar && !newTarget?.content?.en
                    }
                  >
                    {infoUpdateLoading && newItemType === 'target' && (
                      <Spinner size="sm" />
                    )}
                    {!infoUpdateLoading &&
                      newItemType !== 'target' &&
                      t('common:button.save')}
                  </Button>
                  {(newTarget?.content?.ar || newTarget?.content?.en) && (
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={() => setNewTarget(null)}
                    >
                      {t('common:button.cancel')}
                    </Button>
                  )}
                </Flex>
              )}
            </Form.Group>
          )}
        </Card.Body>
      </Card>
      <Card className="mb-3">
        <Card.Header as="h5" className="bg-light">
          {t('course:labels.requirementsTitle')}
        </Card.Header>
        <Card.Body className="pt-0 p-all-m-0">
          {!!infoItems['requirement'] && (
            <Flex alignItems="start" className={`hover-actions-trigger py-3`}>
              {/* <FontAwesomeIcon
                        icon={faDotCircle}
                        className="fs--2 text-secondary me-3 mt-1"
                      /> */}
              <p
                className="fs--1 mb-0 me-6"
                dangerouslySetInnerHTML={{
                  __html: infoItems['requirement'].content[currentLanguage]
                }}
              ></p>
              {!isReadOnly && (
                <div
                  className="hover-actions end-0 translate-middle-y"
                  style={{ top: '10%' }}
                >
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="editRequirementTooltip">
                        {' '}
                        {t('common:tooltips.edit')}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="link"
                      type="button"
                      onClick={() =>
                        handleEditClick(infoItems['requirement'], 'requirement')
                      }
                      className="d-flex align-items-center fs--1 text-700 p-0 me-3"
                    >
                      <FontAwesomeIcon icon="pencil-alt" />
                    </Button>
                  </OverlayTrigger>
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="removeRequirementTooltip">
                        {' '}
                        {t('common:tooltips.delete')}
                      </Tooltip>
                    }
                  >
                    <Button
                      variant="spark-default"
                      type="button"
                      size="sm"
                      onClick={() => handleDeleteItem(infoItems['requirement'])}
                      className="icon-item icon-item-sm rounded-3 fs--2"
                    >
                      <FontAwesomeIcon icon="times" />
                    </Button>
                  </OverlayTrigger>
                </div>
              )}
            </Flex>
          )}
          {(!infoItems['requirement'] || openNewRequirement) && !isReadOnly && (
            <Form.Group
              controlId="courseRequirement"
              className="position-relative mt-2"
            >
              <MultiLanguageRTE
                t={t}
                enableFullscreen
                activeLanguage={currentLanguage}
                valueEn={newRequirement?.content?.en || ''}
                valueAr={newRequirement?.content?.ar || ''}
                errorEn={!!errors?.requirement?.en}
                errorAr={!!errors?.requirement?.ar}
                errorArMessage={t('common:validation.fieldIsRequired')}
                errorEnMessage={t('common:validation.fieldIsRequired')}
                placeholderEn={t('course:placeholders.addNewItem')}
                placeholderAr={t('course:placeholders.addNewItem')}
                handleEnChange={newValue => {
                  setNewRequirement({
                    ...newRequirement,
                    content: {
                      ...newRequirement?.content,
                      en: newValue
                    }
                  });
                }}
                handleArChange={newValue => {
                  setNewRequirement({
                    ...newRequirement,
                    content: {
                      ...newRequirement?.content,
                      ar: newValue
                    }
                  });
                }}
              />

              <Flex className="mt-2 gap-1">
                <Button
                  size="sm"
                  onClick={() => handleSave('requirement')}
                  type="button"
                  disabled={
                    !newRequirement?.content?.ar && !newRequirement?.content?.en
                  }
                >
                  {infoUpdateLoading && newItemType === 'requirement' && (
                    <Spinner size="sm" />
                  )}
                  {!infoUpdateLoading &&
                    newItemType !== 'requirement' &&
                    t('common:button.save')}
                </Button>
                {(newRequirement?.content?.ar ||
                  newRequirement?.content?.en) && (
                  <Button
                    variant="danger"
                    size="sm"
                    onClick={() => setNewRequirement(null)}
                  >
                    {t('common:button.cancel')}
                  </Button>
                )}
              </Flex>
            </Form.Group>
          )}
        </Card.Body>
      </Card>
    </>
  );
};

export default CourseRequirements;
