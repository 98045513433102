import React from 'react';
const LinearIndeterminateProgressBar = ({ className }) => {
  return (
    <div className={`linearIndeterminateProgress ${className || ''}`}>
      <div className="linearIndeterminateProgressBar"></div>
    </div>
  );
};

export default LinearIndeterminateProgressBar;
