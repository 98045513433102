import { matchRoutes, useLocation } from 'react-router-dom';

const useCurrentPath = routes => {
  const location = useLocation();
  const routesMatched = matchRoutes(routes, location);

  return routesMatched?.[0] || {};
};

export default useCurrentPath;
