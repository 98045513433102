import React from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';

import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import StaticPagesService from 'http/StaticPagesService';
import { toStaticPageFormSchema } from './utils';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import ConfirmModal from 'components/common/ConfirmModal';

const initPage = {
  title:null,
  keywords: null,
  description: null,
  content: null
};

const optionalFields = ['pageKeywordsAr', 'pageKeywordsEn','content'];

const CreateStaticPage = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const [page, setPage] = useState(initPage);
  const [pageValues, setPageValues] = useState(null);
  const [formKey, setFormKey] = useState(Date.now()); // Create a state variable to force re-render
  const [errors, setErrors] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);

  const {
    isLoading: staticPageLoading,
    error: staticPageError,
    data: staticPageData,
    refetch: fetchStaticPage
  } = useQuery(['StaticPage', id], () => StaticPagesService.get(id), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false,
    enabled: false
  });

  useEffect(() => {
    if (id) {
      fetchStaticPage(id);
    } else {
      setPageValues(toStaticPageFormSchema({}));
    }
  }, [id]);

  const {
    mutate: createStaticPage,
    error: staticPageCreateError,
    isLoading: staticPageCreateLoading
  } = useMutation({
    mutationFn: StaticPagesService.save,
    onSuccess: data => onCreateStaticPageSuccess(data)
  });
  
  const {
    mutate: updateStaticPage,
    error: planUpdateError,
    isLoading: planUpdateLoading
  } = useMutation({
    mutationFn: StaticPagesService.update,
    onSuccess: data => onUpdateStaticPageSuccess(data)
  });
  const onUpdateStaticPageSuccess = response => {
    toast.success(
      t(`common:message.${!page?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
    if (typeof response?.data.items === 'string' && !page?.id) {
      navigate(`/countries/create/${response.data}`);
    } 
  };
  const fillInitialData = data => {
    if (!data) {
      return;
    }
    const formattedData = toStaticPageFormSchema(data);
    //console.log('formattedData', formattedData);
    setPageValues(formattedData);
    setFormKey(Date.now());
  };

  useEffect(() => {
    if (staticPageData && !staticPageLoading && !staticPageError) {
      if (!staticPageData?.data) {
        toast.error(t('error:404'), {
          theme: 'colored',
          autoClose: false
        });
        navigate(`/contents/pages`);
      }
      setPage(staticPageData?.data || null);
      fillInitialData(staticPageData?.data || null);
    }
    if (staticPageError) {
      toast.error(getErrorMessage(t, staticPageError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [staticPageLoading, staticPageError, staticPageData]);

  const onSubmit = () => {
    const isValid = validateForm(pageValues);
    if (!isValid) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    const values = { ...pageValues };

    let createPayload = {
      title:values.title ,
      keywords: values.keywords,
      description: values.description,
      content: values.content
    };
    if (page?.id) {
      createPayload['id'] = page?.id;
      updateStaticPage(createPayload);
    }else{
      createStaticPage(createPayload);
    }
  };
  const onCreateStaticPageSuccess = response => {
    //console.log('response', response);   ///,kjnlk;lk;l;kmlk;
    if (typeof response?.data.items === 'string' && !page?.id) {
      navigate(`/contents/pages/create/${response.data.items}`);
    }
    toast.success(
      t(`common:message.${!page?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
  };

  const validateForm = data => {
    let valid = true;
    let errorsObj = {};
    if (!data) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return false;
    }
    Object.entries(data).map(([key, value]) => {
      if (!value && !optionalFields.includes(key)) {
        errorsObj[key] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
        valid = false;
      } else {
        if (key === 'pageTitleAr') {
          if (value?.length > 60) {
            errorsObj['pageTitleAr'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else if (key === 'pageTitleEn') {
          if (value?.length > 60) {
            errorsObj['pageTitleEn'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else if (key === 'pageKeywordsAr') {
          if (value?.length > 60) {
            errorsObj['pageKeywordsAr'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else if (key === 'pageKeywordsEn') {
          if (value?.length > 60) {
            errorsObj['pageKeywordsEn'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else {
          errorsObj[key] = null;
        }
      }
    });
    setErrors(errorsObj);
    console.log('onError', errorsObj);
    return valid;
  };

  const handleChange = (value, name) => {
    setErrors({ ...errors, [name]: null });
    setPageValues({ ...pageValues, [name]: value });
  };

  const onCancel = () => {
    if (isEdit) {
      fillInitialData(page);
      setIsEdit(false);
      return;
    }
    // console.log('page', toStaticPageFormSchema(page));
    // console.log('values', { ...pageValues });
    const hasChanges =
      JSON.stringify({ ...toStaticPageFormSchema(page) }) !==
      JSON.stringify({ ...pageValues });
    if (hasChanges) {
      setConfirmModalProps({
        isOpen: true,
        onClose: () => setConfirmModalProps(null),
        onConfirm: () => navigate('/contents/pages'),
        message: t('staticPages:message.discardPageConfirm'),
        header: t('staticPages:message.discardPageTitle'),
        actionButtonColor: 'danger',
        actionButtonText: t('common:button.discard')
      });
      return;
    }
    navigate('/contents/pages');
  };

  useEffect(() => {
    if (staticPageCreateError) {
      toast.error(getErrorMessage(t, staticPageCreateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [staticPageCreateError]);

  if (staticPageLoading) {
    return (
      <LoaderWithMessage message={t('staticPages:message.loadingStaticPage')} />
    );
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h5">
        {!!id
          ? page?.title?.[currentLanguage]
          : t('staticPages:labels.newStaticPage')}
      </Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3" key={formKey}>
          <Col md="6">
            <Form.Group controlId="pageTitle">
              <Form.Label>
                {`${t('staticPages:labels.title')} (${t(
                  'common:language.en.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="title"
                required
                placeholder={t('staticPages:labels.title')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={pageValues?.title || ''}
                disabled={
                  staticPageCreateLoading ||
                  staticPageLoading ||
                  (!!id && !isEdit)
                }
                isInvalid={!!errors?.title?.error}
              />
              {!!errors?.title?.error && (
                <Form.Text className="text-danger">
                  {errors?.title?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="pageKeywords">
              <Form.Label>
                {`${t('staticPages:labels.keywords')} (${t(
                  'common:language.en.name'
                )})`}
                <small>
                  {` (${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="keywords"
                required
                placeholder={t('staticPages:labels.keywords')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={pageValues?.keywords || ''}
                disabled={
                  staticPageCreateLoading ||
                  staticPageLoading ||
                  (!!id && !isEdit)
                }
                isInvalid={!!errors?.keywords?.error}
              />
              {!!errors?.keywords?.error && (
                <Form.Text className="text-danger">
                  {errors?.keywords?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId="pageDescription">
              <Form.Label>
                {`${t('staticPages:labels.description')} (${t(
                  'common:language.en.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 150 })})`}
                </small>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="description"
                required
                placeholder={t('staticPages:labels.description')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={pageValues?.description || ''}
                disabled={
                  staticPageCreateLoading ||
                  staticPageLoading ||
                  (!!id && !isEdit)
                }
                isInvalid={!!errors?.description}
              />
              {!!errors?.description?.error && (
                <Form.Text className="text-danger">
                  {errors?.description?.message}
                </Form.Text>
              )}
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer>
        {!id && (
          <>
            <Button size="sm" onClick={onSubmit}>
              {staticPageCreateLoading && <Spinner size="sm" />}
              {!staticPageCreateLoading && t('common:button.save')}
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
            >
              {!staticPageCreateLoading && t('common:button.cancel')}
            </Button>
          </>
        )}
        {isEdit && !!id && (
          <>
            <Button
              size="sm"
              onClick={onSubmit}
              disabled={!!staticPageCreateLoading || staticPageLoading}
            >
              {staticPageCreateLoading && <Spinner size="sm" />}
              {!staticPageCreateLoading && t('common:button.save')}
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
              disabled={!!staticPageCreateLoading || staticPageLoading}
            >
              {t('common:button.cancel')}
            </Button>
          </>
        )}
        {!isEdit && !!id && (
          <>
            <Button
              size="sm"
              variant="info"
              onClick={() => setIsEdit(true)}
              disabled={!!staticPageCreateLoading || staticPageLoading}
            >
              {t('common:button.edit')}
            </Button>
          </>
        )}
      </Card.Footer>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={false} />
      )}
    </Card>
  );
};
export default CreateStaticPage;
