import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Image,
  OverlayTrigger,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';

const ImageCard = ({
  title,
  tooltip,
  onFileChange,
  url,
  loading,
  baseUrl,
  isReadOnly,
  hidden
}) => {
  const { t } = useTranslation();
  const [image, setImage] = useState(null);
  const [imageHovered, setImageHovered] = useState(false);

  useEffect(() => {
    if (url) {
      const name = url.split('/')[url.split('/').length - 1];
      const source = `${baseUrl || ''}${url}`;
      setImage({
        preview: source,
        path: name
      });
    }
  }, [url]);

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept: 'image/*',
    onDrop: async acceptedFiles => {
      setImage(
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0])
        })
      );
      onFileChange && onFileChange(acceptedFiles[0]);
    }
  });

  if (hidden) {
    return '';
  }

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="mb-0 d-inline-block">
          {title || 'Upload Image'}
          {!!tooltip && (
            <OverlayTrigger
              overlay={<Tooltip id="coverPphotoTooltip">{tooltip}</Tooltip>}
            >
              <span className="ms-2 text-primary fs-0">
                <FontAwesomeIcon icon="info-circle" />
              </span>
            </OverlayTrigger>
          )}
        </h5>
      </Card.Header>
      <Card.Body className="bg-light">
        {!isReadOnly && (
          <div
            {...getRootProps({ className: !image ? 'dropzone-area py-6' : '' })}
          >
            <input {...getInputProps({ multiple: false })} />
            {!image && (
              <div className="fs--1">
                <img src={cloudUpload} alt="" width={20} className="me-2" />
                <span className="d-none d-lg-inline">
                  {t('common:labels.dragYourImageHere')}
                  <br />
                  {t('common:labels.or')},{' '}
                </span>
                <Button variant="link" size="sm" className="p-0 fs--1">
                  {t('common:labels.browse')}
                </Button>
              </div>
            )}
          </div>
        )}
        {image && (
          <Flex
            className="position-relative"
            style={{ zIndex: 9 }}
            onMouseEnter={() => setImageHovered(true)}
            onMouseLeave={() => setImageHovered(false)}
          >
            <Image
              rounded
              width="100%"
              height="100%"
              src={image.preview}
              alt={image.path}
            />
            {(imageHovered || loading) && !isReadOnly && (
              <Flex
                direction="column"
                justifyContent="center"
                alignItems="center"
                className="position-absolute w-100 h-100 cursor-pointer"
                style={{
                  left: 0,
                  right: 0,
                  top: 0,
                  zIndex: 10,
                  background: '#36454F',
                  opacity: 0.75
                }}
              >
                {loading && <Spinner />}
                {!loading && (
                  <Button
                    onClick={() => {
                      inputRef?.current?.click();
                    }}
                    variant="primary"
                  >
                    {t('common:labels.replace')}
                  </Button>
                )}
                {/* <Button variant="danger" className="mt-2">
                  {t('common:button.delete')}
                </Button> */}
              </Flex>
            )}
          </Flex>
        )}
      </Card.Body>
    </Card>
  );
};
export default ImageCard;
