import {
  faEdit,
  faQuestionCircle,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { truncateString } from 'helpers/utils';
import React from 'react';
import { OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const CurriculumQuizItem = ({
  t,
  chapter,
  breakpoints,
  currentLanguage,
  isReadOnly,
  setQuizToEdit,
  handleClickDeleteQuiz
}) => {
  return (
    <Row className="gx-0 gy-1">
      <div className="d-flex align-items-center">
        <Flex
          key={`quiz-content-${chapter?.quiz?.id}`}
          gap="2"
          className={`w-100 ${
            breakpoints.down('md') ? 'mx-1' : 'ms-2'
          } p-2 border-grey border-1 border rounded`}
          alignItems="center"
          justifyContent="between"
        >
          <Flex
            className={`w-75  `}
            alignItems="center"
            justifyContent="start"
            gap="2"
          >
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={'tooltip-quiz-' + chapter?.quiz?.id}>
                  {t('course:labels.quiz')}
                </Tooltip>
              }
            >
              <span className="mx-1 fs-0">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </span>
            </OverlayTrigger>
            <p className="mx-2 mb-0 text-nowrap">
              {truncateString(
                chapter?.quiz?.title?.[currentLanguage],
                breakpoints.down('md') ? 15 : 150
              )}
            </p>
          </Flex>
          {!isReadOnly && (
            <Flex
              gap="2"
              className="w-100"
              alignItems="center"
              justifyContent="end"
            >
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={'tooltip-edit-lesson-' + chapter?.quiz?.id}>
                    {t('common:button.edit')}
                  </Tooltip>
                }
              >
                <span className="ms-2 text-primary fs-0">
                  <FontAwesomeIcon
                    icon={faEdit}
                    size="sm"
                    className="cursor-pointer"
                    onClick={() =>
                      setQuizToEdit({
                        quiz: chapter?.quiz,
                        chapter
                      })
                    }
                  />
                </span>
              </OverlayTrigger>
              <OverlayTrigger
                placement="top"
                overlay={
                  <Tooltip id={'tooltip-delete-lesson-' + chapter?.quiz?.id}>
                    {t('common:button.delete')}
                  </Tooltip>
                }
              >
                <span className="ms-2 text-danger fs-0">
                  <FontAwesomeIcon
                    icon={faTrash}
                    size="sm"
                    className="cursor-pointer"
                    onClick={() =>
                      handleClickDeleteQuiz({
                        id: chapter?.quiz?.id,
                        chapterId: chapter.id
                      })
                    }
                  />
                </span>
              </OverlayTrigger>
            </Flex>
          )}
        </Flex>
      </div>
    </Row>
  );
};

export default CurriculumQuizItem;
