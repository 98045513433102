import React, { useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import BlogBasicInformation from './components/BlogBasicInformation';
import BlogWriter from './components/BlogWriter';
import BlogsService from 'http/BlogsService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import ImageCard from 'components/common/ImageCard';
import { convertToUniqueName, getFileExtensionFromUrl } from 'helpers/utils';
import { useContext } from 'react';
import { BlogContext } from 'context/Context';
import { validateBlogBasicForm } from './utils';
import BlogReferences from './components/BlogReferences';
import RecommendedCourses from './components/RecommendedCourses';
import BlogActions from './components/BlogActions';

const CreateBlog = () => {
  const { t } = useTranslation();
  const { blog, setSectionValidation, fetchBlog, isReadOnly } =
    useContext(BlogContext);

  const {
    mutate: updateBlogImage,
    error: updateBlogImageError,
    isLoading: updateBlogImageLoading
  } = useMutation({
    mutationFn: BlogsService.uploadImage,
    onSuccess: data => onUpdateBlogImageSuccess(data)
  });

  const onUpdateBlogImageSuccess = () => {
    fetchBlog(blog.id);
    toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
  };

  const handleBlogImageChange = file => {
    let data = new FormData();
    data.append('id', blog.id);
    data.append(
      'file',
      file,
      `${convertToUniqueName(blog?.title?.en)}.${getFileExtensionFromUrl(
        file.name
      )}`
    );
    updateBlogImage(data);
  };

  useEffect(() => {
    if (updateBlogImageError) {
      toast.error(getErrorMessage(t, updateBlogImageError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [updateBlogImageError]);

  useEffect(() => {
    if (!!blog?.id && blog?.status?.code === 'draft') {
      let validations = {};
      const isValid = validateBlogBasicForm(blog);
      validations = {
        ...validations,
        basicForm: {
          isValid,
          message: isValid ? null : t('blogs:validation.message.basicForm')
        }
      };
      if (!blog?.image) {
        validations = {
          ...validations,
          image: {
            isValid: false,
            message: t('blogs:validation.message.image')
          }
        };
      } else {
        validations = {
          ...validations,
          image: {
            isValid: true,
            message: null
          }
        };
      }

      if (!blog?.writerName) {
        validations = {
          ...validations,
          writerName: {
            isValid: false,
            message: t('blogs:validation.message.writerName')
          }
        };
      } else {
        validations = {
          ...validations,
          writerName: {
            isValid: true,
            message: null
          }
        };
      }

      if (!blog?.writerImage) {
        validations = {
          ...validations,
          writerImage: {
            isValid: false,
            message: t('blogs:validation.message.writerImage')
          }
        };
      } else {
        validations = {
          ...validations,
          writerImage: {
            isValid: true,
            message: null
          }
        };
      }
      //console.log('blog validations:::', validations);
      setSectionValidation(validations);
    }
  }, [blog]);

  return (
    <Row className="g-lg-3 font-sans-serif">
      <Col lg={8}>
        <BlogBasicInformation />
        {!!blog?.id && <BlogReferences />}
        {!!blog?.id && <RecommendedCourses />}
      </Col>
      {!!blog?.id && (
        <Col lg={4}>
          <BlogActions />
          <ImageCard
            onFileChange={file => handleBlogImageChange(file)}
            url={blog?.image || null}
            title={t('blogs:labels.blogImage')}
            tooltip={t('blogs:labels.uploadBlogImage')}
            loading={updateBlogImageLoading}
            baseUrl={process.env.REACT_APP_FILES_URL}
            isReadOnly={isReadOnly}
            hidden={!blog?.id}
          />
          <BlogWriter />
        </Col>
      )}
    </Row>
  );
};

export default CreateBlog;
