import React from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';

import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { toDiscountFormSchema } from './utils';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import ConfirmModal from 'components/common/ConfirmModal';
import DiscountsService from 'http/DiscountsService';
import AsyncSelect from 'components/common/AsyncSelect';
import CourseService from 'http/CourseService';
import LearningPathService from 'http/LearningPathService';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import DiscountActions from './components/DiscountActions';

const types = [
  { label: 'offer', value: 'offer' },
  { label: 'discount', value: 'discount' }
];

const targets = [
  { label: 'all', value: 'all' },
  { label: 'courses', value: 'courses' },
  { label: 'learningPaths', value: 'learningPaths' }
];

const initDiscount = {
  id: '',
  type: '',
  customTargets: null,
  target: null,
  appliedForAll: 'all',
  discountPercentage: null,
  cutomePrice: null
};

const CreateDiscount = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const [discount, setDiscount] = useState(initDiscount);
  const [discountValues, setDiscountValues] = useState(null);
  const [formKey, setFormKey] = useState(Date.now()); // Create a state variable to force re-render
  const [errors, setErrors] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [selectedEntites, setSelectedEntites] = useState([]);

  const {
    isLoading: discountLoading,
    error: discountError,
    data: discountData,
    refetch: fetchDiscount
  } = useQuery(['Discount', id], () => DiscountsService.get(id), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false,
    enabled: false
  });

  useEffect(() => {
    console.log('id', id);
    if (id) {
      fetchDiscount(id);
    } else {
      fillInitialData(initDiscount);
    }
  }, [id]);

  const {
    mutate: createDiscount,
    error: discountCreateError,
    isLoading: discountCreateLoading
  } = useMutation({
    mutationFn: DiscountsService.save,
    onSuccess: data => onCreateDiscountSuccess(data)
  });

  const fillInitialData = data => {
    if (!data) {
      return;
    }
    const formattedData = toDiscountFormSchema(data);
    //console.log('formattedData', formattedData);
    setDiscountValues(formattedData);
    setFormKey(Date.now());
  };

  useEffect(() => {
    if (discountData && !discountLoading && !discountError) {
      if (!discountData?.payload) {
        toast.error(t('error:404'), {
          theme: 'colored',
          autoClose: false
        });
        navigate(`/contents/discounts`);
      }
      setDiscount(discountData?.payload || null);
      fillInitialData(discountData?.payload || null);
      if (discountData?.payload?.customTargets) {
        setSelectedEntites(discountData?.payload?.customTargets);
      }
    }
    if (discountError) {
      toast.error(getErrorMessage(t, discountError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [discountLoading, discountError, discountData]);

  const onSubmit = () => {
    const isValid = validateForm(discountValues);
    if (!isValid) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    const values = { ...discountValues };

    let createPayload = {
      ...values,
      appliedForAll: discountValues?.appliedForAll === 'all' ? true : false,
      customTargets:
        selectedEntites?.map(e => ({ id: e.id, title: e.title })) || []
    };
    if (discount?.id) {
      createPayload['id'] = discount?.id;
    }
    console.log('payload', createPayload);
    createDiscount(createPayload);
  };
  const onCreateDiscountSuccess = response => {
    //console.log('response', response);   ///,kjnlk;lk;l;kmlk;
    if (typeof response?.payload === 'string' && !discount?.id) {
      navigate(`/discounts/create/${response.payload}`);
    }
    setIsEdit(false);
    setErrors(null);
    toast.success(
      t(`common:message.${!discount?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
  };

  const validateForm = discountValues => {
    let valid = true;
    let errors = {};
    if (!discountValues) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return false;
    }
    if (!discountValues?.type) {
      errors['type'] = {
        error: true,
        message: t('discounts:validation.pleaseSelectTheDiscountType')
      };
    } else {
      errors['type'] = {
        error: false,
        message: null
      };
    }
    if (!discountValues?.target) {
      errors['target'] = {
        error: true,
        message: t('discounts:validation.pleaseSelectTheDiscountTarget')
      };
    } else {
      errors['target'] = {
        error: false,
        message: null
      };
    }
    if (discountValues?.target !== 'all' && !discountValues?.appliedForAll) {
      errors['appliedForAll'] = {
        error: true,
        message: t(
          'discounts:validation.pleaseSelectWhatThisDiscountAppliedFor'
        )
      };
    } else {
      errors['appliedForAll'] = {
        error: false,
        message: null
      };
    }
    if (
      discountValues?.target !== 'all' &&
      discountValues?.appliedForAll === 'custom' &&
      !selectedEntites?.length
    ) {
      errors['customTargets'] = {
        error: true,
        message: t('discounts:validation.pleaseSelectTheDiscountCustomTargets')
      };
    } else {
      errors['customTargets'] = {
        error: false,
        message: null
      };
    }
    if (
      !discountValues?.type === 'offer' &&
      isNaN(discountValues?.customPrice)
    ) {
      errors['customPrice'] = {
        error: true,
        message: t('discounts:validation.pleaseSelectTheOfferCustomPrice')
      };
    } else {
      errors['customPrice'] = {
        error: false,
        message: null
      };
    }
    if (
      !discountValues?.type === 'discount' &&
      isNaN(discountValues?.discountPercentage)
    ) {
      errors['discountPercentage'] = {
        error: true,
        message: t('discounts:validation.pleaseSelectTheDiscountPercentage')
      };
    } else {
      errors['discountPercentage'] = {
        error: false,
        message: null
      };
    }
    valid = !Object.entries(errors)
      // eslint-disable-next-line
      .map(([key, value]) => value?.error)
      .some(s => !!s);
    console.log('discountValues', discountValues);
    setErrors(errors);
    console.log('onError', errors);
    return valid;
  };

  const handleChange = e => {
    if (
      e.target.getAttribute('data-type') === 'number' &&
      !!e.target.value &&
      isNaN(e.target.value)
    ) {
      return;
    }
    if (
      e.target.name === 'discountPercentage' &&
      Number(e.target.value) > 100
    ) {
      return;
    }

    setDiscountValues({ ...discountValues, [e.target.name]: e.target.value });
    if (!!e.target.value) {
      setErrors({
        ...errors,
        [name]: { error: false }
      });
    }
  };
  const handleEntitiesChange = option => {
    setSelectedEntites([...selectedEntites, option]);
    if (!![...selectedEntites, option]?.length) {
      setErrors({
        ...errors,
        customTargets: { error: false }
      });
    }
  };

  const onCancel = () => {
    if (isEdit) {
      fillInitialData(discount);
      setIsEdit(false);
      return;
    }
    console.log('discount', toDiscountFormSchema(discount));
    console.log('values', { ...discountValues });
    const hasChanges =
      JSON.stringify({ ...toDiscountFormSchema(discount) }) !==
      JSON.stringify({ ...discountValues });
    if (hasChanges) {
      setConfirmModalProps({
        isOpen: true,
        onClose: () => setConfirmModalProps(null),
        onConfirm: () => navigate('/discounts'),
        message: t('discounts:message.discardDiscountConfirm'),
        header: t('discounts:message.discardDiscountTitle'),
        actionButtonColor: 'danger',
        actionButtonText: t('common:button.discard')
      });
      return;
    }
    navigate('/contents/discounts');
  };

  useEffect(() => {
    if (discountCreateError) {
      toast.error(getErrorMessage(t, discountCreateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [discountCreateError]);

  const asyncRequest = (target, searchTerm) => {
    const service = target === 'courses' ? CourseService : LearningPathService;
    return new Promise(resolve => {
      service
        .list({
          pageNumber: 1,
          pageSize: 10,
          query: {
            term: searchTerm,
            conjunction: 'or',
            properties: [
              'title.ar',
              'title.en',
              'subtitle.ar',
              'subtitle.en',
              'description.ar',
              'description.en'
            ]
          }
        })
        .then(response => {
          console.log('response', response);
          if (response?.payload?.data) {
            resolve(response.payload.data);
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          resolve([]);
          toast.error(getErrorMessage(t, error), {
            theme: 'colored',
            autoClose: false
          });
        });
    });
  };

  const columns = [
    {
      accessor: 'type',
      Header: t('discounts:labels.title'),
      disableSortBy: true,
      headerProps: {
        className: 'px-3',
        style: {
          width: '150px',
          height: '46px'
        }
      },
      cellProps: {
        className: 'py-2 white-space-nowrap px-3 pe-xxl-4'
      },
      Cell: rowData => {
        const { title } = rowData.row.original;
        return (
          <p className="fw-semi-bold m-0 mt-2 mb-0">
            {title?.[currentLanguage]}
          </p>
        );
      }
    },
    {
      accessor: 'actions',
      Header: '',
      disableSortBy: true,
      headerProps: {
        className: 'px-3'
      },
      cellProps: {
        className: 'py-2 white-space-nowrap px-3 pe-xxl-4'
      },
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <FontAwesomeIcon
            icon={faTrash}
            variant="danger"
            color="danger"
            className="cursor-pointer"
            onClick={() =>
              (!!isEdit || !id) &&
              setSelectedEntites(selectedEntites.filter(e => e.id !== id))
            }
          />
        );
      }
    }
  ];

  if (discountLoading) {
    return (
      <LoaderWithMessage message={t('discounts:message.loadingDiscount')} />
    );
  }

  return (
    <>
      <Row className="g-lg-3 font-sans-serif">
        <Col lg={8}>
          <Card className="mb-3">
            <Card.Header as="h5">{t('discounts:labels.discount')}</Card.Header>
            <Card.Body className="bg-light">
              <Row className="gx-2 gy-3" key={formKey}>
                <Col md="6">
                  <Form.Group controlId="type">
                    <Form.Label>
                      {`${t('discounts:labels.type')}`}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="type"
                      required
                      placeholder={t('discounts:labels.type')}
                      onChange={handleChange}
                      value={discountValues?.type || ''}
                      disabled={
                        discountCreateLoading ||
                        discountLoading ||
                        (!!id && !isEdit)
                      }
                      isInvalid={!!errors?.type?.error}
                    >
                      <option value="">
                        {t('discounts:placeholders.selectType')}
                      </option>
                      {types.map(type => {
                        return (
                          <option key={type.value} value={type.value}>
                            {t(`discounts:type.${type.label}`)}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {!!errors?.type?.error && (
                      <Form.Text className="text-danger">
                        {errors?.type?.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>

                <Col md="6">
                  <Form.Group controlId="target">
                    <Form.Label>
                      {`${t('discounts:labels.target')}`}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Select
                      name="target"
                      required
                      placeholder={t('discounts:labels.target')}
                      onChange={handleChange}
                      value={discountValues?.target || ''}
                      disabled={
                        discountCreateLoading ||
                        discountLoading ||
                        (!!id && !isEdit)
                      }
                      isInvalid={!!errors?.target?.error}
                    >
                      <option value="">
                        {t('discounts:placeholders.selectTarget')}
                      </option>
                      {targets.map(target => {
                        return (
                          <option key={target.value} value={target.value}>
                            {t(`discounts:target.${target.label}`)}
                          </option>
                        );
                      })}
                    </Form.Select>
                    {!!errors?.target?.error && (
                      <Form.Text className="text-danger">
                        {errors?.target?.message}
                      </Form.Text>
                    )}
                  </Form.Group>
                </Col>
                {!!discountValues?.target &&
                  discountValues?.target !== 'all' && (
                    <Col md="12">
                      <Form.Group controlId="custom">
                        <Form.Label>
                          {`${t('discounts:labels.isCustom')}`}
                          <span className="text-danger">*</span>
                        </Form.Label>
                        <Form.Select
                          name="appliedForAll"
                          required
                          placeholder={t('discounts:labels.isCustom')}
                          onChange={handleChange}
                          value={discountValues?.appliedForAll}
                          disabled={
                            discountCreateLoading ||
                            discountLoading ||
                            (!!id && !isEdit) ||
                            discountValues?.target === 'all'
                          }
                          isInvalid={!!errors?.appliedForAll?.error}
                        >
                          <option value="">
                            {t('discounts:placeholders.isCustom')}
                          </option>
                          <option value="all">{`${t(
                            'discounts:labels.' +
                              (discountValues?.target === 'courses'
                                ? 'allCourses'
                                : 'allLearningPaths')
                          )}`}</option>
                          <option value="custom">
                            {t('discounts:labels.custom')}
                          </option>
                        </Form.Select>
                        {!!errors?.appliedForAll?.error && (
                          <Form.Text className="text-danger">
                            {errors?.appliedForAll?.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                {!!discountValues?.target &&
                  discountValues?.target !== 'all' &&
                  discountValues?.appliedForAll !== 'all' && (
                    <Col md="12">
                      <Form.Group controlId="customTargets">
                        <Form.Label>
                          {`${t('discounts:labels.' + discountValues?.target)}`}
                        </Form.Label>
                        <AsyncSelect
                          asyncRequest={search =>
                            asyncRequest(discountValues?.target, search)
                          }
                          defaultOptions={[]}
                          placeholder={t(
                            'learningPath:placeholders.selectCourse'
                          )}
                          classNamePrefix="react-select"
                          value={null}
                          onChange={handleEntitiesChange}
                          getOptionLabel={option =>
                            option.title?.[currentLanguage]
                          }
                          getOptionValue={option => option.id}
                          invalid={!!errors?.customTargets?.error}
                          isDisabled={
                            discountCreateLoading ||
                            discountLoading ||
                            (!!id && !isEdit)
                          }
                        />
                        {!!errors?.customTargets?.error && (
                          <Form.Text className="text-danger">
                            {errors?.customTargets?.message}
                          </Form.Text>
                        )}
                      </Form.Group>
                    </Col>
                  )}
                {discountValues?.type === 'offer' && (
                  <Col md="12">
                    <Form.Group controlId="customPrice">
                      <Form.Label>
                        {`${t('discounts:labels.customPrice')}`}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        name="customPrice"
                        required
                        data-type="number"
                        placeholder={t('discounts:labels.customPrice')}
                        onChange={handleChange}
                        value={discountValues?.customPrice || ''}
                        disabled={
                          discountCreateLoading ||
                          discountLoading ||
                          (!!id && !isEdit) ||
                          discountValues?.type !== 'offer'
                        }
                        isInvalid={!!errors?.customPrice?.error}
                      />
                      {!!errors?.customPrice?.error && (
                        <Form.Text className="text-danger">
                          {errors?.customPrice?.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                )}
                {discountValues?.type === 'discount' && (
                  <Col md="12">
                    <Form.Group controlId="discountPercentage">
                      <Form.Label>
                        {`${t('discounts:labels.discountPercentage')}`}
                        <span className="text-danger">*</span>
                      </Form.Label>
                      <Form.Control
                        name="discountPercentage"
                        required
                        data-type="number"
                        placeholder={t('discounts:labels.discountPercentage')}
                        onChange={handleChange}
                        value={discountValues?.discountPercentage || ''}
                        disabled={
                          discountCreateLoading ||
                          discountLoading ||
                          (!!id && !isEdit) ||
                          discountValues?.type !== 'discount'
                        }
                        isInvalid={!!errors?.discountPercentage?.error}
                      />
                      {!errors?.discountPercentage?.error && (
                        <Form.Text className="text-danger">
                          {errors?.discountPercentage?.message}
                        </Form.Text>
                      )}
                    </Form.Group>
                  </Col>
                )}
              </Row>
            </Card.Body>

            <Card.Footer>
              {!id && (
                <>
                  <Button size="sm" onClick={onSubmit}>
                    {discountCreateLoading && <Spinner size="sm" />}
                    {!discountCreateLoading && t('common:button.save')}
                  </Button>
                  <Button
                    size="sm"
                    variant="danger"
                    onClick={onCancel}
                    className="mx-2"
                  >
                    {!discountCreateLoading && t('common:button.cancel')}
                  </Button>
                </>
              )}
              {isEdit && !!id && (
                <>
                  <Button
                    size="sm"
                    onClick={onSubmit}
                    disabled={!!discountCreateLoading || discountLoading}
                  >
                    {discountCreateLoading && <Spinner size="sm" />}
                    {!discountCreateLoading && t('common:button.save')}
                  </Button>

                  <Button
                    size="sm"
                    variant="danger"
                    onClick={onCancel}
                    className="mx-2"
                    disabled={!!discountCreateLoading || discountLoading}
                  >
                    {t('common:button.cancel')}
                  </Button>
                </>
              )}
              {!isEdit && !!id && (
                <>
                  <Button
                    size="sm"
                    variant="info"
                    onClick={() => setIsEdit(true)}
                    disabled={!!discountCreateLoading || discountLoading}
                  >
                    {t('common:button.edit')}
                  </Button>
                </>
              )}
            </Card.Footer>
            {!!confirmModalProps && (
              <ConfirmModal {...confirmModalProps} loading={false} />
            )}
          </Card>
        </Col>
        <Col lg={4}>
          <DiscountActions discount={discount} refetch={fetchDiscount} />
        </Col>
      </Row>
      {!!discountValues?.target &&
        !!discountValues?.appliedForAll &&
        discountValues?.appliedForAll !== 'all' && (
          <Row>
            <Col md={8}>
              <Card>
                <Card.Header className="border-bottom border-200 ">
                  {`${t('discounts:labels.targets.' + discountValues?.target)}`}
                </Card.Header>
                <Card.Body className="p-0">
                  <AdvanceTableWrapper
                    columns={columns}
                    data={selectedEntites || []}
                    sortable
                    pagination
                    perPage={10}
                    manualSortBy
                  >
                    <AdvanceTable
                      table
                      headerClassName="bg-light text-800 align-middle"
                      rowClassName="btn-reveal-trigger align-top"
                      tableProps={{
                        size: 'sm',
                        className: 'fs--1 mb-0',
                        style: { minHeight: '200px' }
                      }}
                    />
                  </AdvanceTableWrapper>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        )}
    </>
  );
};
export default CreateDiscount;
