import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  Col,
  Collapse,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { getLogIcon, isHTMLContent, toApiFileUrl } from 'helpers/utils';
import moment from 'moment';
import { useContext } from 'react';
import AppContext from 'context/Context';
import Flex from '../Flex';
import Avatar from '../Avatar';
import {
  faCaretDown,
  faCaretLeft,
  faCaretRight,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faSyncAlt
} from '@fortawesome/free-solid-svg-icons';
import LoaderWithMessage from '../LoaderWithMessage';
import IconButton from '../IconButton';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';

const AuditEventsCard = ({
  eventsFn = () => {},
  eventsFnName = 'UNKNOWN',
  eventsFnPaylaod = null
}) => {
  const { t } = useTranslation();
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const [events, setEvents] = useState([]);
  const [openLogs, setOpenLogs] = useState([]);

  const {
    isLoading: auditEventsLoading,
    data: auditEventsData,
    error: auditEventsError,
    refetch: fetchAuditEvents
  } = useQuery(
    [eventsFnName, eventsFnPaylaod],
    () => eventsFn(eventsFnPaylaod),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  useEffect(() => {
    // console.log({ eventsFn, eventsFnName });
    if (eventsFnName !== 'UNKNOWN') {
      fetchAuditEvents();
    }
  }, [eventsFnName]);

  useEffect(() => {
    if (auditEventsData && !auditEventsLoading && !auditEventsError) {
      if (auditEventsData?.errors?.length) {
        toast.error(getErrorMessage(t, auditEventsData?.errors), {
          theme: 'colored',
          autoClose: false
        });
      }
      setEvents(auditEventsData.payload);
    }

    if (auditEventsError) {
      toast.error(getErrorMessage(t, auditEventsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [auditEventsLoading, auditEventsData, auditEventsError]);

  const getValue = value => {
    if (!value) {
      return t('auditEvents:none');
    }
    if (value.toLowerCase() === 'none') {
      return t('auditEvents:none');
    }
    return value;
  };

  const getValueProps = value => {
    if (!value || value.toLowerCase() === 'none') {
      return { className: 'text-danger', style: { fontStyle: 'italic' } };
    }
    return { style: { wordBreak: 'break-all' } };
  };

  const getValueElement = value => {
    const finalValue = getValue(value);
    if (isHTMLContent(finalValue)) {
      return (
        <span
          {...getValueProps(value)}
          dangerouslySetInnerHTML={{ __html: finalValue }}
        ></span>
      );
    }
    return <span {...getValueProps(value)}>{finalValue}</span>;
  };

  useEffect(() => {
    if (auditEventsError) {
      toast.error(getErrorMessage(t, auditEventsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [auditEventsError]);

  if (auditEventsLoading) {
    return <LoaderWithMessage message={t('auditEvents:loadingEvents')} />;
  }

  if (!events?.length) {
    return '';
  }

  return (
    <Card className="mb-3">
      <Card.Header
        as="h5"
        className="bg-light d-flex justify-content-between align-items-center"
      >
        {t('auditEvents:title')}
        <OverlayTrigger
          placement={isRTL ? 'right' : 'left'}
          overlay={
            <Tooltip id="events-refresh-tooltip">
              {t('common:labels.refresh')}
            </Tooltip>
          }
        >
          <span>
            <IconButton
              size="sm"
              variant="light"
              onClick={() => fetchAuditEvents(true)}
              icon={faSyncAlt}
            />
          </span>
        </OverlayTrigger>
      </Card.Header>
      <Card.Body className="overflow-auto" style={{ maxHeight: '500px' }}>
        <div className={`timeline-vertical log-timeline pt-0 `}>
          {events.map((item, index) => {
            const {
              id,
              action,
              changes,
              createdAt,
              user: { name: userName, avatar }
            } = item;
            const isOpen = !!openLogs?.includes(id);
            return (
              <div
                key={index}
                className={`timeline-item timeline-item-end ${
                  isRTL ? 'rtl' : 'ltr'
                }`}
              >
                <OverlayTrigger
                  overlay={
                    <Tooltip id={`audit-event-${id}`}>
                      {t(`auditEvents:actions.${action}`)}
                    </Tooltip>
                  }
                >
                  <div
                    className={`timeline-icon ${
                      isRTL ? 'rtl' : 'ltr'
                    } icon-item icon-item-lg text-primary border-300`}
                  >
                    <FontAwesomeIcon
                      icon={getLogIcon(action)}
                      className="fs-1"
                    />
                  </div>
                </OverlayTrigger>
                <Row className="timeline-item-end justify-content-end">
                  <Col sm={10}>
                    <div className="mx-3">
                      <h5 className="mb-2 ">
                        <span>{`${t(`auditEvents:actions.${action}`)} `}</span>
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip
                              id={`audit-event-${id}`}
                              className="user-tooltip"
                            >
                              <Flex
                                justifyContent="start"
                                alignItems="center"
                                gap="2"
                              >
                                <Avatar src={toApiFileUrl(avatar)} />
                                <Flex
                                  direction="column"
                                  justifyContent="center"
                                  alignItems="start"
                                >
                                  <p className="mb-0">{userName}</p>
                                  <small className="mb-0">{''}</small>
                                </Flex>
                              </Flex>
                            </Tooltip>
                          }
                        >
                          <span style={{ fontStyle: 'italic' }}>
                            {`${t(`auditEvents:doneBy`, {
                              user: ` (${userName})`
                            })} `}
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`audit-event-date-${id}`}>
                              {moment(createdAt).format('DD MM YYYY hh:mm A')}
                            </Tooltip>
                          }
                        >
                          <small
                            style={{ fontStyle: 'italic', fontSize: '12px' }}
                          >
                            {moment(createdAt).format('DD MM YYYY')}
                          </small>
                        </OverlayTrigger>
                      </h5>
                      {!!changes?.length && (
                        <Flex
                          className="cursor-pointer w-100 mb-2"
                          justifyContent="start"
                          alignItems="center"
                          gap="2"
                          onClick={() => {
                            if (isOpen) {
                              setOpenLogs([...openLogs.filter(l => l !== id)]);
                            } else {
                              setOpenLogs([...openLogs, id]);
                            }
                          }}
                        >
                          <FontAwesomeIcon
                            icon={
                              isOpen
                                ? faCaretDown
                                : isRTL
                                ? faCaretLeft
                                : faCaretRight
                            }
                          />
                          <p className="text-primary mb-0">
                            {t('auditEvents:changes')}
                          </p>
                        </Flex>
                      )}
                      {!!changes?.length && (
                        <Collapse in={isOpen}>
                          <div className="w-100">
                            {changes.map((change, i) => {
                              return (
                                <Flex
                                  key={`${change.fieldName}-${i}`}
                                  direction="column"
                                  justifyContent="center"
                                  alignItems="start"
                                  className="mt-3"
                                >
                                  <p className="mb-0">
                                    {t(
                                      `auditEvents:fields.${change.fieldName.toLowerCase()}`
                                    )}
                                    :
                                  </p>
                                  <Flex
                                    justifyContent="start"
                                    alignItems="center"
                                    gap="3"
                                    wrap="wrap"
                                  >
                                    {getValueElement(change?.fromValue)}
                                    <FontAwesomeIcon
                                      icon={
                                        isRTL
                                          ? faLongArrowAltLeft
                                          : faLongArrowAltRight
                                      }
                                    />

                                    {getValueElement(change?.toValue)}
                                  </Flex>
                                </Flex>
                              );
                            })}
                          </div>
                        </Collapse>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            );
          })}
        </div>
      </Card.Body>
    </Card>
  );
};

export default AuditEventsCard;
