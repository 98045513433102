import Flex from 'components/common/Flex';
import React from 'react';
import { Spinner } from 'react-bootstrap';

const LoaderWithMessage = ({ message, className }) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      className={`${className || ''} w-100`}
    >
      <Spinner animation="grow" variant="primary" />
      <p className="mt-3">{message || ''}</p>
    </Flex>
  );
};

export default LoaderWithMessage;
