import { LearningPathContext, AuthContext } from 'context/Context';
import LearningPathService from 'http/LearningPathService';
import { getErrorMessage } from 'http/utils';
import {
  isLearningPathEditMode,
  canEditLearningPath
} from 'pages/Operations/LearningPath/utils';
import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import LoaderWithMessage from 'components/common/LoaderWithMessage';

const LearningPathProvider = ({ children }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [learningPath, setLearningPath] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editCard, setEditCard] = useState(null);
  const [isCreate, setIsCreate] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [sectionValidation, setSectionValidation] = useState(null);

  const {
    isLoading: learningPathLoading,
    data: learningPathData,
    error: learningPathError,
    refetch: fetchLearningPath
  } = useQuery(
    ['CreateLearningPathData', id],
    () => LearningPathService.get(id),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  const {
    isLoading: categoriesLoading,
    data: categoriesData,
    error: categoriesError,
    refetch: fetchCategories
  } = useQuery(
    'LearningPathCategories',
    () =>
      LearningPathService.listCategories({
        pageSize: 1000,
        pageNumber: 1
      }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );
  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (!!id && !!user) {
      fetchLearningPath(id);
    } else {
      setIsCreate(true);
    }
  }, [id, user]);

  // useEffect(() => {
  //   setIsReadOnly(isLearningPathEditMode(learningPath));
  // }, [learningPath]);

  // useEffect(() => {
  //   console.log('isReadOnly', isReadOnly);
  // }, [isReadOnly]);

  useEffect(() => {
    if (learningPathData && !learningPathLoading && !learningPathError) {
      if (learningPathData?.errors?.length) {
        toast.error(getErrorMessage(t, learningPathData.errors), {
          theme: 'colored',
          autoClose: false
        });
      }

      //console.log('courseData', courseData);
      const canEdit =
        canEditLearningPath(user) &&
        isLearningPathEditMode(learningPathData.payload);
      setIsCreate(false);
      setIsReadOnly(!canEdit);
      setLearningPath(learningPathData.payload);
    }

    if (learningPathError) {
      toast.error(getErrorMessage(t, learningPathError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [learningPathLoading, learningPathData, learningPathError]);

  useEffect(() => {
    if (categoriesData && !categoriesLoading && !categoriesError) {
      setCategories(categoriesData.payload.data);
    }
    if (categoriesError) {
      toast.error(getErrorMessage(t, categoriesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [categoriesLoading, categoriesData, categoriesError]);

  const learningPathProviderValue = {
    editCard,
    setEditCard,
    categories,
    sectionValidation,
    setSectionValidation,
    learningPath,
    setLearningPath,
    fetchLearningPath,
    learningPathLoading,
    isReadOnly,
    isCreate
  };

  if (learningPathLoading) {
    return (
      <LoaderWithMessage
        message={t('learningPath:message.loadingLearningPath')}
      />
    );
  }

  if (learningPathError) {
    return (
      <ErrorPlaceholder
        message={t('learningPath:message.learningPathLoadingError')}
      />
    );
  }

  return (
    <LearningPathContext.Provider value={learningPathProviderValue}>
      {children}
    </LearningPathContext.Provider>
  );
};

export default LearningPathProvider;
