import React from 'react';
import PlanBasicForm from './components/PlanBasicForm';
// import PlanQuestion from './components/PlanQuestion';

const CreateExam = () => {
  return (
    <>
      <PlanBasicForm />
      {/* <PlanQuestion /> */}
    </>
  );
};
export default CreateExam;
