import { ExamContext, AuthContext } from 'context/Context';
import PlanService from 'http/PlanService';
import { getErrorMessage } from 'http/utils';
import { canEditExam } from 'pages/Operations/Plans/utils';
import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import DealService from 'http/DealService';

const DealProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [exam, setExam] = useState(null);
  const [plans, setPlans] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [editCard, setEditCard] = useState(null);
  const [isCreate, setIsCreate] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [sectionValidation, setSectionValidation] = useState(null);


  const {
    isLoading: examLoading,
    error: examError,
    data: examData,
    refetch: fetchExam
  } = useQuery(['PlanGet', id], () => DealService.get(id), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false,
    enabled: false
  });

  const {
    isLoading: plansLoading,
    data: plansData,
    error: plansError,
    refetch: fetchPlans
  } = useQuery(
    'Plans',
    () =>
    DealService.listPlans({
        pageSize: 1000,
        pageNumber: 1
      }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );
  const {
    isLoading: companiesLoading,
    data: companiesData,
    error: companiesError,
    refetch: fetchCompanies
  } = useQuery(
    'Companies',
    () =>
    DealService.listCompanies({
        pageSize: 1000,
        pageNumber: 1
      }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  useEffect(() => {
    if (!!id && !!user) {
      fetchExam(id);
    } else {
      setIsCreate(true);
    }

    if (canEditExam(user)) {
      setIsReadOnly(false);
    } else {
      setIsReadOnly(true);
    }
  }, [id, user]);

  useEffect(() => {
    fetchPlans();
    fetchCompanies();
  }, []);

  // useEffect(() => {
  //   console.log('sectionValidation', sectionValidation);
  // }, [sectionValidation]);

  useEffect(() => {
    if (examData && !examLoading && !examError) {
      if (!examData?.data) {
        toast.error(t('error:404'), {
          theme: 'colored',
          autoClose: false
        });
        navigate(`/deals`);
      }
      setIsCreate(false);
      setExam(examData?.data || null);
    }
    if (examError) {
      toast.error(getErrorMessage(t, examError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [examLoading, examError, examData]);

  useEffect(() => {
    if (plansData && !plansLoading && !plansError) {
      setPlans(plansData.data.items);
    }
    if (plansError) {
      toast.error(getErrorMessage(t, plansError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [plansLoading, plansData, plansError]);

  useEffect(() => {
    if (companiesData && !companiesLoading && !companiesError) {
      setCompanies(companiesData.data.items);
    }
    if (companiesError) {
      toast.error(getErrorMessage(t, companiesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [companiesLoading, companiesData, companiesError]);
  
  const examProviderValue = {
    editCard,
    setEditCard,
    plans,
    companies,
    sectionValidation,
    setSectionValidation,
    exam,
    setExam,
    fetchExam,
    examLoading,
    isReadOnly,
    isCreate
  };

  if (examLoading) {
    return <LoaderWithMessage message={t('exams:message.loadingExam')} />;
  }

  if (examError) {
    return <ErrorPlaceholder message={t('exams:labels.errorLoadingExam')} />;
  }
  if (id && !exam) {
    return '';
  }

  return (
    <ExamContext.Provider value={examProviderValue}>
      {children}
    </ExamContext.Provider>
  );
};

export default DealProvider;
