import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.min.css';
//1
import Layout from 'layouts/Layout';

const App = () => {
  //console.log('Mount App:::');
  return (
    <Router basename={process.env.PUBLIC_URL} history={history}>
      <Layout />
    </Router>
  );
};

export default App;
