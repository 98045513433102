import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import {
  Card,
  Col,
  OverlayTrigger,
  Placeholder,
  Row,
  Tooltip
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import Flex from 'components/common/Flex';
import { toast } from 'react-toastify';
import moment from 'moment';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from 'components/common/ConfirmModal';
import { getErrorMessage } from 'http/utils';
import { truncateString } from 'helpers/utils';
import PlansListTableHeader from './components/PlansListTableHeader';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import PlanService from 'http/PlanService';
import SoftBadge from 'components/common/SoftBadge';

const defaultPaging = {
  pageNumber: 1,
  pageSize: 10
};

const defaultSorting = {
  sortBy: 'Name',
  sortDirection: 'DESC'
};

const PlanList = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const isRtl = i18n.language === 'ar';
  const currentLanguage = i18n.language;
  const [exams, setExams] = useState([]);
  const [pagingInfo, setPagingInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState({ ...defaultSorting });
  const [filters, setFilters] = useState({
    ...defaultPaging,
    ...defaultSorting
  });
  const [confirmModalProps, setConfirmModalProps] = useState(null);

  const {
    isLoading: examsLoading,
    error: examsError,
    data: examsData,
    refetch: fetchExams
  } = useQuery(
    ['PlansList', { ...filters, ...sortInfo }],
    () => PlanService.list({ ...filters, ...sortInfo }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  const {
    mutate: deleteExam,
    error: deleteExamError,
    isLoading: deleteExamLoading
  } = useMutation({
    mutationFn: PlanService.delete,
    onSuccess: data => onDeleteExamSuccess(data)
  });

  const onDeleteExamSuccess = () => {
    fetchExams({ ...filters, ...sortInfo, ...defaultPaging });
    setConfirmModalProps(null);
    toast.success(t(`common:message.deleteSuccess`), { theme: 'colored' });
  };

  useEffect(() => {
    fetchExams({ ...filters, ...sortInfo, ...defaultPaging });
  }, [filters, sortInfo]);

  useEffect(() => {
    if (examsData && !examsLoading && !examsError) {
      setExams(examsData?.data?.items || []);
      setPagingInfo(examsData?.payload?.pagingInfo);
    }
    if (examsError) {
      toast.error(getErrorMessage(t, examsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [examsLoading, examsError, examsData]);

  const handleSort = column => {
    const direction = sortInfo.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortInfo({
      sortBy: column,
      sortDirection: direction
    });
  };

  const handleDelete = id => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => deleteExam(id),
      message: t('plans:message.deleteExamConfirm'),
      header: t('plans:message.deleteExamTitle'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.delete')
    });
  };

  const columns = [
    {
      accessor: 'name',
      Header: t('plans:labels.name'),
      headerProps: {
        className: 'px-3',
        style: { height: '46px' },
        onClick: () => handleSort(`name`)
      },
      cellProps: {
        className: 'py-2 white-space-nowrap px-3 pe-xxl-4'
      },
      Cell: rowData => {
        const { name } = rowData.row.original;
        return (
          <p className="fw-semi-bold m-0 mt-2 mb-0">
            {name}
          </p>
        );
      }
    },
    {
      accessor: 'serviceType',
      Header: t('plans:labels.serviceType'),
      headerProps: {
        onClick: () => handleSort('serviceType')
      },
      cellProps: {
        className: 'py-2 pe-4'
      },
      Cell: rowData => {
        const { serviceTypeId } = rowData.row.original;
    
        let displayText;
    
        switch (serviceTypeId) {
            case 1:
                displayText = '3D Virtual Tour';
                break;
            case 2:
                displayText = '360 Virtual Tour';
                break;
            case 3:
                displayText = '3D Shopping';
                break;
            case 4:
                displayText = 'Drone';
                break;
            default:
                displayText = '-';
                break;
        }
        return <p className="break-word m-0 mt-2 mb-0">{displayText}</p>;
    }
    },
    {
      accessor: 'smallAreaRate',
      Header: t('plans:labels.smallAreaRate'),

      headerProps: {
        onClick: () => handleSort('smallAreaRate')
      },
      cellProps: {
        className: 'fs-0 '
      },
      Cell: rowData => {
        const { smallAreaRate } = rowData.row.original;
        return (
          <p className=" break-word m-0 mt-2 mb-0">
            {smallAreaRate +' '+'JOD'}
          </p>
        );
      }
    },
    {
      accessor: 'mediumAreaRate',
      Header: t('plans:labels.mediumAreaRate'),

      headerProps: {
        onClick: () => handleSort('mediumAreaRate')
      },
      cellProps: {
        className: 'fs-0 '
      },
      Cell: rowData => {
        const { mediumAreaRate } = rowData.row.original;
        return (
          <p className=" break-word m-0 mt-2 mb-0">
            {mediumAreaRate +' '+'JOD'}
          </p>
        );
      }
    },
    {
      accessor: 'largeAreaRate',
      Header: t('plans:labels.largeAreaRate'),

      headerProps: {
        onClick: () => handleSort('largeAreaRate')
      },
      cellProps: {
        className: 'fs-0 '
      },
      Cell: rowData => {
        const { largeAreaRate } = rowData.row.original;
        return (
          <p className=" break-word m-0 mt-2 mb-0">
            {largeAreaRate +' '+'JOD'}
          </p>
        );
      }
    },
    {
      accessor: 'hugeAreaRate',
      Header: t('plans:labels.hugeAreaRate'),

      headerProps: {
        onClick: () => handleSort('hugeAreaRate')
      },
      cellProps: {
        className: 'fs-0 '
      },
      Cell: rowData => {
        const { hugeAreaRate } = rowData.row.original;
        return (
          <p className=" break-word m-0 mt-2 mb-0">
            {hugeAreaRate +' '+'JOD'}
          </p>
        );
      }
    },
    {
      accessor: 'modifiedAt',
      Header: t('plans:labels.modifiedAt'),
      headerProps: {
        className: 'text-start',
        onClick: () => handleSort('modifiedAt')
      },
      Cell: rowData => {
        const { modifiedAt } = rowData.row.original;
        return (
          <p className=" mb-0 mt-2">
            {modifiedAt
              ? moment(modifiedAt || new Date())
                  .locale(currentLanguage)
                  .format('YYYY-MM-DD hh:mm A')
              : t('common:labels.noDataAvailable')}
          </p>
        );
      }
    },
    {
      accessor: 'action',
      Header: '',
      headerProps: { className: 'text-start' },
      disableSortBy: true,
      exportable: false,
      Cell: rowData => {
        const { id } = rowData.row.original;
        return (
          <Flex justifyContent="center" alignItems="center" gap="2">
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`staticPage-tooltip-edit`}>
                  {t('common:button.edit')}
                </Tooltip>
              }
            >
              <span className="ms-2 text-primary fs-0 cursor-pointer">
                <FontAwesomeIcon
                  icon={faEdit}
                  onClick={() => navigate(`/plans/create/${id}`)}
                />
              </span>
            </OverlayTrigger>
            <OverlayTrigger
              placement="top"
              overlay={
                <Tooltip id={`staticPage-tooltip-delete`}>
                  {t('common:button.delete')}
                </Tooltip>
              }
            >
              <span className="ms-2 text-primary fs-0 cursor-pointer">
                <FontAwesomeIcon
                  icon={faTrash}
                  onClick={() => handleDelete(id)}
                />
              </span>
            </OverlayTrigger>
          </Flex>
        );
      }
    }
  ];

  useEffect(() => {
    if (deleteExamError) {
      toast.error(getErrorMessage(t, deleteExamError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [deleteExamError]);

  if (examsError) {
    return (
      <ErrorPlaceholder
        message={t('staticPages:labels.errorLoadingStaticPages')}
        className="mt-3"
      />
    );
  }

  return (
    <Row className="gx-3">
      <Col>
        <Card>
          <Card.Header className="border-bottom border-200 px-0">
            <PlansListTableHeader
              table
              t={t}
              filters={filters}
              setFilters={setFilters}
            />
          </Card.Header>
          <Card.Body className={examsLoading ? '' : 'p-0'}>
            {examsLoading &&
              Array(14)
                .fill(1)
                .map((n, i) => {
                  return (
                    <Placeholder
                      key={`exams-table-skeleton-${i}`}
                      className="w-100 my-1"
                      style={{ height: '33px' }}
                      size="lg"
                    />
                  );
                })}
            {!examsLoading && (
              <AdvanceTableWrapper
                columns={columns}
                data={exams || []}
                sortable
                pagination
                perPage={10}
                rowCount={exams?.length || 0}
                manualSortBy
              >
                <AdvanceTable
                  table
                  headerClassName="bg-light text-800 align-middle"
                  rowClassName="btn-reveal-trigger align-top"
                  tableProps={{
                    size: 'sm',
                    className: 'fs--1 mb-0',
                    style: { minHeight: '200px' }
                  }}
                />
              </AdvanceTableWrapper>
            )}
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination
              table
              pageCount={pagingInfo?.totalPages || 0}
              pageIndex={pagingInfo?.currentPage || 1}
              gotoPage={page => {
                setFilters({ ...filters, pageNumber: page });
              }}
              isRtl={isRtl}
            />
          </Card.Footer>
        </Card>
      </Col>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={deleteExamLoading} />
      )}
    </Row>
  );
};

export default PlanList;
