import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const UPLOAD_TEMP_FILE_URL = `https://admin.api.360realize.com/file/file/temp/upload`;

const FileService = {
  uploadTempFile: data => {
    return http.post(
      UPLOAD_TEMP_FILE_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'multipart/form-data' }
      }
    );
  }
};

export default FileService;
