import React, { useEffect, useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { useTranslation } from 'react-i18next';
import { LearningPathContext } from 'context/Context';
import LearningPathService from 'http/LearningPathService';
import CreateLearningPathHeader from './components/CreateLearningPathHeader';
import LearningPathInformationForm from './components/LearningPathInformationForm';
import LearningPathPrice from './components/LearningPathPrice';
import ImageCard from 'components/common/ImageCard';
import VideoCard from 'components/common/VideoCard';
import LearningPathRequirements from './components/LearningPathRequirements';
import LearningPathActions from './components/LearningPathActions';
import AuditEventsCard from 'components/common/AuditEvents/AuditEventsCard';
import LearningPathCourses from './components/LearningPathCourses';
import { validateLearningPathBasicForm } from './utils';

const CreateLearningPath = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { learningPath, isReadOnly, fetchLearningPath, setSectionValidation } =
    useContext(LearningPathContext);

  const {
    mutate: uploadLearningPathImage,
    error: uploadLearningPathImageError,
    isLoading: uploadLearningPathImageLoading
  } = useMutation({
    mutationFn: LearningPathService.uploadLearningPathImage,
    onSuccess: data => onUploadLearningPathImageSuccess(data)
  });

  const {
    mutate: uploadLearningPathCover,
    error: uploadLearningPathCoverError,
    isLoading: uploadLearningPathCoverLoading
  } = useMutation({
    mutationFn: LearningPathService.uploadLearningPathCover,
    onSuccess: data => onUploadLearningPathImageSuccess(data)
  });

  const {
    mutate: uploadLearningPathPromoVideo,
    error: uploadLearningPathPromoVideoError,
    isLoading: uploadLearningPathPromoVideoLoading
  } = useMutation({
    mutationFn: LearningPathService.updateLearningPathPromoVideo,
    onSuccess: data => onUploadLearningPathImageSuccess(data)
  });

  const onUploadLearningPathImageSuccess = () => {
    fetchLearningPath(learningPath?.id);
    toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
  };

  const handleLearningPathImageChange = file => {
    let data = new FormData();
    data.append('id', learningPath.id);
    data.append('file', file);
    uploadLearningPathImage(data);
  };
  const handleLearningPathCoverChange = file => {
    let data = new FormData();
    data.append('id', learningPath.id);
    data.append('file', file);
    uploadLearningPathCover(data);
  };

  const handleLearningPathPromoVideoChange = ({ url }) => {
    uploadLearningPathPromoVideo({
      id: learningPath?.id,
      url: url
    });
  };

  useEffect(() => {
    if (uploadLearningPathImageError) {
      toast.error(getErrorMessage(t, uploadLearningPathImageError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (uploadLearningPathCoverError) {
      toast.error(getErrorMessage(t, uploadLearningPathCoverError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (uploadLearningPathPromoVideoError) {
      toast.error(getErrorMessage(t, uploadLearningPathPromoVideoError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [
    uploadLearningPathImageError,
    uploadLearningPathCoverError,
    uploadLearningPathPromoVideoError
  ]);

  useEffect(() => {
    // console.log('useEffect::: learning Path', learningPath);
    if (!!learningPath?.id && learningPath?.status?.code === 'draft') {
      let validations = {};
      const isValid = validateLearningPathBasicForm(learningPath);

      validations = {
        ...validations,
        basicForm: {
          isValid,
          message: isValid ? null : t('course:validation.message.instructors')
        }
      };

      if (!!learningPath?.courses?.length) {
        validations = {
          ...validations,
          courses: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          instructors: {
            isValid: false,
            message: t('learningPath:validation.message.courses')
          }
        };
      }

      if (!!learningPath?.info.find(inf => inf.type === 'learn')) {
        validations = {
          ...validations,
          learn: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          learn: {
            isValid: false,
            message: t('learningPath:validation.message.learn')
          }
        };
      }
      if (!!learningPath?.info.find(inf => inf.type === 'target')) {
        validations = {
          ...validations,
          target: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          target: {
            isValid: false,
            message: t('learningPath:validation.message.learn')
          }
        };
      }
      if (!!learningPath?.info.find(inf => inf.type === 'requirement')) {
        validations = {
          ...validations,
          requirement: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          requirement: {
            isValid: false,
            message: t('learningPath:validation.message.requirement')
          }
        };
      }
      if (!!learningPath?.image) {
        validations = {
          ...validations,
          image: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          image: {
            isValid: false,
            message: t('learningPath:validation.message.image')
          }
        };
      }
      if (!!learningPath?.cover) {
        validations = {
          ...validations,
          cover: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          cover: {
            isValid: false,
            message: t('learningPath:validation.message.cover')
          }
        };
      }
      if (!!learningPath?.promoVideo) {
        validations = {
          ...validations,
          promoVideo: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          promoVideo: {
            isValid: false,
            message: t('course:validation.message.promoVideo')
          }
        };
      }

      setSectionValidation(validations);
    }
  }, [learningPath]);

  return (
    <>
      <Form>
        <Row className="g-lg-3 font-sans-serif">
          <Col lg={8}>
            <CreateLearningPathHeader />
            <LearningPathInformationForm />
            <LearningPathCourses />
            <LearningPathRequirements />
          </Col>
          <Col lg={4}>
            <div className="sticky-sidebar top-navbar-height d-flex flex-column">
              <LearningPathActions />
              <LearningPathPrice />
              <ImageCard
                onFileChange={file => handleLearningPathImageChange(file)}
                url={learningPath?.image || null}
                title={t('learningPath:labels.learningPathImage')}
                tooltip={t('learningPath:labels.uploadLearningPathImage')}
                loading={uploadLearningPathImageLoading}
                baseUrl={process.env.REACT_APP_FILES_URL}
                isReadOnly={isReadOnly}
                hidden={!learningPath?.id}
              />
              <ImageCard
                onFileChange={file => handleLearningPathCoverChange(file)}
                url={learningPath?.cover || null}
                title={t('learningPath:labels.learningPathCover')}
                tooltip={t('learningPath:labels.uploadLearningPathCover')}
                loading={uploadLearningPathCoverLoading}
                baseUrl={process.env.REACT_APP_FILES_URL}
                isReadOnly={isReadOnly}
                hidden={!learningPath?.id}
              />
              <VideoCard
                onFileChange={file => handleLearningPathPromoVideoChange(file)}
                url={learningPath?.promoVideo || null}
                title={t('learningPath:labels.learningPathPromoVideo')}
                tooltip={t('learningPath:labels.uploadLearningPathPromoVideo')}
                loading={uploadLearningPathPromoVideoLoading}
                //baseUrl={process.env.REACT_APP_FILES_URL}
                vimeo
                isReadOnly={isReadOnly}
                hidden={!learningPath?.id}
              />
              {!!learningPath?.id && (
                <AuditEventsCard
                  eventsFn={() =>
                    LearningPathService.getLearningPathAuditEvents(id)
                  }
                  eventsFnName="getLearningPathAuditEvents"
                  eventsFnPaylaod={id}
                />
              )}
            </div>
          </Col>
        </Row>
      </Form>
    </>
  );
};

export default CreateLearningPath;
