import React, { useState, useEffect } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { programmingLanguages } from '../utils';
import { useMutation } from 'react-query';
import CourseService from 'http/CourseService';
import { toast } from 'react-toastify';
import ConfirmModal from 'components/common/ConfirmModal';
import SelectComponent from 'components/common/SelectComponent';
import CodeEditor from 'components/common/CodeEditor';
import { getErrorMessage } from 'http/utils';

const PracticeCodeModal = ({
  practiceCode,
  course,
  chapter,
  refetchCourse,
  close
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n?.language;
  const [practiceCodeDetails, setPracticeCodeDetails] = useState(practiceCode);
  const [errors, setErrors] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);

  useEffect(() => {
    setPracticeCodeDetails(practiceCode);
  }, [practiceCode]);

  const {
    mutate: savePracticeCode,
    error: savePracticeCodeError,
    isLoading: savePracticeLoading
  } = useMutation({
    mutationFn: CourseService.createPracticeCode,
    onSuccess: data => onSavePracticeCodeSuccess(data)
  });

  const onSavePracticeCodeSuccess = () => {
    if (practiceCode?.id) {
      toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
    } else {
      toast.success(t(`common:message.createSuccess`), { theme: 'colored' });
    }
    refetchCourse && refetchCourse(course?.id);
    setHasUnsavedChanges(false);
    close && close();
  };

  const validateQuiz = () => {
    let hasError = false;
    let errorsObj = {};
    if (!practiceCodeDetails?.code) {
      errorsObj['code'] = true;
      hasError = true;
    }
    if (!practiceCodeDetails?.language) {
      errorsObj['language'] = { en: true };
      hasError = true;
    }

    if (!!practiceCodeDetails?.code && !!practiceCodeDetails?.language) {
      errorsObj = null;
      hasError = false;
    }

    setErrors(errorsObj);
    return hasError;
  };

  const handleSubmitPracticeCode = () => {
    const hasError = validateQuiz();
    if (hasError) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    savePracticeCode({
      id: practiceCodeDetails?.id,
      code: practiceCodeDetails?.code,
      courseId: course.id,
      chapterId: chapter.id,
      language: practiceCodeDetails?.language
    });
  };

  const handleCancel = () => {
    if (!hasUnsavedChanges) {
      close(false);
      return;
    }
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => close(false),
      message: t('common:message.confirmDiscardMessage'),
      header: t('common:message.confirmDiscardTitle'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.discard')
    });
  };

  useEffect(() => {
    if (savePracticeCodeError) {
      toast.error(getErrorMessage(t, savePracticeCodeError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [savePracticeCodeError]);

  return (
    <Modal
      show={!!chapter?.id}
      size="xl"
      aria-labelledby="contained-modal-title-quiz"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-quiz">
          {t('course:labels.practiceCodeModalTitle', {
            name: !!practiceCode?.id
              ? practiceCode?.title?.[currentLanguage]
              : t('common:button.new')
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="newChapter" className="position-relative">
          <Row className="w-100 gx-0 gy-1">
            <Col md={12}>
              <Form.Label>
                {`${t('course:labels.codeLanguage')}`}
                <span className="text-danger">*</span>
              </Form.Label>
              <SelectComponent
                placeholder={t('course:placeholders.selectLanguage')}
                onChange={option => {
                  console.log('option', option);
                  if (option?.value) {
                    setErrors({ type: false });
                  }
                  setPracticeCodeDetails({
                    ...practiceCodeDetails,
                    language: option.value || null
                  });
                }}
                disabled={false}
                invalid={errors?.language}
                value={
                  practiceCodeDetails?.language
                    ? programmingLanguages.find(
                        l => l.value === practiceCodeDetails?.language
                      )
                    : null
                }
                options={programmingLanguages}
                getOptionValue={option => option.value}
                getOptionLabel={option => option.value}
              />
            </Col>
          </Row>
          <Row className="w-100 gx-0 gy-1 mt-2">
            <Col md={12}>
              <Form.Label>
                {`${t('course:labels.practiceCode')}`}
                <span className="text-danger">*</span>
              </Form.Label>
              <CodeEditor
                readOnly={false}
                mode={practiceCodeDetails?.language || ''}
                value={practiceCodeDetails?.code || ''}
                invalid={errors?.code}
                onChange={val => {
                  console.log('data', val);
                  if (val) {
                    setErrors({ type: false });
                  }
                  setPracticeCodeDetails({
                    ...practiceCodeDetails,
                    code: val || null
                  });
                }}
                name="practiceCodeDetails.code"
              />
            </Col>
          </Row>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={savePracticeLoading}
          onClick={handleSubmitPracticeCode}
          className="mt-1"
        >
          {savePracticeLoading && <Spinner size="sm" />}
          {!savePracticeLoading && t('common:button.save')}
        </Button>
        <Button
          onClick={handleCancel}
          variant="secondary"
          disabled={savePracticeLoading}
        >
          {t('common:button.cancel')}
        </Button>
      </Modal.Footer>
      <ConfirmModal {...confirmModalProps} />
    </Modal>
  );
};

export default PracticeCodeModal;
