import BlogsService from 'http/BlogsService';
import CourseService from 'http/CourseService';
import { getErrorMessage } from 'http/utils';
import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, Col, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import AsyncSelect from 'components/common/AsyncSelect';
import BlogCourseCard from './BlogCourseCard';
import Flex from 'components/common/Flex';
import { BlogContext } from 'context/Context';

const initCourse = {
  title: {
    ar: '',
    en: ''
  },
  subtitle: {
    ar: '',
    en: ''
  },
  link: '',
  image: ''
};

const toRecommendedCourse = course => {
  return {
    title: course.title,
    subtitle: course.subtitle,
    link: `/course/${course.id}`,
    image: course.image
  };
};

const extractCourses = data => {
  if (!data?.length || !Array.isArray(data)) {
    return [];
  }
  return [
    ...data.map(r => {
      return {
        title: r?.title,
        subtitle: r?.subtitle,
        link: `/course/${r.id}`,
        image: r.image
      };
    })
  ];
};

const RecommendedCourses = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { blog, refetch } = useContext(BlogContext);
  const [isEdit, setIsEdit] = useState(false);
  const [courses, setCourses] = useState([initCourse]);

  useEffect(() => {
    if (!!blog.id) {
      setCourses(extractCourses(blog.recommendedCourses));
    }
  }, [blog]);

  const {
    mutate: updateBlog,
    error: updateBlogError,
    isLoading: updateBlogLoading
  } = useMutation({
    mutationFn: BlogsService.updateReferences,
    onSuccess: data => onUpdateBlogSuccess(data)
  });

  const onUpdateBlogSuccess = () => {
    refetch && refetch(blog.id);
    setIsEdit(false);
    toast.success(
      t(`common:message.${!blog?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
  };

  const onSubmit = () => {
    let updatePayload = {
      id: blog.id,
      references: blog.references,
      recommendedCourses: courses
    };

    updateBlog(updatePayload);
  };

  const onCancel = () => {
    setCourses(extractCourses(blog.recommendedCourses));
    setIsEdit(false);
  };

  const asyncCoursesRequest = searchTerm => {
    return new Promise(resolve => {
      CourseService.list({
        pageNumber: 1,
        pageSize: 10,
        query: {
          term: searchTerm,
          conjunction: 'or',
          properties: [
            'title.ar',
            'title.en',
            'subtitle.ar',
            'subtitle.en',
            'description.ar',
            'description.en'
          ]
        }
      })
        .then(response => {
          console.log('response', response);
          if (response?.payload?.data) {
            resolve(response.payload.data);
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          resolve([]);
          toast.error(getErrorMessage(t, error), {
            theme: 'colored',
            autoClose: false
          });
        });
    });
  };

  const handleDeleteCourse = (course, index) => {
    let OldCourses = [...courses];
    OldCourses.splice(index, 1);
    setCourses(OldCourses);
  };

  useEffect(() => {
    if (updateBlogError) {
      toast.error(getErrorMessage(t, updateBlogError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [updateBlogError]);

  return (
    <Card className="mb-3">
      <Card.Header as="h5">{t('blogs:labels.recommendedCourses')}</Card.Header>
      <Card.Body className="bg-light">
        {isEdit && (
          <Row>
            <Col>
              <AsyncSelect
                asyncRequest={asyncCoursesRequest}
                defaultOptions={courses}
                placeholder={t('learningPath:placeholders.selectCourse')}
                classNamePrefix="react-select"
                value={null}
                clearOnSelect={true}
                onChange={option =>
                  setCourses([...courses, toRecommendedCourse(option)])
                }
                getOptionLabel={option => option.title?.[currentLanguage]}
                getOptionValue={option => option.id}
                isDisabled={!isEdit || updateBlogLoading}
              />
            </Col>
          </Row>
        )}
        {!!courses?.length && (
          <Flex
            className="mt-3"
            wrap="wrap"
            justifyContent="start"
            alignItems="center"
          >
            {courses?.map((course, index) => {
              return (
                <BlogCourseCard
                  key={`blog-recommended-course-${index}`}
                  course={course}
                  onDelete={course => handleDeleteCourse(course, index)}
                  isReadOnly={!isEdit}
                />
              );
            })}
          </Flex>
        )}
      </Card.Body>
      <Card.Footer>
        {!blog?.id && (
          <>
            <Button size="sm" onClick={onSubmit}>
              {updateBlogLoading && <Spinner size="sm" />}
              {!updateBlogLoading && t('common:button.save')}
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
            >
              {!updateBlogLoading && t('common:button.cancel')}
            </Button>
          </>
        )}
        {isEdit && !!blog?.id && (
          <>
            <Button size="sm" onClick={onSubmit} disabled={!!updateBlogLoading}>
              {updateBlogLoading && <Spinner size="sm" />}
              {!updateBlogLoading && t('common:button.save')}
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
              disabled={!!updateBlogLoading}
            >
              {t('common:button.cancel')}
            </Button>
          </>
        )}
        {!isEdit && !!blog?.id && (
          <>
            <Button
              size="sm"
              variant="info"
              onClick={() => setIsEdit(true)}
              disabled={!!updateBlogLoading}
            >
              {t('common:button.edit')}
            </Button>
          </>
        )}
      </Card.Footer>
    </Card>
  );
};

export default RecommendedCourses;
