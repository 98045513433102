export const toStaticPageFormSchema = page => {
  if (!page) {
    return null;
  }
  return {
    title: page?.title || '',
    keywords: page?.keywords || '',
    description: page?.description || '',
  };
};
