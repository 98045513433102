export const toExamFormSchema = country => {
  if (!country) {
    return null;
  }
  return {
    ...country,
    name: country.name || '',
    nameAr: country.nameAr || '',
  };
};

export const canEditExam = user => {
  if (!user || !user?.roles?.length) {
    console.log('user missing roles', user);
    return false;
  }
  let roles = user.roles;
  const allowedRoles = ['admin', 'planner'];
  if (typeof roles === 'string') {
    roles = [roles];
  }
  const canEdit = !!roles
    .map(r => r.toLowerCase())
    .some(r => allowedRoles.includes(r));
  // console.log(
  //   'canEditCourse',
  //   `${canEdit} user is ${roles.map(r => r).join(',')}`
  // );
  return canEdit;
};
