import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api`;
const LIST_COMPANIES_URL = `https://admin.api.360realize.com/api/Companies/List`;
const GET_COMPANY_URL = `https://admin.api.360realize.com/api/Companies/GetCompanyById`;
const CREATE_COMPANY_URL = `https://admin.api.360realize.com/api/Companies/Create`;
const UPDATE_COMPANY_URL = `https://admin.api.360realize.com/api/Companies/Update`;
const DELETE_COMPANY_URL = `https://admin.api.360realize.com/api/Companies/Delete`;
const LIST_CITIES_URL = `https://admin.api.360realize.com/api/cities/List`;
const UPLOAD_COMPANY_LOGO_URL = `https://admin.api.360realize.com/api/Companies/UploadCompanyLogo`;


const CompanyService = {
  list: data => {
    return http.post(
      LIST_COMPANIES_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  get: id => {
    return http.get(`${GET_COMPANY_URL}?id=${id}`, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  create: data => {
    return http.post(
      CREATE_COMPANY_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  update: data => {
    return http.post(
      UPDATE_COMPANY_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  delete: data => {
    return http.delete(`${DELETE_COMPANY_URL}?id=${data}`, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  listCities: filters => {
    const url = LIST_CITIES_URL;
    return http.post(url, filters, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  uploadImage: data => {
    const url = `${UPLOAD_COMPANY_LOGO_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
};

export default CompanyService;
