import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api`;
const LIST_CITIES_URL = `https://admin.api.360realize.com/api/Cities/List`;
const GET_CITY_URL = `https://admin.api.360realize.com/api/Cities/GetCityById`;
const CREATE_CITY_URL = `https://admin.api.360realize.com/api/Cities/Create`;
const UPDATE_CITY_URL = `https://admin.api.360realize.com/api/Cities/Update`;
const DELETE_CITY_URL = `https://admin.api.360realize.com/api/Cities/Delete`;
const LIST_COUNTRIES_URL = `https://admin.api.360realize.com/api/countries/List`;


const CityService = {
  list: data => {
    return http.post(
      LIST_CITIES_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  get: id => {
    return http.get(`${GET_CITY_URL}?id=${id}`, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  create: data => {
    return http.post(
      CREATE_CITY_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  update: data => {
    return http.post(
      UPDATE_CITY_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  delete: data => {
    return http.delete(`${DELETE_CITY_URL}?id=${data}`, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  listCountries: filters => {
    const url = LIST_COUNTRIES_URL;
    return http.post(url, filters, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
};

export default CityService;
