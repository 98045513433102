import { faFirstdraft } from '@fortawesome/free-brands-svg-icons';
import {
  faBook,
  faCheckCircle,
  faRedo,
  faRocket,
  faSave,
  faSearch,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

export const courseStatuses = [
  {
    name: 'draft',
    icon: faFirstdraft,
    color: 'secondary',
    editable: true
  },
  {
    name: 'modificationsRequested',
    icon: faRedo,
    color: 'warning',
    editable: true
  },
  {
    name: 'prepared',
    icon: faBook,
    color: 'info'
  },
  {
    name: 'modified',
    icon: faSave,
    color: 'warning'
  },
  {
    name: 'onReview',
    icon: faSearch,
    color: 'warning'
  },
  {
    name: 'rejected',
    icon: faTimes,
    color: 'danger'
  },
  {
    name: 'approved',
    icon: faCheckCircle,
    color: 'success'
  },
  {
    name: 'published',
    icon: faRocket,
    color: 'primary'
  }
];

export const getCourseActionColor = stateTo => {
  return (
    courseStatuses.find(state => state.name === stateTo)?.color || 'primary'
  );
};

export const getCourseCommentColor = status => {
  return (
    courseStatuses.find(state => state.name === status)?.color || 'warning'
  );
};

export const getCourseProgressStages = status => {
  const normalCases = ['draft', 'onReview', 'approved', 'published'];
  if (normalCases.includes(status)) {
    return courseStatuses.filter(s => normalCases.includes(s.name));
  }
  const modificationCase = [
    'modificationsRequested',
    'onReview',
    'approved',
    'published'
  ];
  if (status === 'modificationsRequested') {
    return courseStatuses.filter(s => modificationCase.includes(s.name));
  }
  const rejectionCase = ['draft', 'prepared', 'onReview', 'rejected'];
  if (status === 'rejected') {
    return courseStatuses.filter(s => rejectionCase.includes(s.name));
  }
};

export const isCourseEditMode = course => {
  if (!course) {
    return false;
  }
  if (!course.status) {
    return false;
  }
  const isEdit =
    courseStatuses.find(st => st.name === course?.status?.code)?.editable ||
    false;
  // console.log('isEdit', isEdit);
  return isEdit;
};

export const canEditCourse = user => {
  if (!user || !user?.roles?.length) {
    console.log('user missing roles', user);
    return false;
  }
  let roles = user.roles;
  const allowedRoles = ['admin', 'planner'];
  if (typeof roles === 'string') {
    roles = [roles];
  }
  const canEdit = !!roles
    .map(r => r.toLowerCase())
    .some(r => allowedRoles.includes(r));
  // console.log(
  //   'canEditCourse',
  //   `${canEdit} user is ${roles.map(r => r).join(',')}`
  // );
  return canEdit;
};

export const programmingLanguages = [
  { value: 'javascript' },
  { value: 'java' },
  { value: 'python' },
  { value: 'xml' },
  { value: 'ruby' },
  { value: 'sass' },
  { value: 'markdown' },
  { value: 'mysql' },
  { value: 'json' },
  { value: 'html' },
  { value: 'handlebars' },
  { value: 'golang' },
  { value: 'csharp' },
  { value: 'coffee' },
  { value: 'css' }
];

export const toBasicFormSchema = course => {
  if (!course) {
    return null;
  }
  return {
    courseTitleAr: course.title.ar,
    courseTitleEn: course.title.en,
    courseSubTitleAr: course.subtitle.ar,
    courseSubTitleEn: course.subtitle.en,
    courseDescriptionAr: course.description.ar,
    courseDescriptionEn: course.description.en,
    tags: course.settings.tags,
    level: course.level,
    category: course.category.parentId
      ? course.category.parentId
      : course.category.id,
    subCategory: course.category.parentId ? course.category.id : ''
  };
};

export const validateCourseBasicForm = course => {
  const data = toBasicFormSchema(course);
  if (!data.courseTitleAr) {
    return false;
  }
  if (!data.courseTitleEn) {
    return false;
  }
  if (!data.courseSubTitleAr) {
    return false;
  }
  if (!data.courseSubTitleEn) {
    return false;
  }
  if (!data.courseDescriptionAr) {
    return false;
  }
  if (!data.courseDescriptionEn) {
    return false;
  }
  if (!data.level) {
    return false;
  }
  if (!data.category) {
    return false;
  }
  return true;
};

export const getCoursePricing = course => {
  if (!course?.discounts?.length) {
    return {
      price: course?.settings?.price,
      oldPrice: course?.settings?.oldPrice
    };
  }
  const discount = course.discounts[0];
  if (discount.type === 'offer') {
    return {
      price: discount.customPrice,
      oldPrice: course?.settings?.price
    };
  }
  if (discount.type === 'discount') {
    return {
      price: (
        (course?.settings?.price || 0) -
        (discount.discountPercentage * course?.settings?.price) / 100
      ).toFixed(2),
      oldPrice: course?.settings?.price
    };
  }
  return {
    price: course?.settings?.price,
    oldPrice: course?.settings?.oldPrice
  };
};
