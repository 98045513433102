import React from 'react';
import CompanyBasicForm from './components/CompanyBasicForm';
import ProjectService from 'http/ProjectService';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { ExamContext } from 'context/Context';
import { Col, Row } from 'react-bootstrap';
import { useMutation } from 'react-query';
import { useContext } from 'react';
import ImageCard from 'components/common/ImageCard';
import CompanyService from 'http/CompanyService';



const CreateCompany = () => {
  const { t } = useTranslation();
  const { exam, examLoading, editCard, setEditCard, fetchExam } = useContext(ExamContext);
  const {
    mutate: updateBlogImage,
    error: updateBlogImageError,
    isLoading: updateBlogImageLoading
  } = useMutation({
    mutationFn: CompanyService.uploadImage,
    onSuccess: data => onUpdateProjectImageSuccess(data)
  });
  const handleBlogImageChange = (file) => {
    let data = new FormData();
    data.append('id', exam.id);
    data.append('file', file, file.name);
    updateBlogImage(data);
  };
  const onUpdateProjectImageSuccess = () => {
    fetchExam(exam.dealId);
    toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
  };
  return (
    <Row className="g-lg-3 font-sans-serif">

      <Col lg={8}>
      <CompanyBasicForm />
      </Col>
      {!!exam?.id && (
        <Col lg={4}>
          <ImageCard
          onFileChange={file => handleBlogImageChange(file, 1)}
          url={exam?.logo || null}
          title={t('projects:labels.image1')}
          tooltip={t('projects:labels.image1')}
          loading={updateBlogImageLoading}
          baseUrl={'http://360realize.com/content'}
        // isReadOnly={isReadOnly}
        />
        </Col>
      )}
     

    </Row>
  );
};
export default CreateCompany;
