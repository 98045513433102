import AuthCardLayout from 'layouts/AuthCardLayout';
import React from 'react';
import LoginForm from 'pages/Auth/LoginForm';
import { useTranslation } from 'react-i18next';

const LoginPage = () => {
  const { t } = useTranslation();
  return (
    <AuthCardLayout leftSideContent={<></>}>
      <h3>{t('auth:accountLogin')}</h3>
      <LoginForm layout="card" hasLabel />
    </AuthCardLayout>
  );
};

export default LoginPage;
