import CourseService from 'http/CourseService';
import { getErrorMessage } from 'http/utils';
import React, { useEffect, useState } from 'react';
import { Button, Card, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import SelectComponent from 'components/common/SelectComponent';
import InstructorCard from './InstructorCard';
import { useContext } from 'react';
import { CourseContext } from 'context/Context';
import InstructorModal from './InstructorModal';

const CourseInstructorCard = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { course, isCreate, isReadOnly, fetchCourse, editCard, setEditCard } =
    useContext(CourseContext);
  const [initInstructors, setInitInstructors] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [selectedInstructors, setSelectedInstructors] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [instructorModalOpen, setInstructorModalOpen] = useState(false);

  useEffect(() => {
    if (course) {
      setInitInstructors(course.instructors);
      setSelectedInstructors(course.instructors);
    }
  }, [course]);

  useEffect(() => {
    if (editCard === 'instructorCard') {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [editCard, isCreate, course]);

  const {
    isLoading: instructorsLoading,
    data: instructorsData,
    error: instructorsError,
    refetch: fetchInstructors
  } = useQuery(
    'FetchInstructors',
    () => CourseService.listInstrutors({ pageSize: 1000, pageNumber: 1 }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  const {
    mutate: addCourseInstructor,
    error: addCourseInstructorError,
    isLoading: addCourseInstructorLoading
  } = useMutation({
    mutationFn: CourseService.saveCourseInstructors,
    onSuccess: data => onAddCourseInstructorSuccess(data)
  });

  const onAddCourseInstructorSuccess = () => {
    setEditCard(null);
    fetchCourse();
    toast.success(t(`common:message.uploadSuccess`), { theme: 'colored' });
  };

  useEffect(() => {
    fetchInstructors();
  }, []);

  useEffect(() => {
    if (instructorsData && !instructorsLoading) {
      // console.log('list instructors response', instructorsData);
      setInstructors(instructorsData?.payload?.data);
    }
  }, [instructorsLoading, instructorsData]);

  useEffect(() => {
    if (instructorsError) {
      toast.error(getErrorMessage(t, instructorsError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (addCourseInstructorError) {
      toast.error(getErrorMessage(t, addCourseInstructorError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [instructorsError, addCourseInstructorError]);

  const handleInstructorChange = options => {
    setSelectedInstructors(options);
  };

  const handleSubmit = () => {
    addCourseInstructor({
      courseId: course.id,
      instructors: selectedInstructors?.map(i => i.id)
    });
  };

  const onCancel = () => {
    setSelectedInstructors(initInstructors);
    setEditCard(null);
  };

  if (instructorsLoading) {
    return (
      <LoaderWithMessage message={t('course:message.loadingInstructors')} />
    );
  }
  if (isCreate) {
    return '';
  }

  return (
    <Card className="mb-3">
      <Card.Header
        as="h5"
        className="bg-light d-flex justify-content-between align-items-center"
      >
        {t('course:labels.courseInstructor')}
        {!isReadOnly && (
          <Button
            size="sm"
            onClick={() => setInstructorModalOpen(true)}
            disabled={!isEdit}
          >
            {t('common:button.create')}
          </Button>
        )}
      </Card.Header>
      <Card.Body className="pt-0 p-all-m-0">
        <SelectComponent
          options={instructors}
          placeholder={t('course:placeholders.selectInstructor')}
          classNamePrefix="react-select"
          value={selectedInstructors}
          isMulti
          onChange={option => handleInstructorChange(option)}
          getOptionLabel={option => option.name}
          getOptionValue={option => option.id}
          isDisabled={!isEdit}
        />
        {!!selectedInstructors?.length &&
          selectedInstructors?.map((instructor, i) => {
            return (
              <InstructorCard
                key={`course-instructor-${instructor?.id || ''}-${i}`}
                name={instructor?.name}
                heading={instructor?.heading}
                bio={instructor?.bio}
                image={`${process.env.REACT_APP_FILES_URL}/${
                  instructor?.image || instructor?.imageUrl || ''
                }`}
                followers={instructor?.followers}
                courses={instructor?.courses}
                students={instructor?.students}
                containerClassName="mt-3"
                currentLanguage={currentLanguage}
                t={t}
              />
            );
          })}
      </Card.Body>
      <Card.Footer>
        {isCreate && (
          <Button size="sm" onClick={handleSubmit}>
            {addCourseInstructorLoading && <Spinner size="sm" />}
            {!addCourseInstructorLoading && t('common:button.save')}
          </Button>
        )}
        {isEdit && (
          <>
            <Button size="sm" onClick={handleSubmit}>
              {addCourseInstructorLoading && <Spinner size="sm" />}
              {!addCourseInstructorLoading && t('common:button.save')}
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
            >
              {t('common:button.cancel')}
            </Button>
          </>
        )}
        {!isEdit && !isReadOnly && (
          <>
            <Button
              size="sm"
              variant="info"
              onClick={() => setEditCard('instructorCard')}
              disabled={!!editCard}
            >
              {t('common:button.edit')}
            </Button>
          </>
        )}
      </Card.Footer>

      <InstructorModal
        isOpen={instructorModalOpen}
        onClose={() => setInstructorModalOpen(false)}
        refetchInstructors={fetchInstructors}
      />
    </Card>
  );
};

export default CourseInstructorCard;
