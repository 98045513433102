import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
const customErrorStyles = isValid => {
  return {
    control: (base, state) => ({
      ...base,
      // state.isFocused can display different borderColor if you need it
      borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red',
      // overwrittes hover style
      '&:hover': {
        borderColor: state.isFocused ? '#ddd' : isValid ? '#ddd' : 'red'
      }
    })
  };
};

const MultiSelect = forwardRef(
  (
    { options, placeholder, isCreatable, createLabel, isInvalid, ...rest },
    ref
  ) => {
    if (isCreatable) {
      return (
        <CreatableSelect
          ref={ref}
          isMulti
          options={options}
          placeholder={placeholder}
          classNamePrefix="react-select"
          formatCreateLabel={createLabel}
          styles={isInvalid ? { ...customErrorStyles(false) } : undefined}
          {...rest}
        />
      );
    }
    return (
      <Select
        ref={ref}
        isMulti
        options={options}
        placeholder={placeholder}
        classNamePrefix="react-select"
        styles={isInvalid ? { ...customErrorStyles(false) } : undefined}
        {...rest}
      />
    );
  }
);

MultiSelect.propTypes = {
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string
};

export default MultiSelect;
