import { BlogContext, AuthContext } from 'context/Context';
import BlogsService from 'http/BlogsService';
import CourseService from 'http/CourseService';
import { getErrorMessage } from 'http/utils';
import { canEditBlogs } from 'pages/Operations/Blogs/utils';
import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import LoaderWithMessage from 'components/common/LoaderWithMessage';

const BlogProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [blog, setBlog] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editCard, setEditCard] = useState(null);
  const [isCreate, setIsCreate] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [sectionValidation, setSectionValidation] = useState(null);

  const {
    isLoading: blogLoading,
    error: blogError,
    data: blogData,
    refetch: fetchBlog
  } = useQuery(['BlogGet', id], () => BlogsService.get(id), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false,
    enabled: false
  });

  const {
    isLoading: categoriesLoading,
    data: categoriesData,
    error: categoriesError,
    refetch: fetchCategories
  } = useQuery(
    'CoursesCategories',
    () =>
      CourseService.listCategories({
        pageSize: 1000,
        pageNumber: 1
      }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  useEffect(() => {
    if (!!id && !!user) {
      fetchBlog(id);
    } else {
      setIsCreate(true);
    }

    if (canEditBlogs(user)) {
      setIsReadOnly(false);
    } else {
      setIsReadOnly(true);
    }
  }, [id, user]);

  useEffect(() => {
    fetchCategories();
  }, []);

  // useEffect(() => {
  //   console.log('sectionValidation', sectionValidation);
  // }, [sectionValidation]);

  useEffect(() => {
    if (blogData && !blogLoading && !blogError) {
      if (!blogData?.payload) {
        toast.error(t('error:404'), {
          theme: 'colored',
          autoClose: false
        });
        navigate(`/contents/blogs`);
      }
      setIsReadOnly(!canEditBlogs(user));
      setBlog(blogData?.payload || null);
    }
    if (blogError) {
      toast.error(getErrorMessage(t, blogError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [blogLoading, blogError, blogData]);

  useEffect(() => {
    if (categoriesData && !categoriesLoading && !categoriesError) {
      setCategories(
        categoriesData.payload.data?.map(c => ({
          value: c.id,
          label: c.title?.[currentLanguage]
        }))
      );
    }
    if (categoriesError) {
      toast.error(getErrorMessage(t, categoriesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [categoriesLoading, categoriesData, categoriesError]);

  const blogProviderValue = {
    editCard,
    setEditCard,
    categories,
    sectionValidation,
    setSectionValidation,
    blog,
    setBlog,
    refetch: fetchBlog,
    blogLoading,
    isReadOnly,
    isCreate
  };

  if (blogLoading) {
    return <LoaderWithMessage message={t('blogs:message.loadingBlog')} />;
  }

  if (blogError) {
    return <ErrorPlaceholder message={t('blogs:labels.errorLoadingBlog')} />;
  }
  if (id && !blog) {
    return '';
  }

  return (
    <BlogContext.Provider value={blogProviderValue}>
      {children}
    </BlogContext.Provider>
  );
};

export default BlogProvider;
