import React, { useEffect, useState } from 'react';
import CourseService from 'http/CourseService';
import { getErrorMessage } from 'http/utils';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import {
  Button,
  Col,
  Form,
  Modal,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import ConfirmModal from 'components/common/ConfirmModal';
import StrictModeDroppable from 'components/common/StrictModeDroppable';
import Question from './Question';
import { useRef } from 'react';
import { generateRandomPassword } from 'helpers/utils';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';

const initQuestion = {
  content: {
    ar: '',
    en: ''
  },
  type: '',
  rank: 0
};

const QuizModal = ({ quiz, course, chapter, refetchCourse, close }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n?.language;
  const [initQuizValue] = useState(quiz);
  const [quizDetails, setquizDetails] = useState(quiz);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [errors, setErrors] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [openQuestionsUID, setOpenQuestionsUID] = useState(null);

  const questionsTitleRef = useRef(null);

  useEffect(() => {
    if (quiz?.id) {
      /* eslint-disable-next-line no-unsafe-optional-chaining */
      setQuestions([...quiz?.questions] || []);
      setquizDetails(quiz);
      validateQuiz(quiz);
    }
    if (!quiz?.id) {
      if (!quizDetails?.questions?.length) {
        setQuestions([initQuestion]);
      }
    }
  }, []);

  const {
    mutate: saveQuiz,
    error: saveQuizError,
    isLoading: saveQuizLoading
  } = useMutation({
    mutationFn: CourseService.createQuiz,
    onSuccess: data => onSaveQuizSuccess(data)
  });

  const onSaveQuizSuccess = () => {
    toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
    refetchCourse && refetchCourse(course?.id);
    setHasUnsavedChanges(false);
    close && close();
  };

  const handleCancel = () => {
    if (!hasUnsavedChanges) {
      close(false);
      return;
    }
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => close(false),
      message: t('common:message.confirmDiscardMessage'),
      header: t('common:message.confirmDiscardTitle'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.discard')
    });
  };

  const validateQuiz = quizData => {
    let hasError = false;
    let err = {
      title: {
        ar: !quizData?.title?.ar,
        en: !quizData?.title?.en
      },
      questions: {
        valid: !!quizData?.questions?.length,
        questionsData: [
          /* eslint-disable-next-line no-unsafe-optional-chaining */
          ...(quizData?.questions || [])?.map(q => {
            return {
              content: {
                ar: !q?.content?.ar,
                en: !q?.content?.en
              },
              type: !q?.type,
              answers: {
                valid: !!q?.answers?.length,
                answersData: [
                  /* eslint-disable-next-line no-unsafe-optional-chaining */
                  ...(q.answers || [])?.map(a => {
                    return {
                      content: {
                        ar: !a?.content?.ar && typeof a?.content !== 'boolean',
                        en: !a?.content?.en && typeof a?.content !== 'boolean'
                      }
                    };
                  })
                ]
              }
            };
          })
        ]
      }
    };
    const hasQuizError = !!err.title.ar || !!err.title.en;
    const hasQuetionsLengthError =
      !err.questions.valid || !err?.questions.questionsData?.length;
    const hasQuetionsContentsError = !!err?.questions.questionsData?.some(
      q => !!q.content.ar || !!q.content.en || !!q.type
    );
    const hasQuetionsAnwersLengthError = !!err?.questions.questionsData?.some(
      q => !q.answers.valid
    );
    const hasQuetionsAnwersContentsError = !!err?.questions.questionsData?.some(
      q =>
        !q?.answers?.answersData?.length ||
        !!q?.answers?.answersData?.some(a => !!a.content.ar || !!a.content.en)
    );
    if (hasQuizError) {
      console.log('hasQuizError', hasQuizError);
      hasError = true;
    }
    if (hasQuetionsLengthError) {
      console.log('hasQuetionsLengthError', hasQuetionsLengthError);
      hasError = true;
    }
    if (hasQuetionsContentsError) {
      console.log('hasQuetionsContentsError', hasQuetionsContentsError);
      hasError = true;
    }
    if (hasQuetionsAnwersLengthError) {
      console.log('hasQuetionsAnwersLengthError', hasQuetionsAnwersLengthError);
      hasError = true;
    }
    if (hasQuetionsAnwersContentsError) {
      console.log(
        'hasQuetionsAnwersContentsError',
        hasQuetionsAnwersContentsError
      );
      hasError = true;
    }
    console.log('validate Quiz', { err, hasError, quizData });
    setErrors(err);
    return hasError;
  };

  const handleAddQuestion = () => {
    const newQuestion = { ...initQuestion, rank: questions?.length - 1 };
    setQuestions([...questions, newQuestion]);
  };

  const handleSubmitQuiz = () => {
    const hasError = validateQuiz(quizDetails);
    if (hasError) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    saveQuiz({
      id: quizDetails?.id,
      title: quizDetails?.title,
      courseId: course.id,
      chapterId: chapter.id,
      isMandatory: quizDetails?.isMandatory || false,
      questions: quizDetails.questions
    });
  };

  const checkUnsavedChanged = () => {
    let hasChanges = false;
    if (!quizDetails?.id) {
      if (!!quizDetails?.title?.ar) {
        hasChanges = true;
      }
      if (!!quizDetails?.title?.en) {
        hasChanges = true;
      }
      if (!!quizDetails?.questions?.length) {
        hasChanges = true;
      }
    } else {
      if (quizDetails?.title?.ar !== initQuizValue?.title?.ar) {
        hasChanges = true;
      }
      if (quizDetails?.title?.en !== initQuizValue?.title?.en) {
        hasChanges = true;
      }
      if (
        JSON.stringify(quizDetails?.questions) !==
        JSON.stringify(initQuizValue?.questions)
      ) {
        hasChanges = true;
      }
    }
    setHasUnsavedChanges(hasChanges);
  };

  const handleQuestionChange = (newQuestion, index) => {
    let newQuestions = [...questions];
    newQuestions[index] = newQuestion;
    setQuestions(newQuestions);
    console.log('handleQuestionChange', {
      ...quizDetails,
      questions: newQuestions
    });
    setquizDetails({ ...quizDetails, questions: newQuestions });
  };

  useEffect(() => {
    //console.log('new quiz', quizDetails);
    checkUnsavedChanged();
  }, [quizDetails]);

  useEffect(() => {
    if (saveQuizError) {
      toast.error(getErrorMessage(t, saveQuizError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [saveQuizError]);

  return (
    <Modal
      show={!!chapter?.id}
      size="xl"
      aria-labelledby="contained-modal-title-quiz"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-quiz">
          {t('course:labels.quizModalTitle', {
            name: !!quizDetails?.id
              ? quizDetails?.title?.[currentLanguage]
              : t('common:button.new')
          })}
        </Modal.Title>
        {/* <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => close(false)}
        /> */}
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="newChapter" className="position-relative">
          <Flex
            direction="column"
            alignItems="start"
            justifyContent="center"
            gap="1"
          >
            <Row className="w-100 gx-0 gy-1">
              <Col md={6} className="mt-1 g-1">
                <Form.Label>
                  {`${t('common:labels.title')} (${t(
                    'common:language.ar.name'
                  )})`}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="quizTitleAr"
                  required
                  value={quizDetails?.title?.ar || ''}
                  className="pe-4"
                  onChange={e =>
                    setquizDetails({
                      ...quizDetails,
                      title: { ...quizDetails?.title, ar: e.target.value }
                    })
                  }
                  placeholder={t('common:placeholder.titleAr')}
                  isInvalid={!!errors?.title?.ar}
                />
              </Col>
              <Col md={6} className="mt-1 g-1">
                <Form.Label>
                  {`${t('common:labels.title')} (${t(
                    'common:language.en.name'
                  )})`}
                  <span className="text-danger">*</span>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="quizTitleEn"
                  required
                  value={quizDetails?.title?.en || ''}
                  className="pe-4"
                  onChange={e =>
                    setquizDetails({
                      ...quizDetails,
                      title: { ...quizDetails?.title, en: e.target.value }
                    })
                  }
                  placeholder={t('common:placeholder.titleEn')}
                  isInvalid={!!errors?.title?.en}
                />
              </Col>
            </Row>
            <Row>
              <Col md={12} className="mt-1">
                <Form.Check
                  type="switch"
                  id="defaultSwitch"
                  size={50}
                  label={t('course:labels.isMandatory')}
                  onChange={e => {
                    setquizDetails({
                      ...quizDetails,
                      isMandatory: e.target.checked || false
                    });
                  }}
                  checked={quizDetails?.isMandatory || false}
                />
              </Col>
            </Row>
            <Row className="w-100 gx-0 gy-1">
              <Col md={12} className="mt-1">
                <div>
                  <Form.Label>
                    <span ref={questionsTitleRef}>
                      {`${t('course:labels.questions')}`}
                      <span className="text-danger">*</span>
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            id={`quiz-questions-add-toolip-${
                              quizDetails?.id || 'newQuiz'
                            }`}
                          >
                            {t('course:button.addQuestion')}
                          </Tooltip>
                        }
                      >
                        <span style={{ height: '1.5rem' }}>
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="cursor-pointer mx-3"
                            onClick={() => handleAddQuestion()}
                            size="sm"
                          />
                        </span>
                      </OverlayTrigger>
                    </span>
                  </Form.Label>
                  <DragDropContext onDragEnd={() => {}}>
                    <StrictModeDroppable droppableId="droppable1" type="DRAG">
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.droppableProps}
                          className=" bg-white dark__bg-1000 rounded-2  mb-3 p-0 overflow-visible"
                        >
                          {questions?.map((question, index) => {
                            return (
                              <Draggable
                                key={
                                  question?.id ||
                                  `course-quiz-question-${index}`
                                }
                                draggableId={`${
                                  question?.id ||
                                  `course-quiz-question-${index}`
                                }`}
                                index={index}
                              >
                                {provided => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    className="d-flex align-items-center"
                                  >
                                    <Question
                                      key={`course-quiz-question-${index}-${
                                        question?.id || ''
                                      }`}
                                      question={question}
                                      index={index}
                                      dragHandleProps={{
                                        ...provided.dragHandleProps
                                      }}
                                      handleChange={handleQuestionChange}
                                      questionsTitleRef={questionsTitleRef}
                                      openQuestionsUID={openQuestionsUID}
                                      onOpenChange={() =>
                                        setOpenQuestionsUID(
                                          generateRandomPassword()
                                        )
                                      }
                                      errors={
                                        errors?.questions?.questionsData?.[
                                          index
                                        ]
                                      }
                                    />
                                  </div>
                                )}
                              </Draggable>
                            );
                          })}
                          {provided.placeholder}
                        </div>
                      )}
                    </StrictModeDroppable>
                  </DragDropContext>
                </div>
              </Col>
            </Row>
          </Flex>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={saveQuizLoading}
          onClick={handleSubmitQuiz}
          className="mt-1"
        >
          {saveQuizLoading && <Spinner size="sm" />}
          {!saveQuizLoading && t('common:button.save')}
        </Button>
        <Button
          onClick={handleCancel}
          variant="secondary"
          disabled={saveQuizLoading}
        >
          {t('common:button.cancel')}
        </Button>
      </Modal.Footer>
      <ConfirmModal {...confirmModalProps} />
    </Modal>
  );
};

export default QuizModal;
