import { ExamContext, AuthContext } from 'context/Context';
import CityService from 'http/CityService';
import { getErrorMessage } from 'http/utils';
import { canEditExam } from 'pages/Operations/Countries/utils';
import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import LoaderWithMessage from 'components/common/LoaderWithMessage';

const CityProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [exam, setExam] = useState(null);
  const [editCard, setEditCard] = useState(null);
  const [isCreate, setIsCreate] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [sectionValidation, setSectionValidation] = useState(null);
  const [countries, setCountries] = useState([]);


  const {
    isLoading: examLoading,
    error: examError,
    data: examData,
    refetch: fetchExam
  } = useQuery(['CityGet', id], () => CityService.get(id), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false,
    enabled: false
  });

  useEffect(() => {
    if (!!id && !!user) {
      fetchExam(id);
    } else {
      setIsCreate(true);
    }

    if (canEditExam(user)) {
      setIsReadOnly(false);
    } else {
      setIsReadOnly(true);
    }
  }, [id, user]);

  // useEffect(() => {
  //   fetchCategories();
  // }, []);

  // useEffect(() => {
  //   console.log('sectionValidation', sectionValidation);
  // }, [sectionValidation]);

  useEffect(() => {
    if (examData && !examLoading && !examError) {
      if (!examData?.data) {
        toast.error(t('error:404'), {
          theme: 'colored',
          autoClose: false
        });
        navigate(`/plans`);
      }
      setIsCreate(false);
      setExam(examData?.data || null);
    }
    if (examError) {
      toast.error(getErrorMessage(t, examError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [examLoading, examError, examData]);

  const {
    isLoading: countriesLoading,
    data: countriesData,
    error: countriesError,
    refetch: fetchCountries
  } = useQuery(
    'Countries',
    () =>
    CityService.listCountries({
        pageSize: 1000,
        pageNumber: 1
      }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );
  useEffect(() => {
    if (countriesData && !countriesLoading && !countriesError) {
      setCountries(countriesData.data.items);
    }
    if (countriesError) {
      toast.error(getErrorMessage(t, countriesData), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [countriesLoading, countriesData, countriesError]);

  useEffect(() => {
    fetchCountries();
  }, []);

  const examProviderValue = {
    editCard,
    setEditCard,
    countries,
    sectionValidation,
    setSectionValidation,
    exam,
    setExam,
    fetchExam,
    examLoading,
    isReadOnly,
    isCreate
  };

  if (examLoading) {
    return <LoaderWithMessage message={t('exams:message.loadingExam')} />;
  }

  if (examError) {
    return <ErrorPlaceholder message={t('exams:labels.errorLoadingExam')} />;
  }
  if (id && !exam) {
    return '';
  }

  return (
    <ExamContext.Provider value={examProviderValue}>
      {children}
    </ExamContext.Provider>
  );
};

export default CityProvider;
