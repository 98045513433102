import { AuthContext } from 'context/Context';
import React, { useContext, useEffect } from 'react';
import { Col, Row } from 'react-bootstrap';
import ProfileBanner from 'pages/User/settings/ProfileBanner';
//import AccountSettings from 'components/user/settings/AccountSettings';
import ChangePassword from 'pages/User/settings/ChangePassword';
import ProfileSettings from 'pages/User/settings/ProfileSettings';

import defaultCoverSrc from 'assets/img/spark/bg-banner.png';
import defaultAvatar from 'assets/img/team/avatar.png';
import { toApiFileUrl } from 'helpers/utils';
import { useState } from 'react';
import { useQuery } from 'react-query';
import UserService from 'http/UserService';
import { useParams } from 'react-router-dom';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';

const UserProfileSettings = () => {
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const { t } = useTranslation();
  const [userData, setUserData] = useState(null);
  const {
    isLoading: userProfileLoading,
    data: userProfileData,
    error: userProfileError,
    refetch: fetchUser
  } = useQuery(
    ['UserData', user?.id],
    () => (!id ? UserService.getProfileInfo() : UserService.getUserInfo(id)),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  useEffect(() => {
    fetchUser(id || null);
  }, []);

  useEffect(() => {
    if (userProfileData && !userProfileLoading && !userProfileError) {
      if (userProfileData?.errors?.length) {
        toast.error(getErrorMessage(t, userProfileData.errors), {
          theme: 'colored',
          autoClose: false
        });
      }
      //console.log('userProfileData', userProfileData);
      setUserData(userProfileData);
    }

    if (userProfileError) {
      toast.error(getErrorMessage(t, userProfileError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [userProfileLoading, userProfileData, userProfileError]);

  if (userProfileLoading) {
    return <LoaderWithMessage message={t('user:message.loadingUser')} />;
  }
  if (!user) {
    return '';
  }

  return (
    <>
      <ProfileBanner>
        <ProfileBanner.Header
          coverSrc={userData?.coverUrl || defaultCoverSrc}
          avatar={
            userData?.avatarUrl
              ? toApiFileUrl(userData?.avatarUrl)
              : defaultAvatar
          }
          userData={userData}
          className="mb-8"
          refresh={() => fetchUser(id || null)}
        />
      </ProfileBanner>
      <Row className="g-3">
        <Col lg={8}>
          <ProfileSettings user={userData} />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar">
            {/* <AccountSettings /> */}
            <ChangePassword user={userData} />
          </div>
        </Col>
      </Row>
    </>
  );
};

export default UserProfileSettings;
