import {
  faBook,
  faCloudDownloadAlt,
  faEdit,
  faGripLines,
  faTrash,
  faVideo
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { truncateString } from 'helpers/utils';
import React from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const CurriculumLessonItem = ({
  t,
  lesson,
  index,
  isReadOnly,
  breakpoints,
  currentLanguage,
  setResourcesOpen,
  course,
  chapter,
  setLessonToEdit,
  handleClickDeleteLesson
}) => {
  const title = lesson.title[currentLanguage];
  return (
    <Draggable draggableId={`${lesson.id}`} index={index} disabled={isReadOnly}>
      {provided => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className="d-flex align-items-center"
        >
          <Flex
            key={`lesson-content-${lesson.id}`}
            gap="2"
            className={`w-100 ${
              breakpoints.down('md') ? 'mx-1' : 'ms-2'
            } my-1 p-2 border-grey border-1 border rounded`}
            alignItems="center"
            justifyContent="between"
          >
            <div
              {...provided.dragHandleProps}
              style={{
                cursor: 'grab'
              }}
              className={`d-flex align-items-center ${
                isReadOnly ? 'd-none' : ''
              }`}
            >
              {!isReadOnly && <FontAwesomeIcon icon={faGripLines} />}
            </div>
            <Flex
              className="w-75"
              alignItems="center"
              justifyContent="start"
              gap="2"
            >
              {!breakpoints.down('md') && (
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={'tooltip-lesson-' + lesson.id}>
                      {t('course:labels.lesson')}
                    </Tooltip>
                  }
                >
                  <span className="ms-2 ">
                    <FontAwesomeIcon
                      icon={lesson.type === 'video' ? faVideo : faBook}
                    />
                  </span>
                </OverlayTrigger>
              )}

              <p className="mx-2 mb-0 text-nowrap">
                {truncateString(title, breakpoints.down('md') ? 15 : 150)}
              </p>
            </Flex>
            {!isReadOnly && (
              <Flex
                gap="2"
                className="w-100"
                alignItems="center"
                justifyContent="end"
              >
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={'tooltip-resources-lesson-' + lesson.id}>
                      {t('course:labels.downloadableResources')}
                    </Tooltip>
                  }
                >
                  <span className="ms-2 text-primary fs-0">
                    <FontAwesomeIcon
                      icon={faCloudDownloadAlt}
                      className="cursor-pointer"
                      size="sm"
                      onClick={() =>
                        setResourcesOpen({
                          lesson,
                          course
                        })
                      }
                    />
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={'tooltip-edit-lesson-' + lesson.id}>
                      {t('common:button.edit')}
                    </Tooltip>
                  }
                >
                  <span className="ms-2 text-primary fs-0">
                    <FontAwesomeIcon
                      icon={faEdit}
                      className="cursor-pointer"
                      size="sm"
                      onClick={() =>
                        setLessonToEdit({
                          lesson,
                          chapter
                        })
                      }
                    />
                  </span>
                </OverlayTrigger>
                <OverlayTrigger
                  placement="top"
                  overlay={
                    <Tooltip id={'tooltip-delete-lesson-' + lesson.id}>
                      {t('common:button.delete')}
                    </Tooltip>
                  }
                >
                  <span className="ms-2 text-danger fs-0">
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="cursor-pointer"
                      size="sm"
                      onClick={() =>
                        handleClickDeleteLesson({
                          id: lesson.id,
                          chapterId: chapter.id
                        })
                      }
                    />
                  </span>
                </OverlayTrigger>
              </Flex>
            )}
          </Flex>
        </div>
      )}
    </Draggable>
  );
};

export default CurriculumLessonItem;
