import React, { useState, useEffect, useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import CourseInformationForm from './components/CourseInformationForm';

import CourseRequirements from './components/CourseRequirements';
import CourseCurriculums from './components/CourseCurriculums';
import CreateCourseHeader from './components/CreateCourseHeader';
import ScheduleDiscountModal from './components/ScheduleDiscountModal';
import { useParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import CourseService from 'http/CourseService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { useTranslation } from 'react-i18next';
import ImageCard from 'components/common/ImageCard';
import VideoCard from 'components/common/VideoCard';
import CourseInstructorCard from './components/CourseInstructorCard';
import CoursePrice from './components/CoursePrice';
import AuditEventsCard from 'components/common/AuditEvents/AuditEventsCard';
import CourseActions from './components/CourseActions';
import { CourseContext } from 'context/Context';
import Error404 from 'components/errors/Error404';
import { convertToUniqueName, getFileExtensionFromUrl } from 'helpers/utils';
import { validateCourseBasicForm } from './utils';

const CreateCourse = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { course, isReadOnly, fetchCourse, setSectionValidation } =
    useContext(CourseContext);
  const [isOpenScheduleModal, setIsOpenScheduleModal] = useState(false);

  const {
    mutate: uploadCourseImage,
    error: uploadCourseImageError,
    isLoading: uploadCourseImageLoading
  } = useMutation({
    mutationFn: CourseService.uploadCourseImage,
    onSuccess: data => onUploadCourseImageSuccess(data)
  });

  const {
    mutate: uploadCourseCover,
    error: uploadCourseCoverError,
    isLoading: uploadCourseCoverLoading
  } = useMutation({
    mutationFn: CourseService.uploadCourseCover,
    onSuccess: data => onUploadCourseImageSuccess(data)
  });

  const {
    mutate: uploadCoursePromoVideo,
    error: uploadCoursePromoVideoError,
    isLoading: uploadCoursePromoVideoLoading
  } = useMutation({
    mutationFn: CourseService.updateCoursePromoVideo,
    onSuccess: data => onUploadCourseImageSuccess(data)
  });

  const onUploadCourseImageSuccess = () => {
    fetchCourse(course?.id);
    toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
  };

  const handleCourseImageChange = file => {
    let data = new FormData();
    data.append('courseId', course.id);
    data.append(
      'file',
      file,
      `${convertToUniqueName(course?.title?.en)}.${getFileExtensionFromUrl(
        file.name
      )}`
    );
    uploadCourseImage(data);
  };
  const handleCourseCoverChange = file => {
    let data = new FormData();
    data.append('courseId', course.id);
    data.append(
      'file',
      file,
      `${convertToUniqueName(
        course?.title?.en
      )}_cover.${getFileExtensionFromUrl(file.name)}`
    );
    uploadCourseCover(data);
  };

  const handleCoursePromoVideoChange = ({ file, url }) => {
    let data = new FormData();
    data.append('courseId', course.id);
    data.append('file', file);
    uploadCoursePromoVideo({
      courseId: course?.id,
      url: url
    });
  };

  useEffect(() => {
    //console.log('useEffect::: course', course);
    if (!!course?.id && course?.status?.code === 'draft') {
      let validations = {};
      const isValid = validateCourseBasicForm(course);

      validations = {
        ...validations,
        basicForm: {
          isValid,
          message: isValid ? null : t('course:validation.message.instructors')
        }
      };
      if (!!course?.instructors?.length) {
        validations = {
          ...validations,
          instructors: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          instructors: {
            isValid: false,
            message: t('course:validation.message.instructors')
          }
        };
      }

      if (!!course?.chapters?.length) {
        if (course?.chapters?.some(c => c.lessons?.length)) {
          if (
            course?.chapters?.map(ch => ch?.lessons?.some(l => !!l.content))
          ) {
            validations = {
              ...validations,
              resources: {
                isValid: true,
                message: null
              }
            };
          } else {
            validations = {
              ...validations,
              resources: {
                isValid: false,
                message: t('course:validation.message.resources')
              }
            };
          }
        } else {
          validations = {
            ...validations,
            resources: {
              isValid: false,
              message: t('course:validation.message.resources')
            }
          };
        }
      } else {
        validations = {
          ...validations,
          resources: {
            isValid: false,
            message: t('course:validation.message.resources')
          }
        };
      }

      if (!!course?.info.find(inf => inf.type === 'learn')) {
        validations = {
          ...validations,
          learn: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          learn: {
            isValid: false,
            message: t('course:validation.message.learn')
          }
        };
      }
      if (!!course?.info.find(inf => inf.type === 'target')) {
        validations = {
          ...validations,
          target: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          target: {
            isValid: false,
            message: t('course:validation.message.learn')
          }
        };
      }
      if (!!course?.info.find(inf => inf.type === 'requirement')) {
        validations = {
          ...validations,
          requirement: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          requirement: {
            isValid: false,
            message: t('course:validation.message.requirement')
          }
        };
      }
      if (!!course?.image) {
        validations = {
          ...validations,
          image: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          image: {
            isValid: false,
            message: t('course:validation.message.image')
          }
        };
      }
      if (!!course?.cover) {
        validations = {
          ...validations,
          cover: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          cover: {
            isValid: false,
            message: t('course:validation.message.cover')
          }
        };
      }
      if (!!course?.promoVideo) {
        validations = {
          ...validations,
          promoVideo: {
            isValid: true,
            message: null
          }
        };
      } else {
        validations = {
          ...validations,
          promoVideo: {
            isValid: false,
            message: t('course:validation.message.promoVideo')
          }
        };
      }

      setSectionValidation(validations);
    }
  }, [course]);

  useEffect(() => {
    if (uploadCourseImageError) {
      toast.error(getErrorMessage(t, uploadCourseImageError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (uploadCourseCoverError) {
      toast.error(getErrorMessage(t, uploadCourseCoverError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (uploadCoursePromoVideoError) {
      toast.error(getErrorMessage(t, uploadCoursePromoVideoError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [
    uploadCourseImageError,
    uploadCourseCoverError,
    uploadCoursePromoVideoError
  ]);

  if (!!id && !course?.id) {
    return <Error404 />;
  }

  return (
    <>
      <Row className="g-lg-3 font-sans-serif">
        <Col lg={8}>
          <CreateCourseHeader />
          <CourseInformationForm />
          <CourseInstructorCard />
          <CourseCurriculums />
          <CourseRequirements />
        </Col>
        <Col lg={4}>
          <div className="sticky-sidebar top-navbar-height d-flex flex-column">
            {/* <PublishCourse /> */}
            <CourseActions />
            <CoursePrice />
            <ImageCard
              onFileChange={file => handleCourseImageChange(file)}
              url={course?.image || null}
              title={t('course:labels.courseImage')}
              tooltip={t('course:labels.uploadCourseImage')}
              loading={uploadCourseImageLoading}
              baseUrl={process.env.REACT_APP_FILES_URL}
              isReadOnly={isReadOnly}
              hidden={!course?.id}
            />
            <ImageCard
              onFileChange={file => handleCourseCoverChange(file)}
              url={course?.cover || null}
              title={t('course:labels.courseCover')}
              tooltip={t('course:labels.uploadCourseCover')}
              loading={uploadCourseCoverLoading}
              baseUrl={process.env.REACT_APP_FILES_URL}
              isReadOnly={isReadOnly}
              hidden={!course?.id}
            />
            <VideoCard
              onFileChange={file => handleCoursePromoVideoChange(file)}
              url={course?.promoVideo || null}
              title={t('course:labels.coursePromoVideo')}
              tooltip={t('course:labels.uploadCoursePromoVideo')}
              loading={uploadCoursePromoVideoLoading}
              //baseUrl={process.env.REACT_APP_FILES_URL}
              vimeo
              isReadOnly={isReadOnly}
              hidden={!course?.id}
            />
            <AuditEventsCard
              eventsFn={() => CourseService.getCourseAuditEvents(id)}
              eventsFnName="getCourseAuditEvents"
              eventsFnPaylaod={id}
            />
          </div>
        </Col>
      </Row>
      {/* Modal */}
      <ScheduleDiscountModal
        isOpenScheduleModal={isOpenScheduleModal}
        setIsOpenScheduleModal={setIsOpenScheduleModal}
      />
    </>
  );
};

export default CreateCourse;
