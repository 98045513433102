import { CourseContext, AuthContext } from 'context/Context';
import CourseService from 'http/CourseService';
import { getErrorMessage } from 'http/utils';
import { isCourseEditMode, canEditCourse } from 'pages/Operations/Course/utils';
import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import LoaderWithMessage from 'components/common/LoaderWithMessage';

const CourseProvider = ({ children }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [course, setCourse] = useState(null);
  const [categories, setCategories] = useState([]);
  const [editCard, setEditCard] = useState(null);
  const [isCreate, setIsCreate] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [sectionValidation, setSectionValidation] = useState(null);

  const {
    isLoading: courseLoading,
    data: courseData,
    error: courseError,
    refetch: fetchCourse
  } = useQuery(['CreateCourseData', id], () => CourseService.get(id), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false,
    enabled: false
  });

  const {
    isLoading: categoriesLoading,
    data: categoriesData,
    error: categoriesError,
    refetch: fetchCategories
  } = useQuery(
    'CoursesCategories',
    () =>
      CourseService.listCategories({
        pageSize: 1000,
        pageNumber: 1
      }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  useEffect(() => {
    if (!!id && !!user) {
      fetchCourse(id);
    } else {
      setIsCreate(true);
    }
  }, [id, user]);

  useEffect(() => {
    fetchCategories();
  }, []);

  useEffect(() => {
    if (courseData && !courseLoading && !courseError) {
      if (courseData?.errors?.length) {
        toast.error(getErrorMessage(t, courseData.errors), {
          theme: 'colored',
          autoClose: false
        });
      }

      //console.log('courseData', courseData);
      const canEdit =
        canEditCourse(user) && isCourseEditMode(courseData.payload);
      //console.log('canEdit', canEdit);
      setIsCreate(false);
      setIsReadOnly(!canEdit);
      setCourse(courseData.payload);
    }

    if (courseError) {
      toast.error(getErrorMessage(t, courseError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [courseLoading, courseData, courseError]);

  useEffect(() => {
    if (categoriesData && !categoriesLoading && !categoriesError) {
      setCategories(categoriesData.payload.data);
    }
    if (categoriesError) {
      toast.error(getErrorMessage(t, categoriesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [categoriesLoading, categoriesData, categoriesError]);

  const courseProviderValue = {
    editCard,
    setEditCard,
    categories,
    sectionValidation,
    setSectionValidation,
    course,
    setCourse,
    fetchCourse,
    courseLoading,
    isReadOnly,
    isCreate
  };

  if (courseLoading) {
    return <LoaderWithMessage message={t('course:message.loadingCourse')} />;
  }

  if (courseError) {
    return (
      <ErrorPlaceholder message={t('course:message.courseLoadingError')} />
    );
  }
  if (id && !course) {
    return '';
  }

  return (
    <CourseContext.Provider value={courseProviderValue}>
      {children}
    </CourseContext.Provider>
  );
};

export default CourseProvider;
