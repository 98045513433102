import { faFirstdraft } from '@fortawesome/free-brands-svg-icons';
import {
  faBook,
  faCheckCircle,
  faRedo,
  faRocket,
  faSave,
  faSearch,
  faTimes
} from '@fortawesome/free-solid-svg-icons';

export const learningPathStatuses = [
  {
    name: 'draft',
    icon: faFirstdraft,
    color: 'secondary',
    editable: true
  },
  {
    name: 'modificationsRequested',
    icon: faRedo,
    color: 'warning',
    editable: true
  },
  {
    name: 'prepared',
    icon: faBook,
    color: 'info'
  },
  {
    name: 'modified',
    icon: faSave,
    color: 'warning'
  },
  {
    name: 'onReview',
    icon: faSearch,
    color: 'warning'
  },
  {
    name: 'rejected',
    icon: faTimes,
    color: 'danger'
  },
  {
    name: 'approved',
    icon: faCheckCircle,
    color: 'success'
  },
  {
    name: 'published',
    icon: faRocket,
    color: 'primary'
  }
];

export const getLearningPathActionColor = stateTo => {
  return (
    learningPathStatuses.find(state => state.name === stateTo)?.color ||
    'primary'
  );
};

export const getLearningPathCommentColor = status => {
  return (
    learningPathStatuses.find(state => state.name === status)?.color ||
    'warning'
  );
};

export const getLearningPathProgressStages = status => {
  const normalCases = ['draft', 'onReview', 'approved', 'published'];
  if (normalCases.includes(status)) {
    return learningPathStatuses.filter(s => normalCases.includes(s.name));
  }
  const modificationCase = [
    'modificationsRequested',
    'onReview',
    'approved',
    'published'
  ];
  if (status === 'modificationsRequested') {
    return learningPathStatuses.filter(s => modificationCase.includes(s.name));
  }
  const rejectionCase = ['draft', 'prepared', 'onReview', 'rejected'];
  if (status === 'rejected') {
    return learningPathStatuses.filter(s => rejectionCase.includes(s.name));
  }
};

export const isLearningPathEditMode = learningPath => {
  if (!learningPath) {
    return false;
  }
  if (!learningPath.status) {
    return false;
  }
  const isEdit =
    learningPathStatuses.find(st => st.name === learningPath?.status?.code)
      ?.editable || false;
  //console.log('isEdit', isEdit);
  return isEdit;
};

export const canEditLearningPath = user => {
  if (!user || !user?.roles?.length) {
    console.log('user missing roles', user);
    return false;
  }
  let roles = user.roles;
  const allowedRoles = ['admin', 'planner'];
  if (typeof roles === 'string') {
    roles = [roles];
  }
  const canEdit = !!roles
    .map(r => r.toLowerCase())
    .some(r => allowedRoles.includes(r));
  //console.log(
  //  'canEditLearningPath',
  //  `${canEdit} user is ${roles.map(r => r).join(',')}`
  //);
  return canEdit;
};

export const programmingLanguages = [
  { value: 'javascript' },
  { value: 'java' },
  { value: 'python' },
  { value: 'xml' },
  { value: 'ruby' },
  { value: 'sass' },
  { value: 'markdown' },
  { value: 'mysql' },
  { value: 'json' },
  { value: 'html' },
  { value: 'handlebars' },
  { value: 'golang' },
  { value: 'csharp' },
  { value: 'coffee' },
  { value: 'css' }
];

export const toBasicFormSchema = learningPath => {
  if (!learningPath) {
    return null;
  }
  return {
    learningPathTitleAr: learningPath.title.ar,
    learningPathTitleEn: learningPath.title.en,
    learningPathSubTitleAr: learningPath.subtitle.ar,
    learningPathSubTitleEn: learningPath.subtitle.en,
    learningPathDescriptionAr: learningPath.description.ar,
    learningPathDescriptionEn: learningPath.description.en,
    tags: learningPath.settings.tags,
    level: learningPath.level,
    category: learningPath.category.parentId
      ? learningPath.category.parentId
      : learningPath.category.id,
    subCategory: learningPath.category.parentId ? learningPath.category.id : ''
  };
};

export const validateLearningPathBasicForm = learningPath => {
  const data = toBasicFormSchema(learningPath);
  if (!data.learningPathTitleAr) {
    return false;
  }
  if (!data.learningPathTitleEn) {
    return false;
  }
  if (!data.learningPathSubTitleAr) {
    return false;
  }
  if (!data.learningPathSubTitleEn) {
    return false;
  }
  if (!data.learningPathDescriptionAr) {
    return false;
  }
  if (!data.learningPathDescriptionEn) {
    return false;
  }
  if (!data.level) {
    return false;
  }
  if (!data.category) {
    return false;
  }
  return true;
};

export const getLearningPathPricing = learningPath => {
  if (!learningPath?.discounts?.length) {
    return {
      price: learningPath?.settings?.price,
      oldPrice: learningPath?.settings?.oldPrice
    };
  }
  const discount = learningPath.discounts[0];
  if (discount.type === 'offer') {
    return {
      price: discount.customPrice,
      oldPrice: learningPath?.settings?.price
    };
  }
  if (discount.type === 'discount') {
    return {
      price: (
        (learningPath?.settings?.price || 0) -
        (discount.discountPercentage * learningPath?.settings?.price) / 100
      ).toFixed(2),
      oldPrice: learningPath?.settings?.price
    };
  }
  return {
    price: learningPath?.settings?.price,
    oldPrice: learningPath?.settings?.oldPrice
  };
};
