import React from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';

import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import ConfirmModal from 'components/common/ConfirmModal';
import { useContext } from 'react';
import { ExamContext } from 'context/Context';
import { toExamFormSchema } from '../utils';
import DealService from 'http/DealService';

const initDeal = {
  plan:null,
  company:null,
  space:null,
  price:null
};

const optionalFields = [
  "modifiedAt",
  "modifiedBy"
];

const DealBasicForm = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const { companies,plans,exam, examLoading, editCard, setEditCard, fetchExam } = useContext(ExamContext);
  const [dealValues, setDealValues] = useState(null);
  const [formKey, setFormKey] = useState(Date.now()); // Create a state variable to force re-render
  const [errors, setErrors] = useState(null);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState(null);

  
  const { register, handleSubmit, setValue, control, reset, getValues } =
  useForm({
    defaultValues: null
  });

  useEffect(() => {
    if (exam?.id) {
      fillInitialData(exam || null);
    } else {
      fillInitialData(initDeal);
    }
  }, [exam]);

  

  useEffect(() => {
    setValue('company', selectedCompany, { shouldValidate: true });
  }, [selectedCompany]);
  useEffect(() => {
    setValue('plan', selectedPlan, { shouldValidate: true });
  }, [selectedPlan]);

  const {
    mutate: createExam,
    error: examCreateError,
    isLoading: examCreateLoading
  } = useMutation({
    mutationFn: DealService.create,
    onSuccess: data => onCreateExamSuccess(data)
  });
  const {
    mutate: updateCompany,
    error: planUpdateError,
    isLoading: planUpdateLoading
  } = useMutation({
    mutationFn: DealService.update,
    onSuccess: data => onUpdateCompanySuccess(data)
  });

  const fillInitialData = data => {
    if (!data) {
      return;
    }
    const formattedData = toExamFormSchema(data);
    Object.entries(formattedData).map(([key, value]) => {
      setValue(key, value);
    });
    setSelectedCompany(formattedData.company);
    setSelectedPlan(formattedData.plan);
    setDealValues(formattedData);
    setFormKey(Date.now());
  };

  const onSubmit = () => {
    //console.log('Submitted Values:', getValues());
    //console.log('planValues before validation:', planValues);
    const isValid = validateForm(dealValues);
    if (!isValid) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    const values = { ...dealValues };
    let result = {};
    Object.entries(values).map(([key, value]) => {
      const isValid = !!value;
      result[key] = isValid;
    });
    let createPayload = {
      planId:values.plan ,
      companyId:values.company,
      price:values.price,
      space:values.space
    };

    if (exam?.id) {
      createPayload['id'] = exam?.id;
      updateCompany(createPayload);
    }else{
      createExam(createPayload);
    }
  };

  const onCreateExamSuccess = response => {
    toast.success(
      t(`common:message.${!exam?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
    if (typeof response?.data === 'string' && !exam?.id) {
      navigate(`/deals/create/${response.data}`);
    } else {
      setEditCard(null);
      fetchExam(exam.id);
    }
  };
  const onUpdateCompanySuccess = response => {
    toast.success(
      t(`common:message.${!exam?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
    if (typeof response?.data === 'string' && !exam?.id) {
      navigate(`/deals/create/${response.data}`);
    } else {
      setEditCard(null);
      fetchExam(exam.id);
    }
  };

  const validateForm = values => {
    let valid = true;
    let errors = {};
    if (!values) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return false;
    }
    Object.entries(values).map(([key, value]) => {
      if (key === 'planNameEn') { 
        if (value?.length > 60) {
          errors['planNameEn'] = {
            error: true,
            message: t('course:validation.message.maxAllowedCharacters', {
              count: 60
            })
          };
        }
      }
      if (!value && !optionalFields.includes(key)) {
        errors[key] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
        valid = false;
      }
    });

    setErrors(errors);
    //console.log('onError', errors);
    return valid;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const dataType =
      e.target.getAttribute && e?.target?.getAttribute('data-type');
    if (dataType === 'number') {
      if (!!value && isNaN(value)) {
        return;
      }
    }

    if (!!value) {
      setErrors({ ...errors, [name]: { error: false, message: '' } });
    }
    setDealValues({ ...dealValues, [name]: value });
  };

  const onCancel = () => {
    if (editCard === 'basicInfo') {
      fillInitialData(exam);
      setEditCard(null);
      return;
    }

    const hasChanges =
      JSON.stringify({ ...toExamFormSchema(exam) }) !==
      JSON.stringify({ ...dealValues });
    if (hasChanges) {
      setConfirmModalProps({
        isOpen: true,
        onClose: () => setConfirmModalProps(null),
        onConfirm: () => navigate('/deals'),
        message: t('deals:message.discardExamConfirm'),
        header: t('deals:message.discardExamTitle'),
        actionButtonColor: 'danger',
        actionButtonText: t('common:button.discard')
      });
      return;
    }
    navigate('/contents/deals');
  };

  useEffect(() => {
    if (examCreateError) {
      toast.error(getErrorMessage(t, examCreateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [examCreateError]);

  if (examLoading) {
    return <LoaderWithMessage message={t('deals:message.loadingCompany')} />;
  }
  useEffect(() => {
    if (planUpdateError) {
      toast.error(getErrorMessage(t, planUpdateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [planUpdateError]);

  useEffect(() => {
    // Check if the "space" value is not empty before making the API call
    if (dealValues?.space && dealValues?.plan && dealValues.space.length >= 3) {
      // Assuming there is a function called makeApiCall in DealService to make the API call
      DealService.GetCalculatedPrice({ space: dealValues.space, planId: dealValues.plan })
        .then(response => {
          
          // Step 3: Update the state with the API response
          // Step 4: Set the value of the "price" input to the value from the API response
         setValue('price', response.data);
         setDealValues({ ...dealValues, 'price': response.data });
        })
        .catch(error => {
          // Handle API call errors if needed
          toast.error(getErrorMessage(t, error), {
            theme: 'colored',
            autoClose: false
          });
        });
    }else{
     // setDealValues({ ...dealValues, 'price': null });
    }
  }, [dealValues?.space, dealValues?.plan]);


  if (examLoading) {
    return <LoaderWithMessage message={t('deals:message.loadingDeal')} />;
  }
  return (
    <Card className="mb-3">
      <Card.Header as="h5">
        {!!id ? exam?.title?.[currentLanguage] : t('deals:labels.newDeal')}
      </Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3" key={formKey}>
        <Col md="6">
            <Form.Group>
              <Form.Label>
                {t('deals:labels.plan')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                {...register(`plan`, { required: true })}
                value={selectedPlan || ''}
                required
                disabled={examCreateLoading ||
                  examLoading ||
                  planUpdateLoading||
                  (!!id && editCard !== 'basicInfo')}
                onChange={e => {
                  setSelectedPlan(e.target.value);
                  setValue('plan', e.target.value);
                  setDealValues({ ...dealValues, 'plan': e.target.value });
                }}
                isInvalid={!!errors?.plan?.error}
              >
                <option value="">
                  {t('deals:labels.selectPlan')}
                </option>
                {plans
                  .map((plan, index) => {
                    return (
                      <option
                        key={`course-category-${plan.id}-${index}`}
                        value={plan.id}
                      >
                        {plan.name}
                      </option>
                    );
                  })}
              </Form.Select>
              <Form.Text className="text-danger">
                {!!errors?.plan?.error && errors?.plan?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group>
              <Form.Label>
                {t('deals:labels.company')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                {...register(`company`, { required: true })}
                value={selectedCompany || ''}
                required
                disabled={examCreateLoading ||
                  examLoading ||
                  planUpdateLoading||
                  (!!id && editCard !== 'basicInfo')}
                onChange={e => {
                  setSelectedCompany(e.target.value);
                  setValue('company', e.target.value);
                  setDealValues({ ...dealValues, 'company': e.target.value });
                }}
                isInvalid={!!errors?.company?.error}
              >
                <option value="">
                  {t('deals:labels.selectCompany')}
                </option>
                {companies
                  .map((company, index) => {
                    return (
                      <option
                        key={`company-category-${company.id}-${index}`}
                        value={company.id}
                      >
                        {company.name}
                      </option>
                    );
                  })}
              </Form.Select>
              <Form.Text className="text-danger">
                {!!errors?.company?.error && errors?.company?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="dealSpace">
              <Form.Label>
                {`${t('deals:labels.space')}`}
                <span className="text-danger">*</span>
                <small>
                {`(${t('common:labels.spaceOfArea')})`}
                </small>
              </Form.Label>
              <Form.Control
                data-type="number"
                name="space"
                required
                placeholder={t('deals:labels.space')}
                onChange={handleChange}
                value={dealValues?.space || ''}
                disabled={
                  examCreateLoading ||
                  examLoading ||
                  planUpdateLoading||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.space?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.space?.error && errors?.space?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="companyPrice">
              <Form.Label>
                {`${t('deals:labels.price')}`}
              </Form.Label>
              <Form.Control
                type="text"
                name="price"
                required
                placeholder={t('deals:labels.price')}
                onChange={handleChange}
                value={dealValues?.price || ''}
                disabled
                isInvalid={!!errors?.price?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.price?.error &&
                  errors?.price?.message}
              </Form.Text>
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer>
        {!id && (
          <>
            <Button size="sm" onClick={onSubmit}>
              {examCreateLoading && <Spinner size="sm" />}
              {!examCreateLoading && t('common:button.save')}
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
            >
              {!examCreateLoading && t('common:button.cancel')}
            </Button>
          </>
        )}
        {editCard === 'basicInfo' && !!id && (
          <>
            <Button
              size="sm"
              onClick={onSubmit}
              disabled={!!examCreateLoading || examLoading}
            >
              {examCreateLoading && <Spinner size="sm" />}
              {!examCreateLoading && t('common:button.save')}
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
              disabled={!!examCreateLoading || examLoading}
            >
              {t('common:button.cancel')}
            </Button>
          </>
        )}
        {editCard !== 'basicInfo' && !!id && (
          <>
            <Button
              size="sm"
              variant="info"
              onClick={() => setEditCard('basicInfo')}
              disabled={!!examCreateLoading || examLoading}
            >
              {t('common:button.edit')}
            </Button>
          </>
        )}
      </Card.Footer>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={false} />
      )}
    </Card>
  );
};
export default DealBasicForm;
