import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faGripLines,
  faPlus
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AppContext from 'context/Context';
import { generateRandomPassword } from 'helpers/utils';
import React, { useState } from 'react';
import { useEffect } from 'react';
import { createRef } from 'react';
import { useRef } from 'react';
import { useContext } from 'react';
import {
  Col,
  Collapse,
  Form,
  OverlayTrigger,
  Row,
  Tab,
  Tabs,
  Tooltip
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';
import TinymceEditor from 'components/common/TinymceEditor';
import Xarrow, { useXarrow } from 'react-xarrows';
import Draggable from 'react-draggable';
import useIsMobile from 'hooks/useIsMobile';
import Answer from './Answer';
import { toast } from 'react-toastify';

const initAnswer = {
  content: {
    ar: '',
    en: ''
  },
  correct: false
};

const DraggableBox = ({ innerRef, children, ...rest }) => {
  const updateXarrow = useXarrow();
  return (
    <Draggable onDrag={updateXarrow} onStop={updateXarrow} disabled>
      <div ref={innerRef} style={undefined} {...rest}>
        {children && children}
      </div>
    </Draggable>
  );
};

const Question = ({
  question,
  index,
  errors,
  dragHandleProps,
  handleChange,
  questionsTitleRef,
  onOpenChange,
  openQuestionsUID
}) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n?.language;
  const elementRef = useRef(null);
  const answersTitleRef = useRef(null);
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const [isOpen, setIsOpen] = useState(false);
  const [hoveredAnswer, setHoveredAnswer] = useState(null);
  const [answersOpen, setAnswersOpen] = useState(false);
  const [answersRefs, setAnswersRefs] = useState([]);

  const [questionArrow, setQuestionArrow] = useState(null);
  const [answersArrow, setAnswersArrow] = useState(null);
  const [answersArrows, setAnswersArrows] = useState([]);

  const isMobile = useIsMobile();
  const initTrueFalseAnswer = [
    {
      content: {
        ar: t('course:labels:true.ar'),
        en: t('course:labels:true.en')
      },
      correct: false
    },
    {
      content: {
        ar: t('course:labels:false.ar'),
        en: t('course:labels:false.en')
      },
      correct: false
    }
  ];
  const drawQuestionArrow = () => {
    const position = isRTL ? 'right' : 'left';
    const quesArrow = () => {
      return (
        <Xarrow
          start={questionsTitleRef} //can be react ref
          end={elementRef} //or an id
          path="grid"
          gridBreak="0%"
          startAnchor={{
            position: position,
            offset: { x: isRTL ? 10 : -10 }
          }}
          endAnchor={{
            position: position,
            offset: { x: isRTL ? -15 : 15 }
          }}
          lineColor="#00205C"
          strokeWidth={2}
          showHead
          headShape="circle"
          tailShape="circle"
          headSize={3}
          headColor="#00205C"
          showTail={true}
          tailSize={3}
          tailColor="#00205C"
          animateDrawing={0.2}
        />
      );
    };
    setQuestionArrow(quesArrow);
  };
  const drawQAnswersArrow = () => {
    const position = isRTL ? 'right' : 'left';
    const aArrow = () => {
      return (
        <Xarrow
          start={elementRef} //can be react ref
          end={answersTitleRef} //or an id
          path="grid"
          gridBreak="0%"
          startAnchor={{
            position: position,
            offset: { x: isRTL ? -5 : 5, y: 0 }
          }}
          endAnchor={{
            position: position,
            offset: { x: isRTL ? -5 : 5, y: 0 }
          }}
          lineColor="#00205C"
          strokeWidth={2}
          headShape="circle"
          tailShape="circle"
          headSize={3}
          headColor="#00205C"
          tailSize={3}
          tailColor="#00205C"
          showTail={false}
          showHead={true}
          animateDrawing={0.2}
        />
      );
    };
    setAnswersArrow(aArrow);
  };

  useEffect(() => {
    console.log('question', question);
  }, [question]);

  useEffect(() => {
    if (!isMobile) {
      setQuestionArrow(null);
      setAnswersArrow(null);
      setTimeout(() => {
        drawQuestionArrow();
        drawQAnswersArrow();
      }, 500);
    }
  }, [isOpen, openQuestionsUID]);

  useEffect(() => {
    if (
      elementRef?.current &&
      questionsTitleRef?.current &&
      answersTitleRef?.current &&
      !isMobile
    ) {
      setTimeout(() => {
        drawQuestionArrow();
        drawQAnswersArrow();
      }, 500);
    }
  }, [elementRef, questionsTitleRef, answersTitleRef]);

  const handleQuestionChange = q => {
    if (answersOpen && !isMobile) {
      setAnswersRefs([]);
      setAnswersArrows([]);
    }
    let answers = [];
    if (!!question?.id) {
      if (q.type === question?.type) {
        answers = question?.answers || [];
      } else {
        switch (q.type) {
          case 'trueOrFalse':
            answers = initTrueFalseAnswer;
            break;
          case 'multipleChoices':
            answers = [initAnswer];
            break;
          default:
            break;
        }
      }
    } else {
      switch (q.type) {
        case 'trueOrFalse':
          answers = initTrueFalseAnswer;
          break;
        case 'multipleChoices':
          answers = [initAnswer];
          break;
        default:
          break;
      }
    }

    handleChange({ ...q, answers }, index);
  };

  const handleAnswerChange = (answer, index, question, questionIndex) => {
    let oldAnswers = [...(question?.answers || [])];
    oldAnswers[index] = answer;
    let newQuestion = { ...question, answers: oldAnswers };
    handleChange(newQuestion, questionIndex);
  };

  const handleDeleteAnswer = (index, questionIndex) => {
    let oldAnswers = [...(question?.answers || [])];
    oldAnswers.splice(index, 1);
    let newQuestion = { ...question, answers: oldAnswers };
    handleChange(newQuestion, questionIndex);
  };

  const handleAddAnswer = () => {
    if (!question?.type) {
      toast.error(t('course:validation.message.questionType'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    if (answersOpen && !isMobile) {
      setAnswersRefs([]);
      setAnswersArrows([]);
    }
    const newAnswer = {
      ...initAnswer,
      uid: generateRandomPassword(5),
      rank: ((question?.answers || [])?.length || 1) - 1
    };
    handleChange(
      { ...question, answers: [...(question?.answers || []), newAnswer] },
      index
    );
  };

  useEffect(() => {
    if (question?.answers?.length) {
      if (question?.answers?.length !== answersRefs?.length) {
        let refs = [...answersRefs];
        question?.answers?.map((a, i) => {
          if (!refs[i]) {
            refs.push(createRef());
          }
        });
        setAnswersRefs(refs);
      }
    }
  }, [question?.answers]);

  useEffect(() => {
    if (
      elementRef?.current &&
      questionsTitleRef?.current &&
      answersTitleRef?.current &&
      answersOpen
    ) {
      if (answersRefs?.length) {
        if (!isMobile && answersOpen) {
          setTimeout(() => {
            drawAnswersArrows(answersRefs);
          }, 500);
        }
      }
    }
  }, [
    answersRefs,
    answersOpen,
    elementRef,
    questionsTitleRef,
    answersTitleRef
  ]);

  const drawAnswersArrows = answersRefs => {
    if (answersRefs?.length) {
      if (answersTitleRef.current) {
        let allArrows = [...answersArrows];
        answersRefs.map(ref => {
          const answerArrow = () => {
            const position = isRTL ? 'right' : 'left';
            return (
              <Xarrow
                key={ref.current.id}
                id={ref.current.id}
                start={answersTitleRef} //can be react ref
                end={ref} //or an id
                path="grid"
                gridBreak="0%"
                startAnchor={{
                  position: position,
                  offset: { x: isRTL ? 5 : -5, y: 0 }
                }}
                endAnchor={{
                  position: position,
                  offset: { x: isRTL ? 10 : -10, y: 0 }
                }}
                lineColor="#00205C"
                strokeWidth={2}
                headShape="circle"
                tailShape="circle"
                headSize={3}
                headColor="#00205C"
                showTail={false}
                tailSize={3}
                tailColor="#00205C"
                showHead={true}
                animateDrawing={0.2}
              />
            );
          };
          allArrows.push(answerArrow());
          return false;
        });
        setAnswersArrows(allArrows);
      }
    }
  };

  const canAddAnswers = (question, answers) => {
    if (!question) {
      return false;
    }
    if (!answers?.length) {
      return true;
    }
    if (question?.type === 'trueOrFalse' && answers?.length === 2) {
      return false;
    }
    return true;
  };

  return (
    <div className="w-100 mt-2">
      <Flex
        direction="column"
        alignItems="start"
        justifyContent="center"
        className="w-100"
      >
        <div
          className="d-flex justify-content-start align-items-center w-100"
          ref={elementRef}
        >
          <DraggableBox>
            <IconButton
              aria-controls={`question-content-${question?.id}`}
              aria-expanded={isOpen}
              icon={
                isOpen ? faChevronDown : isRTL ? faChevronLeft : faChevronRight
              }
              variant="transparent"
              style={{ boxShadow: 'none' }}
              onClick={() => {
                onOpenChange && onOpenChange();
                setQuestionArrow(null);
                setAnswersArrows([]);
                setAnswersArrow(null);
                setIsOpen(prev => !prev);
              }}
              className={`${
                !isMobile
                  ? `${isRTL ? 'ps-3' : 'pe-3'}`
                  : `${isRTL ? 'ps-0' : 'pe-0'}`
              } ${isRTL ? 'ps-2' : 'pe-2'}`}
            />
            {!!questionArrow && !isMobile && questionArrow}
          </DraggableBox>

          <p className="mb-0">{`${t('course:labels.question')} ${
            index + 1
          }`}</p>
          {!!dragHandleProps && (
            <div
              {...dragHandleProps}
              style={{
                cursor: 'grab'
              }}
              className="d-flex align-items-center"
            >
              <FontAwesomeIcon
                icon={faGripLines}
                className={` ${isRTL ? 'me-2' : 'ms-2'}`}
              />
            </div>
          )}
        </div>
        <Collapse in={isOpen} className=" w-100 mt-2">
          <div className="px-md-5">
            <Row className="w-100 gx-0 gy-1">
              <Col md={12} className="mt-1">
                <Form.Label>
                  {`${t('common:labels.type')}`}
                  <span className="text-danger">*</span>
                </Form.Label>

                <Form.Select
                  onChange={e => {
                    handleQuestionChange({
                      ...question,
                      type: e.target.value
                    });
                  }}
                  value={question?.type || ''}
                  isInvalid={!!errors?.type}
                >
                  <option value="">
                    {t('course:labels.selectQuestionType')}
                  </option>
                  <option value="trueOrFalse">
                    {t('course:question.types.trueOrFalse')}
                  </option>
                  <option value="multipleChoices">
                    {t('course:question.types.multipleChoices')}
                  </option>
                </Form.Select>
              </Col>
            </Row>
            <Row className="w-100 gx-0 gy-1 mt-2">
              <Col md={12} className="mt-1">
                <Form.Label>
                  {`${t('common:labels.content')}`}
                  <span className="text-danger">*</span>
                </Form.Label>

                <div>
                  <Tabs
                    defaultActiveKey={`question-content-${currentLanguage}`}
                    id="uncontrolled-tab-question-content"
                  >
                    <Tab
                      eventKey="question-content-en"
                      title={t('common:language.en.name')}
                      className="border-bottom border-x"
                    >
                      <div
                        className={`tinymce-editor-container ${
                          !!errors?.content?.ar ? 'invalid' : ''
                        }`}
                      >
                        <TinymceEditor
                          height="13.438rem"
                          name="question-content"
                          handleChange={newValue =>
                            handleQuestionChange({
                              ...question,
                              content: {
                                ...question?.content,
                                en: newValue
                              }
                            })
                          }
                          value={question?.content?.en || ''}
                          placeholder={t('course:placeholders.addNewItem')}
                        />
                      </div>
                    </Tab>
                    <Tab
                      eventKey="question-content-ar"
                      title={t('common:language.ar.name')}
                      className="border-bottom border-x"
                    >
                      <div
                        className={`tinymce-editor-container ${
                          !!errors?.content?.en ? 'invalid' : ''
                        }`}
                      >
                        <TinymceEditor
                          height="13.438rem"
                          name="question-content"
                          handleChange={newValue =>
                            handleQuestionChange({
                              ...question,
                              content: {
                                ...question?.content,
                                ar: newValue
                              }
                            })
                          }
                          value={question?.content?.ar || ''}
                          placeholder={t('course:placeholders.addNewItem')}
                        />
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </Col>
            </Row>

            <Row className="w-100 gx-0 gy-1 mt-2">
              <Flex
                direction="column"
                alignItems="start"
                justifyContent="center"
                className="w-100"
              >
                <div
                  className="w-100 d-flex justify-content-start align-items-center"
                  ref={answersTitleRef}
                >
                  <DraggableBox>
                    <IconButton
                      aria-controls={`question-content-${
                        question?.id || index
                      }`}
                      aria-expanded={answersOpen}
                      icon={
                        answersOpen
                          ? faChevronDown
                          : isRTL
                          ? faChevronLeft
                          : faChevronRight
                      }
                      variant="transparent"
                      style={{ boxShadow: 'none' }}
                      className={`${
                        !isMobile
                          ? `${isRTL ? 'pe-2' : 'ps-2'}`
                          : `${isRTL ? 'pe-0' : 'ps-0'}`
                      } ${isRTL ? 'ps-2' : 'pe-2'}`}
                      onClick={() => {
                        if (answersOpen && !isMobile) {
                          setAnswersArrows([]);
                        }
                        onOpenChange && onOpenChange();
                        setAnswersOpen(prev => !prev);
                      }}
                    />
                    {!!answersArrow && !isMobile && answersArrow}
                  </DraggableBox>

                  <p className="mb-0">
                    {`${t('course:labels.answers')} `}{' '}
                    <span className="text-danger">*</span>
                    {canAddAnswers(question, question?.answers) && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip
                            id={`question-answers-add-toolip-${
                              question?.id || index
                            }`}
                          >
                            {t('course:button.addAnswer')}
                          </Tooltip>
                        }
                      >
                        <span style={{ height: '1.5rem' }}>
                          <FontAwesomeIcon
                            icon={faPlus}
                            className="cursor-pointer mx-3"
                            onClick={() => handleAddAnswer()}
                            size="sm"
                          />
                        </span>
                      </OverlayTrigger>
                    )}
                  </p>
                </div>
                <Collapse in={answersOpen} className="w-100">
                  <div className="w-100 px-md-4">
                    {question?.answers?.map((answer, answerIndex) => {
                      const answerKey = answer?.id || answerIndex;
                      const showDelete =
                        (hoveredAnswer === answerKey || isMobile) &&
                        answerIndex !== 0 &&
                        question?.type !== 'trueOrFalse';
                      const correctDisabled =
                        !!question?.answers?.find(a => !!a.correct) &&
                        question?.type === 'trueOrFalse' &&
                        !answer?.correct;

                      return (
                        <Answer
                          key={`quiz-question-answer-${answerIndex}-${answerKey}`}
                          t={t}
                          answer={answer}
                          chapterIndex={index}
                          answersRefs={answersRefs}
                          answerIndex={answerIndex}
                          answerKey={answerKey}
                          showDelete={showDelete}
                          handleDeleteAnswer={handleDeleteAnswer}
                          handleAnswerChange={handleAnswerChange}
                          questionDetails={question}
                          currentLanguage={currentLanguage}
                          disableEditing={false}
                          correctDisabled={correctDisabled}
                          errors={errors?.answers?.answersData?.[answerIndex]}
                          answersArrows={answersArrows}
                          isMobile={isMobile}
                          setHoveredAnswer={setHoveredAnswer}
                        />
                      );
                    })}
                  </div>
                </Collapse>
              </Flex>
            </Row>
          </div>
        </Collapse>
      </Flex>
    </div>
  );
};

export default Question;
