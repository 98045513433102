import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const LIST_DISCOUNTS_URL = `https://admin.api.360realize.com/pricingmodel/list`;
const SAVE_DISCOUNTS_URL = `https://admin.api.360realize.com/pricingmodel/create`;
const GET_DISCOUNTS_URL = `https://admin.api.360realize.com/pricingmodel/get`;
const DELETE_DISCOUNTS_URL = `https://admin.api.360realize.com/pricingmodel/delete`;
const UPDATE_DISCOUNT_STATUS_URL = `https://admin.api.360realize.com/pricingmodel/status/update`;

const DiscountsService = {
  list: data => {
    return http.post(
      LIST_DISCOUNTS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  get: id => {
    return http.get(`${GET_DISCOUNTS_URL}?id=${id}`, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  save: data => {
    return http.post(
      SAVE_DISCOUNTS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  delete: data => {
    return http.post(`${DELETE_DISCOUNTS_URL}?id=${data}`, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  updateStatus: data => {
    return http.post(
      UPDATE_DISCOUNT_STATUS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  }
};

export default DiscountsService;
