import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { AuthContext } from 'context/Context';
import AuthService from 'http/AuthService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';
import { getRedirectUrl } from 'helpers/utils';
import { flattenRoutes } from 'routes/routes';

const isAuthorizedPath = (url, roles) => {
  const route = flattenRoutes.find(c => c.to.startsWith(url));
  if (!route) {
    return false;
  }
  if (
    typeof roles === 'string' &&
    (!route.roles || route.roles.includes(roles))
  ) {
    return true;
  }
  if (
    Array.isArray(roles) &&
    (!route.roles || route.roles.some(r => roles.includes(r)))
  ) {
    return true;
  }

  return false;
};

const isUserLoggedIn = () => {
  let userAuthData = null;
  try {
    userAuthData = JSON.parse(localStorage.getItem('spark_admin_user'));
  } catch {
    userAuthData = null;
  }
  if (!!userAuthData?.tokens.access_token) {
    //console.log('logged in ...');
    return true;
  }
  return false;
};

const getLoggedInUser = () => {
  let userAuthData = null;
  try {
    userAuthData = JSON.parse(localStorage.getItem('spark_admin_user'));
  } catch {
    userAuthData = null;
  }
  return userAuthData;
};

const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    user: getLoggedInUser(),
    isLoggedIn: isUserLoggedIn()
  });
  const [returnUrl, setReturnUrl] = useState(null);
  const { t } = useTranslation();

  const authProviderValue = {
    user: authState?.user || null,
    setUser: userData => {
      setAuthState({ ...authState, user: { ...userData } });
      localStorage.setItem('spark_admin_user', JSON.stringify({ ...userData }));
    },
    isLoggedIn: authState?.isLoggedIn || false,
    returnUrl: null,
    getRedirectUrl,
    setReturnUrl,
    login: ({ ...userData }, location, onFail) => {
      AuthService.login({
        email: userData.email,
        password: userData.password
      })
        .then(response => {
          if (response.data.user.email === (userData.email || userData.emailAddress)) {
            var decoded = jwt_decode(response.data.tokens.access_token);
            // console.log('decoded', decoded);
            toast.success(t('auth:loggedInAs', { username: userData.email }), {
              theme: 'colored'
            });
            const baseUrl = getRedirectUrl({
              ...response.data,
              roles: decoded.role,
              remember: userData.remember
            });
            if (location?.state?.from?.pathname) {
              if (
                isAuthorizedPath(location?.state?.from?.pathname, decoded.role)
              ) {
                window.location.href = location?.state?.from?.pathname;
              } else {
                window.location.href = baseUrl;
              }
            } else {
              window.location.href = baseUrl;
            }
            localStorage.setItem(
              'spark_admin_user',
              JSON.stringify({
                ...response.data,
                roles: decoded.role,
                remember: userData.remember
              })
            );
          } else {
            onFail && onFail();
            console.log('login incorrect data', response.data);
            toast.error(getErrorMessage(t, response.data), {
              theme: 'colored',
              autoClose: false
            });
          }
        })
        .catch(error => {
          // console.log('login error', error);
          onFail && onFail();
          toast.error(getErrorMessage(t, error), {
            theme: 'colored',
            autoClose: false
          });
        });
    },
    logout: () => {
      localStorage.removeItem('spark_admin_user');
      setAuthState(null);
      if (returnUrl) {
        // window.location.href = '/';
      }
    }
  };

  return (
    <AuthContext.Provider value={authProviderValue}>
      {children}
    </AuthContext.Provider>
  );
};

AuthProvider.propTypes = { children: PropTypes.node };
export default AuthProvider;
