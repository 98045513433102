import React from 'react';

const CoursePage = () => {
  return (
    <>
      <h1>Course Page</h1>
    </>
  );
};

export default CoursePage;
