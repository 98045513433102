import { createContext } from 'react';
import { settings } from 'config';

const AppContext = createContext(settings);

export const CourseContext = createContext({ courses: [], primaryCourses: [] });
export const ExamContext = createContext({});
export const LearningPathContext = createContext({
  courses: [],
  primaryCourses: []
});
export const AuthContext = createContext({ user: null, isLoggedIn: false });
export const BlogContext = createContext({ user: null, isLoggedIn: false });

export default AppContext;
