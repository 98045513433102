import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import UserService from 'http/UserService';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import UserListTableHeader from './settings/UserListTableHeader';
import {
  Card,
  Col,
  Dropdown,
  Offcanvas,
  Placeholder,
  Row
} from 'react-bootstrap';
import AdvanceTableWrapper from 'components/common/advance-table/AdvanceTableWrapper';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTablePagination from 'components/common/advance-table/AdvanceTablePagination';
import UserListFilters from './settings/UserListFilters';
import { useBreakpoints } from 'hooks/useBreakpoints';
import Flex from 'components/common/Flex';
import Avatar from 'components/common/Avatar';
import { Link } from 'react-router-dom';
import SoftBadge from 'components/common/SoftBadge';
import { toast } from 'react-toastify';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEdit,
  faKey,
  faToggleOff,
  faToggleOn
} from '@fortawesome/free-solid-svg-icons';
import ConfirmModal from 'components/common/ConfirmModal';
import ChangePasswordModal from './settings/ChangePasswordModal';
import { getErrorMessage } from 'http/utils';
import { toApiFileUrl, truncateString } from 'helpers/utils';

const defaultPaging = {
  pageNumber: 1,
  pageSize: 10
};

const defaultSorting = {
  sortBy: 'createdAt',
  sortDirection: 'DESC'
};

const UserList = () => {
  const { t, i18n } = useTranslation();
  const isRtl = i18n.language === 'ar';
  const currentLanguage = i18n.language;
  const [roles, setRoles] = useState([]);
  const [users, setUsers] = useState([]);
  const [pagingInfo, setPagingInfo] = useState(null);
  const [sortInfo, setSortInfo] = useState({ ...defaultSorting });
  const [filters, setFilters] = useState({
    ...defaultPaging,
    ...defaultSorting
  });
  const [show, setShow] = useState(false);
  const { breakpoints } = useBreakpoints();
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [changePasswordModalProps, setChangePasswordModalProps] =
    useState(null);
  const [requestLoading, setRequestLoading] = useState(false);
  const [userToEdit, setUserToEdit] = useState(null);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const {
    isLoading: rolesLoading,
    error: rolesError,
    data: rolesData
  } = useQuery('RolesList', () => UserService.listRoles(), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false
  });

  const {
    isLoading: usersLoading,
    error: usersError,
    data: usersData,
    refetch: fetchUsers
  } = useQuery(
    ['UsersList', { ...filters, ...sortInfo }],
    () => UserService.list({ ...filters, ...sortInfo }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (rolesData && !rolesLoading && !rolesError) {
      const data = rolesData.data.items.map(role => {
        return {
          ...role,
          key: role.name,
          value: role.id,
          label: t(`common:roles.${role.name.toLowerCase().trim()}`)
        };
      });
      setRoles(data);
    }
    if (rolesError) {
      toast.error(getErrorMessage(t, rolesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [rolesData, rolesError, rolesLoading]);

  useEffect(() => {
    fetchUsers({ ...filters, ...sortInfo, ...defaultPaging });
  }, [filters, sortInfo]);

  useEffect(() => {
    if (usersData && !usersLoading && !usersError) {
      setUsers(usersData.data.items);
      setPagingInfo(usersData.pagingInfo);
    }
    if (usersError) {
      toast.error(getErrorMessage(t, usersError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [usersLoading, usersError, usersData]);

  const handleSort = column => {
    //console.log('handleSort', column);
    const direction = sortInfo.sortDirection === 'ASC' ? 'DESC' : 'ASC';
    setSortInfo({
      sortBy: column,
      sortDirection: direction
    });
  };

  const columns = [
    {
      accessor: 'fullName',
      Header: t('user:labels.username'),
      headerProps: {
        className: 'px-3',
        style: { height: '46px' },
        onClick: () => handleSort('NormalizedDisplayName')
      },
      cellProps: {
        className: 'py-2 white-space-nowrap px-3 pe-xxl-4'
      },
      Cell: rowData => {
        const { fullName, avatarUrl, id } = rowData.row.original;
        return (
          <Flex alignItems="center" className="position-relative py-1 ">
            <Avatar
              src={toApiFileUrl(avatarUrl)}
              size="xl"
              className="me-2"
              name={fullName || t('common:labels.unknown')}
            />
            <Link
              to={`/user/profile/${id}`}
              className="stretched-link fw-semi-bold"
            >
              {fullName}
            </Link>
          </Flex>
        );
      }
    },
    {
      accessor: 'email',
      Header: t('user:labels.email'),
      headerProps: {
        style: {
          minWidth: '14.625rem',
          width: '150px'
        },
        onClick: () => handleSort('NormalizedEmail')
      },
      cellProps: {
        className: 'py-2 pe-4'
      },
      Cell: rowData => {
        const { email } = rowData.row.original;
        return (
          <p className="fw-semi-bold break-word m-0 mt-2">{`${truncateString(
            email,
            35
          )}`}</p>
        );
      }
    },
    {
      accessor: 'status',
      Header: t('user:labels.status'),
      headerProps: {
        onClick: () => handleSort('Status')
      },
      cellProps: {
        className: 'fs-0 ',
        style: {
          width: '100px'
        }
      },
      Cell: rowData => {
        const { status } = rowData.row.original;
        return (
          <SoftBadge
            bg={
              status === 'active'
                ? 'success'
                : status === 'inactive'
                ? 'danger'
                : status === 'pending'
                ? 'warning'
                : 'dark'
            }
            className="me-2 mt-2"
          >
            {status ? t(`common:status.${status}`) : t(`common:labels.unknown`)}
          </SoftBadge>
        );
      }
    },
    {
      accessor: 'lastLoginDate',
      Header: t('user:labels.lastLoginDate'),
      headerProps: {
        onClick: () => handleSort('LastLoginDate')
      },
      cellProps: {
        className: 'pe-4',
        style: {
          width: '180px'
        }
      },
      Cell: rowData => {
        const { lastLoginDate } = rowData.row.original;
        return (
          <p className="mb-0 mt-2">
            {lastLoginDate
              ? moment(lastLoginDate || new Date())
                  .locale(currentLanguage)
                  .format('DD/MM/YYYY hh:mm A')
              : t('common:labels.noDataAvailable')}
          </p>
        );
      }
    },
    {
      accessor: 'roleNames',
      Header: t('user:labels.roles'),
      headerProps: { className: 'text-start' },
      cellProps: {
        className: 'pe-4'
      },
      disableSortBy: true,
      Cell: rowData => {
        const { roleNames } = rowData.row.original;
        return roleNames?.map(role => {
          return (
            <SoftBadge key={role.id} bg="info" className="me-2 mt-2">
              {t(`common:roles.${role.name.toLowerCase().trim()}`)}
            </SoftBadge>
          );
        });
      }
    },
    {
      accessor: 'metadata.createdAt',
      Header: t('user:labels.createdAt'),
      headerProps: {
        className: 'text-start',
        onClick: () => handleSort('metadata.createdAt')
      },
      Cell: rowData => {
        const { metadata } = rowData.row.original;
        return (
          <p className="fw-semi-bold mb-0 mt-2">
            {metadata?.createdAt
              ? moment(metadata?.createdAt || new Date())
                  .locale(currentLanguage)
                  .format('YYYY-MM-DD hh:mm A')
              : t('common:labels.noDataAvailable')}
          </p>
        );
      }
    },
    {
      accessor: 'action',
      Header: t('user:labels.action'),
      headerProps: { className: 'text-start' },
      disableSortBy: true,
      exportable: false,
      Cell: rowData => {
        const { status, id } = rowData.row.original;
        const isActive = status === 'active';
        const isDeactivated = status === 'inactive';
        const isPending = status === 'pending';
        return (
          <Dropdown
            align="end"
            className="btn-reveal-trigger d-inline-block mt-2"
          >
            <Dropdown.Toggle split variant="spark-default" size="sm">
              <FontAwesomeIcon icon="ellipsis-h" className="fs--2" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="border py-0">
              <div className="py-2">
                <Dropdown.Item
                  onClick={() =>
                    handleUserAction(
                      'changePassword',
                      null,
                      rowData.row.original
                    )
                  }
                >
                  <FontAwesomeIcon icon={faKey} className="mx-1" />
                  {t('common:button.changePassword')}
                </Dropdown.Item>
                <Dropdown.Item
                  onClick={() =>
                    handleUserAction('edit', null, rowData.row.original)
                  }
                >
                  <FontAwesomeIcon icon={faEdit} className="mx-1" />
                  {t('common:button.editRoles')}
                </Dropdown.Item>

                {isActive && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="text-danger"
                      onClick={() =>
                        handleUserAction('deactivate', changeStatus, {
                          userId: id,
                          newStatus: 'inactive',
                          oldStatus: status
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faToggleOff} className="mx-1" />
                      {t('common:button.deactivate')}
                    </Dropdown.Item>
                  </>
                )}
                {(isDeactivated || isPending) && (
                  <>
                    <Dropdown.Divider />
                    <Dropdown.Item
                      className="text-success"
                      onClick={() =>
                        handleUserAction('activate', changeStatus, {
                          userId: id,
                          newStatus: 'active',
                          oldStatus: status
                        })
                      }
                    >
                      <FontAwesomeIcon icon={faToggleOn} className="mx-1" />
                      {t('common:button.activate')}
                    </Dropdown.Item>
                  </>
                )}
              </div>
            </Dropdown.Menu>
          </Dropdown>
        );
      }
    }
  ];

  const handleUserAction = (action, callBack, callbackPayload) => {
    let message = '';
    let header = '';
    let btnColor = '';
    let btnText = '';
    switch (action) {
      case 'changePassword':
        setChangePasswordModalProps({
          isOpen: true,
          user: callbackPayload,
          onClose: () => setChangePasswordModalProps(null)
        });
        return;
      case 'edit':
        setUserToEdit(callbackPayload);
        return;
      case 'deactivate':
        message = t('user:message.deactivateConfirm');
        header = t('user:message.deactivateConfirmHeader');
        btnColor = 'danger';
        btnText = t('common:button.deactivate');
        break;
      case 'activate':
        message = t('user:message.activateConfirm');
        header = t('user:message.activateConfirmHeader');
        btnColor = 'success';
        btnText = t('common:button.activate');
        break;
      default:
        break;
    }
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => callBack(callbackPayload),
      message,
      header,
      actionButtonColor: btnColor,
      actionButtonText: btnText
    });
    return;
  };

  const changeStatus = ({ userId, newStatus }) => {
    setRequestLoading(true);
    UserService.changeStatus({ userId, newStatus })
      .then(response => {
        console.log('changeStatus response', response);
        if (response === true) {
          let message = '';
          switch (newStatus) {
            case 'active':
              message = 'userActivateSuccess';
              break;
            case 'inactive':
              message = 'userDeActivateSuccess';
              break;
            default:
              message = 'userActivateSuccess';
              break;
          }
          fetchUsers();
          setConfirmModalProps(null);
          setRequestLoading(false);
          toast.success(t(`user:message.${message}`), { theme: 'colored' });
        } else {
          setRequestLoading(false);
          toast.error(getErrorMessage(t, response), {
            theme: 'colored',
            autoClose: false
          });
        }
      })
      .catch(error => {
        setRequestLoading(false);
        toast.error(getErrorMessage(t, error), {
          theme: 'colored',
          autoClose: false
        });
      });
  };

  const handleFilterChange = newFilters => {
    setFilters({
      ...newFilters
    });
  };

  if (rolesLoading) {
    return (
      <LoaderWithMessage message={t('common:loading.roles')} className="mt-3" />
    );
  }

  if (rolesError) {
    return (
      <ErrorPlaceholder
        message={t('common:loading.errorRoles')}
        className="mt-3"
      />
    );
  }

  if (usersError) {
    return (
      <ErrorPlaceholder
        message={t('common:loading.errorUsers')}
        className="mt-3"
      />
    );
  }

  // if (usersLoading) {
  //   <LoaderWithMessage message={t('common:loading.users')} className="mt-3" />;
  // }

  return (
    <Row className="gx-3">
      <Col xxl={10} xl={9}>
        <Card>
          <Card.Header className="border-bottom border-200 px-0">
            <UserListTableHeader
              table
              layout="table-view"
              handleShow={handleShow}
              handleSearch={searchTerm =>
                handleFilterChange({ search: searchTerm })
              }
              t={t}
              roles={roles}
              refetch={fetchUsers}
              user={userToEdit}
              setUserToEdit={setUserToEdit}
              filters={filters}
              setFilters={setFilters}
              sortInfo={sortInfo}
              tableColumns={columns}
              totalCount={pagingInfo?.totalItems}
            />
          </Card.Header>
          <Card.Body className={usersLoading ? '' : 'p-0'}>
            {usersLoading &&
              Array(14)
                .fill(1)
                .map((n, i) => {
                  return (
                    <Placeholder
                      key={`users-table-skeleton-${i}`}
                      className="w-100 my-1"
                      style={{ height: '33px' }}
                      size="lg"
                    />
                  );
                })}
            {!usersLoading && (
              <AdvanceTableWrapper
                columns={columns}
                data={users || []}
                sortable
                pagination
                perPage={10}
                rowCount={users?.length || 0}
                manualSortBy
              >
                <AdvanceTable
                  table
                  headerClassName="bg-light text-800 align-middle"
                  rowClassName="btn-reveal-trigger align-top"
                  tableProps={{
                    size: 'sm',
                    className: 'fs--1 mb-0',
                    style: { minHeight: '200px' }
                  }}
                />
              </AdvanceTableWrapper>
            )}
            {!usersLoading && !users?.length && (
              <p className="w-100 text-center">
                {t('common:placeholder.noDataAvailable')}
              </p>
            )}
          </Card.Body>
          <Card.Footer>
            <AdvanceTablePagination
              table
              pageCount={pagingInfo?.totalPages || 0}
              pageIndex={pagingInfo?.currentPage || 1}
              gotoPage={page => {
                setFilters({ ...filters, pageNumber: page });
              }}
              isRtl={isRtl}
            />
          </Card.Footer>
        </Card>
      </Col>
      <Col xxl={2} xl={3}>
        {breakpoints.down('xl') ? (
          <Offcanvas
            show={show}
            onHide={handleClose}
            placement="end"
            className="dark__bg-card-dark"
          >
            <Offcanvas.Header closeButton className="bg-light">
              <h6 className="fs-0 mb-0 fw-semi-bold">
                {t('common:labels.filters')}
              </h6>
            </Offcanvas.Header>
            <UserListFilters
              roles={roles}
              setShow={setShow}
              oldFilters={filters}
              onSubmit={newFilters =>
                handleFilterChange({
                  ...newFilters
                })
              }
            />
          </Offcanvas>
        ) : (
          <UserListFilters
            roles={roles}
            setShow={setShow}
            oldFilters={filters}
            onSubmit={newFilters =>
              handleFilterChange({
                ...newFilters
              })
            }
          />
        )}
      </Col>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={requestLoading} />
      )}
      {!!changePasswordModalProps && (
        <ChangePasswordModal
          {...changePasswordModalProps}
          loading={requestLoading}
        />
      )}
    </Row>
  );
};

export default UserList;
