import BlogsService from 'http/BlogsService';
import { getErrorMessage } from 'http/utils';
import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { BlogContext } from 'context/Context';

const initReference = {
  titleEn: '',
  titleAr: '',
  link: ''
};

const extractReferences = data => {
  if (!data?.length || !Array.isArray(data)) {
    return [initReference];
  }
  return [
    ...data.map(r => {
      return {
        titleEn: r.title?.en,
        titleAr: r.title?.ar,
        link: r?.link
      };
    }),
    initReference
  ];
};

const BlogReferences = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const { blog, isReadOnly, refetch } = useContext(BlogContext);
  const [isEdit, setIsEdit] = useState(false);
  const [references, setReferences] = useState([initReference]);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (!!blog.id) {
      setReferences(extractReferences(blog.references));
    }
  }, [blog]);

  const {
    mutate: updateBlog,
    error: updateBlogError,
    isLoading: updateBlogLoading
  } = useMutation({
    mutationFn: BlogsService.updateReferences,
    onSuccess: data => onUpdateBlogSuccess(data)
  });

  const onUpdateBlogSuccess = () => {
    refetch && refetch(blog.id);
    setIsEdit(false);
    setErrors(null);
    toast.success(
      t(`common:message.${!blog?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
  };

  const handleChange = (name, value, index) => {
    let oldRefs = [...references];
    oldRefs[index] = {
      ...oldRefs[index],
      [name]: value
    };
    setReferences(oldRefs);
  };

  const onSubmit = () => {
    const hasSingleValue = references?.find(
      r => !!r?.titleAr && !!r?.titleEn && !!r?.link
    );
    if (!hasSingleValue) {
      const hasAnyValue = references?.[0];
      if (hasAnyValue) {
        let errObj = {};
        Object.entries(hasAnyValue).map(([key, value]) => {
          errObj[key] = !value;
        });
        setErrors([errObj]);
      }
      toast.error(t('blogs:message.pleaseAddAtleastOneReference'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }

    const validReferences = references
      ?.filter(r => {
        if (!!r?.titleAr && !!r?.titleEn && !!r?.link) {
          return true;
        }
        return false;
      })
      .map(r => {
        return {
          title: {
            ar: r?.titleAr,
            en: r?.titleEn
          },
          link: r?.link
        };
      });
    let updatePayload = {
      id: blog.id,
      references: validReferences
    };
    updateBlog(updatePayload);
  };

  const handleLastItemFocus = index => {
    if (index !== references?.length - 1) {
      return;
    }
    setReferences([...references, initReference]);
  };

  const onCancel = () => {
    setReferences(extractReferences(blog.references));
    setIsEdit(false);
    setErrors(null);
  };

  useEffect(() => {
    if (updateBlogError) {
      toast.error(getErrorMessage(t, updateBlogError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [updateBlogError]);

  return (
    <Card className="mb-3">
      <Card.Header as="h5">{t('blogs:labels.references')}</Card.Header>
      <Card.Body className="bg-light">
        {isEdit &&
          references?.map((reference, index) => {
            const refErrors = errors?.[index] || null;
            return (
              <Row key={`blog-reference-${index}`}>
                {index !== 0 && <hr className="my-3" />}
                <Row>
                  <Col md={6}>
                    <Form.Label>
                      {`${t('blogs:labels.linkDisplayName')} (${t(
                        'common:language.ar.name'
                      )})`}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="titleAr"
                      required
                      placeholder={t('blogs:labels.linkDisplayName')}
                      onChange={e =>
                        handleChange(e.target.name, e.target.value, index)
                      }
                      value={reference?.titleAr || ''}
                      disabled={updateBlogLoading || (!!blog.id && !isEdit)}
                      isInvalid={!!refErrors?.titleAr}
                    />
                    <Form.Text className="text-danger">
                      {!!refErrors?.titleAr &&
                        t('common:validation.fieldIsRequired')}
                    </Form.Text>
                  </Col>

                  <Col md={6}>
                    <Form.Label>
                      {`${t('blogs:labels.linkDisplayName')} (${t(
                        'common:language.en.name'
                      )})`}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="titleEn"
                      required
                      placeholder={t('blogs:labels.linkDisplayName')}
                      onChange={e =>
                        handleChange(e.target.name, e.target.value, index)
                      }
                      value={reference?.titleEn || ''}
                      disabled={updateBlogLoading || (!!blog.id && !isEdit)}
                      isInvalid={!!refErrors?.titleEn}
                    />
                    <Form.Text className="text-danger">
                      {!!refErrors?.titleEn &&
                        t('common:validation.fieldIsRequired')}
                    </Form.Text>
                  </Col>
                  <Col sm={12}>
                    <Form.Label>
                      {`${t('blogs:labels.referenceLink')}`}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <Form.Control
                      type="text"
                      name="link"
                      required
                      placeholder={t('blogs:labels.referenceLink')}
                      onChange={e =>
                        handleChange(e.target.name, e.target.value, index)
                      }
                      value={reference?.link || ''}
                      disabled={updateBlogLoading || (!!blog.id && !isEdit)}
                      isInvalid={!!refErrors?.link}
                      onFocus={() => handleLastItemFocus(index)}
                    />
                    <Form.Text className="text-danger">
                      {!!refErrors?.link &&
                        t('common:validation.fieldIsRequired')}
                    </Form.Text>
                  </Col>
                </Row>
              </Row>
            );
          })}

        {!isEdit && !!blog?.references?.length && (
          <ul>
            {blog?.references?.map(r => {
              return (
                <li key={r?.link}>
                  <a href={r.link} target="_blank" rel="noreferrer">
                    {r?.title?.[currentLanguage]}
                  </a>
                </li>
              );
            })}
          </ul>
        )}
      </Card.Body>
      {!isReadOnly && (
        <Card.Footer>
          {!blog?.id && (
            <>
              <Button size="sm" onClick={onSubmit}>
                {updateBlogLoading && <Spinner size="sm" />}
                {!updateBlogLoading && t('common:button.save')}
              </Button>
              <Button
                size="sm"
                variant="danger"
                onClick={onCancel}
                className="mx-2"
              >
                {!updateBlogLoading && t('common:button.cancel')}
              </Button>
            </>
          )}
          {isEdit && !!blog?.id && (
            <>
              <Button
                size="sm"
                onClick={onSubmit}
                disabled={!!updateBlogLoading}
              >
                {updateBlogLoading && <Spinner size="sm" />}
                {!updateBlogLoading && t('common:button.save')}
              </Button>

              <Button
                size="sm"
                variant="danger"
                onClick={onCancel}
                className="mx-2"
                disabled={!!updateBlogLoading}
              >
                {t('common:button.cancel')}
              </Button>
            </>
          )}
          {!isEdit && !!blog?.id && (
            <>
              <Button
                size="sm"
                variant="info"
                onClick={() => setIsEdit(true)}
                disabled={!!updateBlogLoading}
              >
                {t('common:button.edit')}
              </Button>
            </>
          )}
        </Card.Footer>
      )}
    </Card>
  );
};

export default BlogReferences;
