import dayjs from 'dayjs';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { getColor, rgbaColor } from 'helpers/utils';
import moment from 'moment';
import React from 'react';

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent
]);

const tooltipFormatter = params => {
  let tooltipItem = ``;
  if (Array.isArray(params)) {
    params?.forEach(el => {
      tooltipItem =
        tooltipItem +
        `<div class='ms-1'> 
      <h6 class="text-700">
      <div class="dot me-1 fs--2 d-inline-block" style="background-color:${
        el.borderColor ? el.borderColor : el.color
      }"></div>
      ${el.seriesName} : ${
          typeof el.value === 'object' ? el.value[1] : el.value
        }
      </h6>
      </div>`;
    });
  }
  return `<div>
            <p class='mb-2 text-600'>
              ${
                dayjs(params[0].axisValue).isValid()
                  ? dayjs(params[0].axisValue).format('MMMM DD')
                  : params[0].axisValue
              }
            </p>
            ${tooltipItem}
          </div>`;
};

const getOptions = (data, currentLanguage, type, t) => {
  if (!data?.[0]) {
    return {};
  }
  const dateFormat = type === 'monthly' ? 'MMM, YYYY' : 'DD MMM, YYYY';
  const lang = currentLanguage;
  let dates = data.map(d => d.startDate);
  let series = [
    {
      name: t('dashboard:labels.courses'),
      data: data.map(c => c.courseRevenue),
      type: 'bar',
      stack: 'total'
    },
    {
      name: t('dashboard:labels.learningPaths'),
      data: data.map(c => c.learningPathRevenue),
      type: 'bar',
      stack: 'total'
    }
  ];
  // console.log('type', type);
  // console.log('dates', dates);
  // console.log('series', series);
  return {
    color: [
      getColor('primary'),
      rgbaColor(getColor('primary'), 0.8),
      rgbaColor(getColor('primary'), 0.6),
      rgbaColor(getColor('primary'), 0.4),
      rgbaColor(getColor('primary'), 0.2)
    ],
    legend: {
      data: series.map(s => s.name),
      left: 5,
      itemWidth: 10,
      itemHeight: 10,
      borderRadius: 0,
      icon: 'circle',
      inactiveColor: getColor('gray-400'),
      textStyle: { color: getColor('gray-700') },
      itemGap: 20
    },
    xAxis: {
      type: 'category',
      data: dates.map(date => moment(date).locale(lang).format(dateFormat)),
      axisLine: {
        show: false
      },
      splitLine: {
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        color: getColor('gray-600'),
        formatter: value => value
      }
    },
    yAxis: {
      type: 'value',
      position: 'right',
      splitLine: {
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: true,
        color: getColor('gray-600'),
        margin: 15
      }
    },
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      axisPointer: {
        type: 'none'
      },
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('gray-700') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: tooltipFormatter
    },

    series: series,

    grid: {
      containLabel: true,
      right: '5px',
      left: 0,
      bottom: 0,
      top: '15%'
    }
  };
};

const RevenuesChart = ({ data, currentLanguage, type, t }) => {
  return (
    <ReactEChartsCore
      echarts={echarts}
      option={getOptions(data, currentLanguage, type, t)}
      style={{ height: '21.875rem' }}
    />
  );
};

export default RevenuesChart;
