export const toBlogFormSchema = blog => {
  if (!blog) {
    return null;
  }
  return {
    blogTitleAr: blog.title?.ar || '',
    blogTitleEn: blog.title?.en || '',
    blogSubTitleAr: blog.subtitle?.ar || '',
    blogSubTitleEn: blog.subtitle?.en || '',
    blogKeywordsAr: blog.keywords?.ar || '',
    blogKeywordsEn: blog.keywords?.en || '',
    blogContentAr: blog.content?.ar || '',
    blogContentEn: blog.content?.en || '',
    type: blog.type
  };
};

export const canEditBlogs = user => {
  if (!user || !user?.roles?.length) {
    console.log('user missing roles', user);
    return false;
  }
  let roles = user.roles;
  const allowedRoles = ['admin', 'marketing'];
  if (typeof roles === 'string') {
    roles = [roles];
  }
  const canEdit = !!roles
    .map(r => r.toLowerCase())
    .some(r => allowedRoles.includes(r));
  // console.log(
  //   'canEditCourse',
  //   `${canEdit} user is ${roles.map(r => r).join(',')}`
  // );
  return canEdit;
};

export const validateBlogBasicForm = blog => {
  const data = toBlogFormSchema(blog);
  if (!data.blogTitleAr) {
    return false;
  }
  if (!data.blogTitleEn) {
    return false;
  }
  if (!data.blogSubTitleAr) {
    return false;
  }
  if (!data.blogSubTitleEn) {
    return false;
  }
  if (!data.blogContentAr) {
    return false;
  }
  if (!data.blogContentEn) {
    return false;
  }
  if (!data.type) {
    return false;
  }
  return true;
};
