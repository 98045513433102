import { ExamContext, AuthContext } from 'context/Context';
import PlanService from 'http/PlanService';
import { getErrorMessage } from 'http/utils';
import { canEditExam } from 'pages/Operations/Plans/utils';
import React, { useEffect, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import ErrorPlaceholder from 'components/common/ErrorPlaceholder';
import LoaderWithMessage from 'components/common/LoaderWithMessage';

const PlanProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const { id } = useParams();
  const { user } = useContext(AuthContext);
  const [exam, setExam] = useState(null);
  const [serviceTypes, setServiceTypes] = useState([]);
  const [editCard, setEditCard] = useState(null);
  const [isCreate, setIsCreate] = useState(true);
  const [isReadOnly, setIsReadOnly] = useState(false);
  const [sectionValidation, setSectionValidation] = useState(null);


  const {
    isLoading: examLoading,
    error: examError,
    data: examData,
    refetch: fetchExam
  } = useQuery(['PlanGet', id], () => PlanService.get(id), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false,
    enabled: false
  });

  const {
    isLoading: serviceTypesLoading,
    data: serviceTypesData,
    error: serviceTypesError,
    refetch: fetchServiceTypes
  } = useQuery(
    'ServiceTypes',
    () =>
    PlanService.listServiceTypes({
        pageSize: 1000,
        pageNumber: 1
      }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  useEffect(() => {
    if (!!id && !!user) {
      fetchExam(id);
    } else {
      setIsCreate(true);
    }

    if (canEditExam(user)) {
      setIsReadOnly(false);
    } else {
      setIsReadOnly(true);
    }
  }, [id, user]);

  useEffect(() => {
    fetchServiceTypes();
  }, []);

  // useEffect(() => {
  //   console.log('sectionValidation', sectionValidation);
  // }, [sectionValidation]);

  useEffect(() => {
    if (examData && !examLoading && !examError) {
      if (!examData?.data) {
        toast.error(t('error:404'), {
          theme: 'colored',
          autoClose: false
        });
        navigate(`/plans`);
      }
      setIsCreate(false);
      setExam(examData?.data || null);
    }
    if (examError) {
      toast.error(getErrorMessage(t, examError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [examLoading, examError, examData]);

  useEffect(() => {
    if (serviceTypesData && !serviceTypesLoading && !serviceTypesError) {
      setServiceTypes(serviceTypesData.data.items);
    }
    if (serviceTypesError) {
      toast.error(getErrorMessage(t, serviceTypesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [serviceTypesLoading, serviceTypesData, serviceTypesError]);
  
  const examProviderValue = {
    editCard,
    setEditCard,
    serviceTypes,
    sectionValidation,
    setSectionValidation,
    exam,
    setExam,
    fetchExam,
    examLoading,
    isReadOnly,
    isCreate
  };

  if (examLoading) {
    return <LoaderWithMessage message={t('exams:message.loadingExam')} />;
  }

  if (examError) {
    return <ErrorPlaceholder message={t('exams:labels.errorLoadingExam')} />;
  }
  if (id && !exam) {
    return '';
  }

  return (
    <ExamContext.Provider value={examProviderValue}>
      {children}
    </ExamContext.Provider>
  );
};

export default PlanProvider;
