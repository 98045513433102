import BlogsService from 'http/BlogsService';
import React, { useState, useEffect, useContext } from 'react';
import { Button, Card, Col, Form, Image, Row, Spinner } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import FileService from 'http/FileService';
import { toApiFileUrl } from 'helpers/utils';
import { getErrorMessage } from 'http/utils';
import { useTranslation } from 'react-i18next';
import { BlogContext } from 'context/Context';

const BlogWriter = () => {
  const { t } = useTranslation();
  const { blog, isReadOnly, refetch } = useContext(BlogContext);
  const [isCreate, setIsCreate] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [writerImage, setWriterImage] = useState(null);
  const [writerName, setWriterName] = useState(null);
  const [writerImageFile, setWriterImageFile] = useState(null);
  const [errors, setErrors] = useState(null);
  const [imageHovered, setImageHovered] = useState(false);

  useEffect(() => {
    if (blog?.writerName && blog?.writerImage) {
      setWriterImage({
        preview: blog?.writerImage
          ? `${toApiFileUrl(blog?.writerImage)}?d=${new Date()}`
          : null,
        path: blog?.writerImage
      });
      setWriterName(blog?.writerName);
    } else {
      setIsCreate(true);
    }
  }, [blog]);

  const {
    mutate: updateWriterInfo,
    error: updateWriterInfoError,
    isLoading: updateWriterInfoLoading
  } = useMutation({
    mutationFn: BlogsService.updateWriter,
    onSuccess: data => onUpdateBlogSuccess(data)
  });

  const {
    mutate: uploadImageTempFile,
    error: uploadImageTempFileError,
    isLoading: uploadImageTempFileLoading
  } = useMutation({
    mutationFn: FileService.uploadTempFile,
    onSuccess: data => onUploadImageTempFileSuccess(data)
  });

  const onUploadImageTempFileSuccess = response => {
    setWriterImage({
      preview: toApiFileUrl(response.payload),
      path: response.payload
    });

    toast.success(t(`common:message.uploadSuccess`), { theme: 'colored' });
  };

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept: 'image/*',
    onDrop: async acceptedFiles => {
      setWriterImageFile(acceptedFiles[0]);
      uploadImageTempFile({ file: acceptedFiles[0] });
      inputRef.current.value = null;
    }
  });

  const onUpdateBlogSuccess = () => {
    setIsEdit(false);
    setWriterImageFile(null);
    refetch && refetch(blog.id);
    toast.success(
      t(`common:message.${!blog?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
  };

  const onSubmit = () => {
    let errors = {};
    let hasError = false;
    if (!writerImageFile) {
      hasError = true;
      errors['writerImage'] = true;
    }
    if (!writerName) {
      hasError = true;
      errors['writerName'] = true;
    }
    setErrors(errors);
    if (hasError) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }

    let upadtePayload = {
      id: blog.id,
      writerName,
      writerImage: writerImage.path
    };

    updateWriterInfo(upadtePayload);
  };
  const onCancel = () => {
    if (isEdit) {
      setWriterImageFile(null);
      setWriterName(blog?.writerName || '');
      setWriterImage({
        preview: blog?.writerImage
          ? `${toApiFileUrl(blog?.writerImage)}?d=${new Date()}`
          : null,
        path: blog?.writerImage
      });
      setIsEdit(false);
      return;
    }
  };

  useEffect(() => {
    if (updateWriterInfoError) {
      toast.error(getErrorMessage(t, updateWriterInfoError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (uploadImageTempFileError) {
      toast.error(getErrorMessage(t, uploadImageTempFileError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [uploadImageTempFileError, updateWriterInfoError]);

  return (
    <Card className="mb-3">
      <Card.Header as="h5">{t('blogs:labels.writerInfo')}</Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3">
          <Col sm="12">
            <Form.Group>
              <Form.Label>
                {t('blogs:labels.writerName')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Control
                type="text"
                name="writerName"
                required
                placeholder={t('blogs:labels.writerName')}
                onChange={e => setWriterName(e.target.value)}
                value={writerName || ''}
                disabled={updateWriterInfoLoading || (!isCreate && !isEdit)}
                isInvalid={!!errors?.blogTitleAr}
              />
              <Form.Text className="text-danger">
                {!!errors?.level && t('common:validation.fieldIsRequired')}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col sm="12">
            {!isReadOnly && (
              <Form.Group>
                <Form.Label>
                  {t('blogs:labels.writerImage')}
                  <span className="text-danger">*</span>
                </Form.Label>
                <div
                  {...getRootProps({
                    className: !writerImage ? 'dropzone-area py-6' : ''
                  })}
                >
                  <input {...getInputProps({ multiple: false })} />
                  {!writerImage && (
                    <div className="fs--1">
                      <img
                        src={cloudUpload}
                        alt=""
                        width={20}
                        className="me-2"
                      />
                      <span className="d-none d-lg-inline">
                        {t('common:labels.dragYourImageHere')}
                        <br />
                        {t('common:labels.or')},{' '}
                      </span>
                      <Button variant="link" size="sm" className="p-0 fs--1">
                        {t('common:labels.browse')}
                      </Button>
                    </div>
                  )}
                </div>
                <Form.Text className="text-danger">
                  {!!errors?.writerImage &&
                    t('common:validation.fieldIsRequired')}
                </Form.Text>
              </Form.Group>
            )}
            {!!writerImage && (
              <Flex
                className="position-relative"
                style={{ zIndex: 9 }}
                onMouseEnter={() => setImageHovered(true)}
                onMouseLeave={() => setImageHovered(false)}
              >
                <Image
                  rounded
                  width="100%"
                  height="100%"
                  src={writerImage.preview}
                  alt={writerImage.path}
                />
                {(imageHovered || uploadImageTempFileLoading) &&
                  (!!isCreate || !!isEdit) &&
                  !isReadOnly && (
                    <Flex
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      className="position-absolute w-100 h-100 cursor-pointer"
                      style={{
                        left: 0,
                        right: 0,
                        top: 0,
                        zIndex: 10,
                        background: '#36454F',
                        opacity: 0.75
                      }}
                    >
                      {uploadImageTempFileLoading && <Spinner />}
                      {!uploadImageTempFileLoading && (
                        <Button
                          onClick={() => {
                            inputRef?.current?.click();
                          }}
                          variant="primary"
                        >
                          {t('common:labels.replace')}
                        </Button>
                      )}
                      {/* <Button variant="danger" className="mt-2">
                  {t('common:button.delete')}
                </Button> */}
                    </Flex>
                  )}
              </Flex>
            )}
          </Col>
        </Row>
      </Card.Body>
      {!isReadOnly && (
        <Card.Footer>
          {isCreate && (
            <>
              <Button size="sm" onClick={onSubmit}>
                {updateWriterInfoLoading && <Spinner size="sm" />}
                {!updateWriterInfoLoading && t('common:button.save')}
              </Button>
            </>
          )}
          {isEdit && !isCreate && (
            <>
              <Button
                size="sm"
                onClick={onSubmit}
                disabled={!!updateWriterInfoLoading}
              >
                {updateWriterInfoLoading && <Spinner size="sm" />}
                {!updateWriterInfoLoading && t('common:button.save')}
              </Button>

              <Button
                size="sm"
                variant="danger"
                onClick={onCancel}
                className="mx-2"
                disabled={!!updateWriterInfoLoading}
              >
                {t('common:button.cancel')}
              </Button>
            </>
          )}
          {!isEdit && !isCreate && (
            <>
              <Button
                size="sm"
                variant="info"
                onClick={() => setIsEdit(true)}
                disabled={!!updateWriterInfoLoading}
              >
                {t('common:button.edit')}
              </Button>
            </>
          )}
        </Card.Footer>
      )}
    </Card>
  );
};

export default BlogWriter;
