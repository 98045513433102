import React from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';

import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { Controller, useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import ConfirmModal from 'components/common/ConfirmModal';
import { useContext } from 'react';
import { ExamContext } from 'context/Context';
import { toExamFormSchema } from '../utils';
import CityService from 'http/CityService';

const initPlan = {
  name: null,
  nameAr:null
};

const optionalFields = [
  "modifiedAt",
  "modifiedBy",
  "numberOfClients"
];

const CityBasicForm = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const { countries } = useContext(ExamContext);
  const { exam, examLoading, editCard, setEditCard, fetchExam } =
    useContext(ExamContext);
  const [cityValues, setCityValues] = useState(null);
  const [formKey, setFormKey] = useState(Date.now()); // Create a state variable to force re-render
  const [errors, setErrors] = useState(null);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const { register, handleSubmit, setValue, control, reset, getValues } =
  useForm({
    defaultValues: null
  });

  useEffect(() => {
    if (exam?.id) {
      fillInitialData(exam || null);
    } else {
      fillInitialData(initPlan);
    }
  }, [exam]);

  useEffect(() => {
    setValue('country', selectedCountry, { shouldValidate: true });
  }, [selectedCountry]);

  const {
    mutate: createExam,
    error: examCreateError,
    isLoading: examCreateLoading
  } = useMutation({
    mutationFn: CityService.create,
    onSuccess: data => onCreateExamSuccess(data)
  });
  const {
    mutate: updateCountry,
    error: planUpdateError,
    isLoading: planUpdateLoading
  } = useMutation({
    mutationFn: CityService.update,
    onSuccess: data => onUpdateCountrySuccess(data)
  });

  const fillInitialData = data => {
    if (!data) {
      return;
    }
    const formattedData = toExamFormSchema(data);
    setSelectedCountry(formattedData.country);
    setCityValues(formattedData);
    setFormKey(Date.now());
  };

  const onSubmit = () => {
    const isValid = validateForm(cityValues);
    if (!isValid) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    const values = { ...cityValues };
    let result = {};
    Object.entries(values).map(([key, value]) => {
      const isValid = !!value;
      result[key] = isValid;
    });
    let createPayload = {
      name:values.name ,
      nameAr: values.nameAr,
      countryId:values.country
    };

    if (exam?.id) {
      createPayload['id'] = exam?.id;
      updateCountry(createPayload);
    }else{
      createExam(createPayload);
    }
  };

  const onCreateExamSuccess = response => {
    toast.success(
      t(`common:message.${!exam?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
    if (typeof response?.data === 'string' && !exam?.id) {
      navigate(`/cities/create/${response.data}`);
    } else {
      setEditCard(null);
      fetchExam(exam.id);
    }
  };
  const onUpdateCountrySuccess = response => {
    toast.success(
      t(`common:message.${!exam?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
    if (typeof response?.data.items === 'string' && !exam?.id) {
      navigate(`/countries/create/${response.data}`);
    } else {
      setEditCard(null);
      fetchExam(exam.id);
    }
  };

  const validateForm = values => {
    let valid = true;
    let errors = {};
    if (!values) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return false;
    }
    Object.entries(values).map(([key, value]) => {
      if (key === 'planNameEn') {
        if (value?.length > 60) {
          errors['planNameEn'] = {
            error: true,
            message: t('course:validation.message.maxAllowedCharacters', {
              count: 60
            })
          };
        }
      }
      if (!value && !optionalFields.includes(key)) {
        errors[key] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
        valid = false;
      }
    });

    setErrors(errors);
    console.log('onError', errors);
    return valid;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const dataType =
      e.target.getAttribute && e?.target?.getAttribute('data-type');
    if (dataType === 'number') {
      if (!!value && isNaN(value)) {
        return;
      }
    }

    if (!!value) {
      setErrors({ ...errors, [name]: { error: false, message: '' } });
    }
    setCityValues({ ...cityValues, [name]: value });
  };

  const onCancel = () => {
    if (editCard === 'basicInfo') {
      fillInitialData(exam);
      setEditCard(null);
      return;
    }

    const hasChanges =
      JSON.stringify({ ...toExamFormSchema(exam) }) !==
      JSON.stringify({ ...cityValues });
    if (hasChanges) {
      setConfirmModalProps({
        isOpen: true,
        onClose: () => setConfirmModalProps(null),
        onConfirm: () => navigate('/cities'),
        message: t('plans:message.discardExamConfirm'),
        header: t('plans:message.discardExamTitle'),
        actionButtonColor: 'danger',
        actionButtonText: t('common:button.discard')
      });
      return;
    }
    navigate('/contents/cities');
  };

  useEffect(() => {
    if (examCreateError) {
      toast.error(getErrorMessage(t, examCreateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [examCreateError]);

  if (examLoading) {
    return <LoaderWithMessage message={t('plans:message.loadingExam')} />;
  }
  useEffect(() => {
    if (planUpdateError) {
      toast.error(getErrorMessage(t, planUpdateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [planUpdateError]);

  if (examLoading) {
    return <LoaderWithMessage message={t('countries:message.loadingExam')} />;
  }
  return (
    <Card className="mb-3">
      <Card.Header as="h5">
        {!!id ? exam?.name: t('cities:labels.newCity')}
      </Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3" key={formKey}>
        <Col md="6">
            <Form.Group>
              <Form.Label>
                {t('cities:labels.country')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                {...register(`country`, { required: true })}
                value={selectedCountry || ''}
                required
                disabled={examCreateLoading ||
                  examLoading ||
                  planUpdateLoading||
                  (!!id && editCard !== 'basicInfo')}
                onChange={e => {
                  setSelectedCountry(e.target.value);
                  setValue('country', e.target.value);
                  setCityValues({ ...cityValues, 'country': e.target.value });
                }}
                isInvalid={!!errors?.country?.error}
              >
                <option value="">
                  {t('cities:labels.selectCountry')}
                </option>
                {
                  countries.map((country, index) => {
                    return (
                      <option
                        key={`course-category-${country.id}-${index}`}
                        value={country.id}
                      >
                        {country.name}
                      </option>
                    );
                  })}
              </Form.Select>
              <Form.Text className="text-danger">
                {!!errors?.country?.error && errors?.country?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="countryName">
              <Form.Label>
                {`${t('cities:labels.name')} (${t('common:language.en.name')})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                required
                placeholder={t('cities:labels.name')}
                onChange={handleChange}
                value={cityValues?.name || ''}
                disabled={
                  examCreateLoading ||
                  examLoading ||
                  planUpdateLoading||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.name?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.name?.error && errors?.name?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="countryNameAr">
              <Form.Label>
                {`${t('cities:labels.nameAr')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 150 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="nameAr"
                required
                placeholder={t('cities:labels.nameAr')}
                onChange={handleChange}
                value={cityValues?.nameAr || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.nameAr?.error}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer>
        {!id && (
          <>
            <Button size="sm" onClick={onSubmit}>
              {examCreateLoading && <Spinner size="sm" />}
              {!examCreateLoading && t('common:button.save')}
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
            >
              {!examCreateLoading && t('common:button.cancel')}
            </Button>
          </>
        )}
        {editCard === 'basicInfo' && !!id && (
          <>
            <Button
              size="sm"
              onClick={onSubmit}
              disabled={!!examCreateLoading || examLoading}
            >
              {examCreateLoading && <Spinner size="sm" />}
              {!examCreateLoading && t('common:button.save')}
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
              disabled={!!examCreateLoading || examLoading}
            >
              {t('common:button.cancel')}
            </Button>
          </>
        )}
        {editCard !== 'basicInfo' && !!id && (
          <>
            <Button
              size="sm"
              variant="info"
              onClick={() => setEditCard('basicInfo')}
              disabled={!!examCreateLoading || examLoading}
            >
              {t('common:button.edit')}
            </Button>
          </>
        )}
      </Card.Footer>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={false} />
      )}
    </Card>
  );
};
export default CityBasicForm;
