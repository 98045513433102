import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { toApiFileUrl, toClientUrl } from 'helpers/utils';
import React from 'react';
import { useState } from 'react';
import { Button, Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import IconButton from 'components/common/IconButton';

const BlogCourseCard = ({ course, onDelete, isReadOnly }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [hovered, setHovered] = useState(false);

  return (
    <Card
      style={{ width: '20rem' }}
      className="position-relative"
      onMouseEnter={() => !isReadOnly && setHovered(true)}
      onMouseLeave={() => !isReadOnly && setHovered(false)}
    >
      <Card.Img src={toApiFileUrl(course?.image)} variant="top" />
      <Card.Body>
        <Card.Title as="h5">{course?.title?.[currentLanguage]}</Card.Title>
        <Card.Text>{course?.subtitle?.[currentLanguage]}</Card.Text>
        {!!course?.link && (
          <Button
            color="primary"
            size="sm"
            as="a"
            href={toClientUrl(course?.link)}
            target="_blank"
            rel="noreferrer"
          >
            {t('common:button.preview')}
          </Button>
        )}
        {!isReadOnly && hovered && (
          <IconButton
            icon={faTrash}
            className="position-absolute"
            style={{ top: '5%', left: '5%' }}
            onClick={() => onDelete(course)}
            variant="danger"
          />
        )}
      </Card.Body>
    </Card>
  );
};

export default BlogCourseCard;
