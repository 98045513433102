import { useState } from 'react';
import axios from 'axios';
import * as tus from 'tus-js-client';
import { useTranslation } from 'react-i18next';
const vimeoVideoBaseUrl = process.env.REACT_APP_VIMEO_VIDEO_URL;
const acceptedExtensions = [
  'webm',
  'mp4',
  'ogv',
  'mov',
  'avi',
  'flv',
  'wmv',
  'mkv',
  'm4v',
  'mpg',
  'mpeg'
];

const isFile = file => {
  return file instanceof File;
};

const validateFile = file => {
  if (!isFile(file)) {
    return false;
  }
  const fileExtension = file?.name?.split('.').pop().toLowerCase();
  if (!fileExtension) {
    return false;
  }
  if (!acceptedExtensions.includes(fileExtension)) {
    return false;
  }
  return true;
};

const fetchVideoMetadata = async (url, headers) => {
  const metadataResponse = await axios.get(url, {
    headers
  });

  const duration = metadataResponse.data?.duration;
  const thunmbnail = metadataResponse.data?.pictures?.base_link;
  if (duration && !!thunmbnail && !thunmbnail?.endsWith('default')) {
    //console.log('Upload complete metadataResponse', { metadataResponse });
    return metadataResponse.data;
  } else {
    console.log('Video is still processing, retrying in 1 second...');
    await new Promise(resolve => setTimeout(resolve, 1000));
    return fetchVideoMetadata(url, headers);
  }
};

const useVimeo = ({ onFinish, onError }) => {
  const { t } = useTranslation();
  const [progress, setProgress] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState(null);

  async function handleUpload(file) {
    if (!validateFile(file)) {
      onError(t('common:error.invalidFile'));
    }
    const endpointUrl = process.env.REACT_APP_VIMEO_URL;
    const apiToken = process.env.REACT_APP_VIMEO_TOKEN;

    let headers = {
      Accept: 'application/vnd.vimeo.*+json;version=3.4',
      'Content-Type': 'application/json',
      Authorization: `Bearer ${apiToken}` //SHAI Bearer Token
    };
    let requestUrlData = JSON.stringify({
      upload: { approach: 'tus', size: file.size },
      name: file.name || 'Untitled',
      description: 'Uploaded By SHAI App'
    });
    let requestUrlResponse;
    let requestUrlResponseData;
    let uploadLink;
    let uploadData;
    let fullVideoData = {};
    let videoPath = {};
    try {
      setIsLoading(true);
      setStatus(t('course:message.preparingToUpload'));
      requestUrlResponse = await axios.post(endpointUrl, requestUrlData, {
        headers
      });
      requestUrlResponseData = requestUrlResponse.data;
      uploadLink = requestUrlResponseData?.upload?.upload_link;
      uploadData = requestUrlResponseData;
      //console.log('uploadData:', uploadData);
      videoPath = uploadData?.uri.slice(8);
      fullVideoData = {
        url: `${vimeoVideoBaseUrl}${videoPath}?title=1&byline=1&portrait=1&autoplay=false&allowfullscreen=1&controls=true`,
        size: uploadData?.upload.size,
        name: file.name || 'Untitled'
      };
    } catch (error) {
      console.log('Error getting upload link from vimeo:', error);
      onError(error);
    }

    const upload = new tus.Upload(file, {
      chunkSize: 5000000,
      uploadUrl: uploadLink,
      endpoint: uploadLink,
      retryDelays: [0, 1000, 3000, 5000], // Set retry delays in milliseconds
      metadata: {
        filename: file.name,
        filetype: file.type
      },
      onError: error => {
        console.log('Upload error:', error);
        onError(error);
      },
      onProgress: (bytesUploaded, bytesTotal) => {
        setStatus(t('course:message.fileIsUploading'));
        const percentage = (bytesUploaded / bytesTotal) * 100;
        //console.log('Upload progress:', percentage, '%');
        if (percentage > 0) {
          setIsLoading(false);
        }
        setProgress(percentage.toFixed(2));
      },
      onSuccess: async () => {
        setProgress(100);
        setIsLoading(true);
        setStatus(t('course:message.processingFile'));
        const videoData = await fetchVideoMetadata(
          `https://api.vimeo.com/videos/${videoPath}`,
          headers
        );
        fullVideoData = {
          ...fullVideoData,
          duration: videoData?.duration,
          thumbnail: videoData?.pictures?.base_link || ''
        };
        onFinish(fullVideoData);
        //console.log('onFinish called with:', fullVideoData);
        setStatus(t('course:message.uploadedSuccessfully'));
        setIsLoading(false);
      }
    });

    try {
      await upload.start();
      fullVideoData = {
        ...fullVideoData,
        type: upload.file.type
      };
      //console.log('Upload setResponseData:', fullVideoData);
    } catch (error) {
      console.log('Upload error:', error);
      onError(error);
    }
  }

  return { status, isLoading, progress, handleUpload };
};

export default useVimeo;
