import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import BlogsService from 'http/BlogsService';
import { toBlogFormSchema } from '../utils';
import ConfirmModal from 'components/common/ConfirmModal';
import MultiLanguageRTE from 'components/common/MultiLanguageRTE';
import { BlogContext } from 'context/Context';

const types = [{ label: 'blog' }, { label: 'article' }];
const optionalFields = ['blogKeywordsAr', 'blogKeywordsEn'];

const BlogBasicInformation = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const { blog, isReadOnly } = useContext(BlogContext);

  const [blogValues, setBlogValues] = useState(null);
  const [formKey, setFormKey] = useState(Date.now()); // Create a state variable to force re-render
  const [errors, setErrors] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);

  useEffect(() => {
    if (blog) {
      fillInitialData(blog);
    } else {
      setBlogValues(toBlogFormSchema({}));
    }
  }, [blog]);

  const {
    mutate: createBlog,
    error: blogCreateError,
    isLoading: blogCreateLoading
  } = useMutation({
    mutationFn: BlogsService.save,
    onSuccess: data => onCreateBlogSuccess(data)
  });

  const fillInitialData = data => {
    if (!data) {
      return;
    }
    const formattedData = toBlogFormSchema(data);
    setBlogValues(formattedData);
    setFormKey(Date.now());
  };

  const onSubmit = () => {
    const isValid = validateForm(blogValues);
    if (!isValid) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    const values = { ...blogValues };
    let result = {};
    Object.entries(values).map(([key, value]) => {
      const isValid = !!value;
      result[key] = isValid;
    });
    let createPayload = {
      title: {
        ar: values.blogTitleAr,
        en: values.blogTitleEn
      },
      keywords: {
        ar: values.blogKeywordsAr,
        en: values.blogKeywordsEn
      },
      subtitle: {
        ar: values.blogSubTitleAr,
        en: values.blogSubTitleEn
      },
      content: {
        ar: values.blogContentAr,
        en: values.blogContentEn
      },
      type: values.type
    };
    if (blog?.id) {
      createPayload['id'] = blog?.id;
    }
    createBlog(createPayload);
  };
  const onCreateBlogSuccess = response => {
    if (typeof response?.payload === 'string' && !blog?.id) {
      navigate(`/contents/blogs/create/${response.payload}`);
    }
    setIsEdit(false);
    setErrors(null);
    toast.success(
      t(`common:message.${!blog?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
  };

  const validateForm = blogValues => {
    let valid = true;
    let errors = {};
    if (!blogValues) {
      //   toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
      //     theme: 'colored',
      //     autoClose: false
      //   });
      //return false;
      //blogValues = {};
    }
    Object.entries(blogValues).map(([key, value]) => {
      if (!value && !optionalFields.includes(key)) {
        errors[key] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
        valid = false;
      } else {
        if (key === 'blogTitleAr') {
          if (value?.length > 60) {
            errors['blogTitleAr'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else if (key === 'blogTitleEn') {
          if (value?.length > 60) {
            errors['blogTitleEn'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else if (key === 'blogSubTitleAr') {
          if (value?.length > 60) {
            errors['blogSubTitleAr'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else if (key === 'blogSubTitleEn') {
          if (value?.length > 60) {
            errors['blogSubTitleEn'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else if (key === 'blogKeywordsEn') {
          if (value?.length > 60) {
            errors['blogKeywordsEn'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else if (key === 'blogKeywordsAr') {
          if (value?.length > 60) {
            errors['blogKeywordsAr'] = {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 60
              })
            };
            valid = false;
          }
        } else {
          errors[key] = null;
        }
      }
    });
    setErrors(errors);
    console.log('onError', errors);
    return valid;
  };

  const handleChange = (value, name) => {
    setBlogValues({ ...blogValues, [name]: value });
  };

  const onCancel = () => {
    if (isEdit) {
      fillInitialData(blog);
      setIsEdit(false);
      return;
    }
    // console.log('blog', toBlogFormSchema(blog));
    // console.log('values', { ...blogValues });
    const hasChanges =
      JSON.stringify({ ...toBlogFormSchema(blog) }) !==
      JSON.stringify({ ...blogValues });
    if (hasChanges) {
      setConfirmModalProps({
        isOpen: true,
        onClose: () => setConfirmModalProps(null),
        onConfirm: () => navigate('/contents/blogs'),
        message: t('blogs:message.discardBlogConfirm'),
        header: t('blogs:message.discardBlogTitle'),
        actionButtonColor: 'danger',
        actionButtonText: t('common:button.discard')
      });
      return;
    }
    navigate('/contents/blogs');
  };

  useEffect(() => {
    if (blogCreateError) {
      toast.error(getErrorMessage(t, blogCreateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [blogCreateError]);

  return (
    <Card className="mb-3">
      <Card.Header as="h5">
        {!!id ? blog?.title?.[currentLanguage] : t('blogs:labels.newBlog')}
      </Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3" key={formKey}>
          <Col sm="12">
            <Form.Group>
              <Form.Label>
                {t('blogs:labels.type')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                disabled={blogCreateLoading || (!!id && !isEdit)}
                isInvalid={!!errors?.type?.error}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={blogValues?.type || ''}
                name="type"
              >
                <option value="">{t('blogs:placeholders.selectType')}</option>
                {types.map((type, index) => {
                  return (
                    <option
                      key={`blog-type-${type.label}-${index}`}
                      value={type.label}
                    >
                      {t(`blogs:type.${type.label}`)}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Text className="text-danger">
                {!!errors?.type?.error && errors?.type?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="blogTitleAr">
              <Form.Label>
                {`${t('blogs:labels.title')} (${t('common:language.ar.name')})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="blogTitleAr"
                required
                placeholder={t('blogs:labels.title')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={blogValues?.blogTitleAr || ''}
                disabled={blogCreateLoading || (!!id && !isEdit)}
                isInvalid={!!errors?.blogTitleAr?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.blogTitleAr?.error && errors?.blogTitleAr?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="blogTitleEn">
              <Form.Label>
                {`${t('blogs:labels.title')} (${t('common:language.en.name')})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="blogTitleEn"
                required
                placeholder={t('blogs:labels.title')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={blogValues?.blogTitleEn || ''}
                disabled={blogCreateLoading || (!!id && !isEdit)}
                isInvalid={!!errors?.blogTitleEn?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.blogTitleEn?.error && errors?.blogTitleEn?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="blogSubTitleAr">
              <Form.Label>
                {`${t('blogs:labels.subtitle')} (${t(
                  'common:language.ar.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="blogSubTitleAr"
                required
                placeholder={t('blogs:labels.subtitle')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={blogValues?.blogSubTitleAr || ''}
                disabled={blogCreateLoading || (!!id && !isEdit)}
                isInvalid={!!errors?.blogSubTitleAr?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.blogSubTitleAr?.error &&
                  errors?.blogSubTitleAr?.message}
              </Form.Text>
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group controlId="blogSubTitleEn">
              <Form.Label>
                {`${t('blogs:labels.subtitle')} (${t(
                  'common:language.en.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="blogSubTitleEn"
                required
                placeholder={t('blogs:labels.subtitle')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={blogValues?.blogSubTitleEn || ''}
                disabled={blogCreateLoading || (!!id && !isEdit)}
                isInvalid={!!errors?.blogSubTitleEn?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.blogSubTitleEn?.error &&
                  errors?.blogSubTitleEn?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="blogKeywordsAr">
              <Form.Label>
                {`${t('blogs:labels.keywords')} (${t(
                  'common:language.ar.name'
                )})`}
                <small>
                  {` (${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="blogKeywordsAr"
                required
                placeholder={t('blogs:labels.keywords')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={blogValues?.blogKeywordsAr || ''}
                disabled={blogCreateLoading || (!!id && !isEdit)}
                isInvalid={!!errors?.blogKeywordsAr?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.blogKeywordsAr?.error &&
                  errors?.blogKeywordsAr?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="blogKeywordsEn">
              <Form.Label>
                {`${t('blogs:labels.keywords')} (${t(
                  'common:language.en.name'
                )})`}
                <small>
                  {` (${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="blogKeywordsEn"
                required
                placeholder={t('blogs:labels.keywords')}
                onChange={e => handleChange(e.target.value, e.target.name)}
                value={blogValues?.blogKeywordsEn || ''}
                disabled={blogCreateLoading || (!!id && !isEdit)}
                isInvalid={!!errors?.blogKeywordsEn?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.blogKeywordsEn?.error &&
                  errors?.blogKeywordsEn?.message}
              </Form.Text>
            </Form.Group>
          </Col>

          <Col xs="12">
            <Form.Label>
              {`${t('blogs:labels.content')}`}
              <span className="text-danger">*</span>
            </Form.Label>

            <MultiLanguageRTE
              t={t}
              enableFullscreen
              activeLanguage={currentLanguage}
              valueEn={blogValues?.blogContentEn || ''}
              valueAr={blogValues?.blogContentAr || ''}
              errorEn={!!errors?.blogContentEn?.error}
              errorAr={!!errors?.blogContentAr?.error}
              errorArMessage={t('common:validation.fieldIsRequired')}
              errorEnMessage={t('common:validation.fieldIsRequired')}
              disabled={blogCreateLoading || (!!id && !isEdit)}
              handleEnChange={newValue => {
                handleChange(newValue || '', 'blogContentEn');
              }}
              handleArChange={newValue => {
                handleChange(newValue || '', 'blogContentAr');
              }}
            />
          </Col>
        </Row>
      </Card.Body>
      {!isReadOnly && (
        <Card.Footer>
          {!id && (
            <>
              <Button size="sm" onClick={onSubmit}>
                {blogCreateLoading && <Spinner size="sm" />}
                {!blogCreateLoading && t('common:button.save')}
              </Button>
              <Button
                size="sm"
                variant="danger"
                onClick={onCancel}
                className="mx-2"
              >
                {!blogCreateLoading && t('common:button.cancel')}
              </Button>
            </>
          )}
          {isEdit && !!id && (
            <>
              <Button
                size="sm"
                onClick={onSubmit}
                disabled={!!blogCreateLoading}
              >
                {blogCreateLoading && <Spinner size="sm" />}
                {!blogCreateLoading && t('common:button.save')}
              </Button>

              <Button
                size="sm"
                variant="danger"
                onClick={onCancel}
                className="mx-2"
                disabled={!!blogCreateLoading}
              >
                {t('common:button.cancel')}
              </Button>
            </>
          )}
          {!isEdit && !!id && (
            <>
              <Button
                size="sm"
                variant="info"
                onClick={() => setIsEdit(true)}
                disabled={!!blogCreateLoading}
              >
                {t('common:button.edit')}
              </Button>
            </>
          )}
        </Card.Footer>
      )}
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={false} />
      )}
    </Card>
  );
};
export default BlogBasicInformation;
