import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import { Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import AppContext, { AuthContext } from 'context/Context';
import { useTranslation } from 'react-i18next';

const LoginForm = ({ hasLabel }) => {
  const { t, i18n } = useTranslation();
  const { setConfig } = useContext(AppContext);

  const location = useLocation();
  const { login } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  // State
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    remember: false
  });

  // Handler
  const handleSubmit = e => {
    e.preventDefault();
    //auth service
    setLoading(true);
    login({ ...formData }, location, onLoginFail);
  };

  const onLoginFail = () => {
    setLoading(false);
  };

  const handleFieldChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const changeLanguage = () => {
    setConfig('preferredLanguage', i18n.language === 'en' ? 'ar' : 'en');
    if (i18n.language === 'en') {
      setConfig('isRTL', true);
    } else {
      setConfig('isRTL', false);
    }
    //window.location.reload();
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t('auth:labels.email')}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Email address' : ''}
          value={formData.email}
          name="email"
          onChange={handleFieldChange}
          type="email"
        />
      </Form.Group>

      <Form.Group className="mb-3">
        {hasLabel && <Form.Label>{t('auth:labels.password')}</Form.Label>}
        <Form.Control
          placeholder={!hasLabel ? 'Password' : ''}
          value={formData.password}
          name="password"
          onChange={handleFieldChange}
          type="password"
        />
      </Form.Group>

      <Row className="justify-content-between align-items-center">
        <Col xs="auto">
          <Form.Check type="checkbox" id="rememberMe" className="mb-0">
            <Form.Check.Input
              type="checkbox"
              name="remember"
              checked={formData.remember}
              onChange={e =>
                setFormData({
                  ...formData,
                  remember: e.target.checked
                })
              }
            />
            <Form.Check.Label className="mb-0 text-700">
              {t('auth:labels.rememberMe')}
            </Form.Check.Label>
          </Form.Check>
        </Col>
      </Row>

      <Form.Group>
        <Button
          type="submit"
          color="primary"
          className="mt-3 w-100"
          disabled={!formData.email || !formData.password || loading}
        >
          {loading && <Spinner size="sm" />}
          {!loading && t('auth:labels.login')}
        </Button>
      </Form.Group>
      {/* <a
        className="w-100 text-center my-2 d-flex justify-content-center cursor-pointer"
        onClick={changeLanguage}
      >
        {i18n.language === 'ar' ? 'English' : 'العربية'}
      </a> */}
    </Form>
  );
};

LoginForm.propTypes = {
  layout: PropTypes.string,
  hasLabel: PropTypes.bool
};

LoginForm.defaultProps = {
  layout: 'simple',
  hasLabel: false
};

export default LoginForm;
