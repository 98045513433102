import React from 'react';
import ShaiCloseButton from 'components/common/ShaiCloseButton';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import Flex from 'components/common/Flex';

const NewChapterModal = ({
  saveChapterLoading,
  setNewChapter,
  chapter,
  errors,
  t,
  handleSubmitChapter,
  setNewChapterModalOpen,
  newChapterModalOpen,
  setErrors
}) => {
  return (
    <Modal
      show={newChapterModalOpen}
      onHide={() => {
        setNewChapterModalOpen(false);
        setNewChapter(null);
        setErrors(null);
      }}
      size="xl"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('course:button.addNewChapter')}
        </Modal.Title>
        <ShaiCloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => {
            setNewChapterModalOpen(false);
            setNewChapter(null);
            setErrors(null);
          }}
        />
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="newChapter" className="position-relative">
          <Flex alignItems="center" justifyContent="start" gap="1">
            <Row className="w-100 px-3">
              <Col md={6} className="mt-1 g-1">
                <Form.Label>
                  {`${t('common:labels.title')} (${t(
                    'common:language.ar.name'
                  )})`}
                  <span className="text-danger">*</span>
                  <small>
                    {`(${t('common:labels.maxCharacters', { count: 70 })})`}
                  </small>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="titleAr"
                  required
                  value={chapter?.title?.ar}
                  className="pe-4"
                  onChange={e =>
                    setNewChapter({
                      ...chapter,
                      title: { ...chapter?.title, ar: e.target.value }
                    })
                  }
                  placeholder={t('common:placeholder.titleAr')}
                  isInvalid={!!errors?.title?.ar}
                />
                <Form.Text className="text-danger">
                  {!!errors?.title?.ar?.error && errors?.title?.ar?.message}
                </Form.Text>
              </Col>
              <Col md={6} className="mt-1 g-1">
                <Form.Label>
                  {`${t('common:labels.title')} (${t(
                    'common:language.en.name'
                  )})`}
                  <span className="text-danger">*</span>
                  <small>
                    {`(${t('common:labels.maxCharacters', { count: 70 })})`}
                  </small>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="courseRequirement"
                  required
                  value={chapter?.title?.en}
                  className="pe-4"
                  onChange={e =>
                    setNewChapter({
                      ...chapter,
                      title: { ...chapter?.title, en: e.target.value }
                    })
                  }
                  placeholder={t('common:placeholder.titleEn')}
                  isInvalid={!!errors?.title?.en}
                />
                <Form.Text className="text-danger">
                  {!!errors?.title?.en?.error && errors?.title?.en?.message}
                </Form.Text>
              </Col>
            </Row>
          </Flex>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={saveChapterLoading}
          onClick={handleSubmitChapter}
          size="sm"
          className="mt-1"
        >
          {saveChapterLoading && <Spinner size="sm" />}
          {!saveChapterLoading && t('common:button.save')}
        </Button>
        <Button
          disabled={saveChapterLoading}
          onClick={() => {
            setNewChapterModalOpen(false);
            setNewChapter(null);
            setErrors(null);
          }}
          size="sm"
          className="mt-1"
          variant="danger"
        >
          {t('common:button.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default NewChapterModal;
