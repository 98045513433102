import React from 'react';
import { Col, Row } from 'react-bootstrap';
import defaultAvatar from 'assets/img/team/avatar.png';
import Avatar from 'components/common/Avatar';

const InstructorCard = ({
  name,
  heading,
  bio,
  image,
  currentLanguage,
  containerClassName
}) => {
  return (
    <Row
      className={`g-4 text-center text-md-start ${containerClassName || ''}`}
    >
      <Col md="auto">
        <Avatar size="4xl" src={image || defaultAvatar} />
      </Col>
      <Col>
        <h5 className="mb-2">
          <p className="mb-0 text-primary">{name || ''}</p>
        </h5>
        <h6 className="fs--1 text-800 fw-normal mb-3">{heading || ''}</h6>
        <div
          className="fs--1 text-700"
          dangerouslySetInnerHTML={{ __html: bio?.[currentLanguage] || '' }}
        ></div>
      </Col>
    </Row>
  );
};

export default InstructorCard;
