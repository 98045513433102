import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap';
import BlogsService from 'http/BlogsService';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { generateRandomPassword, getStateIcon } from 'helpers/utils';
import ShaiCloseButton from 'components/common/ShaiCloseButton';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { useContext } from 'react';
import { BlogContext } from 'context/Context';
import IconAlert from 'components/common/IconAlert';

const BlogActions = () => {
  const { t } = useTranslation();
  const { blog, sectionValidation, refetch } = useContext(BlogContext);
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [newAction, setNewAction] = useState();

  const {
    mutate: updateStatus,
    error: updateStatusError,
    isLoading: updateStatusLoading
  } = useMutation({
    mutationFn: BlogsService.updateStatus,
    onSuccess: data => onUpdateStatusSuccess(data)
  });

  const validateBlog = () => {
    let hasError = false;
    if (!!sectionValidation) {
      // eslint-disable-next-line
      Object.entries(sectionValidation).map(([key, value]) => {
        if (!value?.isValid) {
          //console.log(key, value);
          hasError = true;
        }
      });
      if (hasError) {
        //to get the first not valid section ordered
        const toShowError = Object.entries(sectionValidation)
          // eslint-disable-next-line
          .map(([sectionKey, sectionValue]) => sectionValue)
          .find(s => !s.isValid);
        toast.error(toShowError?.message, {
          theme: 'colored',
          autoClose: false,
          toastId: generateRandomPassword(5)
        });
      }
    }
    return hasError;
  };

  const handleActionClick = action => {
    const isValid = !validateBlog();
    if (isValid) {
      setNewAction(action);
      setConfirmModalOpen(true);
    }
  };

  const handleUpdateBlogStatus = action => {
    const isValid = !validateBlog();
    if (isValid) {
      updateStatus({
        id: blog.id,
        status: action === 'publish' ? 'published' : 'draft'
      });
    }
  };

  const onUpdateStatusSuccess = () => {
    refetch && refetch(blog.id);
    setConfirmModalOpen(false);
    toast.success(t(`common:message.updateSuccess`), {
      theme: 'colored'
    });
  };

  useEffect(() => {
    if (updateStatusError) {
      toast.error(getErrorMessage(t, updateStatusError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [updateStatusError]);

  return (
    <Card className="mb-3">
      <Card.Header as="h5">{t('blogs:labels.blogActions')}</Card.Header>
      <Card.Body className="bg-light">
        <ul className="list-unstyled fs--1 mb-0">
          <li className="hover-actions-trigger mb-2">
            <FontAwesomeIcon
              icon={getStateIcon(blog?.status?.code)}
              className="me-2"
            />
            <span className="ms-1">{`${t('blogs:labels.currentState')}`}:</span>
            <span className="text-800 fw-medium me-2">
              {` ${
                !!blog?.status?.code
                  ? t(`blogs:status.${blog?.status?.code}`)
                  : 'unknown'
              }`}
            </span>
          </li>
        </ul>
        {blog?.status?.code?.toLowerCase() === 'published' && (
          <IconAlert variant="warning">
            <p className="mb-0">{t('blogs:message.editNote')}</p>
          </IconAlert>
        )}
      </Card.Body>
      {blog?.status?.code?.toLowerCase() === 'draft' && (
        <Card.Footer>
          <Col xs="auto">
            <Button
              size="md"
              variant="primary"
              className="px-xxl-5 px-4"
              onClick={() => handleActionClick('publish')}
            >
              {t(`blogs:actions.publish`)}
            </Button>
          </Col>
        </Card.Footer>
      )}
      {blog?.status?.code?.toLowerCase() !== 'draft' && (
        <Card.Footer>
          <Col xs="auto">
            <Button
              size="md"
              variant="primary"
              className="px-xxl-5 px-4"
              onClick={() => handleActionClick('unPublish')}
            >
              {t(`blogs:actions.unPublish`)}
            </Button>
          </Col>
        </Card.Footer>
      )}

      <Modal
        show={confirmModalOpen}
        onHide={() => setConfirmModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('blogs:labels.actionModalHeader', {
              actionTitle: t(`blogs:actions.${newAction}`)
            })}
          </Modal.Title>
          <ShaiCloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setConfirmModalOpen(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <p>
              {t('blogs:labels.actionConfirm', {
                action: t(`blogs:actions.${newAction}`)
              })}
            </p>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => handleUpdateBlogStatus(newAction)}
            variant={'primary'}
            disabled={updateStatusLoading}
          >
            {!updateStatusLoading && t(`blogs:actions.${newAction}`)}
            {updateStatusLoading && (
              <Spinner animation="border" role="status" size="sm" />
            )}
          </Button>
          <Button
            onClick={() => setConfirmModalOpen(null)}
            variant="secondary"
            disabled={updateStatusLoading}
          >
            {t('common:button.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default BlogActions;
