import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Card } from 'react-bootstrap';
import Background from 'components/common/Background';
import Avatar from 'components/common/Avatar';
import classNames from 'classnames';
import { AuthContext } from 'context/Context';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import UserService from 'http/UserService';
import { getErrorMessage } from 'http/utils';

const ProfileBannerHeader = ({
  avatar,
  coverSrc,
  className,
  refresh,
  userData
}) => {
  const { t } = useTranslation();
  const { user } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const allowedFileTypes = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'webp'];
  const onUpload = event => {
    const fileData = event.target.files[0];
    if (fileData) {
      const ext = fileData.name.split('.').pop();
      if (!allowedFileTypes.includes(ext)) {
        toast.error(t('user:validation.notAllowedFileType'));
        return;
      }
      UserService.uploadProfileImage({ userId: user?.id, file: fileData })
        .then(response => {
          if (response) {
            toast.success(t('user:message.updateUserSettingsSuccess'), {
              theme: 'colored'
            });
            //setUser({ ...user, avatarUrl: response });
            refresh && refresh();
          } else {
            toast.error(getErrorMessage(t, response), {
              theme: 'colored'
            });
          }
          setLoading(false);
        })
        .catch(error => {
          setLoading(false);
          toast.error(getErrorMessage(t, error), {
            theme: 'colored'
          });
        });
    }

    return;
  };

  return (
    <Card.Header
      className={classNames(className, 'position-relative min-vh-25 mb-7')}
    >
      <Background image={coverSrc} className="rounded-3 rounded-bottom-0" />
      <Avatar
        size="5xl"
        className="avatar-profile"
        src={avatar}
        mediaClass="img-thumbnail shadow-sm"
        enableUpload={
          !window.location.pathname.includes('profile') &&
          userData?.id === user.id
        }
        onUpload={onUpload}
        disableUpload={loading}
      />
    </Card.Header>
  );
};

const ProfileBannerBody = ({ children }) => {
  return <Card.Body>{children}</Card.Body>;
};

const ProfileBanner = ({ children }) => {
  return <Card className="mb-3">{children}</Card>;
};

ProfileBanner.Header = ProfileBannerHeader;
ProfileBanner.Body = ProfileBannerBody;

ProfileBannerHeader.propTypes = {
  avatar: PropTypes.string.isRequired,
  coverSrc: PropTypes.string.isRequired,
  className: PropTypes.string
};

ProfileBannerBody.propTypes = {
  children: PropTypes.node.isRequired
};

ProfileBanner.propTypes = {
  children: PropTypes.node.isRequired
};

export default ProfileBanner;
