import React, { useEffect, useState } from 'react';
import Select from 'react-select/async';

const AsyncSelect = ({ asyncRequest, invalid, defaultOptions, ...rest }) => {
  const [styles, setStyles] = useState({});

  useEffect(() => {
    if (invalid) {
      setStyles({
        control: (base, state) => ({
          ...base,
          border: '1px solid #C1272D !important',
          borderColor: '#C1272D',
          boxShadow: state.isFocused ? '#C1272D' : '',
          '&:hover': {
            borderColor: '#C1272D'
          },
          zIndex: 5
        }),
        menu: base => ({
          ...base,
          zIndex: 5
        }),
        listBox: base => ({
          ...base,
          zIndex: 5
        })
      });
    } else {
      setStyles({
        control: (base, state) => ({
          ...base,
          border: state.isFocused ? '1px solid #00205C !important' : '',
          borderColor: state.isFocused ? '#00205C' : 'lightGrey',
          boxShadow: state.isFocused ? '#00205C' : 'lightGrey',
          '&:hover': {
            borderColor: state.isFocused ? '#00205C' : ''
          },
          zIndex: 4
        }),
        menu: base => ({
          ...base,
          zIndex: 5
        }),
        listBox: base => ({
          ...base,
          zIndex: 5
        })
      });
    }
  }, [invalid]);

  const promiseOptions = inputValue => asyncRequest(inputValue);

  return (
    <Select
      cacheOptions
      defaultOptions={defaultOptions}
      loadOptions={promiseOptions}
      styles={styles}
      invalid={invalid}
      openMenuOnClick={false}
      {...rest}
    />
  );
};

export default AsyncSelect;
