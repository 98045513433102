import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Card, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useContext } from 'react';
import { CourseContext } from 'context/Context';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { useMutation, useQuery } from 'react-query';
import CourseService from 'http/CourseService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { useState } from 'react';
import {
  generateRandomPassword,
  getStateIcon,
  toClientUrl
} from 'helpers/utils';
import { faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import ShaiCloseButton from 'components/common/ShaiCloseButton';
import { getCourseActionColor } from '../utils';

const CourseActions = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const { course, fetchCourse, sectionValidation } = useContext(CourseContext);

  const [transitions, setTransitions] = useState([]);
  const [action, setAction] = useState(null);
  const [comment, setComment] = useState('');

  const {
    isLoading: courseTransitionsLoading,
    data: courseTransitionsData,
    error: courseTransitionsError,
    refetch: fetchCourseTransitions
  } = useQuery(
    ['FetchCourseTransitions', id],
    () => CourseService.getCourseAvailableTransitions(id),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  const {
    mutate: doTransition,
    error: doTransitionError,
    isLoading: doTransitionLoading
  } = useMutation({
    mutationFn: CourseService.doTransition,
    onSuccess: data => onDoTransitionSuccess(data)
  });

  const getActionSuccessMessage = action => {
    switch (action) {
      case 'submit':
        return 'submitSuccess';
      case 'save':
        return 'updateSuccess';
      case 'approve':
        return 'approveSuccess';
      case 'published':
        return 'publishSuccess';
      case 'reject':
        return 'rejectSuccess';
      default:
        return 'updateSuccess';
    }
  };

  const onDoTransitionSuccess = () => {
    fetchCourse && fetchCourse(id);
    setAction(null);
    setComment('');
    toast.success(
      t(`course:message.${getActionSuccessMessage(action.action)}`),
      {
        theme: 'colored'
      }
    );
  };

  const handleActionClick = transition => {
    const isValid = !validateCourse();
    if (isValid) {
      setAction(transition);
    }
  };

  const handleDoTransition = action => {
    const isValid = !validateCourse();
    if (isValid) {
      doTransition({
        courseId: id,
        action: action.action,
        comment
      });
    }
  };

  useEffect(() => {
    if (course?.id) {
      fetchCourseTransitions(course?.id);
    }
  }, [course]);

  useEffect(() => {
    if (
      !courseTransitionsLoading &&
      courseTransitionsData &&
      !courseTransitionsError
    ) {
      setTransitions(courseTransitionsData.payload);
    }
    if (courseTransitionsError) {
      toast.error(getErrorMessage(t, courseTransitionsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [courseTransitionsLoading, courseTransitionsData, courseTransitionsError]);

  const getNextStateElement = course => {
    if (!course) {
      return '';
    }
    if (!transitions?.length) {
      return '';
    }
    const nextStatus = transitions?.find(
      t => t.stateFrom === course.status.code
    );

    if (!nextStatus || !nextStatus?.stateTo) {
      return '';
    }

    return (
      <li className="hover-actions-trigger mb-2">
        <FontAwesomeIcon
          icon={getStateIcon(nextStatus?.stateTo)}
          className="me-2"
        />
        <span className="ms-1">{t('course:labels.nextState')}:</span>
        <span className="text-800 fw-medium me-2">
          {` ${t(`course:status.${nextStatus?.stateTo}`)}`}
        </span>
      </li>
    );
  };

  const getCourseVisibility = course => {
    if (course?.status?.code === 'published') {
      return true;
    }
    return false;
  };

  const validateCourse = () => {
    let hasError = false;
    if (!!sectionValidation) {
      // eslint-disable-next-line
      Object.entries(sectionValidation).map(([key, value]) => {
        if (!value?.isValid) {
          //console.log(key, value);
          hasError = true;
        }
      });
      if (hasError) {
        //to get the first not valid section ordered
        const toShowError = Object.entries(sectionValidation)
          // eslint-disable-next-line
          .map(([sectionKey, sectionValue]) => sectionValue)
          .find(s => !s.isValid);
        toast.error(toShowError?.message, {
          theme: 'colored',
          autoClose: false,
          toastId: generateRandomPassword(5)
        });
      }
    }
    return hasError;
  };

  useEffect(() => {
    if (doTransitionError) {
      toast.error(getErrorMessage(t, doTransitionError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [doTransitionError]);

  if (!course) {
    return <></>;
  }

  return (
    <>
      <Card className="mb-lg-3 order-lg-0 order-1">
        <Card.Header className="py-2 d-flex flex-between-center">
          <h5 className="mb-0">{t('course:labels.courseActions')}</h5>
          <Button
            variant="link"
            size="sm"
            className="px-0 fw-medium text-secondary "
            href={toClientUrl(`course/${course.id}`)}
            target="_blank"
          >
            <span className="d-lg-none d-xxl-inline-block">
              {t('course:button.preview')}
            </span>
            <FontAwesomeIcon icon="external-link-alt" className="ms-2 fs--2" />
          </Button>
        </Card.Header>
        <Card.Body className="bg-light">
          <ul className="list-unstyled fs--1 mb-0">
            <li className="hover-actions-trigger mb-2">
              <FontAwesomeIcon
                icon={getStateIcon(course.status.code)}
                className="me-2"
              />
              <span className="ms-1">
                {`${t('course:labels.currentState')}`}:
              </span>
              <span className="text-800 fw-medium me-2">
                {` ${t(`course:status.${course.status.code}`)}`}
              </span>
            </li>
            {getNextStateElement(course)}
            <li className="hover-actions-trigger mb-2">
              <FontAwesomeIcon icon={faEyeSlash} className="me-2" />
              <span className="ms-1">{t('course:labels.visibility')}:</span>
              <span className="text-800 fw-medium me-2">
                {` ${
                  getCourseVisibility(course)
                    ? t('course:status.published')
                    : t('course:status.notPublished')
                }`}
              </span>
            </li>
          </ul>
        </Card.Body>
        <Card.Footer className="py-2">
          <Row className="flex-between-center g-0">
            {transitions?.map((trans, index) => {
              return (
                <Col
                  xs="auto"
                  key={`course-transition-${index}-${trans.action}`}
                >
                  <Button
                    size="md"
                    variant={getCourseActionColor(trans.stateTo)}
                    className="px-xxl-5 px-4"
                    onClick={() => handleActionClick(trans)}
                  >
                    {t(`course:actions.${trans.action}`)}
                  </Button>
                </Col>
              );
            })}
          </Row>
        </Card.Footer>
        {!!action?.action && (
          <Modal
            show={!!action?.action}
            onHide={() => setAction(null)}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header>
              <Modal.Title id="contained-modal-title-vcenter">
                {t('course:labels.actionModalHeader', {
                  actionTitle: t(`course:actions.${action?.action}`)
                })}
              </Modal.Title>
              <ShaiCloseButton
                className="btn btn-circle btn-sm transition-base p-0"
                onClick={() => setAction(null)}
              />
            </Modal.Header>
            <Modal.Body>
              <Row>
                <p>
                  {t('course:labels.actionConfirm', {
                    action: t(`course:actions.${action?.action}`)
                  })}
                </p>
                <Col xs="12">
                  <Form.Group>
                    <Form.Label>{t('course:labels.comment')}</Form.Label>
                    <Form.Control
                      type="textarea"
                      as="textarea"
                      name="comment"
                      required
                      placeholder={t('course:placeholders.actionComment')}
                      disabled={doTransitionLoading}
                      value={comment}
                      onChange={e => setComment(e.target.value)}
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer>
              <Button
                onClick={() => handleDoTransition(action)}
                variant={'primary'}
                disabled={doTransitionLoading}
              >
                {!doTransitionLoading && t(`course:actions.${action?.action}`)}
                {doTransitionLoading && (
                  <Spinner animation="border" role="status" size="sm" />
                )}
              </Button>
              <Button
                onClick={() => setAction(null)}
                variant="secondary"
                disabled={doTransitionLoading}
              >
                {t('common:button.cancel')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </Card>
    </>
  );
};

export default CourseActions;
