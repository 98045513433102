import React from 'react';
import CountryBasicForm from './components/CountryBasicForm';

const CreateCountry = () => {
  return (
    <>
      <CountryBasicForm />
    </>
  );
};
export default CreateCountry;
