import React from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import createMarkup from 'helpers/createMarkup';
import Avatar from 'components/common/Avatar';
import defaultAvatar from 'assets/img/team/avatar.png';
import moment from 'moment';

const Notification = ({
  createdAt,
  className,
  isRead,
  flush,
  content,

  user,
  actionUrl,

  currentLanguage,
  onClick
}) => {
  return (
    <Link
      className={classNames(
        'notification',
        { 'notification-unread': !isRead, 'notification-flush': flush },
        className
      )}
      to={actionUrl || '#'}
      onClick={onClick}
    >
      <div className="notification-avatar">
        <Avatar src={user?.avatar || defaultAvatar} className="me-3" />
      </div>
      <div className="notification-body">
        <p
          className="mb-1"
          dangerouslySetInnerHTML={createMarkup(content?.[currentLanguage])}
        />
        <span className="notification-time">
          {moment(createdAt).locale(currentLanguage).fromNow()}
        </span>
      </div>
    </Link>
  );
};

export default Notification;
