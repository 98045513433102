import React, { useContext, useState, useEffect } from 'react';
import { Button, Card, Col, Collapse, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { useMutation } from 'react-query';
import CourseService from 'http/CourseService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { sort } from 'helpers/utils';
import ChapterModal from './ChapterModal';
import AppContext, { CourseContext } from 'context/Context';
import ConfirmModal from 'components/common/ConfirmModal';
import LessonModal from './LessonModal';
import QuizModal from './QuizModal';
import StrictModeDroppable from 'components/common/StrictModeDroppable';
import PracticeCodeModal from './PracticeCodeModal';
import LessonResourcesModal from './LessonResourcesModal';
import { useBreakpoints } from 'hooks/useBreakpoints';
import NewChapterModal from './NewChapterModal';
import CurriculumChapterItem from './CurriculumChapterItem';
import CurriculumLessonItem from './CurriculumLessonItem';
import CurriculumQuizItem from './CurriculumQuizItem';
import CurriculumPracticeCodeItem from './CurriculumPracticeCodeItem';

const CourseCurriculums = () => {
  const { t, i18n } = useTranslation();
  const { breakpoints } = useBreakpoints();
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const { course, isReadOnly, isCreate, fetchCourse } =
    useContext(CourseContext);
  const currentLanguage = i18n.language;
  const [draggableData, setDraggableData] = useState([]);
  const [newChapter, setNewChapter] = useState(null);
  const [errors, setErrors] = useState(null);
  const [chapterToEdit, setChapterToEdit] = useState(null);
  const [lessonToEdit, setLessonToEdit] = useState(null);
  const [quizToEdit, setQuizToEdit] = useState(null);
  const [practiceCodeToEdit, setPracticeCodeToEdit] = useState(null);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [openChapters, setOpenChapters] = useState([]);
  const [resourcesOpen, setResourcesOpen] = useState(null);
  const [newChapterModalOpen, setNewChapterModalOpen] = useState(false);
  useEffect(() => {
    if (course?.chapters?.length) {
      const sorted = sort(course?.chapters, 'rank', 'asc');
      setDraggableData(sorted);
    } else {
      setDraggableData([]);
    }
  }, [course]);

  const {
    mutate: saveChapter,
    error: saveChapterError,
    isLoading: saveChapterLoading
  } = useMutation({
    mutationFn: CourseService.saveChapter,
    onSuccess: data => onSaveChapterSucces(data)
  });

  const { mutate: reorderChapters, error: reorderChaptersError } = useMutation({
    mutationFn: CourseService.reorderChapters,
    onSuccess: data => onReorderChaptersSucces(data)
  });

  const { mutate: reorderLessons, error: reorderLessonsError } = useMutation({
    mutationFn: CourseService.reorderLessons,
    onSuccess: data => onReorderLessonsSucces(data)
  });

  const {
    mutate: deleteChapter,
    error: deleteChapterError,
    isLoading: deleteChapterLoading
  } = useMutation({
    mutationFn: CourseService.deleteChapter,
    onSuccess: data => onDeleteChapterSucces(data)
  });

  const {
    mutate: deleteLesson,
    error: deleteLessonError,
    isLoading: deleteLessonLoading
  } = useMutation({
    mutationFn: CourseService.deleteLesson,
    onSuccess: data => onDeleteLessonSucces(data)
  });

  const {
    mutate: deleteQuiz,
    error: deleteQuizError,
    isLoading: deleteQuizLoading
  } = useMutation({
    mutationFn: CourseService.deleteQuiz,
    onSuccess: data => onDeleteQuizSucces(data)
  });

  const {
    mutate: deletePracticeCode,
    error: deletePracticeCodeError,
    isLoading: deletePracticeCodeLoading
  } = useMutation({
    mutationFn: CourseService.deletePracticeCode,
    onSuccess: data => onDeletePracticeCodeSucces(data)
  });

  const handleSubmitChapter = () => {
    let errorsObj = {};
    if (!newChapter?.title?.ar) {
      if (!errorsObj['title']) {
        errorsObj['title'] = {
          ar: {
            error: true,
            message: t('common:validation.fieldIsRequired')
          }
        };
      } else {
        errorsObj['title']['ar'] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
      }
    }
    if (!newChapter?.title?.en) {
      if (!errorsObj['title']) {
        errorsObj['title'] = {
          en: { error: true, message: t('common:validation.fieldIsRequired') }
        };
      } else {
        errorsObj['title']['en'] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
      }
    }
    if (!!newChapter?.title?.ar) {
      if (newChapter?.title?.ar.length > 70) {
        if (!errorsObj['title']) {
          errorsObj['title'] = {
            ar: {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 70
              })
            }
          };
        } else {
          errorsObj['title']['ar'] = {
            error: true,
            message: t('course:validation.message.maxAllowedCharacters', {
              count: 70
            })
          };
        }
      } else {
        if (!!errorsObj['title']) {
          errorsObj['title']['ar'] = null;
        }
      }
    }
    if (!!newChapter?.title?.en) {
      if (newChapter?.title?.en.length > 70) {
        if (!errorsObj['title']) {
          errorsObj['title'] = {
            en: {
              error: true,
              message: t('course:validation.message.maxAllowedCharacters', {
                count: 70
              })
            }
          };
        } else {
          errorsObj['title']['en'] = {
            error: true,
            message: t('course:validation.message.maxAllowedCharacters', {
              count: 70
            })
          };
        }
      } else {
        if (!!errorsObj['title']) {
          errorsObj['title']['en'] = null;
        }
      }
    }
    setErrors(errorsObj);
    if (errorsObj?.title?.en?.error || errorsObj?.title?.ar?.error) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    saveChapter({ ...newChapter, rank: newChapter?.rank || 0 });
  };

  const onSaveChapterSucces = () => {
    fetchCourse(course?.id);
    if (newChapter?.id) {
      toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
    } else {
      toast.success(t(`common:message.createSuccess`), { theme: 'colored' });
    }
    setNewChapterModalOpen(false);
    setErrors(null);
    setNewChapter(null);
  };
  const onReorderChaptersSucces = () => {
    fetchCourse(course?.id);
    toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
  };
  const onReorderLessonsSucces = () => {
    fetchCourse(course?.id);
    toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
  };
  const onDeleteChapterSucces = () => {
    fetchCourse(course?.id);
    setConfirmModalProps(null);
    toast.success(t(`common:message.deleteSuccess`), { theme: 'colored' });
  };

  const onDeleteLessonSucces = () => {
    fetchCourse(course?.id);
    setConfirmModalProps(null);
    toast.success(t(`common:message.deleteSuccess`), { theme: 'colored' });
  };

  const onDeleteQuizSucces = () => {
    fetchCourse(course?.id);
    setConfirmModalProps(null);
    toast.success(t(`common:message.deleteSuccess`), { theme: 'colored' });
  };
  const onDeletePracticeCodeSucces = () => {
    fetchCourse(course?.id);
    setConfirmModalProps(null);
    toast.success(t(`common:message.deleteSuccess`), { theme: 'colored' });
  };
  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.index === destination.index) {
      return;
    }
    const items = draggableData;
    const reorderedItems = reorder(items, source.index, destination.index);

    setDraggableData(reorderedItems);

    let newData = reorderedItems?.map((chap, i) => {
      return {
        id: chap.id,
        rank: i
      };
    });
    reorderChapters({
      chapters: newData,
      courseId: course?.id
    });
  };
  const onLessonDragEnd = result => {
    const { source, destination, chapterId } = result;
    if (!destination) {
      return;
    }
    if (source.index === destination.index) {
      return;
    }
    let chapters = [...draggableData];
    let chapter = chapters?.find(c => c.id === chapterId);
    const chapterIndex = chapters?.findIndex(c => c.id === chapterId);
    const items = chapter?.lessons;
    const reorderedItems = reorder(items, source.index, destination.index);

    chapter.lessons = reorderedItems;
    chapters[chapterIndex] = chapter;
    setDraggableData(chapters);

    let newData = reorderedItems?.map((lesson, i) => {
      return {
        id: lesson.id,
        rank: i
      };
    });
    reorderLessons({
      lessons: newData,
      chapterId,
      courseId: course?.id
    });
  };

  const newChapterClick = () => {
    setNewChapter({
      title: {
        ar: '',
        en: ''
      },
      courseId: course?.id,
      rank: course?.chapter?.length + 1
    });
    setNewChapterModalOpen(true);
  };

  const handleDeleteChapter = id => {
    deleteChapter({ id, courseId: course.id });
  };
  const handleDeleteLesson = ({ id, chapterId }) => {
    deleteLesson({ id, courseId: course.id, chapterId });
  };

  const handleDeleteQuiz = ({ id, chapterId }) => {
    deleteQuiz({ id, courseId: course.id, chapterId });
  };

  const handleDeletePracticeCode = ({ id, chapterId }) => {
    deletePracticeCode({ id, courseId: course.id, chapterId });
  };

  const handleClickDeleteQuiz = ({ id, chapterId }) => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleDeleteQuiz({ id, chapterId }),
      message: t('course:message.deleteQuizConfirm'),
      header: t('course:message.deleteQuizTitle'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.delete')
    });
  };
  const handleClickDeleteChapter = id => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleDeleteChapter(id),
      message: t('course:message.deleteChapterConfirm'),
      header: t('course:message.deleteChapterTitle'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.delete')
    });
  };
  const handleClickDeleteLesson = ({ id, chapterId }) => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleDeleteLesson({ id, chapterId }),
      message: t('lesson:message.deleteLessonConfirm'),
      header: t('lesson:message.deleteLessonTitle'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.delete')
    });
  };

  const handleClickDeletePracticeCode = ({ id, chapterId }) => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleDeletePracticeCode({ id, chapterId }),
      message: t('course:message.deletePracticeCodeConfirm'),
      header: t('course:message.deletePracticeCodeTitle'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.delete')
    });
  };

  useEffect(() => {
    if (saveChapterError) {
      toast.error(getErrorMessage(t, saveChapterError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (reorderChaptersError) {
      toast.error(getErrorMessage(t, reorderChaptersError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (deleteChapterError) {
      toast.error(getErrorMessage(t, deleteChapterError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (deleteLessonError) {
      toast.error(getErrorMessage(t, deleteLessonError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (reorderLessonsError) {
      toast.error(getErrorMessage(t, reorderLessonsError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (deleteQuizError) {
      toast.error(getErrorMessage(t, deleteQuizError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (deletePracticeCodeError) {
      toast.error(getErrorMessage(t, deletePracticeCodeError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [
    saveChapterError,
    reorderChaptersError,
    deleteChapterError,
    deleteLessonError,
    reorderLessonsError,
    deleteQuizError,
    deletePracticeCodeError
  ]);

  // useEffect(() => {
  //   console.log('user', user);
  // }, []);

  if (isCreate) {
    return '';
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h5">{t('course:labels.curriculumsTitle')}</Card.Header>
      <Card.Body className="p-0">
        <DragDropContext onDragEnd={onDragEnd}>
          <Row>
            <Col auto="true">
              <StrictModeDroppable droppableId="droppable1" type="DRAG">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className=" border bg-white dark__bg-1000 rounded-2  mb-3 p-0 overflow-visible"
                  >
                    {draggableData.map((item, index) => {
                      const isOpen = !!openChapters?.includes(item.id);

                      return (
                        <Draggable
                          key={item.id}
                          draggableId={`${item.id}`}
                          index={index}
                          disabled={isReadOnly}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={`fs--1 mb-0 d-flex flex-column align-items-start justify-content-center m-2 border-grey border-1 border rounded mb-2 ${
                                breakpoints.down('md') ? 'p-0' : 'p-3'
                              }`}
                            >
                              <CurriculumChapterItem
                                t={t}
                                setOpenChapters={setOpenChapters}
                                openChapters={openChapters}
                                chapter={item}
                                dragHandleProps={provided.dragHandleProps}
                                isReadOnly={isReadOnly}
                                isRTL={isRTL}
                                breakpoints={breakpoints}
                                currentLanguage={currentLanguage}
                                setLessonToEdit={setLessonToEdit}
                                setQuizToEdit={setQuizToEdit}
                                setChapterToEdit={setChapterToEdit}
                                setPracticeCodeToEdit={setPracticeCodeToEdit}
                                handleClickDeleteChapter={
                                  handleClickDeleteChapter
                                }
                              />
                              <Collapse in={isOpen}>
                                <div
                                  className={` w-100 ${
                                    breakpoints.down('md') ? 'px-0' : 'px-3'
                                  }`}
                                >
                                  <DragDropContext
                                    onDragEnd={result =>
                                      onLessonDragEnd({
                                        ...result,
                                        chapterId: item.id
                                      })
                                    }
                                  >
                                    <StrictModeDroppable
                                      droppableId="droppable2"
                                      type="DRAG"
                                    >
                                      {provided => (
                                        <div
                                          //id={`lesson-drag-${item.id}`}
                                          ref={provided.innerRef}
                                          {...provided.droppableProps}
                                          className="kanban-items-container  bg-white dark__bg-1000 rounded-2  mb-3 p-0 overflow-visible"
                                        >
                                          {!!item.lessons?.length &&
                                            sort(
                                              item.lessons,
                                              'rank',
                                              'asc'
                                            )?.map((lesson, index) => {
                                              return (
                                                <CurriculumLessonItem
                                                  t={t}
                                                  key={lesson.id}
                                                  lesson={lesson}
                                                  index={index}
                                                  isReadOnly={isReadOnly}
                                                  breakpoints={breakpoints}
                                                  currentLanguage={
                                                    currentLanguage
                                                  }
                                                  setResourcesOpen={
                                                    setResourcesOpen
                                                  }
                                                  course={course}
                                                  chapter={item}
                                                  setLessonToEdit={
                                                    setLessonToEdit
                                                  }
                                                  handleClickDeleteLesson={
                                                    handleClickDeleteLesson
                                                  }
                                                />
                                              );
                                            })}
                                          {!item.lessons?.length && (
                                            <p className="w-100 my-2 text-center">
                                              {t(
                                                'lesson:message.noLessonsFound'
                                              )}
                                            </p>
                                          )}
                                          {provided.placeholder}
                                        </div>
                                      )}
                                    </StrictModeDroppable>
                                  </DragDropContext>

                                  {!!item?.quiz && (
                                    <CurriculumQuizItem
                                      t={t}
                                      chapter={item}
                                      breakpoints={breakpoints}
                                      currentLanguage={currentLanguage}
                                      isReadOnly={isReadOnly}
                                      setQuizToEdit={setQuizToEdit}
                                      handleClickDeleteQuiz={
                                        handleClickDeleteQuiz
                                      }
                                    />
                                  )}
                                  {!!item?.practiceCode && (
                                    <CurriculumPracticeCodeItem
                                      t={t}
                                      chapter={item}
                                      breakpoints={breakpoints}
                                      currentLanguage={currentLanguage}
                                      isReadOnly={isReadOnly}
                                      setPracticeCodeToEdit={
                                        setPracticeCodeToEdit
                                      }
                                      handleClickDeletePracticeCode={
                                        handleClickDeletePracticeCode
                                      }
                                    />
                                  )}
                                </div>
                              </Collapse>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </Col>
          </Row>
        </DragDropContext>
      </Card.Body>

      {!isReadOnly && (
        <Card.Footer className={`text-center py-2`}>
          <Button variant="link" size="sm" onClick={newChapterClick}>
            <FontAwesomeIcon icon="plus" className="me-1 fs--2" />
            {t('course:button.addNewChapter')}
          </Button>
        </Card.Footer>
      )}
      <NewChapterModal
        chapter={newChapter}
        setNewChapter={setNewChapter}
        errors={errors}
        saveChapterLoading={saveChapterLoading}
        t={t}
        setNewChapterModalOpen={setNewChapterModalOpen}
        newChapterModalOpen={newChapterModalOpen}
        handleSubmitChapter={handleSubmitChapter}
        setErrors={setErrors}
      />
      {!!chapterToEdit?.id && (
        <ChapterModal
          chapter={chapterToEdit}
          course={course}
          close={() => setChapterToEdit(null)}
          refetchCourse={fetchCourse}
        />
      )}
      {!!lessonToEdit?.chapter?.id && (
        <LessonModal
          lesson={lessonToEdit.lesson}
          course={course}
          chapter={lessonToEdit.chapter}
          close={() => setLessonToEdit(null)}
          refetchCourse={fetchCourse}
        />
      )}
      {!!quizToEdit?.chapter?.id && (
        <QuizModal
          quiz={quizToEdit.quiz}
          course={course}
          chapter={quizToEdit.chapter}
          close={() => setQuizToEdit(null)}
          refetchCourse={fetchCourse}
        />
      )}
      {!!practiceCodeToEdit?.chapter?.id && (
        <PracticeCodeModal
          practiceCode={practiceCodeToEdit.practiceCode}
          course={course}
          chapter={practiceCodeToEdit.chapter}
          close={() => setPracticeCodeToEdit(null)}
          refetchCourse={fetchCourse}
        />
      )}
      {!!resourcesOpen?.lesson?.id && (
        <LessonResourcesModal
          lesson={resourcesOpen.lesson}
          course={course}
          close={() => setResourcesOpen(null)}
          refetchCourse={fetchCourse}
        />
      )}

      <ConfirmModal
        {...confirmModalProps}
        loading={
          deleteChapterLoading ||
          deleteLessonLoading ||
          deletePracticeCodeLoading ||
          deleteQuizLoading
        }
      />
    </Card>
  );
};

export default CourseCurriculums;
