import React from 'react';
import { Button, Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

const Error401 = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <Card className="text-center">
      <Card.Body className="p-5">
        <div className="display-1 text-300 fs-error">401</div>
        <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
          {t('error:unauthorizedTitle')}
        </p>
        <hr />
        <p>{t('error:unauthorizedDescription')}</p>
        <Button
          className="btn btn-primary btn-sm mt-3"
          onClick={() => navigate(-1)}
        >
          <FontAwesomeIcon icon={faHome} className="me-2" />
          {t('common:button.goBack')}
        </Button>
      </Card.Body>
    </Card>
  );
};

export default Error401;
