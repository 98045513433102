import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api`;
const LIST_COUNTRIES_URL = `https://admin.api.360realize.com/api/countries/List`;
const GET_COUNTRY_URL = `https://admin.api.360realize.com/api/countries/GetCountryById`;
const CREATE_COUNTRY_URL = `https://admin.api.360realize.com/api/countries/Create`;
const UPDATE_COUNTRY_URL = `https://admin.api.360realize.com/api/countries/Update`;
const DELETE_COUNTRY_URL = `https://admin.api.360realize.com/api/countries/Delete`;

const CountryService = {
  list: data => {
    return http.post(
      LIST_COUNTRIES_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  get: id => {
    return http.get(`${GET_COUNTRY_URL}?id=${id}`, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  create: data => {
    return http.post(
      CREATE_COUNTRY_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  update: data => {
    return http.post(
      UPDATE_COUNTRY_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  delete: data => {
    return http.delete(`${DELETE_COUNTRY_URL}?id=${data}`, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
};

export default CountryService;
