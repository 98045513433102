import React, { useContext } from 'react';
import MultiSelect from 'components/common/MultiSelect';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { LearningPathContext } from 'context/Context';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import LearningPathService from 'http/LearningPathService';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import MultiLanguageRTE from 'components/common/MultiLanguageRTE';

const levels = [
  { label: 'starter' },
  { label: 'intermediate' },
  { label: 'advanced' },
  { label: 'professional' }
];

const optionalFields = ['subCategory'];

const toFormSchema = learningPath => {
  if (!learningPath) {
    return null;
  }
  return {
    learningPathTitleAr: learningPath.title.ar,
    learningPathTitleEn: learningPath.title.en,
    learningPathSubTitleAr: learningPath.subtitle.ar,
    learningPathSubTitleEn: learningPath.subtitle.en,
    learningPathDescriptionAr: learningPath.description.ar,
    learningPathDescriptionEn: learningPath.description.en,
    tags: learningPath.settings.tags,
    level: learningPath.level,
    category: !!learningPath.category.parentId
      ? learningPath.category.parentId
      : learningPath.category.id,
    subCategory: learningPath.category.parentId ? learningPath.category.id : ''
  };
};

const LearningPathInformationForm = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const {
    learningPath,
    isCreate,
    editCard,
    setEditCard,
    isReadOnly,
    categories
  } = useContext(LearningPathContext);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [description, setDescription] = useState(null);
  const [formKey, setFormKey] = useState(Date.now()); // Create a state variable to force re-render
  const [errors, setErrors] = useState(null);
  const {
    mutate: createLearningPath,
    error: learningPathCreateError,
    isLoading: learningPathCreateLoading
  } = useMutation({
    mutationFn: LearningPathService.create,
    onSuccess: data => onCreateLearningPathSuccess(data)
  });

  const { register, handleSubmit, setValue, control, reset, getValues } =
    useForm({
      defaultValues: null
    });

  const fillInitialData = () => {
    const formattedData = toFormSchema(learningPath);
    //console.log('formattedData', formattedData);
    Object.entries(formattedData).map(([key, value]) => {
      if (key === 'tags') {
        setValue(key, value?.map(tag => ({ label: tag, value: tag })) || []);
      } else {
        setValue(key, value);
      }
    });
    setSelectedCategory(formattedData.category);
    setDescription({
      en: formattedData.learningPathDescriptionEn,
      ar: formattedData.learningPathDescriptionAr
    });
    setFormKey(Date.now());
  };

  useEffect(() => {
    if (learningPath) {
      fillInitialData();
    }
  }, [learningPath]);

  useEffect(() => {
    //console.log('form', { editCard, learningPath, isCreate, isReadOnly });
    if (editCard === 'basicInfo') {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [editCard, learningPath, isCreate, isReadOnly]);

  const onSubmit = values => {
    const isValid = !validateLearningPathForm(values);
    if (!isValid) {
      return;
    }
    setErrors(null);
    let createPayload = {
      title: {
        ar: values.learningPathTitleAr,
        en: values.learningPathTitleEn
      },
      subTitle: {
        ar: values.learningPathSubTitleAr,
        en: values.learningPathSubTitleEn
      },
      description: {
        ar: values.learningPathDescriptionAr,
        en: values.learningPathDescriptionEn
      },
      category: values.category,
      subCategory: values.subCategory || '',
      level: values.level,
      tags: values.tags?.map(tag => tag.value) || []
    };
    if (learningPath?.id) {
      createPayload['id'] = learningPath?.id;
    }
    createLearningPath(createPayload);
  };
  const onCreateLearningPathSuccess = response => {
    //console.log('response', response);   ///,kjnlk;lk;l;kmlk;
    if (typeof response?.payload === 'string' && !learningPath?.id) {
      navigate(`/learningPath/create/${response.payload}`);
    }
    setEditCard(null);
    setErrors(null);
    toast.success(
      t(
        `learningPath:message.${
          !learningPath?.id
            ? 'learningPathCreateSuccess'
            : 'learningPathUpdateSuccess'
        }`
      ),
      { theme: 'colored' }
    );
  };

  const onError = errors => {
    validateLearningPathForm(getValues());
    toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
      theme: 'colored',
      autoClose: false
    });
    console.log('onError', errors);
  };

  const validateLearningPathForm = data => {
    if (data) {
      let errorsObj = {};
      Object.entries(data).map(([key, value]) => {
        if (!value && !optionalFields.includes(key)) {
          errorsObj[key] = {
            error: true,
            message: t('common:validation.fieldIsRequired')
          };
        }

        if (key === 'learningPathTitleAr') {
          if (value?.length > 70) {
            errorsObj['learningPathTitleAr'] = {
              error: true,
              message: t(
                'learningPath:validation.message.maxAllowedCharacters',
                {
                  count: 70
                }
              )
            };
          }
        }
        if (key === 'learningPathTitleEn') {
          if (value?.length > 70) {
            errorsObj['learningPathTitleEn'] = {
              error: true,
              message: t(
                'learningPath:validation.message.maxAllowedCharacters',
                {
                  count: 70
                }
              )
            };
          }
        }
        if (key === 'learningPathSubTitleEn') {
          if (value?.length > 100) {
            errorsObj['learningPathSubTitleEn'] = {
              error: true,
              message: t(
                'learningPath:validation.message.maxAllowedCharacters',
                {
                  count: 100
                }
              )
            };
          }
        }
        if (key === 'learningPathSubTitleAr') {
          if (value?.length > 100) {
            errorsObj['learningPathSubTitleAr'] = {
              error: true,
              message: t(
                'learningPath:validation.message.maxAllowedCharacters',
                {
                  count: 100
                }
              )
            };
          }
        }
      });
      const hasError = Object.values(errorsObj).some(r => !!r.error);
      setErrors(errorsObj);
      console.log('errorsObj', errorsObj);
      return hasError;
    }
    return true;
  };

  const onCancel = () => {
    setEditCard(undefined);
    reset();
    fillInitialData();
    setErrors(null);
  };

  useEffect(() => {
    if (learningPathCreateError) {
      toast.error(getErrorMessage(t, learningPathCreateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [learningPathCreateError]);

  return (
    <Card className="mb-3">
      <Card.Header as="h5">
        {t('learningPath:labels.learningPathInformation')}
      </Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3" key={formKey}>
          <Col md="12">
            <Form.Group controlId="learningPathTitle">
              <Form.Label>
                {`${t('learningPath:labels.learningPathTitle')} (${t(
                  'common:language.ar.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 70 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="learningPathTitleAr"
                required
                placeholder={t('learningPath:labels.learningPathTitle')}
                {...register('learningPathTitleAr', {
                  required: true,
                  maxLength: 70
                })}
                disabled={!isEdit && !isCreate}
                isInvalid={!!errors?.learningPathTitleAr?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.learningPathTitleAr?.error &&
                  errors?.learningPathTitleAr?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId="learningPathTitle">
              <Form.Label>
                {`${t('learningPath:labels.learningPathTitle')} (${t(
                  'common:language.en.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 70 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="learningPathTitleEn"
                required
                placeholder={t('learningPath:labels.learningPathTitle')}
                {...register('learningPathTitleEn', {
                  required: true,
                  maxLength: 70
                })}
                disabled={!isEdit && !isCreate}
                isInvalid={!!errors?.learningPathTitleEn?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.learningPathTitleEn?.error &&
                  errors?.learningPathTitleEn?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId="learningPathSubTitle">
              <Form.Label>
                {`${t('learningPath:labels.learningPathSubTitle')} (${t(
                  'common:language.ar.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 100 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="learningPathSubTitleAr"
                required
                placeholder={t('learningPath:labels.learningPathSubTitle')}
                {...register('learningPathSubTitleAr', {
                  required: true,
                  maxLength: 100
                })}
                disabled={!isEdit && !isCreate}
                isInvalid={!!errors?.learningPathSubTitleAr?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.learningPathSubTitleAr?.error &&
                  errors?.learningPathSubTitleAr?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group controlId="learningPathSubTitle">
              <Form.Label>
                {`${t('learningPath:labels.learningPathSubTitle')} (${t(
                  'common:language.en.name'
                )})`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 100 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="learningPathSubTitleEn"
                required
                placeholder={t('learningPath:labels.learningPathSubTitle')}
                {...register('learningPathSubTitleEn', {
                  required: true,
                  maxLength: 100
                })}
                disabled={!isEdit && !isCreate}
                isInvalid={!!errors?.learningPathSubTitleEn?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.learningPathSubTitleEn?.error &&
                  errors?.learningPathSubTitleEn?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="12">
            <Form.Group>
              <Form.Label>
                {t('learningPath:labels.level')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                {...register(`level`, { required: true })}
                required
                disabled={!isEdit && !isCreate}
                isInvalid={!!errors?.level?.error}
              >
                <option value="">
                  {t('learningPath:placeholders.selectLevel')}
                </option>
                {levels.map((level, index) => {
                  return (
                    <option
                      key={`learningPath-level-${level.label}-${index}`}
                      value={level.label}
                    >
                      {t(`learningPath:level.${level.label}`)}
                    </option>
                  );
                })}
              </Form.Select>
              <Form.Text className="text-danger">
                {!!errors?.level?.error && errors?.level?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col
            md={`${
              categories?.find(c => !!c.parentId === selectedCategory)
                ? '6'
                : '12'
            }`}
          >
            <Form.Group>
              <Form.Label>
                {t('learningPath:labels.category')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                {...register(`category`, { required: true })}
                value={selectedCategory || ''}
                required
                disabled={!isEdit && !isCreate}
                onChange={e => {
                  setSelectedCategory(e.target.value);
                  setValue('category', e.target.value);
                }}
                isInvalid={!!errors?.category?.error}
              >
                <option value="">
                  {t('learningPath:placeholders.selectCategory')}
                </option>
                {categories
                  ?.filter(c => !c.parentId)
                  .map((category, index) => {
                    return (
                      <option
                        key={`learningPath-category-${category.id}-${index}`}
                        value={category.id}
                      >
                        {category.title[currentLanguage]}
                      </option>
                    );
                  })}
              </Form.Select>
              <Form.Text className="text-danger">
                {!!errors?.category?.error && errors?.category?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          {categories?.find(c => !!c.parentId === selectedCategory) && (
            <Col md="6">
              <Form.Group>
                <Form.Label>{t('learningPath:labels.subCategory')}</Form.Label>
                <Form.Select
                  {...register(`subCategory`)}
                  disabled={(!isEdit && !isCreate) || !selectedCategory}
                  onChange={e => {
                    setValue('subCategory', e.target.value);
                  }}
                >
                  <option value="">
                    {t('learningPath:placeholders.selectSubCategory')}
                  </option>
                  {categories
                    ?.filter(c => c.parentId === selectedCategory)
                    .map((category, index) => {
                      return (
                        <option
                          key={`learningPath-category-${category.id}-${index}`}
                          value={category.id}
                        >
                          {category.title[currentLanguage]}
                        </option>
                      );
                    })}
                </Form.Select>
              </Form.Group>
            </Col>
          )}
          <Col xs="12">
            <Form.Group>
              <Form.Label>{t('learningPath:labels.tags')}</Form.Label>
              <Controller
                name="tags"
                render={({ field, ref }) => (
                  <MultiSelect
                    {...register(`tags`, { required: false })}
                    {...field}
                    ref={ref}
                    closeMenuOnSelect={false}
                    isMulti
                    options={[]}
                    placeholder={t('learningPath:placeholders.selectTag')}
                    isDisabled={!isEdit && !isCreate}
                    isCreatable={true}
                    createLabel={value =>
                      t('learningPath:labels.addTag') + ' ' + value
                    }
                    isInvalid={!!errors?.tags}
                  />
                )}
                control={control}
              />
            </Form.Group>
          </Col>
          <Col xs="12">
            <Form.Label>
              {`${t('learningPath:labels.learningPathDescription')}`}
              <span className="text-danger">*</span>
            </Form.Label>
            <MultiLanguageRTE
              t={t}
              enableFullscreen
              activeLanguage={currentLanguage}
              valueEn={description?.en || ''}
              valueAr={description?.ar || ''}
              errorEn={!!errors?.learningPathDescriptionEn?.error}
              errorAr={!!errors?.learningPathDescriptionAr?.error}
              errorArMessage={t('common:validation.fieldIsRequired')}
              errorEnMessage={t('common:validation.fieldIsRequired')}
              placeholderEn={t('course:placeholders.addNewItem')}
              placeholderAr={t('course:placeholders.addNewItem')}
              disabled={!isEdit && !isCreate}
              handleEnChange={newValue => {
                setDescription({ ...description, en: newValue });
                setValue('learningPathDescriptionEn', newValue);
              }}
              handleArChange={newValue => {
                setDescription({ ...description, ar: newValue });
                setValue('learningPathDescriptionAr', newValue);
              }}
              arEditorProps={{
                ...register(`learningPathDescriptionAr`, { required: true }),
                ref: undefined
              }}
              enEditorProps={{
                ...register(`learningPathDescriptionEn`, { required: true }),
                ref: undefined
              }}
            />
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer>
        {isCreate && (
          <Button size="sm" onClick={handleSubmit(onSubmit, onError)}>
            {learningPathCreateLoading && <Spinner size="sm" />}
            {!learningPathCreateLoading && t('common:button.save')}
          </Button>
        )}
        {isEdit && (
          <>
            <Button size="sm" onClick={handleSubmit(onSubmit, onError)}>
              {learningPathCreateLoading && <Spinner size="sm" />}
              {!learningPathCreateLoading && t('common:button.save')}
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
            >
              {t('common:button.cancel')}
            </Button>
          </>
        )}
        {!isEdit && !isCreate && !isReadOnly && (
          <>
            <Button
              size="sm"
              variant="info"
              onClick={() => setEditCard('basicInfo')}
              disabled={!!editCard}
            >
              {t('common:button.edit')}
            </Button>
          </>
        )}
      </Card.Footer>
    </Card>
  );
};

export default LearningPathInformationForm;
