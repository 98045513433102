import http from 'http/interceptor';
// import { getDeviceId } from 'helpers/utils';
// const currentLocale =
//   getItemFromStore('preferredLanguage', 'en') ||
//   process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_AUTH_URL = `https://admin.api.360realize.com/api/identity`;
const LOGIN_URL = `https://admin.api.360realize.com/api/identity/login`;

// const deviceId = getDeviceId();

const AuthService = {
  login: data => {
    return http.post(LOGIN_URL, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  logout: () => {
    setTimeout(() => {
      return new Promise.resolve(true);
    }, 500);
  }
};

export default AuthService;
