import React, { useContext, useEffect } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import is from 'is_js';
import MainLayout from './MainLayout';

import ErrorLayout from './ErrorLayout';
import { toast, ToastContainer } from 'react-toastify';
import { CloseButton } from 'components/common/Toast';

import AppContext, { AuthContext } from 'context/Context';
import { flattenRoutes } from 'routes/routes';
import useCurrentPath from 'hooks/useCurrentPath';
import { getRedirectUrl } from 'helpers/utils';

const Layout = () => {
  const location = useLocation();
  const { isLoggedIn, user } = useContext(AuthContext);
  const currentRoute = useCurrentPath(flattenRoutes);
  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  const {
    config: { navbarPosition }
  } = useContext(AppContext);

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    if (is.safari()) {
      HTMLClassList.add('safari');
    }
    //console.log('useEffect : HTMLClassList');
  }, [HTMLClassList]);

  useEffect(() => {
    if (navbarPosition === 'double-top') {
      HTMLClassList.add('double-top-nav-layout');
    }
    //console.log('useEffect : navbarPosition');
    return () => HTMLClassList.remove('double-top-nav-layout');
  }, [navbarPosition]);

  return (
    <>
      <Routes>
        {!isLoggedIn && (
          <>
            {flattenRoutes
              ?.filter(route => route.layout === 'auth')
              .map((route, index) => {
                return (
                  <Route
                    key={`app-route-${index}-route.path`}
                    path={route.path}
                    element={route.component()}
                  />
                );
              })}
            <Route
              path="*"
              element={
                <Navigate to="/login" replace state={{ from: location }} />
              }
            />
          </>
        )}
        {!!isLoggedIn && !!user && (
          <>
            <Route element={<MainLayout />}>
              {flattenRoutes
                ?.filter(route => {
                  if (route.layout !== 'main') {
                    return false;
                  }
                  let userRoles = user?.roles;
                  if (typeof user?.roles === 'string') {
                    userRoles = [user?.roles.toLowerCase().trim()];
                  } else {
                    userRoles = user?.roles.map(r => r.toLowerCase().trim());
                  }
                  const requiredRoles = currentRoute?.route?.roles?.map(r =>
                    r.toLowerCase()
                  );
                  if (!requiredRoles) {
                    return true;
                  }
                  const isAuthorized = userRoles.some(r =>
                    requiredRoles.includes(r)
                  );
                  if (!isAuthorized) {
                    return false;
                  }
                  return true;
                })
                .map((route, index) => {
                  return (
                    <Route
                      key={`app-route-${index}-${route.path}`}
                      path={route.path}
                      element={route.component()}
                    />
                  );
                })}
            </Route>

            <Route
              path="/"
              element={<Navigate to={getRedirectUrl(user)} replace />}
            />
            <Route element={<ErrorLayout />}>
              {flattenRoutes
                ?.filter(route => route.layout === 'error')
                .map((route, index) => {
                  return (
                    <Route
                      key={`app-route-${index}-route.path`}
                      path={route.path}
                      element={route.component()}
                    />
                  );
                })}
            </Route>
            <Route path="*" element={<Navigate to="/not-found" replace />} />
          </>
        )}
      </Routes>
      <ToastContainer
        closeButton={CloseButton}
        icon={false}
        position={toast.POSITION.BOTTOM_LEFT}
      />
    </>
  );
};

export default Layout;
