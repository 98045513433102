import React, { useEffect, useState } from 'react';
import ProfileBanner from './Banner';
import ProfileIntro from './ProfileIntro';
import { Col, Row } from 'react-bootstrap';

import { useQuery } from 'react-query';
import UserService from 'http/UserService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { useTranslation } from 'react-i18next';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import { useParams } from 'react-router-dom';

const Profile = () => {
  const { id } = useParams();

  const { t } = useTranslation();
  const [user, setUser] = useState(null);
  const {
    isLoading: userLoading,
    data: userData,
    error: userError
  } = useQuery(
    'UserData',
    () => (!id ? UserService.getProfileInfo() : UserService.getUserInfo(id)),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false
    }
  );

  useEffect(() => {
    if (userData && !userLoading && !userError) {
      setUser(userData);
    }
    if (userError) {
      toast.error(getErrorMessage(t, userError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [userLoading, userData, userError]);

  return (
    <>
      {userLoading && (
        <LoaderWithMessage message={t('user:message.loadingUser')} />
      )}
      {!userLoading && <ProfileBanner user={user} t={t} />}
      <Row className="g-3 mb-3">
        <Col lg={8}>{!userLoading && <ProfileIntro user={user} t={t} />}</Col>
        <Col lg={4}>
          <div className="sticky-sidebar"></div>
        </Col>
      </Row>
    </>
  );
};

export default Profile;
