import React, { useContext, useState, useEffect } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { DragDropContext, Draggable } from 'react-beautiful-dnd';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { sort } from 'helpers/utils';
import { LearningPathContext } from 'context/Context';
import ConfirmModal from 'components/common/ConfirmModal';
import StrictModeDroppable from 'components/common/StrictModeDroppable';
import { useBreakpoints } from 'hooks/useBreakpoints';
import LearningPathService from 'http/LearningPathService';
import {
  faEdit,
  faGripLines,
  faTrash
} from '@fortawesome/free-solid-svg-icons';
import Flex from 'components/common/Flex';
import CoursesModal from './CoursesModal';

const LearningPathCourses = () => {
  const { t, i18n } = useTranslation();
  const { breakpoints } = useBreakpoints();

  const { learningPath, isReadOnly, isCreate, fetchLearningPath } =
    useContext(LearningPathContext);
  const currentLanguage = i18n.language;
  const [courses, setCourses] = useState([]);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [courseToEdit, setCourseToEdit] = useState(false);

  useEffect(() => {
    if (learningPath?.courses?.length) {
      const sorted = sort(learningPath?.courses, 'rank', 'asc');
      //console.log('sorted', sorted);
      setCourses(sorted);
    }
  }, [learningPath]);

  const {
    mutate: saveCourses,
    error: saveCoursesError,
    isLoading: saveCoursesLoading
  } = useMutation({
    mutationFn: LearningPathService.saveCourses,
    onSuccess: data => onSaveCoursesSuccess(data)
  });

  const onSaveCoursesSuccess = () => {
    fetchLearningPath(learningPath?.id);
    setCourseToEdit(null);
    setConfirmModalProps(null);
    toast.success(t(`learningPath:message.updateSuccess`), {
      theme: 'colored'
    });
  };

  const handleSubmitCourses = newCourse => {
    let oldCourses = [...(courses || [])];
    if (!!courseToEdit?.id) {
      oldCourses = oldCourses.filter(c => c.id !== courseToEdit?.id);
    }
    let newCoursesPayload = [...oldCourses, newCourse].map(c => {
      return {
        courseId: c.id,
        examId: c.examId || c.exam?.id || null,
        rank: isNaN(c.rank) ? 0 : c.rank
      };
    });
    saveCourses({
      learningPathId: learningPath.id,
      courses: newCoursesPayload
    });
  };

  const reorder = (array, fromIndex, toIndex) => {
    const newArr = [...array];

    const chosenItem = newArr.splice(fromIndex, 1)[0];
    newArr.splice(toIndex, 0, chosenItem);

    return newArr;
  };

  const onDragEnd = result => {
    const { source, destination } = result;
    if (!destination) {
      return;
    }
    if (source.index === destination.index) {
      return;
    }
    const items = [...courses];
    const reorderedItems = reorder(items, source.index, destination.index);

    setCourses(reorderedItems);

    //console.log('reordered chapters', reorderedItems);
    let newData = reorderedItems?.map((c, i) => {
      return {
        ...c,
        examId: c.exam?.id || c?.examId || null,
        courseId: c.id,
        rank: i
      };
    });
    saveCourses({
      courses: newData,
      learningPathId: learningPath?.id
    });
  };

  const newCourseClick = () => {
    setCourseToEdit(true);
  };

  const handleDeleteCourse = id => {
    let newCourses = [...courses].filter(c => c.id !== id);
    let newCoursesPayload = newCourses.map(c => {
      return {
        courseId: c.id,
        examId: c.examId || null
      };
    });
    setCourses(newCourses);
    saveCourses({
      courses: newCoursesPayload,
      learningPathId: learningPath.id
    });
  };

  const handleClickDeleteCourse = id => {
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => handleDeleteCourse(id),
      message: t('learningPath:message.deleteCourseConfirm'),
      header: t('learningPath:message.deleteCourseTitle'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.delete')
    });
  };

  useEffect(() => {
    if (saveCoursesError) {
      toast.error(getErrorMessage(t, saveCoursesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [saveCoursesError]);

  if (isCreate) {
    return '';
  }

  return (
    <Card className="mb-3">
      <Card.Header as="h5">{t('learningPath:labels.coursesTitle')}</Card.Header>
      <Card.Body className="p-0">
        <DragDropContext onDragEnd={onDragEnd}>
          <Row>
            <Col auto="true">
              <StrictModeDroppable droppableId="droppable1" type="DRAG">
                {provided => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className=" border bg-white dark__bg-1000 rounded-2  mb-3 p-0 overflow-visible"
                  >
                    {courses.map((course, index) => {
                      return (
                        <Draggable
                          key={course.id}
                          draggableId={`${course.id}`}
                          index={index}
                          disabled={isReadOnly}
                        >
                          {provided => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={`fs--1 mb-0 d-flex align-items-center justify-content-between m-2 border-grey border-1 border rounded mb-2 gap-2 ${
                                breakpoints.down('md') ? 'p-0' : 'p-3'
                              }`}
                            >
                              <Flex gap="2">
                                <div
                                  {...provided.dragHandleProps}
                                  style={{ cursor: 'grab' }}
                                  className="d-flex align-items-center"
                                >
                                  {!isReadOnly && (
                                    <FontAwesomeIcon
                                      icon={faGripLines}
                                      size={
                                        breakpoints.down('md') ? 'md' : 'lg'
                                      }
                                    />
                                  )}
                                </div>
                                <p className="mb-0">
                                  {course?.title?.[currentLanguage]}
                                </p>
                              </Flex>
                              {!isReadOnly && (
                                <div>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        id={
                                          'tooltip-delete-lesson-' + course.id
                                        }
                                      >
                                        {t('common:button.edit')}
                                      </Tooltip>
                                    }
                                  >
                                    <span className="ms-2 text-primary fs-0">
                                      <FontAwesomeIcon
                                        icon={faEdit}
                                        className="cursor-pointer"
                                        size="sm"
                                        onClick={() => setCourseToEdit(course)}
                                      />
                                    </span>
                                  </OverlayTrigger>
                                  <OverlayTrigger
                                    placement="top"
                                    overlay={
                                      <Tooltip
                                        id={
                                          'tooltip-delete-lesson-' + course.id
                                        }
                                      >
                                        {t('common:button.unlink')}
                                      </Tooltip>
                                    }
                                  >
                                    <span className="ms-2 text-danger fs-0">
                                      <FontAwesomeIcon
                                        icon={faTrash}
                                        className="cursor-pointer"
                                        size="sm"
                                        onClick={() =>
                                          handleClickDeleteCourse(course.id)
                                        }
                                      />
                                    </span>
                                  </OverlayTrigger>
                                </div>
                              )}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </StrictModeDroppable>
            </Col>
          </Row>
        </DragDropContext>
      </Card.Body>

      {!isReadOnly && (
        <Card.Footer className={`text-center py-2`}>
          <Button variant="link" size="sm" onClick={newCourseClick}>
            <FontAwesomeIcon icon="plus" className="me-1 fs--2" />
            {t('learningPath:button.addNewCourse')}
          </Button>
        </Card.Footer>
      )}
      {!!courseToEdit && (
        <CoursesModal
          course={courseToEdit}
          saveLoading={saveCoursesLoading}
          onClose={() => setCourseToEdit(null)}
          onSubmit={handleSubmitCourses}
          currentLanguage={currentLanguage}
          targetRank={courses?.length + 1}
        />
      )}
      <ConfirmModal {...confirmModalProps} loading={saveCoursesLoading} />
    </Card>
  );
};

export default LearningPathCourses;
