import React from 'react';
import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import Dashboard from 'pages/dashboard';
import CoursePage from 'pages/Operations/Course/CoursePage';
import LearningPathList from 'pages/Operations/LearningPath/LearningPathList';
import LearningPathPage from 'pages/Operations/LearningPath/LearningPathPage';
import BlogsList from 'pages/Operations/Blogs/BlogsList';
import CreateBlog from 'pages/Operations/Blogs/CreateBlog';
import Payments from 'pages/Reports/Payments';
import UserList from 'pages/User/UserList';
import LoginPage from 'pages/Auth/LoginPage';
import Settings from 'pages/User/settings/UserProfileSettings';
import Profile from 'pages/User/profile/Profile';
import CreateCourse from 'pages/Operations/Course/CreateCourse';
import CourseProvider from 'providers/CourseProvider';
import CreateLearningPath from 'pages/Operations/LearningPath/CreateLearningPath';
import LearningPathProvider from 'providers/LearningPathProvider';
import Error401 from 'components/errors/Error401';
import { faNewspaper } from '@fortawesome/free-regular-svg-icons';
import {
  faBlog,
  faCity,
  faFile,
  faGlobe,
  faGripLinesVertical,
  faLandmark,
  faMoneyBill,
  faPaste,
  faTasks,
  faUsers,
  faFileContract
} from '@fortawesome/free-solid-svg-icons';
import StaticPagesList from 'pages/ContentManagement/ListStaticPages';
import CreateStaticPage from 'pages/ContentManagement/CreateStaticPage';
import PreviewStaticPage from 'pages/ContentManagement/PreviewStaticPage';
import CreatePlan from 'pages/Operations/Plans/CreatePlan';
import PreviewBlog from 'pages/Operations/Blogs/BlogPage';
import BlogProvider from 'providers/BlogProvider';
import CreateDiscount from 'pages/Operations/Discounts/CreateDiscount';
import PlanList from 'pages/Operations/Plans/PlansList';
import PlanProvider from 'providers/PlanProvider';
import CountriesList from 'pages/Operations/Countries/CountriesList';
import CreateCountry from 'pages/Operations/Countries/CreateCountry';
import CountryProvider from 'providers/CountryProvider';
import CitiesList from 'pages/Operations/Cities/CitiesList';
import CreateCity from 'pages/Operations/Cities/CreateCity';
import CityProvider from 'providers/CityProvider';
import CompanyList from 'pages/Operations/Companies/CompaniesList';
import CompanyProvider from 'providers/CompanyProvider';
import CreateCompany from 'pages/Operations/Companies/CreateCompany';
import DealList from 'pages/Operations/Deals/DealsList';
import DealProvider from 'providers/DealProvider';
import CreateDeal from 'pages/Operations/Deals/CreateDeal';
import ProjectProvider from 'providers/ProjectProvider';
import CreateProject from 'pages/Operations/Projects/CreateProject';

const CreateCoursePage = () => {
  return (
    <CourseProvider>
      <CreateCourse />
    </CourseProvider>
  );
};

const CreateLearningPathPage = () => {
  return (
    <LearningPathProvider>
      <CreateLearningPath />
    </LearningPathProvider>
  );
};

const CreatePlanPage = () => {
  return (
    <PlanProvider>
      <CreatePlan />
    </PlanProvider>
  );
};
const CreateCountryPage = () => {
  return (
    <CountryProvider>
      <CreateCountry />
    </CountryProvider>
  );
};

const CreateCityPage = () => {
  return (
    <CityProvider>
      <CreateCity />
    </CityProvider>
  );
};
const CreateCompayPage = () => {
  return (
    <CompanyProvider>
      <CreateCompany />
    </CompanyProvider>
  );
};
const CreateDealPage = () => {
  return (
    <DealProvider>
      <CreateDeal />
    </DealProvider>
  );
};
const CreateProjectPage = () => {
  return (
    <ProjectProvider>
      <CreateProject />
    </ProjectProvider>
  );
};
const CreateBlogPage = () => {
  return (
    <BlogProvider>
      <CreateBlog />
    </BlogProvider>
  );
};

export const dashboardRoutes = {
  label: 'dashboard',
  //labelDisable: true,
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'dashboard',
      active: true,
      icon: 'chart-pie',
      to: '/dashboard',
      path: '/dashboard',
      component: () => <Dashboard />,
      exact: true,
      roles: ['admin'],
      layout: 'main'
    }
  ],
  roles: ['admin']
};

export const adminRoutes = {
  label: 'admin',
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'users',
      active: true,
      icon: faUsers,
      to: '/admin/users',
      path: '/admin/users',
      component: () => <UserList />,
      exact: true,
      hidden: false,
      roles: ['admin'],
      layout: 'main'
    },
    {
      name: 'countries',
      active: true,
      icon: faGlobe,
      to: '/countries',
      path: '/countries',
      component: () => <CountriesList />,
      exact: true,
      hidden: false,
      roles: ['admin'],
      layout: 'main'
    },
    {
      name: 'createCountry',
      active: true,
      icon: faGripLinesVertical,
      to: '/countries/create',
      path: '/countries/create/:id?',
      component: () => <CreateCountryPage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'cities',
      active: true,
      icon: faCity,
      to: '/cities',
      path: '/cities',
      component: () => <CitiesList />,
      exact: true,
      hidden: false,
      roles: ['admin'],
      layout: 'main'
    },
    {
      name: 'createCity',
      active: true,
      icon: faGripLinesVertical,
      to: '/cities/create',
      path: '/cities/create/:id?',
      component: () => <CreateCityPage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
  ],
  roles: ['admin']
};

export const userRoutes = {
  label: 'user',
  menu: true,
  layout: 'main',
  hidden: true,
  children: [
    {
      name: 'users',
      active: true,
      icon: faUsers,
      to: '/user/profile/:id?',
      path: '/user/profile/:id?',
      component: () => <Profile />,
      exact: true,
      hidden: true,
      layout: 'main'
    },
    {
      name: 'users',
      active: true,
      icon: faUsers,
      to: '/user/settings/:id?',
      path: '/user/settings/:id?',
      component: () => <Settings />,
      exact: true,
      hidden: true,
      layout: 'main'
    }
  ]
};

export const operationsRoutes = {
  label: 'operations',
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'plans',
      active: true,
      icon: faPaste,
      to: '/plans',
      path: '/plans',
      component: () => <PlanList />,
      exact: true,
      roles: ['admin'],
      layout: 'main'
    },
    {
      name: 'companies',
      active: true,
      icon: faLandmark,
      to: '/companies',
      path: '/companies',
      component: () => <CompanyList />,
      exact: true,
      roles: ['admin'],
      layout: 'main'
    },
    {
      name: 'deals',
      active: true,
      icon: faFileContract,
      to: '/deals',
      path: '/deals',
      component: () => <DealList />,
      exact: true,
      roles: ['admin'],
      layout: 'main'
    },
    {
      name: 'createDeal',
      active: true,
      icon: faGripLinesVertical,
      to: '/deals/create',
      path: '/deals/create/:id?',
      component: () => <CreateDealPage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'createProject',
      active: true,
      icon: faGripLinesVertical,
      to: '/projects/create',
      path: '/projects/create/:id?',
      component: () => <CreateProjectPage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'createCompany',
      active: true,
      icon: faGripLinesVertical,
      to: '/companies/create',
      path: '/companies/create/:id?',
      component: () => <CreateCompayPage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'createCourse',
      active: true,
      icon: faGripLinesVertical,
      to: '/course/create',
      path: '/course/create/:id?',
      component: () => <CreateCoursePage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'createLearningPath',
      active: true,
      icon: faGripLinesVertical,
      to: '/learningPath/create',
      path: '/learningPath/create/:id?',
      component: () => <CreateLearningPathPage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'course',
      active: true,
      icon: faGripLinesVertical,
      to: '/course',
      path: '/course/:id?',
      component: () => <CoursePage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'viewLearningPath',
      active: true,
      icon: faGripLinesVertical,
      to: '/learningPath',
      path: '/learningPath/:id?',
      component: () => <LearningPathPage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'createPlan',
      active: true,
      icon: faGripLinesVertical,
      to: '/plans/create',
      path: '/plans/create/:id?',
      component: () => <CreatePlanPage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
    // {
    //   name: 'discounts',
    //   active: true,
    //   icon: faTag,
    //   to: '/discounts',
    //   path: '/discounts',
    //   component: () => <DiscountsList />,
    //   exact: true,
    //   roles: ['admin'],
    //   layout: 'main'
    // },
    {
      name: 'createDiscount',
      active: true,
      icon: faNewspaper,
      to: '/discounts/create',
      path: '/discounts/create/:id?',
      component: () => <CreateDiscount />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    }
  ],
  roles: ['admin']
};

export const contentManagementRoutes = {
  label: 'contentManagement',
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'staticPages',
      active: true,
      icon: faFile,
      to: '/contents/pages',
      path: '/contents/pages',
      component: () => <StaticPagesList />,
      exact: true,
      roles: ['admin'],
      layout: 'main'
    },
    {
      name: 'createPage',
      active: true,
      icon: faNewspaper,
      to: '/contents/pages',
      path: '/contents/pages/create/:id?',
      component: () => <CreateStaticPage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'previewPage',
      active: true,
      icon: faNewspaper,
      to: '/contents/pages/preview',
      path: '/contents/pages/preview/:id?',
      component: () => <PreviewStaticPage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'blogsAndArticles',
      active: true,
      icon: faBlog,
      to: '/contents/blogs',
      path: '/contents/blogs',
      component: () => <BlogsList />,
      exact: true,
      roles: ['admin'],
      layout: 'main'
    },
    {
      name: 'createBlog',
      active: true,
      icon: faNewspaper,
      to: '/contents/blogs/create',
      path: '/contents/blogs/create/:id?',
      component: () => <CreateBlogPage />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    },
    {
      name: 'blog',
      active: true,
      icon: faNewspaper,
      to: '/contents/blogs/preview',
      path: '/contents/blogs/preview/:id?',
      component: () => <PreviewBlog />,
      exact: true,
      roles: ['admin'],
      hidden: true,
      layout: 'main'
    }
  ],
  roles: ['admin']
};

export const reportsRoutes = {
  label: 'reports',
  menu: true,
  layout: 'main',
  children: [
    {
      name: 'payments',
      active: true,
      icon: faMoneyBill,
      to: '/reports/payments',
      path: '/reports/payments',
      component: () => <Payments />,
      exact: true,
      roles: ['admin'],
      layout: 'main'
    }
  ],
  roles: ['admin'],
  hidden: true
};

export const authRoutes = {
  label: '',
  menu: false,
  layout: 'auth',
  children: [
    {
      name: 'login',
      active: true,
      icon: 'sign-in',
      to: '/login',
      path: '/login',
      component: () => <LoginPage />,
      exact: true,
      hidden: true,
      layout: 'auth'
    }
  ],
  hidden: true
};

export const errorRoutes = {
  label: '',
  menu: false,
  layout: 'error',
  hidden: true,
  children: [
    {
      name: '404',
      active: false,
      icon: '',
      to: '/not-found',
      path: '/not-found',
      component: () => <Error404 />,
      exact: true,
      hidden: true,
      layout: 'error'
    },
    {
      name: '401',
      active: false,
      icon: '',
      to: '/unauthorized',
      path: '/unauthorized',
      component: () => <Error401 />,
      exact: true,
      hidden: true,
      layout: 'error'
    },
    {
      name: '500',
      active: false,
      icon: '',
      to: '/error',
      path: '/error',
      component: () => <Error500 />,
      exact: true,
      hidden: true,
      layout: 'error'
    }
  ]
};

export default [
  dashboardRoutes,
  adminRoutes,
  userRoutes,
  operationsRoutes,
  contentManagementRoutes,
  reportsRoutes,
  authRoutes
];

export const flattenRoutes = [
  dashboardRoutes,
  userRoutes,
  adminRoutes,
  operationsRoutes,
  contentManagementRoutes,
  reportsRoutes,
  errorRoutes,
  authRoutes
]
  ?.map(r => r.children || [])
  .flat(1);
