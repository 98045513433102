import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import CourseService from 'http/CourseService';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { CourseContext } from 'context/Context';
import { getErrorMessage } from 'http/utils';
import Flex from 'components/common/Flex';

const CoursePrice = () => {
  const { t } = useTranslation();
  const { course, editCard, setEditCard, isCreate, isReadOnly, fetchCourse } =
    useContext(CourseContext);
  const [isEdit, setIsEdit] = useState(false);

  const [initPrice, setInitPrice] = useState({});
  const [newPrice, setNewPrice] = useState({});
  const [isFree, setIsFree] = useState(false);
  const [errors, setErrors] = useState(null);

  useEffect(() => {
    if (course) {
      setInitPrice({
        price: course?.settings?.price || 0,
        oldPrice: course?.settings?.oldPrice || 0
      });
      setNewPrice({
        price: course?.settings?.price || 0,
        oldPrice: course?.settings?.oldPrice || 0
      });
    }
  }, [course]);

  const {
    mutate: saveCourse,
    error: saveCourseError,
    isLoading: saveCourseLoading
  } = useMutation({
    mutationFn: CourseService.saveCoursePrice,
    onSuccess: data => onSaveCourseSuccess(data)
  });

  const onSaveCourseSuccess = () => {
    fetchCourse(course.id);
    setEditCard(null);
    setErrors(null);
    toast.success(
      t(
        `course:message.${
          !course?.id ? 'courseCreateSuccess' : 'courseUpdateSuccess'
        }`
      ),
      { theme: 'colored' }
    );
  };
  const handleChange = e => {
    if (!!e.target.value && isNaN(e.target.value)) {
      return;
    }
    setNewPrice({ ...newPrice, [e.target.name]: e.target.value });
    setErrors(null);
  };
  const handleSubmit = () => {
    let errorsObj = {};
    if (newPrice.price >= newPrice.oldPrice && !isFree) {
      errorsObj['price'] = {
        error: true,
        message: t('course:validation.message.priceGreaterThanOldPrice')
      };
      errorsObj['oldPrice'] = {
        error: true,
        message: t('course:validation.message.priceGreaterThanOldPrice')
      };
      setErrors(errorsObj);
      return;
    }
    saveCourse({
      courseId: course.id,
      price: newPrice.price,
      oldPrice: newPrice.oldPrice
    });
  };
  const onCancel = () => {
    setNewPrice({ ...initPrice });
    setErrors(null);
    setEditCard(null);
  };

  useEffect(() => {
    if (editCard === 'pricing') {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [editCard, isReadOnly, course]);

  useEffect(() => {
    if (saveCourseError) {
      toast.error(getErrorMessage(t, saveCourseError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [saveCourseError]);

  if (isCreate) {
    return '';
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h5">{t('course:labels.pricing')}</Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col xs="12">
              <Form.Group controlId="courseTitle">
                <Form.Label>
                  {t('course:labels.basePrice')}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="basePriceTooltip">
                        {t('course:tooltips.price')}
                      </Tooltip>
                    }
                  >
                    <span className="ms-2 text-primary fs--1">
                      <FontAwesomeIcon icon="question-circle" />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="oldPrice"
                  value={newPrice?.oldPrice || 0}
                  onChange={handleChange}
                  disabled={!isEdit || saveCourseLoading}
                />
                {!!errors?.oldPrice?.error && (
                  <Form.Text className="text-danger">
                    {errors?.oldPrice?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
            <Col xs="12">
              <Form.Group controlId="courseTitle">
                <Form.Label className="d-flex align-items-center justify-content-between">
                  <Flex gap="1">
                    {t('course:labels.discountedPrice')}
                    <OverlayTrigger
                      overlay={
                        <Tooltip id="basePriceTooltip">
                          {t('course:tooltips.discountedPrice')}
                        </Tooltip>
                      }
                    >
                      <span className="ms-2 text-primary fs--1">
                        <FontAwesomeIcon icon="question-circle" />
                      </span>
                    </OverlayTrigger>
                  </Flex>
                  <Form.Check
                    type="switch"
                    className="mb-0"
                    size={50}
                    label={t('course:labels.free')}
                    onChange={e => {
                      setIsFree(e.target.checked);
                      setNewPrice({ ...newPrice, price: 0 });
                    }}
                    disabled={!isEdit || saveCourseLoading}
                    checked={isFree}
                  />
                </Form.Label>
                <Form.Control
                  type="text"
                  name="price"
                  value={newPrice?.price || 0}
                  onChange={handleChange}
                  disabled={!isEdit || saveCourseLoading || isFree}
                />
                {!!errors?.price?.error && (
                  <Form.Text className="text-danger">
                    {errors?.price?.message}
                  </Form.Text>
                )}
              </Form.Group>
            </Col>
          </Row>
          {/* <div className="text-end">
            <Button
              variant="link"
              size="sm"
              className="fw-medium fs--1"
              onClick={() => {
                setIsOpenScheduleModal(!isOpenScheduleModal);
              }}
            >
              Schedule Discount
            </Button>
          </div> */}
        </Card.Body>
        <Card.Footer>
          {isCreate && (
            <Button size="sm" onClick={handleSubmit}>
              {saveCourseLoading && <Spinner size="sm" />}
              {!saveCourseLoading && t('common:button.save')}
            </Button>
          )}
          {isEdit && (
            <>
              <Button size="sm" onClick={handleSubmit}>
                {saveCourseLoading && <Spinner size="sm" />}
                {!saveCourseLoading && t('common:button.save')}
              </Button>

              <Button
                size="sm"
                variant="danger"
                onClick={onCancel}
                className="mx-2"
                disabled={saveCourseLoading}
              >
                {t('common:button.cancel')}
              </Button>
            </>
          )}
          {!isEdit && !isReadOnly && (
            <>
              <Button
                size="sm"
                variant="info"
                onClick={() => setEditCard('pricing')}
                disabled={!!editCard || saveCourseLoading}
              >
                {t('common:button.edit')}
              </Button>
            </>
          )}
        </Card.Footer>
      </Card>
    </>
  );
};

export default CoursePrice;
