import React from 'react';
import DealBasicForm from './components/DealBasicForm';
// import PlanQuestion from './components/PlanQuestion';

const CreateDeal = () => {
  return (
    <>
      <DealBasicForm />
    </>
  );
};
export default CreateDeal;
