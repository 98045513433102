import React from 'react';
import corner4 from 'assets/img/illustrations/corner-4.png';
import { Card, Nav } from 'react-bootstrap';
import Background from 'components/common/Background';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { getCourseCommentColor, getCourseProgressStages } from '../utils';
import { useState } from 'react';
import { useEffect } from 'react';
import IconAlert from 'components/common/IconAlert';
import { useContext } from 'react';
import { CourseContext } from 'context/Context';
import { useTranslation } from 'react-i18next';

const NavItem = ({ index, step, handleNavs, icon, label, color }) => {
  const isDone = index < step;
  const isActive = index === step;
  return (
    <Nav.Item>
      <Nav.Link
        className={classNames('fw-semi-bold', {
          done: isDone
        })}
        onClick={() => handleNavs(index)}
      >
        <span className="nav-item-circle-parent">
          <span
            className={`nav-item-circle ${isActive ? `bg-${color}` : ''}`}
            style={{ color: !!color && (isDone || isActive) ? '#fff' : '' }}
          >
            <FontAwesomeIcon icon={icon} />
          </span>
        </span>
        <span className="d-none d-md-block mt-1 fs--1">{label}</span>
      </Nav.Link>
    </Nav.Item>
  );
};

const CreateCourseHeader = () => {
  const { t } = useTranslation();
  const { course, isCreate } = useContext(CourseContext);
  const [stages, setStages] = useState([]);
  const [activeStage, setActiveStage] = useState([]);
  const [showComment, setShowComment] = useState(true);

  useEffect(() => {
    if (course?.status) {
      const stagesData = getCourseProgressStages(course.status.code);
      const active =
        stagesData.findIndex(s => s.name === course.status.code) + 1;
      setStages(stagesData);
      setActiveStage(active);
    }
  }, [course]);

  if (!course?.status) {
    return '';
  }

  if (isCreate) {
    return '';
  }

  return (
    <Card className="mb-3 theme-wizard">
      <Background
        image={corner4}
        className="bg-card d-none d-md-block"
        style={{
          borderTopRightRadius: '0.375rem',
          borderBottomRightRadius: '0.375rem'
        }}
      />
      <Card.Body
        className={classNames('bg-light', {
          'pb-2': true
        })}
      >
        <Nav className="justify-content-center" variant={undefined}>
          {stages?.map((item, index) => (
            <NavItem
              key={`${item.name}-${index}`}
              index={index + 1}
              step={activeStage}
              handleNavs={() => {}}
              icon={item.icon}
              label={t(`course:status.${item.name}`)}
              color={item.color}
            />
          ))}
        </Nav>
        {!!course?.status?.comment && showComment && (
          <IconAlert
            variant={getCourseCommentColor(course?.status?.code)}
            dismissible
            onClose={() => setShowComment(false)}
          >
            <p className="mb-0">{course?.status?.comment}</p>
          </IconAlert>
        )}
      </Card.Body>
    </Card>
  );
};

export default CreateCourseHeader;
