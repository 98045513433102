import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Flex from 'components/common/Flex';
import React from 'react';

const ErrorPlaceholder = ({ message, className }) => {
  return (
    <Flex
      direction="column"
      alignItems="center"
      justifyContent="center"
      className={`${className || ''} mt-5`}
    >
      <FontAwesomeIcon icon={faTimesCircle} size="10x" />
      <p className="mt-3">{message}</p>
    </Flex>
  );
};

export default ErrorPlaceholder;
