import {
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faEllipsisH,
  faGripLines
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { truncateString } from 'helpers/utils';
import React from 'react';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import IconButton from 'components/common/IconButton';

const CurriculumChapterItem = ({
  t,
  setOpenChapters,
  openChapters,
  chapter,
  dragHandleProps,
  isReadOnly,
  isRTL,
  breakpoints,
  currentLanguage,
  setLessonToEdit,
  setQuizToEdit,
  setChapterToEdit,
  setPracticeCodeToEdit,
  handleClickDeleteChapter
}) => {
  const isOpen = !!openChapters?.includes(chapter.id);
  return (
    <Flex className="w-100 p-3 " gap="2" alignItems="center">
      <div
        {...dragHandleProps}
        style={{ cursor: 'grab' }}
        className="d-flex align-items-center"
      >
        {!isReadOnly && (
          <FontAwesomeIcon
            icon={faGripLines}
            size={breakpoints.down('md') ? 'md' : 'lg'}
          />
        )}
      </div>
      <div>
        <IconButton
          aria-controls={`chapter-content-${chapter.id}`}
          aria-expanded={isOpen}
          size="sm"
          icon={isOpen ? faChevronDown : isRTL ? faChevronLeft : faChevronRight}
          variant="transparent"
          style={{
            boxShadow: 'none',
            width: breakpoints.down('md') ? '15px' : '40px'
          }}
          className="cursor-pointer"
          onClick={() =>
            setOpenChapters(
              isOpen
                ? openChapters.filter(c => c !== chapter.id)
                : [...openChapters, chapter.id]
            )
          }
        />
      </div>
      <Flex
        alignItems="center"
        className="position-relative gap-3 w-75 cursor-pointer"
        onClick={() =>
          setOpenChapters(
            isOpen
              ? openChapters.filter(c => c !== chapter.id)
              : [...openChapters, chapter.id]
          )
        }
      >
        <div>
          <h5 className="fs-0 text-primary mb-0 text-nowrap">
            {truncateString(
              chapter.title[currentLanguage],
              breakpoints.down('md') ? 15 : 150
            )}

            <OverlayTrigger
              overlay={
                <Tooltip id={`chapter-status-toolip-${chapter.id}`}>
                  {t('course:labels.chapter')}
                </Tooltip>
              }
            >
              <span className="ms-2 text-primary fs-0">
                <FontAwesomeIcon icon="exclamation-circle" />
              </span>
            </OverlayTrigger>
          </h5>
        </div>
      </Flex>
      {!breakpoints.down('md') && (
        <div className="w-25">
          <p className="mb-0">
            {t('course:totalLessons', {
              count: chapter.lessons?.length || 0
            })}
          </p>
        </div>
      )}

      {!isReadOnly && (
        <div>
          <Dropdown
            className="font-sans-serif btn-reveal-trigger"
            align={isRTL ? 'start' : 'end'}
          >
            <Dropdown.Toggle
              variant="link"
              size="sm"
              data-boundary="viewport"
              className={classNames('text-600')}
            >
              <FontAwesomeIcon icon={faEllipsisH} className="fs--2" />
            </Dropdown.Toggle>
            <Dropdown.Menu className="border py-0">
              <div className="py-2">
                <Dropdown.Item
                  onClick={() =>
                    setLessonToEdit({
                      lesson: null,
                      chapter
                    })
                  }
                >
                  {t('lesson:button.addNewLesson')}
                </Dropdown.Item>
                {!chapter?.quiz?.id && (
                  <Dropdown.Item
                    onClick={() =>
                      setQuizToEdit({
                        quiz: null,
                        chapter
                      })
                    }
                  >
                    {t('course:button.addNewQuiz')}
                  </Dropdown.Item>
                )}
                {!chapter?.practiceCode?.id && (
                  <Dropdown.Item
                    onClick={() =>
                      setPracticeCodeToEdit({
                        practiceCode: null,
                        chapter
                      })
                    }
                  >
                    {t('course:button.addNewPracticeCode')}
                  </Dropdown.Item>
                )}
                <Dropdown.Item onClick={() => setChapterToEdit(chapter)}>
                  {t('common:button.edit')}
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  className="text-danger"
                  onClick={() => handleClickDeleteChapter(chapter.id)}
                >
                  {t('common:button.delete')}
                </Dropdown.Item>
              </div>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
    </Flex>
  );
};

export default CurriculumChapterItem;
