import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api`;
const LIST_PROJECTS_URL = `https://admin.api.360realize.com/api/projects/List`;
const GET_PROJECT_URL = `https://admin.api.360realize.com/api/projects/GetProjectById`;
const CREATE_PROJECT_URL = `https://admin.api.360realize.com/api/projects/Create`;
const UPDATE_PROJECT_URL = `https://admin.api.360realize.com/api/projects/Update`;
const DELETE_PROJECT_URL = `https://admin.api.360realize.com/api/projects/Delete`;
const LIST_SERVICE_TYPES_URL = `https://admin.api.360realize.com/api/projects/GetCalculatedPrice`;
const UPLOAD_PROJECT_IMAGE_URL = `https://admin.api.360realize.com/api/projects/UploadProjectImage`;


const ProjectService = {
  list: data => {
    return http.post(
      LIST_PROJECTS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  get: id => {
    return http.get(`${GET_PROJECT_URL}?id=${id}`, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  create: data => {
    return http.post(
      CREATE_PROJECT_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  uploadImage: data => {
    const url = `${UPLOAD_PROJECT_IMAGE_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  update: data => {
    return http.post(
      UPDATE_PROJECT_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  delete: data => {
    return http.delete(`${DELETE_PROJECT_URL}?id=${data}`, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  listServiceTypes: filters => {
    const url = LIST_SERVICE_TYPES_URL;
    return http.post(url, filters, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
};

export default ProjectService;
