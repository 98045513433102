import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  Col,
  Form,
  OverlayTrigger,
  Row,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { useContext } from 'react';
import { LearningPathContext } from 'context/Context';
import { getErrorMessage } from 'http/utils';
import LearningPathService from 'http/LearningPathService';
import { sumProperty } from 'helpers/utils';

const LearningPathPrice = () => {
  const { t } = useTranslation();
  const { learningPath, editCard, setEditCard, isCreate, isReadOnly } =
    useContext(LearningPathContext);
  const [isEdit, setIsEdit] = useState(false);

  const [initPrice, setInitPrice] = useState({});
  const [newPrice, setNewPrice] = useState({});

  useEffect(() => {
    if (learningPath) {
      const totalCourses = Number(
        (sumProperty(learningPath?.courses, 'price') || 0).toFixed(1)
      );
      let discount = Number(
        (
          100 -
          (learningPath?.settings?.price * 100) /
            learningPath?.settings?.oldPrice
        ).toFixed(1)
      );

      let values = {
        price: learningPath?.settings?.price || totalCourses,
        oldPrice: totalCourses || 0,
        discount: discount || 0
      };
      setInitPrice(values);
      setNewPrice(values);
    }
  }, [learningPath]);

  const {
    mutate: saveLearningPath,
    error: saveLearningPathError,
    isLoading: saveLearningPathLoading
  } = useMutation({
    mutationFn: LearningPathService.updatePrice,
    onSuccess: data => onSaveLearningPathSuccess(data)
  });

  const onSaveLearningPathSuccess = () => {
    setEditCard(null);
    toast.success(
      t(
        `common:message.${
          !learningPath?.id ? 'createSuccess' : 'updateSuccess'
        }`
      ),
      { theme: 'colored' }
    );
  };
  const handleChange = e => {
    if (!!e.target.value && isNaN(e.target.value)) {
      return;
    }
    if (!!e.target.value && !isNaN(e.target.value) && e.target.value > 100) {
      return;
    }
    let discountedPrice = newPrice?.price;
    if (
      e.target.name === 'discount' &&
      !isNaN(newPrice.price) &&
      newPrice.price > 0
    ) {
      discountedPrice = (
        newPrice.oldPrice -
        (Number(e.target.value) * (newPrice.oldPrice || 0)) / 100
      ).toFixed(1);
    } else {
      discountedPrice = newPrice.price;
    }
    setNewPrice({
      ...newPrice,
      [e.target.name]: e.target.value,
      price: discountedPrice
    });
  };

  const handleSubmit = () => {
    saveLearningPath({
      id: learningPath.id,
      price: newPrice.price,
      oldPrice: newPrice.oldPrice
    });
  };
  const onCancel = () => {
    setNewPrice({ ...initPrice });
    setEditCard(null);
  };

  useEffect(() => {
    if (editCard === 'pricing') {
      setIsEdit(true);
    } else {
      setIsEdit(false);
    }
  }, [editCard, isReadOnly, learningPath]);

  useEffect(() => {
    if (saveLearningPathError) {
      toast.error(getErrorMessage(t, saveLearningPathError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [saveLearningPathError]);

  const handleClickEdit = () => {
    if (!learningPath?.courses?.length) {
      toast.error(t('learningPath:validation.message.priceAddCoursesFirst'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    setEditCard('pricing');
  };

  if (isCreate) {
    return '';
  }

  return (
    <>
      <Card className="mb-3">
        <Card.Header as="h5">{t('learningPath:labels.pricing')}</Card.Header>
        <Card.Body className="bg-light">
          <Row className="gx-2 gy-3">
            <Col xs="12">
              <Form.Group controlId="learningPathDiscount">
                <Form.Label>
                  {t('learningPath:labels.discount')}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="discountTooltip">
                        {t('learningPath:tooltips.discount')}
                      </Tooltip>
                    }
                  >
                    <span className="ms-2 text-primary fs--1">
                      <FontAwesomeIcon icon="question-circle" />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="discount"
                  value={newPrice?.discount || ''}
                  onChange={handleChange}
                  disabled={!isEdit || saveLearningPathLoading}
                />
              </Form.Group>
            </Col>
            <Col xs="12">
              <Form.Group controlId="learningPathBasePrice">
                <Form.Label>
                  {t('learningPath:labels.basePrice')}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="basePriceTooltip">
                        {t('learningPath:tooltips.price')}
                      </Tooltip>
                    }
                  >
                    <span className="ms-2 text-primary fs--1">
                      <FontAwesomeIcon icon="question-circle" />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="oldPrice"
                  value={newPrice?.oldPrice || 0}
                  onChange={handleChange}
                  disabled={true}
                />
              </Form.Group>
            </Col>

            <Col xs="12">
              <Form.Group controlId="learningPathDiscountedPrice">
                <Form.Label>
                  {t('learningPath:labels.discountedPrice')}
                  <OverlayTrigger
                    overlay={
                      <Tooltip id="basePriceTooltip">
                        {t('learningPath:tooltips.discountedPrice')}
                      </Tooltip>
                    }
                  >
                    <span className="ms-2 text-primary fs--1">
                      <FontAwesomeIcon icon="question-circle" />
                    </span>
                  </OverlayTrigger>
                </Form.Label>
                <Form.Control
                  type="text"
                  name="price"
                  value={newPrice?.price || 0}
                  onChange={handleChange}
                  disabled={true}
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
        <Card.Footer>
          {isCreate && (
            <Button size="sm" onClick={handleSubmit}>
              {saveLearningPathLoading && <Spinner size="sm" />}
              {!saveLearningPathLoading && t('common:button.save')}
            </Button>
          )}
          {isEdit && (
            <>
              <Button size="sm" onClick={handleSubmit}>
                {saveLearningPathLoading && <Spinner size="sm" />}
                {!saveLearningPathLoading && t('common:button.save')}
              </Button>

              <Button
                size="sm"
                variant="danger"
                onClick={onCancel}
                className="mx-2"
                disabled={saveLearningPathLoading}
              >
                {t('common:button.cancel')}
              </Button>
            </>
          )}
          {!isEdit && !isReadOnly && (
            <>
              <Button
                size="sm"
                variant="info"
                onClick={() => handleClickEdit()}
                disabled={!!editCard || saveLearningPathLoading}
              >
                {t('common:button.edit')}
              </Button>
            </>
          )}
        </Card.Footer>
      </Card>
    </>
  );
};

export default LearningPathPrice;
