import React from 'react';

const Payments = () => {
  return (
    <>
      <h1>Payments Report Page</h1>
    </>
  );
};

export default Payments;
