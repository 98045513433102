import { faDotCircle, faMinus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { Col, Form, OverlayTrigger, Tab, Tabs, Tooltip } from 'react-bootstrap';
import Flex from 'components/common/Flex';
import TinymceEditor from 'components/common/TinymceEditor';

const Answer = ({
  t,
  answer,
  chapterIndex,
  answersRefs,
  answerIndex,
  answerKey,
  showDelete,
  handleDeleteAnswer,
  handleAnswerChange,
  questionDetails,
  currentLanguage,
  disableEditing,
  correctDisabled,
  errors,
  answersArrows,
  isMobile,
  setHoveredAnswer
}) => {
  if (questionDetails?.type === 'trueOrFalse') {
    return (
      <div
        key={`quiz-question-answer-${answerIndex}-${answerKey}`}
        id={`quiz-question-answer-${answerIndex}-${answerKey}`}
        className="w-100 d-flex align-items-start justify-content-start gap-2 mt-2"
      >
        <span className="mt-1" ref={answersRefs[answerIndex] || undefined}>
          <FontAwesomeIcon icon={faDotCircle} />
        </span>

        <Col sm={12} className="w-100">
          <Flex
            justifyContent="between"
            alignItems="center"
            className="w-100"
            onMouseEnter={() => setHoveredAnswer(answerKey)}
            onMouseLeave={() => setHoveredAnswer(null)}
          >
            <Form.Label>{answer?.content[currentLanguage]}</Form.Label>
            <Flex>
              {showDelete && (
                <OverlayTrigger
                  overlay={
                    <Tooltip
                      id={`quiz-question-answer-delete-toolip-${answerKey}`}
                    >
                      {t('common:button.delete')}
                    </Tooltip>
                  }
                >
                  <span style={{ height: '1.5rem' }}>
                    <FontAwesomeIcon
                      icon={faMinus}
                      className="cursor-pointer mx-3"
                      onClick={() =>
                        handleDeleteAnswer(answerIndex, chapterIndex)
                      }
                      size="sm"
                    />
                  </span>
                </OverlayTrigger>
              )}
              <Form.Check
                type="switch"
                id="defaultSwitch"
                size={50}
                label={t('course:labels.correct')}
                onChange={e => {
                  handleAnswerChange(
                    {
                      ...answer,
                      correct: e.target.checked || false
                    },
                    answerIndex,
                    questionDetails,
                    chapterIndex
                  );
                }}
                disabled={disableEditing || correctDisabled}
                checked={answer?.correct || false}
              />
            </Flex>
          </Flex>
        </Col>
        {answersArrows[answerIndex] && !isMobile && answersArrows[answerIndex]}
      </div>
    );
  }
  return (
    <div
      key={`quiz-question-answer-${answerIndex}-${answerKey}`}
      id={`quiz-question-answer-${answerIndex}-${answerKey}`}
      className="w-100 d-flex align-items-start justify-content-start gap-2 mt-2"
    >
      <span className="mt-1" ref={answersRefs[answerIndex] || undefined}>
        <FontAwesomeIcon icon={faDotCircle} />
      </span>

      <Col sm={12} className="w-100">
        <Flex
          justifyContent="between"
          alignItems="center"
          className="w-100"
          onMouseEnter={() => setHoveredAnswer(answerKey)}
          onMouseLeave={() => setHoveredAnswer(null)}
        >
          <Form.Label>
            {`${t('course:labels.answer')} ${answerIndex + 1} `}
          </Form.Label>
          <Flex>
            {showDelete && (
              <OverlayTrigger
                overlay={
                  <Tooltip
                    id={`quiz-question-answer-delete-toolip-${answerKey}`}
                  >
                    {t('common:button.delete')}
                  </Tooltip>
                }
              >
                <span style={{ height: '1.5rem' }}>
                  <FontAwesomeIcon
                    icon={faMinus}
                    className="cursor-pointer mx-3"
                    onClick={() =>
                      handleDeleteAnswer(answerIndex, chapterIndex)
                    }
                    size="sm"
                  />
                </span>
              </OverlayTrigger>
            )}
            <Form.Check
              type="switch"
              id="defaultSwitch"
              size={50}
              label={t('course:labels.correct')}
              onChange={e => {
                handleAnswerChange(
                  {
                    ...answer,
                    correct: e.target.checked || false
                  },
                  answerIndex,
                  questionDetails,
                  chapterIndex
                );
              }}
              disabled={disableEditing || correctDisabled}
              checked={answer?.correct || false}
            />
          </Flex>
        </Flex>
        <div>
          <Tabs
            defaultActiveKey={`answer-content-${currentLanguage}`}
            id="uncontrolled-tab-answer-content"
          >
            <Tab
              eventKey="answer-content-en"
              title={t('common:language.en.name')}
              className="border-bottom border-x"
            >
              <div
                className={`tinymce-editor-container ${
                  !!errors?.content?.ar ? 'invalid' : ''
                }`}
              >
                <TinymceEditor
                  height="13.438rem"
                  name="answer-content"
                  handleChange={newValue =>
                    handleAnswerChange(
                      {
                        ...answer,
                        content: {
                          ...answer?.content,
                          en: newValue
                        }
                      },
                      answerIndex,
                      questionDetails,
                      chapterIndex
                    )
                  }
                  value={answer?.content?.en || ''}
                  placeholder={t('course:placeholders.addNewItem')}
                />
              </div>
            </Tab>
            <Tab
              eventKey="answer-content-ar"
              title={t('common:language.ar.name')}
              className="border-bottom border-x"
            >
              <div
                className={`tinymce-editor-container ${
                  !!errors?.content?.en ? 'invalid' : ''
                }`}
              >
                <TinymceEditor
                  height="13.438rem"
                  name="answer-content"
                  handleChange={newValue =>
                    handleAnswerChange(
                      {
                        ...answer,
                        content: {
                          ...answer?.content,
                          ar: newValue
                        }
                      },
                      answerIndex,
                      questionDetails,
                      chapterIndex
                    )
                  }
                  value={answer?.content?.ar || ''}
                  placeholder={t('course:placeholders.addNewItem')}
                />
              </div>
            </Tab>
          </Tabs>
        </div>
      </Col>
      {answersArrows[answerIndex] && !isMobile && answersArrows[answerIndex]}
    </div>
  );
};

export default Answer;
