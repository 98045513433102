import React from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';

import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import ConfirmModal from 'components/common/ConfirmModal';
import { useContext } from 'react';
import { ExamContext } from 'context/Context';
import { toExamFormSchema } from '../utils';
import CompanyService from 'http/CompanyService';

const initPlan = {
  name: null,
  owner:null,
  email:null,
  phoneNumber:null,
  city:null,
  address:null
};

const optionalFields = [
  "modifiedAt",
  "modifiedBy"
];

const CompanyBasicForm = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const { cities } = useContext(ExamContext);
  const { exam, examLoading, editCard, setEditCard, fetchExam } = useContext(ExamContext);
  const [companyValues, setCompanyValues] = useState(null);
  const [formKey, setFormKey] = useState(Date.now()); // Create a state variable to force re-render
  const [errors, setErrors] = useState(null);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  
  const { register, handleSubmit, setValue, control, reset, getValues } =
  useForm({
    defaultValues: null
  });

  useEffect(() => {
    if (exam?.id) {
      fillInitialData(exam || null);
    } else {
      fillInitialData(initPlan);
    }
  }, [exam]);

  useEffect(() => {
    setValue('City', selectedCity, { shouldValidate: true });
  }, [selectedCity]);

  const {
    mutate: createExam,
    error: examCreateError,
    isLoading: examCreateLoading
  } = useMutation({
    mutationFn: CompanyService.create,
    onSuccess: data => onCreateExamSuccess(data)
  });
  const {
    mutate: updateCompany,
    error: planUpdateError,
    isLoading: planUpdateLoading
  } = useMutation({
    mutationFn: CompanyService.update,
    onSuccess: data => onUpdateCompanySuccess(data)
  });

  const fillInitialData = data => {
    if (!data) {
      return;
    }
    const formattedData = toExamFormSchema(data);
    Object.entries(formattedData).map(([key, value]) => {
      setValue(key, value);
    });
    setCompanyValues(formattedData);
    setSelectedCity(formattedData.city?.id);
    setFormKey(Date.now());
  };

  const onSubmit = () => {
    //console.log('Submitted Values:', getValues());
    //console.log('planValues before validation:', planValues);
    const isValid = validateForm(companyValues);
    if (!isValid) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    const values = { ...companyValues };
    let result = {};
    Object.entries(values).map(([key, value]) => {
      const isValid = !!value;
      result[key] = isValid;
    });
    let createPayload = {
      name:values.name ,
      owner:values.owner,
      email:values.email,
      phoneNumber:values.phoneNumber,
      cityId:values.city,
      address:values.address
    };

    if (exam?.id) {
      createPayload['id'] = exam?.id;
      updateCompany(createPayload);
    }else{
      createExam(createPayload);
    }
  };

  const onCreateExamSuccess = response => {
    toast.success(
      t(`common:message.${!exam?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
    if (typeof response?.data === 'string' && !exam?.id) {
      navigate(`/companies/create/${response.data}`);
    } else {
      setEditCard(null);
      fetchExam(exam.id);
    }
  };
  const onUpdateCompanySuccess = response => {
    toast.success(
      t(`common:message.${!exam?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
    if (typeof response?.data === 'string' && !exam?.id) {
      navigate(`/companies/create/${response.data}`);
    } else {
      setEditCard(null);
      fetchExam(exam.id);
    }
  };

  const validateForm = values => {
    let valid = true;
    let errors = {};
    if (!values) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return false;
    }
    Object.entries(values).map(([key, value]) => {
      if (key === 'planNameEn') {
        if (value?.length > 60) {
          errors['planNameEn'] = {
            error: true,
            message: t('course:validation.message.maxAllowedCharacters', {
              count: 60
            })
          };
        }
      }
      if (!value && !optionalFields.includes(key)) {
        errors[key] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
        valid = false;
      }
    });

    setErrors(errors);
    //console.log('onError', errors);
    return valid;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const dataType =
      e.target.getAttribute && e?.target?.getAttribute('data-type');
    if (dataType === 'number') {
      if (!!value && isNaN(value)) {
        return;
      }
    }

    if (!!value) {
      setErrors({ ...errors, [name]: { error: false, message: '' } });
    }
    setCompanyValues({ ...companyValues, [name]: value });
  };

  const onCancel = () => {
    if (editCard === 'basicInfo') {
      fillInitialData(exam);
      setEditCard(null);
      return;
    }

    const hasChanges =
      JSON.stringify({ ...toExamFormSchema(exam) }) !==
      JSON.stringify({ ...companyValues });
    if (hasChanges) {
      setConfirmModalProps({
        isOpen: true,
        onClose: () => setConfirmModalProps(null),
        onConfirm: () => navigate('/companies'),
        message: t('companies:message.discardExamConfirm'),
        header: t('companies:message.discardExamTitle'),
        actionButtonColor: 'danger',
        actionButtonText: t('common:button.discard')
      });
      return;
    }
    navigate('/contents/companies');
  };

  useEffect(() => {
    if (examCreateError) {
      toast.error(getErrorMessage(t, examCreateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [examCreateError]);

  if (examLoading) {
    return <LoaderWithMessage message={t('plans:message.loadingCompany')} />;
  }
  useEffect(() => {
    if (planUpdateError) {
      toast.error(getErrorMessage(t, planUpdateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [planUpdateError]);

  if (examLoading) {
    return <LoaderWithMessage message={t('companies:message.loadingCompany')} />;
  }
  return (
    <Card className="mb-3">
      <Card.Header as="h5">
        {!!id ? exam?.title?.[currentLanguage] : t('companies:labels.newCompany')}
      </Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3" key={formKey}>
        <Col md="6">
            <Form.Group>
              <Form.Label>
                {t('companies:labels.city')}
                <span className="text-danger">*</span>
              </Form.Label>
              <Form.Select
                {...register(`city`, { required: true })}
                value={selectedCity || ''}
                required
                disabled={examCreateLoading ||
                  examLoading ||
                  planUpdateLoading||
                  (!!id && editCard !== 'basicInfo')}
                onChange={e => {
                  setSelectedCity(e.target.value);
                  setValue('city', e.target.value);
                  setCompanyValues({ ...companyValues, 'city': e.target.value });
                }}
                isInvalid={!!errors?.city?.error}
              >
                <option value="">
                  {t('companies:labels.city')}
                </option>
                {cities
                  .map((city, index) => {
                    return (
                      <option
                        key={`course-category-${city.id}-${index}`}
                        value={city.id}
                      >
                        {city.name}
                      </option>
                    );
                  })}
              </Form.Select>
              <Form.Text className="text-danger">
                {!!errors?.city?.error && errors?.city?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="companyNameEn">
              <Form.Label>
                {`${t('companies:labels.name')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                required
                placeholder={t('companies:labels.name')}
                onChange={handleChange}
                value={companyValues?.name || ''}
                disabled={
                  examCreateLoading ||
                  examLoading ||
                  planUpdateLoading||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.name?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.name?.error && errors?.name?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="companyOwner">
              <Form.Label>
                {`${t('companies:labels.owner')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="owner"
                required
                placeholder={t('companies:labels.owner')}
                onChange={handleChange}
                value={companyValues?.owner || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.owner?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.owner?.error &&
                  errors?.owner?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="companyEmail">
              <Form.Label>
                {`${t('companies:labels.email')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="email"
                required
                placeholder={t('companies:labels.email')}
                onChange={handleChange}
                value={companyValues?.email || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.smallAreaRate?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.email?.error &&
                  errors?.email?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="companyPhoneNumber">
              <Form.Label>
                {`${t('companies:labels.phoneNumber')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 10 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="phoneNumber"
                required
                placeholder={t('companies:labels.phoneNumber')}
                onChange={handleChange}
                data-type="number"
                value={companyValues?.phoneNumber || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.phoneNumber?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.phoneNumber?.error &&
                  errors?.phoneNumber?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="companyAddress">
              <Form.Label>
                {`${t('companies:labels.address')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 150 })})`}
                </small>
              </Form.Label>
              <Form.Control
                as="textarea"
                name="address"
                required
                placeholder={t('companies:labels.address')}
                onChange={handleChange}
                value={companyValues?.address || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.address?.error}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card.Body>

      <Card.Footer>
        {!id && (
          <>
            <Button size="sm" onClick={onSubmit}>
              {examCreateLoading && <Spinner size="sm" />}
              {!examCreateLoading && t('common:button.save')}
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
            >
              {!examCreateLoading && t('common:button.cancel')}
            </Button>
          </>
        )}
        {editCard === 'basicInfo' && !!id && (
          <>
            <Button
              size="sm"
              onClick={onSubmit}
              disabled={!!examCreateLoading || examLoading}
            >
              {examCreateLoading && <Spinner size="sm" />}
              {!examCreateLoading && t('common:button.save')}
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
              disabled={!!examCreateLoading || examLoading}
            >
              {t('common:button.cancel')}
            </Button>
          </>
        )}
        {editCard !== 'basicInfo' && !!id && (
          <>
            <Button
              size="sm"
              variant="info"
              onClick={() => setEditCard('basicInfo')}
              disabled={!!examCreateLoading || examLoading}
            >
              {t('common:button.edit')}
            </Button>
          </>
        )}
      </Card.Footer>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={false} />
      )}
    </Card>
  );
};
export default CompanyBasicForm;
