import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  OverlayTrigger,
  ProgressBar,
  Tooltip
} from 'react-bootstrap';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from 'components/common/Flex';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import useVimeo from 'hooks/useVimeo';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import LinearIndeterminateProgressBar from './LinearIndeterminateProgressBar';

const VideoCard = ({
  title,
  tooltip,
  onFileChange,
  url,
  loading,
  baseUrl,
  vimeo,
  isReadOnly,
  hidden
}) => {
  const { t } = useTranslation();
  const [video, setVideo] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);

  useEffect(() => {
    if (url) {
      let name = url.split('/')[url.split('/').length - 1];
      if (name?.includes('?')) {
        name = name.split('?')[0];
      }
      const source = `${baseUrl || ''}${url}`;
      setVideo({
        preview: source,
        path: name
      });
    }
  }, [url]);

  const handleVimeoUpload = file => {
    if (file) {
      handleUpload(file);
    }
    //console.log('handleVimeoUpload files', { file });
  };

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept: 'video/*',
    onDrop: async acceptedFiles => {
      if (vimeo) {
        setUploadedFile(acceptedFiles[0]);
        handleVimeoUpload && handleVimeoUpload(acceptedFiles[0]);
      } else {
        setVideo(
          Object.assign(acceptedFiles[0], {
            preview: URL.createObjectURL(acceptedFiles[0])
          })
        );
        onFileChange && onFileChange(acceptedFiles[0]);
      }
    }
  });

  const onVimeoSuccessUpload = responseData => {
    //console.log('onVimeoSuccessUpload responseData', { responseData });
    onFileChange && onFileChange({ file: uploadedFile, ...responseData });
  };

  const onVimeoErrorUpload = error => {
    toast.error(getErrorMessage(t, error), {
      theme: 'colored',
      autoClose: false
    });
    //console.log('onVimeoErrorUpload error', { error });
  };

  const { status, isLoading, progress, handleUpload } = useVimeo({
    onFinish: onVimeoSuccessUpload,
    onError: onVimeoErrorUpload
  });

  useEffect(() => {
    if (!!status) {
      toast.success(status, {
        theme: 'colored',
        autoClose: 5000
      });
    }
  }, [status]);

  if (hidden) {
    return '';
  }

  return (
    <Card className="mb-3">
      <Card.Header>
        <h5 className="mb-0 d-inline-block">
          {title || 'Upload Image'}
          {!!tooltip && (
            <OverlayTrigger
              overlay={<Tooltip id="coverPphotoTooltip">{tooltip}</Tooltip>}
            >
              <span className="ms-2 text-primary fs-0">
                <FontAwesomeIcon icon="info-circle" />
              </span>
            </OverlayTrigger>
          )}
        </h5>
      </Card.Header>
      <Card.Body className="bg-light">
        {!isReadOnly && (
          <div
            {...getRootProps({ className: !video ? 'dropzone-area py-6' : '' })}
          >
            <input {...getInputProps({ multiple: false })} />
            {!video && (
              <div className="fs--1">
                <img src={cloudUpload} alt="" width={20} className="me-2" />
                <span className="d-none d-lg-inline">
                  {t('common:placeholder.dropFileHere')}
                  <br />
                  {t('common:labels.or')},{' '}
                </span>
                <Button variant="link" size="sm" className="p-0 fs--1">
                  {t('common:labels.browse')}
                </Button>
              </div>
            )}
          </div>
        )}
        {!!video && (
          <Flex className="position-relative" style={{ zIndex: 9 }}>
            {!!vimeo && (
              <iframe
                src={video.preview}
                allowFullScreen={true}
                className="react-player"
                title="vimeo"
              />
            )}
            {!vimeo && (
              <iframe
                src={video.preview}
                allowFullScreen={true}
                title="YouTube video"
              />
            )}
          </Flex>
        )}
        {!!progress && progress < 100 && (
          <ProgressBar now={progress} label={`${progress}%`} />
        )}
        {isLoading && <LinearIndeterminateProgressBar />}
      </Card.Body>
      {!isReadOnly && (
        <Card.Footer>
          <Button
            onClick={() => {
              inputRef?.current?.click();
            }}
            variant="primary"
            disabled={loading || isLoading || (!!progress && progress < 100)}
          >
            {t('common:labels.replace')}
          </Button>
        </Card.Footer>
      )}
    </Card>
  );
};
export default VideoCard;
