import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import { Card, Dropdown, ListGroup, Placeholder } from 'react-bootstrap';
import ShaiCardHeader from 'components/common/ShaiCardHeader';
import Notification from 'components/notification/Notification';
import { useMutation, useQuery } from 'react-query';
import NotificationsService from 'http/NotificationsService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AuthContext } from 'context/Context';
import { Link } from 'react-router-dom';

const NotificationDropdown = () => {
  const { t, i18n } = useTranslation();
  const { user } = useContext(AuthContext);
  const currentLanguage = i18n.language;
  const [notifications, setNotifications] = useState([]);
  const [connection, setConnection] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [hasNewNotifications, setHasNewNotifications] = useState(false);

  const {
    isLoading: notificationsLoading,
    error: notificationsError,
    data: notificationsData,
    refetch: fetchNotifications
  } = useQuery(
    ['NotificationsList', { pageSize: 10, pageNumber: 1 }],
    () => NotificationsService.list({ pageSize: 10, pageNumber: 1 }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  const { mutate: markAllAsRead } = useMutation({
    mutationFn: NotificationsService.markAllAsRead,
    onSuccess: data => onMarkAllAsReadSuccess(data)
  });

  const { mutate: markNotificationAsRead } = useMutation({
    mutationFn: NotificationsService.markAsRead,
    onSuccess: data => onMarkNotificationAsReadSuccess(data)
  });

  useEffect(() => {
    if (!connection) {
      initiateConnection();
    }
  }, []);

  useEffect(() => {
    if (notificationsData && !notificationsLoading && !notificationsError) {
      setNotifications(notificationsData.payload.data);
    }
    if (notificationsError) {
      toast.error(getErrorMessage(t, notificationsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [notificationsData, notificationsError, notificationsLoading]);

  const onMarkAllAsReadSuccess = () => {
    let old = [...notifications];
    setNotifications(old.map(n => ({ ...n, isRead: true })));
  };

  const onMarkNotificationAsReadSuccess = () => {};

  const initiateConnection = async () => {
    let accessToken;
    try {
      accessToken = JSON.parse(localStorage.getItem('SHAI_admin_user'))?.token;
    } catch {
      accessToken = null;
    }

    if (!connection && !!accessToken && !!user) {
      await NotificationsService.initiateConnection(
        user,
        accessToken,
        newConnection => {
          setConnection(newConnection);
        },
        () => {
          setHasNewNotifications(true);
        }
      );
    }
  };

  // Handlers
  const handleToggle = () => {
    if (!isOpen) {
      fetchNotifications({ pageSize: 10, pageNumber: 1 });
      setHasNewNotifications(false);
    }
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    window.addEventListener('scroll', () => {
      window.innerWidth < 1200 && setIsOpen(false);
    });
  }, []);

  const markAllNotificationsAsRead = e => {
    e.preventDefault();
    markAllAsRead();
  };

  const onNotificationClick = id => {
    markNotificationAsRead({ id });
  };

  return (
    <Dropdown navbar={true} as="li" show={isOpen} onToggle={handleToggle}>
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        className={classNames('px-0 cursor-pointer nav-link bg-none', {
          'notification-indicator notification-indicator-primary':
            !!hasNewNotifications
        })}
      >
        <FontAwesomeIcon icon="bell" transform="shrink-6" className="fs-4" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-menu-card dropdown-menu-end dropdown-caret dropdown-caret-bg">
        <Card
          className="dropdown-menu-notification dropdown-menu-end shadow-none"
          style={{ maxWidth: '20rem' }}
        >
          <ShaiCardHeader
            className="card-header"
            title="Notifications"
            titleTag="h6"
            light={false}
            endEl={
              <>
                {!notificationsLoading && !!notifications?.length && (
                  <Link
                    className="card-link fw-normal"
                    to="#!"
                    onClick={markAllNotificationsAsRead}
                  >
                    {t('common:placeholder.markAllAsRead')}
                  </Link>
                )}
              </>
            }
          />
          <ListGroup
            variant="flush"
            className="fw-normal fs--1 scrollbar"
            style={{ maxHeight: '19rem' }}
          >
            {notificationsLoading &&
              Array(14)
                .fill(1)
                .map((n, i) => {
                  return (
                    <Placeholder
                      key={`notification-skeleton-${i}`}
                      className="w-100 my-1"
                      style={{ minHeight: '100px' }}
                      size="lg"
                    />
                  );
                })}
            {!notificationsLoading &&
              !!notifications?.length &&
              notifications.map((notification, index) => {
                return (
                  <ListGroup.Item
                    key={`${notification.id}-${index}`}
                    onClick={handleToggle}
                  >
                    <Notification
                      {...notification}
                      currentLanguage={currentLanguage}
                      onClick={() => onNotificationClick(notification.id)}
                      flush
                    />
                  </ListGroup.Item>
                );
              })}
            {!notificationsLoading && !notifications?.length && (
              <p className="text-center w-100">
                {t('common:placeholder.noDataAvailable')}
              </p>
            )}
          </ListGroup>
        </Card>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default NotificationDropdown;
