import React, { useState } from 'react';
import {
  Button,
  Modal,
  OverlayTrigger,
  Spinner,
  Tooltip
} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import CourseService from 'http/CourseService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { useEffect } from 'react';
import FileDropZone from 'components/common/FileDropZone';
import FileService from 'http/FileService';
import LinearIndeterminateProgressBar from 'components/common/LinearIndeterminateProgressBar';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  downloadFile,
  getFileExtensionFromUrl,
  getFileIcon,
  getFileNameFromUrl,
  toApiFileUrl
} from 'helpers/utils';
import { faDownload, faTrash } from '@fortawesome/free-solid-svg-icons';
import LoaderWithMessage from 'components/common/LoaderWithMessage';

const LessonResourcesModal = ({ lesson, course, close }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [resources, setResources] = useState([]);
  const [tempResources, setTempResources] = useState([]);
  const [hoveredResource, setHoveredResource] = useState(null);

  const {
    isLoading: lessonLoading,
    data: lessonData,
    error: lessonError,
    refetch: fetchLesson
  } = useQuery(
    ['GetLesson', lesson?.id],
    () => CourseService.getLesson(lesson?.id),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  useEffect(() => {
    if (lesson?.id) {
      fetchLesson(lesson.id);
    }
  }, [lesson]);

  useEffect(() => {
    if (lessonData && !lessonLoading && !lessonError) {
      if (lessonData?.errors?.length) {
        toast.error(getErrorMessage(t, lessonData.errors), {
          theme: 'colored',
          autoClose: false
        });
      }
      setResources(lessonData?.payload?.resources ?? []);
    }

    if (lessonError) {
      toast.error(getErrorMessage(t, lessonError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [lessonLoading, lessonData, lessonError]);

  const {
    mutate: uploadTempFile,
    error: uploadTempFileError,
    isLoading: uploadTempFileLoading
  } = useMutation({
    mutationFn: FileService.uploadTempFile,
    onSuccess: data => onUploadTempFileSuccess(data)
  });

  const {
    mutate: saveResources,
    error: saveResourcesError,
    isLoading: saveResourcesLoading
  } = useMutation({
    mutationFn: CourseService.saveLessonResources,
    onSuccess: data => onSaveResourcesSuccess(data)
  });

  const {
    mutate: deleteResource,
    error: deleteResourceError,
    isLoading: deleteResourceLoading
  } = useMutation({
    mutationFn: CourseService.deleteLessonResource,
    onSuccess: data => onDeleteResourceSuccess(data)
  });

  const onSaveResourcesSuccess = () => {
    close && close();
    toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
  };

  const onUploadTempFileSuccess = response => {
    setTempResources([...tempResources, response.payload]);
    toast.success(t(`common:message.uploadSuccess`), { theme: 'colored' });
  };

  const onDeleteResourceSuccess = () => {
    toast.success(t(`common:message.deleteSuccess`), { theme: 'colored' });
  };

  const handleChange = files => {
    const file = files?.[0];
    if (!file) {
      return;
    }
    uploadTempFile({ file });
  };

  const handleDeleteResource = id => {
    deleteResource({
      courseId: course.id,
      lessonId: lesson.id,
      id
    });
  };
  const handleDeleteTempResource = resource => {
    setTempResources([...tempResources.filter(r => r !== resource)]);
  };
  const handleCancel = () => {
    close && close();
  };
  const handleSubmitResources = () => {
    saveResources({
      courseId: course.id,
      id: lesson.id,
      resources: tempResources
    });
  };

  useEffect(() => {
    if (uploadTempFileError) {
      toast.error(getErrorMessage(t, uploadTempFileError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (saveResourcesError) {
      toast.error(getErrorMessage(t, saveResourcesError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (deleteResourceError) {
      toast.error(getErrorMessage(t, deleteResourceError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [uploadTempFileError, saveResourcesError, deleteResourceError]);

  return (
    <Modal
      show={!!lesson?.id}
      size="xl"
      aria-labelledby="contained-modal-title-quiz"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-quiz">
          {t('course:labels.lessonResourcesModalTitle', {
            name: !!lesson?.id
              ? lesson?.title?.[currentLanguage]
              : t('common:button.new')
          })}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {lessonLoading && (
          <LoaderWithMessage message={t('lesson:message.lessonLoading')} />
        )}
        {!lessonLoading && (
          <>
            <Flex>
              {resources?.map(resource => {
                return (
                  <Flex
                    key={resource?.id}
                    onMouseEnter={() => setHoveredResource(resource.id)}
                    onMouseLeave={() => setHoveredResource(null)}
                    justifyContent="start"
                    alignItems="center"
                    className="w-100"
                  >
                    <Flex justifyContent="start" alignItems="center">
                      <FontAwesomeIcon
                        icon={getFileIcon(
                          getFileExtensionFromUrl(resource.path)
                        )}
                      />
                      <p className="mb-0 mx-2">{resource.name}</p>
                    </Flex>
                    {hoveredResource === resource.id && (
                      <>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tempResource-toolip-${resource.id}`}>
                              {t('common:button.download')}
                            </Tooltip>
                          }
                        >
                          <span className="ms-2 text-primary fs-0">
                            <FontAwesomeIcon
                              icon={faDownload}
                              onClick={() =>
                                downloadFile(toApiFileUrl(resource.path))
                              }
                              className="cursor-pointer"
                            />
                          </span>
                        </OverlayTrigger>
                        <OverlayTrigger
                          overlay={
                            <Tooltip id={`tempResource-toolip-${resource.id}`}>
                              {t('common:button.delete')}
                            </Tooltip>
                          }
                        >
                          <span className="ms-2 text-danger fs-0">
                            <FontAwesomeIcon
                              icon={faTrash}
                              onClick={() => handleDeleteResource(resource.id)}
                              className="cursor-pointer"
                            />
                          </span>
                        </OverlayTrigger>
                      </>
                    )}
                  </Flex>
                );
              })}
              {tempResources?.map(tempResource => {
                return (
                  <Flex
                    key={tempResource}
                    onMouseEnter={() => setHoveredResource(tempResource)}
                    onMouseLeave={() => setHoveredResource(null)}
                    justifyContent="start"
                    alignItems="center"
                    className="w-100"
                  >
                    <Flex justifyContent="start" alignItems="center">
                      <FontAwesomeIcon
                        icon={getFileIcon(
                          getFileExtensionFromUrl(tempResource)
                        )}
                      />
                      <p className="mb-0 mx-2">
                        {getFileNameFromUrl(tempResource)}
                      </p>
                    </Flex>
                    {hoveredResource === tempResource && (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id={`tempResource-toolip-${tempResource}`}>
                            {t('common:button.delete')}
                          </Tooltip>
                        }
                      >
                        <span className="ms-2 text-danger fs-0">
                          <FontAwesomeIcon
                            icon={faTrash}
                            onClick={() =>
                              handleDeleteTempResource(tempResource)
                            }
                            className="cursor-pointer"
                          />
                        </span>
                      </OverlayTrigger>
                    )}
                  </Flex>
                );
              })}
            </Flex>
            <div>
              <FileDropZone
                onFilesChange={files => handleChange(files)}
                multiple={false}
                displayUploadedFiles={false}
                disabled={
                  saveResourcesLoading ||
                  deleteResourceLoading ||
                  uploadTempFileLoading
                }
              />
              {uploadTempFileLoading && <LinearIndeterminateProgressBar />}
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={
            saveResourcesLoading ||
            deleteResourceLoading ||
            uploadTempFileLoading ||
            !tempResources.length
          }
          onClick={handleSubmitResources}
          className="mt-1"
        >
          {saveResourcesLoading && <Spinner size="sm" />}
          {!saveResourcesLoading && t('common:button.save')}
        </Button>
        <Button
          onClick={handleCancel}
          variant="secondary"
          disabled={
            saveResourcesLoading ||
            deleteResourceLoading ||
            uploadTempFileLoading
          }
        >
          {t('common:button.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LessonResourcesModal;
