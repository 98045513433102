import LearningPathService from 'http/LearningPathService';
import { getErrorMessage } from 'http/utils';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { toast } from 'react-toastify';
import AsyncSelect from 'components/common/AsyncSelect';
import ShaiCloseButton from 'components/common/ShaiCloseButton';
import LoaderWithMessage from 'components/common/LoaderWithMessage';

const CoursesModal = ({
  onClose,
  onSubmit,
  saveLoading,
  currentLanguage,
  course,
  targetRank
}) => {
  const { t } = useTranslation();
  const [courses, setCourses] = useState([]);

  const [selectedCourse, setSelectedCourse] = useState(null);

  const [exams, setExams] = useState([]);

  const [selectedExam, setSelectedExam] = useState(null);

  const [error, setError] = useState(null);

  const {
    isLoading: coursesLoading,
    data: coursesData,
    error: coursesError,
    refetch: fetchCourses
  } = useQuery(
    'CreateLearningPathCourses',
    () =>
      LearningPathService.listCourses({
        pageNumber: 1,
        pageSize: 10,
        query: {
          term: course?.title?.en || '',
          conjunction: 'or',
          properties: [
            'title.ar',
            'title.en',
            'subtitle.ar',
            'subtitle.en',
            'description.ar',
            'description.en'
          ]
        }
      }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  const {
    isLoading: examsLoading,
    data: examsData,
    error: examsError,
    refetch: fetchExams
  } = useQuery(
    'CreateLearningPathExams',
    () =>
      LearningPathService.listExams({
        pageNumber: 1,
        pageSize: 10,
        courseId: course?.id || null
      }),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  useEffect(() => {
    if (course?.id) {
      let initFetchPayload = {
        pageNumber: 1,
        pageSize: 10
      };
      if (course?.id) {
        initFetchPayload['query'] = {
          term: course?.title?.en,
          conjunction: 'or',
          properties: [
            'title.ar',
            'title.en',
            'subtitle.ar',
            'subtitle.en',
            'description.ar',
            'description.en'
          ]
        };
      }
      fetchCourses(initFetchPayload);
      setSelectedCourse(course);
    } else if (course === true) {
      fetchCourses();
    }
  }, [course]);

  useEffect(() => {
    if (!!selectedCourse?.id) {
      //console.log('useEffect ::: selectedCourse fetchExams', selectedCourse);
      fetchExams({
        courseId: selectedCourse?.id,
        pageNumber: 1,
        pageSize: 10,
        query: null
      });
    } else {
      setExams([]);
    }
  }, [selectedCourse]);

  useEffect(() => {
    if (coursesData && !coursesLoading && !coursesError) {
      if (coursesData?.errors?.length) {
        toast.error(getErrorMessage(t, coursesData.errors), {
          theme: 'colored',
          autoClose: false
        });
      }

      setCourses(coursesData.payload.data);
    }

    if (coursesError) {
      toast.error(getErrorMessage(t, coursesError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [coursesLoading, coursesData, coursesError]);

  useEffect(() => {
    if (examsData && !examsLoading && !examsError) {
      if (examsData?.errors?.length) {
        toast.error(getErrorMessage(t, examsData.errors), {
          theme: 'colored',
          autoClose: false
        });
      }
      //console.log('examsData', { exams: examsData.payload.data, course });
      if (course?.exam) {
        const exam = examsData.payload.data.find(e => e.id === course?.exam.id);
        setSelectedExam(exam);
      }
      setExams(examsData.payload.data);
    }

    if (examsError) {
      toast.error(getErrorMessage(t, examsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [examsLoading, examsData, examsError]);

  const asyncCoursesRequest = searchTerm => {
    //console.log('asyncCoursesRequest', searchTerm);
    return new Promise(resolve => {
      LearningPathService.listCourses({
        pageNumber: 1,
        pageSize: 10,
        query: {
          term: searchTerm,
          conjunction: 'or',
          properties: [
            'title.ar',
            'title.en',
            'subtitle.ar',
            'subtitle.en',
            'description.ar',
            'description.en'
          ]
        }
      })
        .then(response => {
          //console.log('response', response);
          if (response?.payload?.data) {
            resolve(response.payload.data);
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          resolve([]);
          toast.error(getErrorMessage(t, error), {
            theme: 'colored',
            autoClose: false
          });
        });
    });
  };
  const asyncExamsRequest = searchTerm => {
    return new Promise(resolve => {
      LearningPathService.listExams({
        pageNumber: 1,
        pageSize: 10,
        courseId: selectedCourse?.id,
        query: {
          term: searchTerm,
          conjunction: 'or',
          properties: [
            'title.ar',
            'title.en',
            'subtitle.ar',
            'subtitle.en',
            'description.ar',
            'description.en'
          ]
        }
      })
        .then(response => {
          //console.log('response', response);
          if (response?.payload?.data) {
            resolve(response.payload.data);
          } else {
            resolve([]);
          }
        })
        .catch(error => {
          resolve([]);
          toast.error(getErrorMessage(t, error), {
            theme: 'colored',
            autoClose: false
          });
        });
    });
  };

  const handleSubmit = () => {
    if (!selectedCourse) {
      setError(true);
      return;
    }
    onSubmit({
      id: selectedCourse.id,
      examId: selectedExam?.id || null,
      rank: isNaN(course.rank) ? targetRank : course.rank
    });
  };

  return (
    <Modal
      show={!!course}
      onHide={() => onClose(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-vcenter">
          {t('learningPath:button.addNewCourse')}
        </Modal.Title>
        <ShaiCloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => onClose(false)}
        />
      </Modal.Header>
      <Modal.Body>
        {coursesLoading && (
          <LoaderWithMessage message={t('course:message.loadingCourses')} />
        )}

        {!coursesLoading && (
          <>
            <Row>
              <Col md="12">
                <Form.Group controlId="course">
                  <Form.Label>
                    {`${t('learningPath:labels.course')}`}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <AsyncSelect
                    asyncRequest={asyncCoursesRequest}
                    defaultOptions={courses}
                    placeholder={t('learningPath:placeholders.selectCourse')}
                    classNamePrefix="react-select"
                    value={selectedCourse}
                    onChange={option => setSelectedCourse(option)}
                    getOptionLabel={option => option.title?.[currentLanguage]}
                    getOptionValue={option => option.id}
                    errorText={error && t('common:validation.fieldIsRequired')}
                    invalid={error}
                    isDisabled={saveLoading}
                  />
                </Form.Group>
              </Col>
            </Row>
            {!!selectedCourse && (
              <Row className="mt-3">
                {examsLoading && (
                  <LoaderWithMessage
                    message={t('course:message.loadingExams')}
                  />
                )}
                {!examsLoading && (
                  <Col md="12">
                    <Form.Group controlId="courseExam">
                      <Form.Label>
                        {`${t('learningPath:labels.courseExam')} (${t(
                          'common:labels.optional'
                        )})`}
                      </Form.Label>
                      <AsyncSelect
                        asyncRequest={asyncExamsRequest}
                        defaultOptions={exams}
                        placeholder={t(
                          'learningPath:placeholders.selectCourseExam'
                        )}
                        classNamePrefix="react-select"
                        value={selectedExam}
                        onChange={option => setSelectedExam(option)}
                        getOptionLabel={option =>
                          option.title?.[currentLanguage]
                        }
                        getOptionValue={option => option.id}
                        isDisabled={saveLoading}
                      />
                    </Form.Group>
                  </Col>
                )}
              </Row>
            )}
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleSubmit}
          variant={'primary'}
          disabled={saveLoading}
        >
          {!saveLoading && t('common:button.save')}
          {saveLoading && (
            <Spinner animation="border" role="status" size="sm" />
          )}
        </Button>
        <Button
          onClick={() => onClose(false)}
          variant="secondary"
          disabled={saveLoading}
        >
          {t('common:button.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default CoursesModal;
