export const toExamFormSchema = plan => {
  if (!plan) {
    return null;
  }
  return {
    ...plan,
    name: plan.name || '',
    description: plan.description || '',
    mediumAreaRate:plan.mediumAreaRate,
    smallAreaRate:plan.smallAreaRate,
    largeAreaRate:plan.largeAreaRate,
    hugeAreaRate:plan.hugeAreaRate,
    serviceType:plan.serviceType,
  };
};

export const canEditExam = user => {
  if (!user || !user?.roles?.length) {
    console.log('user missing roles', user);
    return false;
  }
  let roles = user.roles;
  const allowedRoles = ['admin', 'planner'];
  if (typeof roles === 'string') {
    roles = [roles];
  }
  const canEdit = !!roles
    .map(r => r.toLowerCase())
    .some(r => allowedRoles.includes(r));
  // console.log(
  //   'canEditCourse',
  //   `${canEdit} user is ${roles.map(r => r).join(',')}`
  // );
  return canEdit;
};
