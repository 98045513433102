import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api/${currentLocale}/${process.env.REACT_APP_API_VERSION}`;
const LIST_CATEGORIES_URL = `https://admin.api.360realize.com/courses/listallcategories`;
const LIST_LEARNINGPATHS_URL = `https://admin.api.360realize.com/learningpath/list`;
const GET_LEARNINGPATH_URL = `https://admin.api.360realize.com/learningpath/get`;
const CREATE_LEARNINGPATH_URL = `https://admin.api.360realize.com/learningpath/create`;
const UPDATE_LEARNINGPATH_PRICE_URL = `https://admin.api.360realize.com/learningpath/UpdateLearningPathPrice`;
const UPDATE_LEARNINGPATH_PROMO_VIDEO = `https://admin.api.360realize.com/learningpath/UpdateLearningPathPromoVideo`;
const UPLOAD_LEARNINGPATH_IMAGE_URL = `https://admin.api.360realize.com/learningpath/UploadLearningPathImage`;
const UPLOAD_LEARNINGPATH_COVER_URL = `https://admin.api.360realize.com/learningpath/UploadLearningPathCover`;
const UPDATE_LEARNINGPATH_INFO_URL = `https://admin.api.360realize.com/learningpath/updateLearningPathinfoitem`;
const DELETE_LEARNINGPATH_INFO_URL = `https://admin.api.360realize.com/learningpath/removeLearningPathinfoitem`;
const GET_LEARNINGPATH_AUDIT_EVENTS_URL = `https://admin.api.360realize.com/learningpath/ListAuditEvants`;
const GET_LEARNINGPATH_AVAILABLE_TRANSITIONS_URL = `https://admin.api.360realize.com/learningpath/ListLearningPathTransitions`;
const LEARNINGPATH_DO_TRANSITION_URL = `https://admin.api.360realize.com/learningpath/UpdateLearningPathTransition`;
const LIST_COURSES_URL = `https://admin.api.360realize.com/courses/listallcourses`;
const LIST_EXAMS_URL = `https://admin.api.360realize.com/courses/ListAllExams`;
const SAVE_LEARNINGPATH_COURSES_URL = `https://admin.api.360realize.com/learningpath/course/add`;
const LearningPathService = {
  list: data => {
    return http.post(
      LIST_LEARNINGPATHS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  listCategories: filters => {
    const url = LIST_CATEGORIES_URL;
    return http.post(url, filters, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  get: id => {
    const url = `${GET_LEARNINGPATH_URL}?id=${id}`;
    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  create: data => {
    const url = CREATE_LEARNINGPATH_URL;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  updatePrice: data => {
    const url = UPDATE_LEARNINGPATH_PRICE_URL;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  updateLearningPathPromoVideo: data => {
    const url = `${UPDATE_LEARNINGPATH_PROMO_VIDEO}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  uploadLearningPathImage: data => {
    const url = `${UPLOAD_LEARNINGPATH_IMAGE_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  uploadLearningPathCover: data => {
    const url = `${UPLOAD_LEARNINGPATH_COVER_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  },
  updateInfo: data => {
    const url = `${UPDATE_LEARNINGPATH_INFO_URL}`;

    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  deleteInfoItem: data => {
    const url = `${DELETE_LEARNINGPATH_INFO_URL}`;

    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  getLearningPathAuditEvents: id => {
    const url = `${GET_LEARNINGPATH_AUDIT_EVENTS_URL}?id=${id}`;
    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  getLearningPathAvailableTransitions: id => {
    const url = `${GET_LEARNINGPATH_AVAILABLE_TRANSITIONS_URL}?id=${id}`;
    return http.get(url, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  doTransition: data => {
    const url = `${LEARNINGPATH_DO_TRANSITION_URL}`;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  listCourses: filters => {
    const url = LIST_COURSES_URL;
    return http.post(url, filters, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  listExams: filters => {
    const url = LIST_EXAMS_URL;
    return http.post(url, filters, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  saveCourses: data => {
    const url = SAVE_LEARNINGPATH_COURSES_URL;
    return http.post(url, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  }
};

export default LearningPathService;
