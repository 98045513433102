import React from 'react';
import { Button, Card, Col, Form, Row, Spinner } from 'react-bootstrap';

import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import ConfirmModal from 'components/common/ConfirmModal';
import { useContext } from 'react';
import { ExamContext } from 'context/Context';
import { toExamFormSchema } from '../utils';
import ProjectService from 'http/ProjectService';

const initProject = {
  name: null,
  providerLink:null,
  photographedBy:null,
  description:null,
  youtube:null,
  facebook:null,
  instagram:null,
  x:null,
  linkedin:null,
  subtitle:null,
  mapLocation:null,
  website:null
};

const optionalFields = [
  "modifiedAt",
  "modifiedBy",
  "image1",
  "image2",
  "image3",
  "image4",
  "dealEnd",
  "dealStart",
  "isActive"
];

const ProjectBasicForm = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const currentLanguage = i18n.language;
  const { cities } = useContext(ExamContext);
  const { exam, examLoading, editCard, setEditCard, fetchExam } = useContext(ExamContext);
  const [companyValues, setCompanyValues] = useState(null);
  const [formKey, setFormKey] = useState(Date.now()); // Create a state variable to force re-render
  const [errors, setErrors] = useState(null);
  const [confirmModalProps, setConfirmModalProps] = useState(null);
  
  const { register, handleSubmit, setValue, control, reset, getValues } =
  useForm({
    defaultValues: null
  });

  useEffect(() => {
    if (exam?.dealId) {
      fillInitialData(exam || null);
    } else {
      fillInitialData(initProject);
    }
  }, [exam]);



  const {
    mutate: createExam,
    error: examCreateError,
    isLoading: examCreateLoading
  } = useMutation({
    mutationFn: ProjectService.update,
    onSuccess: data => onCreateExamSuccess(data)
  });
  const {
    mutate: updateProject,
    error: planUpdateError,
    isLoading: planUpdateLoading
  } = useMutation({
    mutationFn: ProjectService.update,
    onSuccess: data => onUpdateProjectSuccess(data)
  });

  const fillInitialData = data => {
    if (!data) {
      return;
    }
    const formattedData = toExamFormSchema(data);
    Object.entries(formattedData).map(([key, value]) => {
      setValue(key, value);
    });
    setCompanyValues(formattedData);
    setFormKey(Date.now());
  };

  const onSubmit = () => {
    //console.log('Submitted Values:', getValues());
    //console.log('planValues before validation:', planValues);
    const isValid = validateForm(companyValues);
    if (!isValid) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    const values = { ...companyValues };
    let result = {};
    Object.entries(values).map(([key, value]) => {
      const isValid = !!value;
      result[key] = isValid;
    });
    let createPayload = {
      name:values.name ,
      providerLink:values.providerLink,
      photographedBy:values.photographedBy,
      description:values.description,
      youtube:values.youtube,
      facebook:values.facebook,
      instagram:values.instagram,
      x:values.x,
      linkedin:values.linkedin,
      subtitle:values.subtitle,
      mapLocation:values.mapLocation,
      website:values.website
    };

    if (exam?.dealId) {
      createPayload['id'] = exam?.dealId;
      updateProject(createPayload);
    }else{
      createExam(createPayload);
    }
  };

  const onCreateExamSuccess = response => {
    toast.success(
      t(`common:message.${!exam?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
    if (typeof response?.data === 'string' && !exam?.id) {
      navigate(`/companies/create/${response.data}`);
    } else {
      setEditCard(null);
      fetchExam(exam.id);
    }
  };
  const onUpdateProjectSuccess = response => {
    toast.success(
      t(`common:message.${!exam?.id ? 'createSuccess' : 'updateSuccess'}`),
      { theme: 'colored' }
    );
    if (typeof response?.data === 'string' && !exam?.id) {
      navigate(`/projects/create/${response.data}`);
      setEditCard(null);
    } else {
      setEditCard(null);
      fetchExam(exam.id);
    }
  };

  const validateForm = values => {
    let valid = true;
    let errors = {};
    if (!values) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return false;
    }
    Object.entries(values).map(([key, value]) => {
      if (key === 'planNameEn') {
        if (value?.length > 60) {
          errors['planNameEn'] = {
            error: true,
            message: t('course:validation.message.maxAllowedCharacters', {
              count: 60
            })
          };
        }
      }
      if (!value && !optionalFields.includes(key)) {
        errors[key] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
        valid = false;
      }
    });

    setErrors(errors);
    //console.log('onError', errors);
    return valid;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    const dataType =
      e.target.getAttribute && e?.target?.getAttribute('data-type');
    if (dataType === 'number') {
      if (!!value && isNaN(value)) {
        return;
      }
    }

    if (!!value) {
      setErrors({ ...errors, [name]: { error: false, message: '' } });
    }
    setCompanyValues({ ...companyValues, [name]: value });
  };

  const onCancel = () => {
    if (editCard === 'basicInfo') {
      fillInitialData(exam);
      setEditCard(null);
      return;
    }

    const hasChanges =
      JSON.stringify({ ...toExamFormSchema(exam) }) !==
      JSON.stringify({ ...companyValues });
    if (hasChanges) {
      setConfirmModalProps({
        isOpen: true,
        onClose: () => setConfirmModalProps(null),
        onConfirm: () => navigate('/companies'),
        message: t('companies:message.discardExamConfirm'),
        header: t('companies:message.discardExamTitle'),
        actionButtonColor: 'danger',
        actionButtonText: t('common:button.discard')
      });
      return;
    }
    navigate('/contents/companies');
  };

  useEffect(() => {
    if (examCreateError) {
      toast.error(getErrorMessage(t, examCreateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [examCreateError]);

  if (examLoading) {
    return <LoaderWithMessage message={t('plans:message.loadingCompany')} />;
  }
  useEffect(() => {
    if (planUpdateError) {
      toast.error(getErrorMessage(t, planUpdateError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [planUpdateError]);

  if (examLoading) {
    return <LoaderWithMessage message={t('companies:message.loadingCompany')} />;
  }
  return (
    <Card className="mb-3">
      <Card.Header as="h5">
        {!!id ? exam?.title?.[currentLanguage] : t('companies:labels.newCompany')}
      </Card.Header>
      <Card.Body className="bg-light">
        <Row className="gx-2 gy-3" key={formKey}>
          <Col md="6">
            <Form.Group controlId="projectNameEn">
              <Form.Label>
                {`${t('projects:labels.name')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="name"
                required
                placeholder={t('projects:labels.name')}
                onChange={handleChange}
                value={companyValues?.name || ''}
                disabled={
                  examCreateLoading ||
                  examLoading ||
                  planUpdateLoading||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.name?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.name?.error && errors?.name?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="photographedBy">
              <Form.Label>
                {`${t('projects:labels.photographedBy')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="photographedBy"
                required
                placeholder={t('projects:labels.photographedBy')}
                onChange={handleChange}
                value={companyValues?.photographedBy || ''}
                disabled={
                  examCreateLoading ||
                  examLoading ||
                  planUpdateLoading||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.photographedBy?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.photographedBy?.error && errors?.photographedBy?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="ProjectproviderLink">
              <Form.Label>
                {`${t('projects:labels.providerLink')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="providerLink"
                required
                placeholder={t('projects:labels.providerLink')}
                onChange={handleChange}
                value={companyValues?.providerLink || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.providerLink?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.providerLink?.error &&
                  errors?.providerLink?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="ProjectMaplocation">
              <Form.Label>
                {`${t('projects:labels.mapLocation')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="mapLocation"
                required
                placeholder={t('projects:labels.mapLocation')}
                onChange={handleChange}
                value={companyValues?.mapLocation || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.mapLocation?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.mapLocation?.error &&
                  errors?.mapLocation?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="ProjectDescription">
              <Form.Label>
                {`${t('projects:labels.description')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="description"
                required
                placeholder={t('projects:labels.description')}
                onChange={handleChange}
                value={companyValues?.description || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.description?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.description?.error &&
                  errors?.description?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="ProjectYoutube">
              <Form.Label>
                {`${t('projects:labels.youtube')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="youtube"
                required
                placeholder={t('projects:labels.youtube')}
                onChange={handleChange}
                value={companyValues?.youtube || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.youtube?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.youtube?.error &&
                  errors?.youtube?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="ProjectFacebook">
              <Form.Label>
                {`${t('projects:labels.facebook')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="facebook"
                required
                placeholder={t('projects:labels.facebook')}
                onChange={handleChange}
                value={companyValues?.facebook || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.facebook?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.facebook?.error &&
                  errors?.facebook?.message}
              </Form.Text>
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group controlId="ProjectInstagram">
              <Form.Label>
                {`${t('projects:labels.instagram')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="instagram"
                required
                placeholder={t('projects:labels.instagram')}
                onChange={handleChange}
                value={companyValues?.instagram || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.instagram?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.instagram?.error &&
                  errors?.instagram?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="ProjectInstagram">
              <Form.Label>
                {`${t('projects:labels.website')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="website"
                required
                placeholder={t('projects:labels.website')}
                onChange={handleChange}
                value={companyValues?.website || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.website?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.website?.error &&
                  errors?.website?.message}
              </Form.Text>
            </Form.Group>
          </Col>

          <Col md="6">
            <Form.Group controlId="ProjectInstagram">
              <Form.Label>
                {`${t('projects:labels.x')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="x"
                required
                placeholder={t('projects:labels.x')}
                onChange={handleChange}
                value={companyValues?.x || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.x?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.x?.error &&
                  errors?.x?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="ProjectLinkedin">
              <Form.Label>
                {`${t('projects:labels.linkedin')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="linkedin"
                required
                placeholder={t('projects:labels.linkedin')}
                onChange={handleChange}
                value={companyValues?.linkedin || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.linkedin?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.linkedin?.error &&
                  errors?.linkedin?.message}
              </Form.Text>
            </Form.Group>
          </Col>
          <Col md="6">
            <Form.Group controlId="ProjectSubtitle">
              <Form.Label>
                {`${t('projects:labels.subtitle')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 60 })})`}
                </small>
              </Form.Label>
              <Form.Control
                type="text"
                name="subtitle"
                required
                placeholder={t('projects:labels.subtitle')}
                onChange={handleChange}
                value={companyValues?.subtitle || ''}
                disabled={
                  examCreateLoading ||
                  planUpdateLoading||
                  examLoading ||
                  (!!id && editCard !== 'basicInfo')
                }
                isInvalid={!!errors?.subtitle?.error}
              />
              <Form.Text className="text-danger">
                {!!errors?.subtitle?.error &&
                  errors?.subtitle?.message}
              </Form.Text>
            </Form.Group>
          </Col> 
        </Row>
      </Card.Body>

      <Card.Footer>
        {!id && (
          <>
            <Button size="sm" onClick={onSubmit}>
              {examCreateLoading && <Spinner size="sm" />}
              {!examCreateLoading && t('common:button.save')}
            </Button>
            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
            >
              {!examCreateLoading && t('common:button.cancel')}
            </Button>
          </>
        )}
        {editCard === 'basicInfo' && !!id && (
          <>
            <Button
              size="sm"
              onClick={onSubmit}
              disabled={!!examCreateLoading || examLoading}
            >
              {examCreateLoading && <Spinner size="sm" />}
              {!examCreateLoading && t('common:button.save')}
            </Button>

            <Button
              size="sm"
              variant="danger"
              onClick={onCancel}
              className="mx-2"
              disabled={!!examCreateLoading || examLoading}
            >
              {t('common:button.cancel')}
            </Button>
          </>
        )}
        {editCard !== 'basicInfo' && !!id && (
          <>
            <Button
              size="sm"
              variant="info"
              onClick={() => setEditCard('basicInfo')}
              disabled={!!examCreateLoading || examLoading}
            >
              {t('common:button.edit')}
            </Button>
          </>
        )}
      </Card.Footer>
      {!!confirmModalProps && (
        <ConfirmModal {...confirmModalProps} loading={false} />
      )}
    </Card>
  );
};
export default ProjectBasicForm;
