import { getErrorMessage } from 'http/utils';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { Card } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import BlogsService from 'http/BlogsService';
import LoaderWithMessage from 'components/common/LoaderWithMessage';
import Flex from 'components/common/Flex';
import BlogCourseCard from './components/BlogCourseCard';

const PreviewBlog = () => {
  const { id } = useParams();
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [blog, setBlog] = useState(null);

  const {
    isLoading: blogLoading,
    error: blogError,
    data: blogData,
    refetch: fetchBlog
  } = useQuery(['BlogPreview', id], () => BlogsService.get(id), {
    retry: false,
    manual: true, // Prevents automatic refetching
    refetchOnWindowFocus: false,
    enabled: false
  });

  useEffect(() => {
    if (id) {
      fetchBlog(id);
    }
  }, [id]);

  useEffect(() => {
    if (blogData && !blogLoading && !blogError) {
      setBlog(blogData?.payload || null);
    }
    if (blogError) {
      toast.error(getErrorMessage(t, blogError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [blogLoading, blogError, blogData]);

  if (blogLoading) {
    return <LoaderWithMessage message={t('blogs:message.loadingBlog')} />;
  }
  if (!blog?.content?.ar || !blog?.content?.en) {
    return '';
  }

  return (
    <Card>
      <Card.Body className="bg-light">
        <h2 className="my-3">{blog?.title?.[currentLanguage]}</h2>
        <h4 className="mb-5">{blog?.subtitle?.[currentLanguage]}</h4>
        <div
          dangerouslySetInnerHTML={{
            __html: blog?.content?.[currentLanguage] || ''
          }}
        ></div>
        {!!blog?.references?.length && (
          <Flex
            className="mt-3"
            wrap="wrap"
            justifyContent="center"
            alignItems="start"
            direction="column"
          >
            <h3 className="my-3">{t('blogs:labels.references')}</h3>
            <ul>
              {blog?.references?.map(r => {
                return (
                  <li key={r?.link}>
                    <a href={r.link} target="_blank" rel="noreferrer">
                      {r?.title?.[currentLanguage]}
                    </a>
                  </li>
                );
              })}
            </ul>
          </Flex>
        )}
        {!!blog?.recommendedCourses?.length && (
          <>
            <h3 className="my-3">{t('blogs:labels.recommendedCourses')}</h3>
            <Flex
              className="mt-3"
              wrap="wrap"
              justifyContent="start"
              alignItems="center"
            >
              {blog?.recommendedCourses?.map((course, index) => {
                return (
                  <BlogCourseCard
                    key={`blog-recommended-course-${index}`}
                    course={course}
                    isReadOnly
                  />
                );
              })}
            </Flex>
          </>
        )}
      </Card.Body>
    </Card>
  );
};

export default PreviewBlog;
