import CourseService from 'http/CourseService';
import FileService from 'http/FileService';
import { getErrorMessage } from 'http/utils';
import React, { useEffect, useState } from 'react';
import {
  Button,
  Col,
  Form,
  Modal,
  Row,
  Spinner,
  Tab,
  Tabs,
  Image
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { countries } from 'data/countries';
import SelectComponent from 'components/common/SelectComponent';
import TinymceEditor from 'components/common/TinymceEditor';
import Avatar from 'components/common/Avatar';
import defaultAvatar from 'assets/img/team/avatar.png';
import { useDropzone } from 'react-dropzone';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import Flex from 'components/common/Flex';

const requiredFields = ['name', 'heading', 'image', 'signature'];

const initForm = {
  name: null,
  heading: null,
  image: null,
  signature: null
};

const InstructorModal = ({ isOpen, onClose, refetchInstructors }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [formData, setFormData] = useState(initForm);
  const [errors, setErrors] = useState(null);
  const [signatureHovered, setSignatureHovered] = useState(null);
  const allCountries = countries.map(c => ({
    label: c[currentLanguage],
    value: c.en.toLowerCase().replaceAll(' ', '').trim()
  }));
  const { mutate: uploadImageTempFile, error: uploadImageTempFileError } =
    useMutation({
      mutationFn: FileService.uploadTempFile,
      onSuccess: data => onUploadImageTempFileSuccess(data)
    });

  const {
    mutate: uploadTempFile,
    error: uploadTempFileError,
    isLoading: uploadTempFileLoading
  } = useMutation({
    mutationFn: FileService.uploadTempFile,
    onSuccess: data => onUploadTempFileSuccess(data)
  });

  const {
    mutate: saveInstructor,
    error: saveInstructorError,
    isLoading: saveInstructorLoading
  } = useMutation({
    mutationFn: CourseService.saveInstructor,
    onSuccess: data => onSaveInstructorSuccess(data)
  });

  const { handleSubmit, register, setValue } = useForm({
    defaultValues: null
  });

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    accept: 'image/*',
    onDrop: async acceptedFiles => {
      uploadTempFile({ file: acceptedFiles[0] });
    }
  });

  const onUploadTempFileSuccess = response => {
    setValue('signature', response.payload);
    setFormData({
      ...formData,
      signature: `${response.payload}`
    });
    toast.success(t(`common:message.uploadSuccess`), { theme: 'colored' });
  };

  const onUploadImageTempFileSuccess = response => {
    setValue('image', response.payload);
    setFormData({
      ...formData,
      image: `${response.payload}`
    });
    toast.success(t(`common:message.uploadSuccess`), { theme: 'colored' });
  };

  const onSaveInstructorSuccess = () => {
    refetchInstructors && refetchInstructors();
    setErrors(null);
    setFormData({});
    onClose();
    toast.success(t(`common:message.uploadSuccess`), { theme: 'colored' });
  };

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleUploadImage = event => {
    const file = event?.target?.files?.[0];
    if (!file) {
      return;
    }
    uploadImageTempFile({ file });
  };

  const onSubmit = () => {
    setErrors(null);
    let formPayload = {
      ...formData,
      bioEn: null,
      bioAr: null,
      image: null,
      imageUrl: formData.image,
      bio:
        formData?.bioAr && formData?.bioEn
          ? { ar: formData?.bioAr, en: formData?.bioEn }
          : null
    };
    saveInstructor(formPayload);
  };

  const onError = () => {
    let errorsObj = {};
    if (!Object.entries(formData)?.length) {
      requiredFields.map(key => {
        errorsObj[key] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
      });
      setErrors(errorsObj);
      return;
    }
    //console.log('formData', formData);
    Object.entries(formData).map(([key, value]) => {
      if ((requiredFields?.includes(key) && !value) || !key) {
        errorsObj[key] = {
          error: true,
          message: t('common:validation.fieldIsRequired')
        };
      } else {
        if (key === 'name' && value.length > 70) {
          errorsObj[key] = {
            error: true,
            message: t('course:validation.message.maxAllowedCharacters', {
              count: 70
            })
          };
        } else if (key === 'heading' && value.length > 250) {
          errorsObj[key] = {
            error: true,
            message: t('course:validation.message.maxAllowedCharacters', {
              count: 250
            })
          };
        } else {
          errorsObj[key] = null;
        }
      }
    });
    //console.log('errorsObj', errorsObj);
    setErrors(errorsObj);
  };

  const handleCancel = () => {
    setFormData(initForm);
    setErrors(null);
    onClose();
  };

  useEffect(() => {
    if (uploadTempFileError) {
      toast.error(getErrorMessage(t, uploadTempFileError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (uploadImageTempFileError) {
      toast.error(getErrorMessage(t, uploadImageTempFileError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (saveInstructorError) {
      toast.error(getErrorMessage(t, saveInstructorError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [uploadTempFileError, uploadImageTempFileError, saveInstructorError]);

  return (
    <Modal
      show={isOpen}
      size="xl"
      aria-labelledby="contained-modal-title-quiz"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-quiz">
          {t('course:labels.createInstructor')}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form.Group controlId="newInstructor" className="position-relative">
          <Row className="w-100 gx-0 gy-1">
            <Col md={12} className="mt-1 g-1 text-center">
              <Avatar
                {...register('image', { required: true })}
                ref={undefined}
                size="4xl"
                src={
                  formData?.image
                    ? `${process.env.REACT_APP_FILES_URL}${formData?.image}`
                    : defaultAvatar
                }
                loading={saveInstructorLoading || uploadTempFileLoading}
                enableUpload
                onUpload={file => handleUploadImage(file)}
                overlayClassName="no-border"
                mediaClass={`${errors?.image?.error ? 'border-danger' : ''}`}
              />
            </Col>
            <Form.Text className="text-danger w-100 text-center">
              {!!errors?.image?.error && t('common:validation.imageIsRequired')}
            </Form.Text>
          </Row>
          <Row className="w-100 gx-0 gy-1">
            <Col md={12} className="mt-1 g-1">
              <Form.Label>
                {`${t('course:form.name')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 70 })})`}
                </small>
              </Form.Label>
              <Form.Control
                {...register('name', { required: true, maxLength: 70 })}
                type="text"
                name="name"
                required
                value={formData?.name || ''}
                className="pe-4"
                onChange={e => handleChange(e)}
                placeholder={t('course:form.name')}
                isInvalid={!!errors?.name?.error}
                disabled={saveInstructorLoading || uploadTempFileLoading}
              />
              <Form.Text className="text-danger">
                {!!errors?.name?.error && errors?.name?.message}
              </Form.Text>
            </Col>
          </Row>
          <Row className="w-100 gx-0 gy-1 mt-1">
            <Col md={12} className="mt-1 g-1">
              <Form.Label>
                {`${t('course:form.heading')}`}
                <span className="text-danger">*</span>
                <small>
                  {`(${t('common:labels.maxCharacters', { count: 250 })})`}
                </small>
              </Form.Label>
              <Form.Control
                {...register('heading', { required: true, maxLength: 250 })}
                type="text"
                name="heading"
                required
                value={formData?.heading || ''}
                className="pe-4"
                onChange={e => handleChange(e)}
                placeholder={t('course:form.heading')}
                isInvalid={!!errors?.heading?.error}
                disabled={saveInstructorLoading || uploadTempFileLoading}
              />
              <Form.Text className="text-danger">
                {!!errors?.heading?.error && errors?.heading?.message}
              </Form.Text>
            </Col>
          </Row>
          <Row className="w-100 gx-0 gy-1 mt-1">
            <Col md={12} className="mt-1 g-1">
              <Form.Label>{`${t('course:form.country')}`}</Form.Label>
              <SelectComponent
                options={allCountries}
                placeholder={t('course:form.country')}
                classNamePrefix="react-select"
                value={
                  formData?.country
                    ? allCountries.find(c => c.value === formData?.country)
                    : null
                }
                onChange={option =>
                  handleChange({
                    target: {
                      value: option.value,
                      name: 'country'
                    }
                  })
                }
                getOptionLabel={option => option.label}
                getOptionValue={option => option.value}
                isDisabled={saveInstructorLoading || uploadTempFileLoading}
              />
            </Col>
          </Row>
          <Row className="w-100 gx-0 gy-1 mt-1">
            <Col md={12} className="mt-1 g-1">
              <Form.Label>{`${t('course:form.address')}`}</Form.Label>
              <Form.Control
                type="text"
                name="address"
                required
                value={formData?.address || ''}
                className="pe-4"
                onChange={e => handleChange(e)}
                placeholder={t('course:form.address')}
                disabled={saveInstructorLoading || uploadTempFileLoading}
              />
            </Col>
          </Row>
          <Row className="w-100 gx-0 gy-1 mt-1">
            <Col md={12} className="mt-1 g-1">
              <Form.Label>{`${t('course:form.bio')}`}</Form.Label>
              <Tabs
                defaultActiveKey={`bio-${currentLanguage}`}
                id="uncontrolled-tab-bio"
              >
                <Tab
                  eventKey="bio-en"
                  title={t('common:language.en.name')}
                  className="border-bottom border-x"
                >
                  <div className={`tinymce-editor-container`}>
                    <TinymceEditor
                      height="13.438rem"
                      name="bioEn"
                      handleChange={newValue =>
                        handleChange({
                          target: { value: newValue, name: 'bioEn' }
                        })
                      }
                      value={formData?.bioEn || ''}
                      placeholder={t('course:form.bio')}
                    />
                  </div>
                </Tab>
                <Tab
                  eventKey="bio-ar"
                  title={t('common:language.ar.name')}
                  className="border-bottom border-x"
                >
                  <div className={`tinymce-editor-container`}>
                    <TinymceEditor
                      height="13.438rem"
                      name="courseLearn"
                      handleChange={newValue =>
                        handleChange({
                          target: { value: newValue, name: 'bioAr' }
                        })
                      }
                      value={formData?.bioAr || ''}
                      placeholder={t('course:form.bio')}
                    />
                  </div>
                </Tab>
              </Tabs>
            </Col>
          </Row>
          <Row className="w-100 gx-0 gy-1 mt-1">
            <Col md={12} className="mt-1 g-1">
              <Form.Label>
                {`${t('course:form.signature')}`}{' '}
                <span className="text-danger">*</span>
              </Form.Label>
              {!formData?.signature && (
                <div
                  {...getRootProps({
                    className: `dropzone-area h-75 py-6 ${
                      errors?.signature?.error ? 'border-danger' : ''
                    }`
                  })}
                >
                  <input
                    {...register('signature', { required: true })}
                    {...getInputProps({ multiple: false })}
                  />
                  <div className="fs--1">
                    <img src={cloudUpload} alt="" width={20} className="me-2" />
                    <span className="d-none d-lg-inline">
                      {t('common:labels.dragYourImageHere')}
                      <br />
                      {t('common:labels.or')},{' '}
                    </span>
                    <Button variant="link" size="sm" className="p-0 fs--1">
                      {t('common:labels.browse')}
                    </Button>
                  </div>
                  <Form.Text className="text-danger">
                    {!!errors?.signature?.error &&
                      t('common:validation.imageIsRequired')}
                  </Form.Text>
                </div>
              )}
              {!!formData?.signature && (
                <Flex
                  className="position-relative"
                  style={{ zIndex: 9 }}
                  onMouseEnter={() => setSignatureHovered(true)}
                  onMouseLeave={() => setSignatureHovered(false)}
                >
                  <Image
                    rounded
                    width="200px"
                    height="200px"
                    src={`${process.env.REACT_APP_FILES_URL}${formData?.signature}`}
                    alt="Instructor Signature"
                  />
                  {(signatureHovered ||
                    saveInstructorLoading ||
                    uploadTempFileLoading) && (
                    <Flex
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      className="position-absolute w-100 h-100 cursor-pointer"
                      style={{
                        left: 0,
                        right: 0,
                        top: 0,
                        zIndex: 10,
                        background: '#36454F',
                        opacity: 0.75
                      }}
                    >
                      {(saveInstructorLoading || uploadTempFileLoading) && (
                        <Spinner />
                      )}
                      {!(saveInstructorLoading || uploadTempFileLoading) && (
                        <Button
                          onClick={() => {
                            inputRef?.current?.click();
                          }}
                          variant="primary"
                        >
                          {t('common:labels.replace')}
                        </Button>
                      )}
                    </Flex>
                  )}
                </Flex>
              )}
            </Col>
          </Row>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={saveInstructorLoading || uploadTempFileLoading}
          onClick={handleSubmit(onSubmit, onError)}
          className="mt-1"
        >
          {saveInstructorLoading && <Spinner size="sm" />}
          {!saveInstructorLoading && t('common:button.save')}
        </Button>
        <Button
          onClick={handleCancel}
          variant="secondary"
          disabled={saveInstructorLoading || uploadTempFileLoading}
        >
          {t('common:button.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default InstructorModal;
