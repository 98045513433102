import React from 'react';
import ProjectBasicForm from './components/ProjectBasicForm';
import { Col, Row } from 'react-bootstrap';
import { useContext } from 'react';
import { ExamContext } from 'context/Context';
import { useMutation } from 'react-query';
import ImageCard from 'components/common/ImageCard';
import { useTranslation } from 'react-i18next';
import ProjectService from 'http/ProjectService';
import { toast } from 'react-toastify';



const CreateProject = () => {
  const { t } = useTranslation();
  const { exam, examLoading, editCard, setEditCard, fetchExam } = useContext(ExamContext);

  const {
    mutate: updateBlogImage,
    error: updateBlogImageError,
    isLoading: updateBlogImageLoading
  } = useMutation({
    mutationFn: ProjectService.uploadImage,
    onSuccess: data => onUpdateProjectImageSuccess(data)
  });
  const handleBlogImageChange = (file, imageNumber) => {
    let data = new FormData();
    data.append('id', exam.dealId);
    data.append('ImageNumber', imageNumber)
    data.append('file', file, file.name);
    updateBlogImage(data);
  };
  const onUpdateProjectImageSuccess = () => {
    fetchExam(exam.dealId);
    toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
  };

  return (
    <Row className="g-lg-3 font-sans-serif">
      <Col lg={3}>
        <ImageCard
          onFileChange={file => handleBlogImageChange(file, 1)}
          url={exam?.image1 || null}
          title={t('projects:labels.image1')}
          tooltip={t('projects:labels.image1')}
          loading={updateBlogImageLoading}
          baseUrl={'http://360realize.com/content'}
        // isReadOnly={isReadOnly}
        />
      </Col>
      <Col lg={3}>
        <ImageCard
          onFileChange={file => handleBlogImageChange(file, 2)}
          url={exam?.image2 || null}
          title={t('projects:labels.image2')}
          tooltip={t('projects:labels.image2')}
          loading={updateBlogImageLoading}
          baseUrl={'http://360realize.com/content'}
        // isReadOnly={isReadOnly}
        />
      </Col>
      <Col lg={3}>
        <ImageCard
          onFileChange={file => handleBlogImageChange(file, 3)}
          url={exam?.image3 || null}
          title={t('projects:labels.image3')}
          tooltip={t('projects:labels.image3')}
          loading={updateBlogImageLoading}
          baseUrl={'http://360realize.com/content'}
        // isReadOnly={isReadOnly}
        />
      </Col>
      <Col lg={3}>
        <ImageCard
          onFileChange={file => handleBlogImageChange(file, 4)}
          url={exam?.image4 || null}
          title={t('projects:labels.image4')}
          tooltip={t('projects:labels.image4')}
          loading={updateBlogImageLoading}
          baseUrl={'http://360realize.com/content'}
        // isReadOnly={isReadOnly}
        />
      </Col>
      <Col lg={12}>
        <ProjectBasicForm />
      </Col>
    </Row>

  );
};
export default CreateProject;
