import React, { useContext, useState } from 'react';
import { Button, Card, Form, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { countries } from 'data/countries';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons';
import SelectComponent from 'components/common/SelectComponent';
import AppContext from 'context/Context';

const UserListFilters = ({ roles, oldFilters, onSubmit, setShow }) => {
  const [filters, setFilters] = useState({ ...oldFilters });
  const { t, i18n } = useTranslation();
  const currentLang = i18n.language;
  const {
    config: { isRTL }
  } = useContext(AppContext);
  const countriesOptions = countries.map(c => ({
    label: c[currentLang],
    value: c.en.toLowerCase().replaceAll(' ', '').trim()
  }));
  const handleFilter = (name, value) => {
    //console.log('handleFilter', { name, value });
    setFilters({ ...(filters || {}), [name]: value });
  };

  const rolesOptions = roles?.map(r => ({
    value: r.id,
    label: t(`common:roles.${r.name.toLowerCase().trim()}`)
  }));

  const statuses = [
    { label: t(`common:status.active`), value: 'active' },
    { label: t(`common:status.inactive`), value: 'inactive' },
    { label: t(`common:status.pending`), value: 'pending' }
  ];

  return (
    <Card className="shadow-none shadow-show-xl scrollbar overflow-visible">
      <Card.Header className="bg-light d-none d-xl-flex align-items-center justify-content-between w-100">
        <h6 className="mb-0">{t('common:labels.filters')}</h6>
        <OverlayTrigger
          placement={isRTL ? 'right' : 'left'}
          overlay={
            <Tooltip id="user-filter-rest-tooltip">
              {t('common:tooltips.resetFilters')}
            </Tooltip>
          }
        >
          <span>
            <Button
              size="sm"
              variant="light"
              onClick={() => {
                setFilters(null);
                onSubmit(null);
              }}
            >
              <FontAwesomeIcon icon={faSyncAlt} size="sm" />
            </Button>
          </span>
        </OverlayTrigger>
      </Card.Header>
      <Card.Body>
        <Form>
          <div className="mb-2 mt-n2">
            <Form.Label className="mb-1 fs--1">
              {t('user:labels.role')}
            </Form.Label>

            <SelectComponent
              options={rolesOptions}
              onChange={option => handleFilter('role', option?.value || null)}
              value={rolesOptions.find(c => c.value === filters?.role) || ''}
              placeholder={t('user:placeholders.rolePlaceholder')}
              isClearable
            />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs--1">
              {t('user:labels.status')}
            </Form.Label>
            <SelectComponent
              options={statuses}
              onChange={option => handleFilter('status', option?.value || null)}
              value={statuses.find(c => c.value === filters?.status) || ''}
              placeholder={t('user:placeholders.statusPlaceholder')}
              isClearable
            />
          </div>
          <div className="mb-2">
            <Form.Label className="mb-1 mt-2 fs--1">
              {t('user:labels.country')}
            </Form.Label>

            <SelectComponent
              options={countriesOptions}
              onChange={option =>
                handleFilter('country', option?.value || null)
              }
              value={
                countriesOptions.find(c => c.value === filters?.country) || ''
              }
              placeholder={t('user:placeholders.countryPlaceholder')}
              isClearable
            />
          </div>
        </Form>
      </Card.Body>
      <Card.Footer className="border-top border-200 py-x1">
        <Button
          varient="primary"
          className="w-100"
          onClick={() => {
            onSubmit({ ...filters });
            setShow(false);
          }}
        >
          {t('common:button.filter')}
        </Button>
      </Card.Footer>
    </Card>
  );
};

export default UserListFilters;
