import React from 'react';
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';
import Avatar from 'components/common/Avatar';
import { useTranslation } from 'react-i18next';
import { useContext } from 'react';
import { AuthContext } from 'context/Context';
import defaultAvatar from 'assets/img/team/avatar.png';
import { toApiFileUrl } from 'helpers/utils';

const ProfileDropdown = () => {
  const { user, logout } = useContext(AuthContext);
  const { t } = useTranslation();

  const onProfileClick = () => {
    if (window.location.pathname.startsWith('/user/profile')) {
      window.location.reload();
    }
  };

  return (
    <Dropdown navbar={true} as="li">
      <Dropdown.Toggle
        bsPrefix="toggle"
        as={Link}
        to="#!"
        className="pe-0 ps-2 nav-link"
      >
        <Avatar
          src={user?.avatarUrl ? toApiFileUrl(user?.avatarUrl) : defaultAvatar}
        />
      </Dropdown.Toggle>

      <Dropdown.Menu className="dropdown-caret dropdown-menu-card  dropdown-menu-end">
        <div className="bg-white rounded-2 py-2 dark__bg-1000">
          <Dropdown.Item
            as={Link}
            to="/user/profile/#"
            onClick={onProfileClick}
          >
            {t('user:labels.profile')}
          </Dropdown.Item>
          <Dropdown.Item as={Link} to="/user/settings">
            {t('user:labels.settings')}
          </Dropdown.Item>
          <Dropdown.Divider />
          <Dropdown.Item as={Link} to="#" onClick={logout}>
            {t('common:labels.logout')}
          </Dropdown.Item>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default ProfileDropdown;
