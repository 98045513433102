import React, { useState, useEffect } from 'react';
import ReactEChartsCore from 'echarts-for-react/lib/core';
import { BarChart } from 'echarts/charts';
import {
  DatasetComponent,
  GridComponent,
  LegendComponent,
  TitleComponent,
  TooltipComponent
} from 'echarts/components';
import * as echarts from 'echarts/core';
import { CanvasRenderer } from 'echarts/renderers';
import { Card, Form } from 'react-bootstrap';
import ShaiCardHeader from 'components/common/ShaiCardHeader';
import {
  constructDate,
  getCategoryColor,
  getColor,
  rgbaColor
} from 'helpers/utils';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import StatisticsService from 'http/StatisticsService';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import { tooltipFormatter } from 'helpers/echart-utils';
import moment from 'moment';
const initFilter = {
  type: 'daily',
  fromDate: constructDate({ date: new Date(), addDays: -7, iso: true }),
  toDate: new Date().toISOString()
};

echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  BarChart,
  CanvasRenderer,
  LegendComponent,
  DatasetComponent
]);

const getOption = (data, categories, currentLanguage, type) => {
  if (!data?.[0]) {
    return {};
  }
  const lang = currentLanguage;
  let dates = data.map(d => d.startDate);
  let series = categories.map(category => {
    const categoryData = data.map(d => {
      const value = d.items.find(t => t.categoryId === category.id);
      if (!value) {
        return {
          categoryId: category.id,
          title: category.title,
          number: 0
        };
      }
      return value;
    });
    const cat = categoryData.find(c => !!c.title);
    return {
      name: cat.title?.[currentLanguage],
      id: cat.categoryId,
      data: categoryData.map(c => c.number),
      type: 'line',
      showSymbol: false,
      symbol: 'circle',
      lineStyle: {
        color: getCategoryColor(
          categoryData[0].title?.[currentLanguage]
            .replace(' ', '')
            .toLowerCase()
        )
      },
      itemStyle: {
        borderColor: getCategoryColor(
          categoryData[0].title?.[currentLanguage]
            .replace(' ', '')
            .toLowerCase()
        ),
        borderWidth: 2,
        backgroundColor: 'transparent'
      },
      symbolSize: 2
    };
  });
  // console.log('dates', dates);
  // console.log('series', series);
  return {
    color: [
      getColor('primary'),
      rgbaColor(getColor('primary'), 0.8),
      rgbaColor(getColor('primary'), 0.6),
      rgbaColor(getColor('primary'), 0.4),
      rgbaColor(getColor('primary'), 0.2)
    ],
    legend: {
      data: series.map(s => s.name),
      left: 5,
      itemWidth: 10,
      itemHeight: 10,
      borderRadius: 0,
      icon: 'circle',
      inactiveColor: getColor('gray-400'),
      textStyle: { color: getColor('gray-700') },
      itemGap: 20
    },
    xAxis: {
      type: 'category',
      data: dates.map(date => moment(date).locale(lang).format('DD MMM, YYYY')),
      boundaryGap: false,
      silent: true,
      axisPointer: {
        lineStyle: {
          color: getColor('gray-300')
        }
      },
      splitLine: { show: false },
      axisLine: {
        lineStyle: {
          color: getColor('gray-300')
        }
      },
      axisTick: {
        show: true,
        length: 20,
        lineStyle: {
          color: getColor('gray-200')
        },

        interval: 5
      },
      axisLabel: {
        color: getColor('gray-600'),
        formatter: value => (type !== 'yearly' ? value : '.'),
        align: 'left',
        fontSize: 11,
        padding: [0, 0, 0, 5],
        interval: 5
      }
    },
    yAxis: {
      type: 'value',
      position: 'right',
      splitLine: {
        lineStyle: {
          color: getColor('gray-200')
        }
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      axisLabel: {
        show: true,
        color: getColor('gray-600'),
        margin: 15
      }
    },
    tooltip: {
      trigger: 'axis',
      padding: [7, 10],
      axisPointer: {
        type: 'none'
      },
      backgroundColor: getColor('gray-100'),
      borderColor: getColor('gray-300'),
      textStyle: { color: getColor('gray-700') },
      borderWidth: 1,
      transitionDuration: 0,
      formatter: tooltipFormatter
    },

    series,

    grid: {
      containLabel: true,
      right: '5px',
      left: 0,
      bottom: 0,
      top: '15%'
    }
  };
};

const CourseEnrollments = () => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n.language;
  const [enrollments, setEnrollments] = useState([]);
  const [filters, setFilters] = useState(initFilter);
  const [categories, setCategories] = useState([]);
  const [chartDays, setChartDays] = useState(7);

  const {
    //refetch: fetchUsersStats,
    isLoading: enrollmentsStatsLoading,
    data: enrollmentsStatsData,
    error: enrollmentsStatsError
  } = useQuery(
    ['EnrollmentsStats', filters],
    () => StatisticsService.enrollmentsOverPeriod(filters),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: true
    }
  );

  useEffect(() => {
    if (
      enrollmentsStatsData &&
      !enrollmentsStatsLoading &&
      !enrollmentsStatsError
    ) {
      if (enrollmentsStatsData?.errors?.length) {
        toast.error(getErrorMessage(t, enrollmentsStatsData?.errors), {
          theme: 'colored',
          autoClose: false
        });
      }
      if (!!enrollmentsStatsData?.payload) {
        setCategories(enrollmentsStatsData?.payload?.categoriesList);
        setEnrollments(enrollmentsStatsData?.payload?.coursePeriodList);
      }
    }

    if (enrollmentsStatsError) {
      toast.error(getErrorMessage(t, enrollmentsStatsError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [enrollmentsStatsLoading, enrollmentsStatsData, enrollmentsStatsError]);

  useEffect(() => {
    let newFilters = {
      ...filters
    };
    switch (chartDays) {
      case 7:
        newFilters = {
          toDate: new Date().toISOString(),
          type: 'daily',
          fromDate: constructDate({ date: new Date(), addDays: -7, iso: true })
        };
        break;
      case 30:
        newFilters = {
          toDate: new Date().toISOString(),
          type: 'daily',
          fromDate: constructDate({
            date: new Date(),
            addMonths: -1,
            iso: true
          })
        };
        break;
      case 365:
        newFilters = {
          toDate: new Date().toISOString(),
          type: 'monthly',
          fromDate: constructDate({ date: new Date(), addYears: -1, iso: true })
        };
        break;
      default:
        break;
    }
    //console.log('newFilters', newFilters);
    setFilters(newFilters);
  }, [chartDays]);

  return (
    <Card className="h-100">
      <ShaiCardHeader
        title={t('dashboard:labels.courseEnrollments')}
        titleTag="h6"
        className="py-2"
        light
        endEl={
          <Form.Select
            size="sm"
            className="me-2"
            onChange={e => setChartDays(Number(e.target.value))}
          >
            <option value={'7'}>{t('common:labels.last7Days')}</option>
            <option value={'30'}>{t('common:labels.lastMonth')}</option>
            <option value={'365'}>{t('common:labels.lastYear')}</option>
          </Form.Select>
        }
      />
      <Card.Body className="h-100 w-100">
        <ReactEChartsCore
          echarts={echarts}
          option={getOption(
            enrollments,
            categories,
            currentLanguage,
            filters?.type
          )}
          style={{ height: '21.875rem' }}
        />
      </Card.Body>
    </Card>
  );
};

export default CourseEnrollments;
