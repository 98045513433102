import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getStateIcon } from 'helpers/utils';
import ShaiCloseButton from 'components/common/ShaiCloseButton';
import { useMutation } from 'react-query';
import { toast } from 'react-toastify';
import { getErrorMessage } from 'http/utils';
import DiscountsService from 'http/DiscountsService';

const DiscountActions = ({ discount, refetch }) => {
  const { t } = useTranslation();
  const [confirmModalOpen, setConfirmModalOpen] = useState(false);
  const [newAction, setNewAction] = useState();

  const {
    mutate: updateStatus,
    error: updateStatusError,
    isLoading: updateStatusLoading
  } = useMutation({
    mutationFn: DiscountsService.updateStatus,
    onSuccess: data => onUpdateStatusSuccess(data)
  });

  const handleActionClick = action => {
    setNewAction(action);
    setConfirmModalOpen(true);
  };

  const handleUpdateStatus = action => {
    updateStatus({
      id: discount.id,
      status: action === 'activate' ? 'active' : 'draft'
    });
  };

  const onUpdateStatusSuccess = () => {
    refetch && refetch(discount.id);
    setConfirmModalOpen(false);
    toast.success(t(`common:message.updateSuccess`), {
      theme: 'colored'
    });
  };

  useEffect(() => {
    if (updateStatusError) {
      toast.error(getErrorMessage(t, updateStatusError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [updateStatusError]);

  return (
    <Card className="mb-3">
      <Card.Header as="h5">{t('discounts:labels.discountActions')}</Card.Header>
      <Card.Body className="bg-light">
        <ul className="list-unstyled fs--1 mb-0">
          <li className="hover-actions-trigger mb-2">
            <FontAwesomeIcon
              icon={getStateIcon(discount?.status?.code)}
              className="me-2"
            />
            <span className="ms-1">
              {`${t('discounts:labels.currentState')}`}:
            </span>
            <span className="text-800 fw-medium me-2">
              {` ${
                !!discount?.status?.code
                  ? t(`discounts:status.${discount?.status?.code}`)
                  : 'unknown'
              }`}
            </span>
          </li>
        </ul>
      </Card.Body>
      {discount?.status?.code?.toLowerCase() === 'draft' && (
        <Card.Footer>
          <Col xs="auto">
            <Button
              size="md"
              variant="primary"
              className="px-xxl-5 px-4"
              onClick={() => handleActionClick('activate')}
            >
              {t(`discounts:actions.activate`)}
            </Button>
          </Col>
        </Card.Footer>
      )}
      {discount?.status?.code?.toLowerCase() === 'active' && (
        <Card.Footer>
          <Col xs="auto">
            <Button
              size="md"
              variant="primary"
              className="px-xxl-5 px-4"
              onClick={() => handleActionClick('deactivate')}
            >
              {t(`discounts:actions.deactivate`)}
            </Button>
          </Col>
        </Card.Footer>
      )}
      <Modal
        show={confirmModalOpen}
        onHide={() => setConfirmModalOpen(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            {t('discounts:labels.actionModalHeader', {
              actionTitle: t(`discounts:actions.${newAction}`)
            })}
          </Modal.Title>
          <ShaiCloseButton
            className="btn btn-circle btn-sm transition-base p-0"
            onClick={() => setConfirmModalOpen(false)}
          />
        </Modal.Header>
        <Modal.Body>
          <Row>
            <p>
              {t('discounts:labels.actionConfirm', {
                action: t(`discounts:actions.${newAction}`)
              })}
            </p>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() => handleUpdateStatus(newAction)}
            variant={'primary'}
            disabled={updateStatusLoading}
          >
            {!updateStatusLoading && t(`discounts:actions.${newAction}`)}
            {updateStatusLoading && (
              <Spinner animation="border" role="status" size="sm" />
            )}
          </Button>
          <Button
            onClick={() => setConfirmModalOpen(null)}
            variant="secondary"
            disabled={updateStatusLoading}
          >
            {t('common:button.cancel')}
          </Button>
        </Modal.Footer>
      </Modal>
    </Card>
  );
};

export default DiscountActions;
