export const toExamFormSchema = project => {
  if (!project) {
    return null;
  }
  return {
    ...project,
    name: project.name,
    providerLink:project.providerLink,
    photographedBy:project.photographedBy,
    youtube:project.youtube,
    facebook:project.facebook,
    instagram:project.instagram,
    website:project.website,
    x:project.x,
    linkedin:project.linkedin,
    subtitle:project.subtitle,
    mapLocation:project.mapLocation
  };
};

export const canEditExam = user => {
  if (!user || !user?.roles?.length) {
    console.log('user missing roles', user);
    return false;
  }
  let roles = user.roles;
  const allowedRoles = ['admin', 'planner'];
  if (typeof roles === 'string') {
    roles = [roles];
  }
  const canEdit = !!roles
    .map(r => r.toLowerCase())
    .some(r => allowedRoles.includes(r));
  // console.log(
  //   'canEditCourse',
  //   `${canEdit} user is ${roles.map(r => r).join(',')}`
  // );
  return canEdit;
};
