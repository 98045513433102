import React from 'react';
import { useDropzone } from 'react-dropzone';
import cloudUpload from 'assets/img/icons/cloud-upload.svg';
import { useEffect } from 'react';
import Flex from './Flex';
import { useTranslation } from 'react-i18next';

const FileDropZone = ({
  multiple,
  files,
  onFilesChange,
  accept,
  filesTitle,
  dropPlaceholder,
  displayUploadedFiles,
  disabled,
  error
}) => {
  const { t } = useTranslation();

  const { acceptedFiles, getRootProps, getInputProps, isDragActive } =
    useDropzone();

  const uploadedFiles = [...(files || []), ...acceptedFiles].map(file => (
    <li key={file.path}>
      {`${file.path} - ${((file.size || 0) / (1024 * 1024)).toFixed(2)} ${t(
        'common:labels.mb'
      )}`}
    </li>
  ));

  useEffect(() => {
    onFilesChange(acceptedFiles);
  }, [acceptedFiles]);

  return (
    <>
      <div className="mt-3">
        {!isDragActive && !!acceptedFiles.length && displayUploadedFiles && (
          <>
            <h6>{filesTitle || t('common:labels.files')}</h6>
            <ul>{uploadedFiles}</ul>
          </>
        )}
      </div>
      <div
        {...getRootProps({ className: 'dropzone-area py-6' })}
        style={{
          border: error ? '1px solid red' : '',
          cursor: disabled ? 'not-allowed' : ''
        }}
      >
        <input
          {...getInputProps({
            multiple: multiple || false,
            accept: accept || undefined
          })}
          disabled={disabled}
        />
        <Flex justifyContent="center">
          <img src={cloudUpload} alt="" width={25} className="me-2" />
          {isDragActive && (
            <p className="fs-0 mb-0 text-700">
              {dropPlaceholder || t('common:placeholder.dropFileHere')}
            </p>
          )}
          {!isDragActive && (
            <p className="fs-0 mb-0 text-700">
              {dropPlaceholder || t('common:placeholder.uploadFileByDragDrop')}
            </p>
          )}
        </Flex>
      </div>
    </>
  );
};

export default FileDropZone;
