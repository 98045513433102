export const countries = [
  {
    ar: 'أفغانستان',
    en: 'Afghanistan',
    code: 'af'
  },
  {
    ar: 'ألبانيا',
    en: 'Albania',
    code: 'al'
  },
  {
    ar: 'الجزائر',
    en: 'Algeria',
    code: 'dz'
  },
  {
    ar: 'أندورا',
    en: 'Andorra',
    code: 'ad'
  },
  {
    ar: 'أنغولا',
    en: 'Angola',
    code: 'ao'
  },
  {
    ar: 'أنتيغوا وباربودا',
    en: 'Antigua and Barbuda',
    code: 'ag'
  },
  {
    ar: 'الأرجنتين',
    en: 'Argentina',
    code: 'ar'
  },
  {
    ar: 'أرمينيا',
    en: 'Armenia',
    code: 'am'
  },
  {
    ar: 'أستراليا',
    en: 'Australia',
    code: 'au'
  },
  {
    ar: 'النمسا',
    en: 'Austria',
    code: 'at'
  },
  {
    ar: 'أذربيجان',
    en: 'Azerbaijan',
    code: 'az'
  },
  {
    ar: 'باهاماس',
    en: 'Bahamas',
    code: 'bs'
  },
  {
    ar: 'البحرين',
    en: 'Bahrain',
    code: 'bh'
  },
  {
    ar: 'بنغلاديش',
    en: 'Bangladesh',
    code: 'bd'
  },
  {
    ar: 'باربادوس',
    en: 'Barbados',
    code: 'bb'
  },
  {
    ar: 'بيلاروس',
    en: 'Belarus',
    code: 'by'
  },
  {
    ar: 'بلجيكا',
    en: 'Belgium',
    code: 'be'
  },
  {
    ar: 'بليز',
    en: 'Belize',
    code: 'bz'
  },
  {
    ar: 'بنين',
    en: 'Benin',
    code: 'bj'
  },
  {
    ar: 'بوتان',
    en: 'Bhutan',
    code: 'bt'
  },
  {
    ar: 'بوليفيا',
    en: 'Bolivia (Plurinational State of)',
    code: 'bo'
  },
  {
    ar: 'البوسنة والهرسك',
    en: 'Bosnia and Herzegovina',
    code: 'ba'
  },
  {
    ar: 'بوتسوانا',
    en: 'Botswana',
    code: 'bw'
  },
  {
    ar: 'البرازيل',
    en: 'Brazil',
    code: 'br'
  },
  {
    ar: 'بروناي',
    en: 'Brunei Darussalam',
    code: 'bn'
  },
  {
    ar: 'بلغاريا',
    en: 'Bulgaria',
    code: 'bg'
  },
  {
    ar: 'بوركينا فاسو',
    en: 'Burkina Faso',
    code: 'bf'
  },
  {
    ar: 'بوروندي',
    en: 'Burundi',
    code: 'bi'
  },
  {
    ar: 'كمبوديا',
    en: 'Cambodia',
    code: 'kh'
  },
  {
    ar: 'الكاميرون',
    en: 'Cameroon',
    code: 'cm'
  },
  {
    ar: 'كندا',
    en: 'Canada',
    code: 'ca'
  },
  {
    ar: 'الرأس الأخضر',
    en: 'Cabo Verde',
    code: 'cv'
  },
  {
    ar: 'جمهورية إفريقيا الوسطى',
    en: 'Central African Republic',
    code: 'cf'
  },
  {
    ar: 'تشاد',
    en: 'Chad',
    code: 'td'
  },
  {
    ar: 'تشيلي',
    en: 'Chile',
    code: 'cl'
  },
  {
    ar: 'الصين',
    en: 'China',
    code: 'cn'
  },
  {
    ar: 'كولومبيا',
    en: 'Colombia',
    code: 'co'
  },
  {
    ar: 'جزر القمر',
    en: 'Comoros',
    code: 'km'
  },
  {
    ar: 'جمهورية الكونغو',
    en: 'Congo',
    code: 'cg'
  },
  {
    ar: 'جمهورية الكونغو الديمقراطية',
    en: 'Congo, Democratic Republic of the',
    code: 'cd'
  },
  {
    ar: 'كوستاريكا',
    en: 'Costa Rica',
    code: 'cr'
  },
  {
    ar: 'ساحل العاج',
    en: "Côte d'Ivoire",
    code: 'ci'
  },
  {
    ar: 'كرواتيا',
    en: 'Croatia',
    code: 'hr'
  },
  {
    ar: 'كوبا',
    en: 'Cuba',
    code: 'cu'
  },
  {
    ar: 'قبرص',
    en: 'Cyprus',
    code: 'cy'
  },
  {
    ar: 'جمهورية التشيك',
    en: 'Czechia',
    code: 'cz'
  },
  {
    ar: 'الدنمارك',
    en: 'Denmark',
    code: 'dk'
  },
  {
    ar: 'جيبوتي',
    en: 'Djibouti',
    code: 'dj'
  },
  {
    ar: 'دومينيكا',
    en: 'Dominica',
    code: 'dm'
  },
  {
    ar: 'جمهورية الدومينيكان',
    en: 'Dominican Republic',
    code: 'do'
  },
  {
    ar: 'الإكوادور',
    en: 'Ecuador',
    code: 'ec'
  },
  {
    ar: 'مصر',
    en: 'Egypt',
    code: 'eg'
  },
  {
    ar: 'السلفادور',
    en: 'El Salvador',
    code: 'sv'
  },
  {
    ar: 'غينيا الاستوائية',
    en: 'Equatorial Guinea',
    code: 'gq'
  },
  {
    ar: 'إريتريا',
    en: 'Eritrea',
    code: 'er'
  },
  {
    ar: 'إستونيا',
    en: 'Estonia',
    code: 'ee'
  },
  {
    ar: 'إثيوبيا',
    en: 'Ethiopia',
    code: 'et'
  },
  {
    ar: 'فيجي',
    en: 'Fiji',
    code: 'fj'
  },
  {
    ar: 'فنلندا',
    en: 'Finland',
    code: 'fi'
  },
  {
    ar: 'فرنسا',
    en: 'France',
    code: 'fr'
  },
  {
    ar: 'الغابون',
    en: 'Gabon',
    code: 'ga'
  },
  {
    ar: 'غامبيا',
    en: 'Gambia',
    code: 'gm'
  },
  {
    ar: 'جورجيا',
    en: 'Georgia',
    code: 'ge'
  },
  {
    ar: 'ألمانيا',
    en: 'Germany',
    code: 'de'
  },
  {
    ar: 'غانا',
    en: 'Ghana',
    code: 'gh'
  },
  {
    ar: 'اليونان',
    en: 'Greece',
    code: 'gr'
  },
  {
    ar: 'غرينادا',
    en: 'Grenada',
    code: 'gd'
  },
  {
    ar: 'غواتيمالا',
    en: 'Guatemala',
    code: 'gt'
  },
  {
    ar: 'غينيا',
    en: 'Guinea',
    code: 'gn'
  },
  {
    ar: 'غينيا بيساو',
    en: 'Guinea-Bissau',
    code: 'gw'
  },
  {
    ar: 'غيانا',
    en: 'Guyana',
    code: 'gy'
  },
  {
    ar: 'هايتي',
    en: 'Haiti',
    code: 'ht'
  },
  {
    ar: 'هندوراس',
    en: 'Honduras',
    code: 'hn'
  },
  {
    ar: 'المجر',
    en: 'Hungary',
    code: 'hu'
  },
  {
    ar: 'آيسلندا',
    en: 'Iceland',
    code: 'is'
  },
  {
    ar: 'الهند',
    en: 'India',
    code: 'in'
  },
  {
    ar: 'إندونيسيا',
    en: 'Indonesia',
    code: 'id'
  },
  {
    ar: 'إيران',
    en: 'Iran (Islamic Republic of)',
    code: 'ir'
  },
  {
    ar: 'العراق',
    en: 'Iraq',
    code: 'iq'
  },
  {
    ar: 'أيرلندا',
    en: 'Ireland',
    code: 'ie'
  },
  {
    ar: 'فلسطين',
    en: 'Palestine',
    code: 'ps'
  },
  {
    ar: 'إيطاليا',
    en: 'Italy',
    code: 'it'
  },
  {
    ar: 'جامايكا',
    en: 'Jamaica',
    code: 'jm'
  },
  {
    ar: 'اليابان',
    en: 'Japan',
    code: 'jp'
  },
  {
    ar: 'الأردن',
    en: 'Jordan',
    code: 'jo'
  },
  {
    ar: 'كازاخستان',
    en: 'Kazakhstan',
    code: 'kz'
  },
  {
    ar: 'كينيا',
    en: 'Kenya',
    code: 'ke'
  },
  {
    ar: 'كيريباتي',
    en: 'Kiribati',
    code: 'ki'
  },
  {
    ar: 'كوريا الشمالية',
    en: "Korea (Democratic People's Republic of)",
    code: 'kp'
  },
  {
    ar: 'كوريا الجنوبية',
    en: 'Korea, Republic of',
    code: 'kr'
  },
  {
    ar: 'الكويت',
    en: 'Kuwait',
    code: 'kw'
  },
  {
    ar: 'قيرغيزستان',
    en: 'Kyrgyzstan',
    code: 'kg'
  },
  {
    ar: 'لاوس',
    en: "Lao People's Democratic Republic",
    code: 'la'
  },
  {
    ar: 'لاتفيا',
    en: 'Latvia',
    code: 'lv'
  },
  {
    ar: 'لبنان',
    en: 'Lebanon',
    code: 'lb'
  },
  {
    ar: 'ليسوتو',
    en: 'Lesotho',
    code: 'ls'
  },
  {
    ar: 'ليبيريا',
    en: 'Liberia',
    code: 'lr'
  },
  {
    ar: 'ليبيا',
    en: 'Libya',
    code: 'ly'
  },
  {
    ar: 'ليختنشتاين',
    en: 'Liechtenstein',
    code: 'li'
  },
  {
    ar: 'ليتوانيا',
    en: 'Lithuania',
    code: 'lt'
  },
  {
    ar: 'لوكسمبورغ',
    en: 'Luxembourg',
    code: 'lu'
  },
  {
    ar: 'مقدونيا',
    en: 'North Macedonia',
    code: 'mk'
  },
  {
    ar: 'مدغشقر',
    en: 'Madagascar',
    code: 'mg'
  },
  {
    ar: 'مالاوي',
    en: 'Malawi',
    code: 'mw'
  },
  {
    ar: 'ماليزيا',
    en: 'Malaysia',
    code: 'my'
  },
  {
    ar: 'جزر المالديف',
    en: 'Maldives',
    code: 'mv'
  },
  {
    ar: 'مالي',
    en: 'Mali',
    code: 'ml'
  },
  {
    ar: 'مالطا',
    en: 'Malta',
    code: 'mt'
  },
  {
    ar: 'جزر مارشال',
    en: 'Marshall Islands',
    code: 'mh'
  },
  {
    ar: 'موريتانيا',
    en: 'Mauritania',
    code: 'mr'
  },
  {
    ar: 'موريشيوس',
    en: 'Mauritius',
    code: 'mu'
  },
  {
    ar: 'المكسيك',
    en: 'Mexico',
    code: 'mx'
  },
  {
    ar: 'ولايات ميكرونيسيا المتحدة',
    en: 'Micronesia (Federated States of)',
    code: 'fm'
  },
  {
    ar: 'المغرب',
    en: 'Morocco',
    code: 'ma'
  },
  {
    ar: 'مولدوفا',
    en: 'Moldova, Republic of',
    code: 'md'
  },
  {
    ar: 'موناكو',
    en: 'Monaco',
    code: 'mc'
  },
  {
    ar: 'منغوليا',
    en: 'Mongolia',
    code: 'mn'
  },
  {
    ar: 'الجبل الأسود',
    en: 'Montenegro',
    code: 'me'
  },
  {
    ar: 'موزمبيق',
    en: 'Mozambique',
    code: 'mz'
  },
  {
    ar: 'ميانمار',
    en: 'Myanmar',
    code: 'mm'
  },
  {
    ar: 'ناميبيا',
    en: 'Namibia',
    code: 'na'
  },
  {
    ar: 'ناورو',
    en: 'Nauru',
    code: 'nr'
  },
  {
    ar: 'نيبال',
    en: 'Nepal',
    code: 'np'
  },
  {
    ar: 'هولندا',
    en: 'Netherlands',
    code: 'nl'
  },
  {
    ar: 'نيوزيلندا',
    en: 'New Zealand',
    code: 'nz'
  },
  {
    ar: 'نيكاراغوا',
    en: 'Nicaragua',
    code: 'ni'
  },
  {
    ar: 'النيجر',
    en: 'Niger',
    code: 'ne'
  },
  {
    ar: 'نيجيريا',
    en: 'Nigeria',
    code: 'ng'
  },
  {
    ar: 'النرويج',
    en: 'Norway',
    code: 'no'
  },
  {
    ar: 'عُمان',
    en: 'Oman',
    code: 'om'
  },
  {
    ar: 'باكستان',
    en: 'Pakistan',
    code: 'pk'
  },
  {
    ar: 'بالاو',
    en: 'Palau',
    code: 'pw'
  },
  {
    ar: 'بنما',
    en: 'Panama',
    code: 'pa'
  },
  {
    ar: 'بابوا غينيا الجديدة',
    en: 'Papua New Guinea',
    code: 'pg'
  },
  {
    ar: 'باراغواي',
    en: 'Paraguay',
    code: 'py'
  },
  {
    ar: 'بيرو',
    en: 'Peru',
    code: 'pe'
  },
  {
    ar: 'الفلبين',
    en: 'Philippines',
    code: 'ph'
  },
  {
    ar: 'بولندا',
    en: 'Poland',
    code: 'pl'
  },
  {
    ar: 'البرتغال',
    en: 'Portugal',
    code: 'pt'
  },
  {
    ar: 'قطر',
    en: 'Qatar',
    code: 'qa'
  },
  {
    ar: 'رومانيا',
    en: 'Romania',
    code: 'ro'
  },
  {
    ar: 'روسيا',
    en: 'Russian Federation',
    code: 'ru'
  },
  {
    ar: 'رواندا',
    en: 'Rwanda',
    code: 'rw'
  },
  {
    ar: 'سانت كيتس ونيفيس',
    en: 'Saint Kitts and Nevis',
    code: 'kn'
  },
  {
    ar: 'سانت لوسيا',
    en: 'Saint Lucia',
    code: 'lc'
  },
  {
    ar: 'سانت فينسنت والغرينادين',
    en: 'Saint Vincent and the Grenadines',
    code: 'vc'
  },
  {
    ar: 'ساموا',
    en: 'Samoa',
    code: 'ws'
  },
  {
    ar: 'سان مارينو',
    en: 'San Marino',
    code: 'sm'
  },
  {
    ar: 'ساو تومي وبرينسيب',
    en: 'Sao Tome and Principe',
    code: 'st'
  },
  {
    ar: 'السعودية',
    en: 'Saudi Arabia',
    code: 'sa'
  },
  {
    ar: 'السنغال',
    en: 'Senegal',
    code: 'sn'
  },
  {
    ar: 'صربيا',
    en: 'Serbia',
    code: 'rs'
  },
  {
    ar: 'سيشل',
    en: 'Seychelles',
    code: 'sc'
  },
  {
    ar: 'سيراليون',
    en: 'Sierra Leone',
    code: 'sl'
  },
  {
    ar: 'سنغافورة',
    en: 'Singapore',
    code: 'sg'
  },
  {
    ar: 'سلوفاكيا',
    en: 'Slovakia',
    code: 'sk'
  },
  {
    ar: 'سلوفينيا',
    en: 'Slovenia',
    code: 'si'
  },
  {
    ar: 'جزر سليمان',
    en: 'Solomon Islands',
    code: 'sb'
  },
  {
    ar: 'الصومال',
    en: 'Somalia',
    code: 'so'
  },
  {
    ar: 'جنوب إفريقيا',
    en: 'South Africa',
    code: 'za'
  },
  {
    ar: 'جنوب السودان',
    en: 'South Sudan',
    code: 'ss'
  },
  {
    ar: 'إسبانيا',
    en: 'Spain',
    code: 'es'
  },
  {
    ar: 'سريلانكا',
    en: 'Sri Lanka',
    code: 'lk'
  },
  {
    ar: 'السودان',
    en: 'Sudan',
    code: 'sd'
  },
  {
    ar: 'سورينام',
    en: 'Suriname',
    code: 'sr'
  },
  {
    ar: 'إسواتيني',
    en: 'Eswatini',
    code: 'sz'
  },
  {
    ar: 'السويد',
    en: 'Sweden',
    code: 'se'
  },
  {
    ar: 'سويسرا',
    en: 'Switzerland',
    code: 'ch'
  },
  {
    ar: 'سوريا',
    en: 'Syrian Arab Republic',
    code: 'sy'
  },
  {
    ar: 'طاجيكستان',
    en: 'Tajikistan',
    code: 'tj'
  },
  {
    ar: 'تنزانيا',
    en: 'Tanzania, United Republic of',
    code: 'tz'
  },
  {
    ar: 'تايلاند',
    en: 'Thailand',
    code: 'th'
  },
  {
    ar: 'تيمور الشرقية',
    en: 'Timor-Leste',
    code: 'tl'
  },
  {
    ar: 'توغو',
    en: 'Togo',
    code: 'tg'
  },
  {
    ar: 'تونغا',
    en: 'Tonga',
    code: 'to'
  },
  {
    ar: 'ترينيداد وتوباغو',
    en: 'Trinidad and Tobago',
    code: 'tt'
  },
  {
    ar: 'تونس',
    en: 'Tunisia',
    code: 'tn'
  },
  {
    ar: 'تركيا',
    en: 'Türkiye',
    code: 'tr'
  },
  {
    ar: 'تركمانستان',
    en: 'Turkmenistan',
    code: 'tm'
  },
  {
    ar: 'توفالو',
    en: 'Tuvalu',
    code: 'tv'
  },
  {
    ar: 'أوغندا',
    en: 'Uganda',
    code: 'ug'
  },
  {
    ar: 'أوكرانيا',
    en: 'Ukraine',
    code: 'ua'
  },
  {
    ar: 'الإمارات العربية المتحدة',
    en: 'United Arab Emirates',
    code: 'ae'
  },
  {
    ar: 'المملكة المتحدة',
    en: 'United Kingdom of Great Britain and Northern Ireland',
    code: 'gb'
  },
  {
    ar: 'الولايات المتحدة',
    en: 'United States of America',
    code: 'us'
  },
  {
    ar: 'الأوروغواي',
    en: 'Uruguay',
    code: 'uy'
  },
  {
    ar: 'أوزبكستان',
    en: 'Uzbekistan',
    code: 'uz'
  },
  {
    ar: 'فانواتو',
    en: 'Vanuatu',
    code: 'vu'
  },
  {
    ar: 'فنزويلا',
    en: 'Venezuela (Bolivarian Republic of)',
    code: 've'
  },
  {
    ar: 'فيتنام',
    en: 'Viet Nam',
    code: 'vn'
  },
  {
    ar: 'اليمن',
    en: 'Yemen',
    code: 'ye'
  },
  {
    ar: 'زامبيا',
    en: 'Zambia',
    code: 'zm'
  },
  {
    ar: 'زيمبابوي',
    en: 'Zimbabwe',
    code: 'zw'
  }
];
