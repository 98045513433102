import React from 'react';
import CityBasicForm from './components/CityBasicForm';

const CreateCity = () => {
  return (
    <>
      <CityBasicForm />
    </>
  );
};
export default CreateCity;
