import React from 'react';
import { Col, Placeholder } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CountUp from 'react-countup';
import { useTranslation } from 'react-i18next';

const LmsStatItem = ({ stat, kpisLoading }) => {
  const { t } = useTranslation();
  return (
    <Col
      xxl={3}
      md={4}
      className={`${stat.className} px-3 text-center kpi-item pt-2`}
    >
      <div className={`icon-circle icon-circle-${stat.color}`}>
        {kpisLoading && <Placeholder className="w-100 h-100 icon-circle m-0" />}
        {!kpisLoading && (
          <FontAwesomeIcon
            icon={stat.icon}
            className={`fs-2 text-${stat.color}`}
          />
        )}
      </div>
      <h4 className="mb-1 font-sans-serif">
        {kpisLoading && <Placeholder className="w-50 h-100 m-0 rounded" />}
        {!kpisLoading && (
          <>
            <CountUp
              start={0}
              end={stat.amount}
              duration={2.75}
              className="text-700"
              separator=","
            />
            <span className="fw-normal text-600">
              {' ' + t(`dashboard:labels.${stat.title}`)}
            </span>
          </>
        )}
      </h4>
      {kpisLoading && <Placeholder className="w-50 m-0 rounded" />}
      {!kpisLoading && (
        <p className="fs--1 fw-semi-bold mb-0">
          {!isNaN(stat.subAmount) && (stat.subAmount || '')}
          <span className="text-600 fw-normal">
            {` ${
              !!stat.subTitle && !isNaN(stat.subAmount)
                ? t(`dashboard:labels.${stat.subTitle}`)
                : ''
            }`}
          </span>
        </p>
      )}
    </Col>
  );
};

export default LmsStatItem;
