import CourseService from 'http/CourseService';
import { getErrorMessage } from 'http/utils';
import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import { toast } from 'react-toastify';
import Flex from 'components/common/Flex';
import LessonContent from './LessonContent';
import ConfirmModal from 'components/common/ConfirmModal';
import LoaderWithMessage from 'components/common/LoaderWithMessage';

const LessonModal = ({ lesson, course, chapter, refetchCourse, close }) => {
  const { t, i18n } = useTranslation();
  const currentLanguage = i18n?.language;
  const [initLessonValue, setInitLessonValue] = useState(lesson);
  const [lessonDetails, setLessonDetails] = useState(lesson);
  const [errors, setErrors] = useState(null);
  const [disableEditing, setDisableEditing] = useState(false);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const [confirmModalProps, setConfirmModalProps] = useState(null);

  const {
    isLoading: lessonLoading,
    data: lessonData,
    error: lessonError,
    refetch: fetchLesson
  } = useQuery(
    ['GetLesson', lesson?.id],
    () => CourseService.getLesson(lesson?.id),
    {
      retry: false,
      manual: true, // Prevents automatic refetching
      refetchOnWindowFocus: false,
      enabled: false
    }
  );

  useEffect(() => {
    if (lesson?.id) {
      fetchLesson(lesson.id);
    }
  }, [lesson]);

  useEffect(() => {
    if (lessonData && !lessonLoading && !lessonError) {
      if (lessonData?.errors?.length) {
        toast.error(getErrorMessage(t, lessonData.errors), {
          theme: 'colored',
          autoClose: false
        });
      }
      setInitLessonValue(lessonData.payload);
      setLessonDetails(lessonData.payload);
    }

    if (lessonError) {
      toast.error(getErrorMessage(t, lessonError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [lessonLoading, lessonData, lessonError]);

  const {
    mutate: saveLesson,
    error: saveLessonError,
    isLoading: saveLessonLoading
  } = useMutation({
    mutationFn: lesson?.id
      ? CourseService.updateLesson
      : CourseService.createLesson,
    onSuccess: data => onSaveLessonSuccess(data)
  });

  const onSaveLessonSuccess = () => {
    if (lesson?.id) {
      toast.success(t(`common:message.updateSuccess`), { theme: 'colored' });
    } else {
      toast.success(t(`common:message.createSuccess`), { theme: 'colored' });
    }
    refetchCourse && refetchCourse(course?.id);
    setHasUnsavedChanges(false);
    close && close();
  };

  const handleLessonContentChange = ({ type, data }) => {
    switch (type) {
      case null:
        setLessonDetails({ ...lessonDetails, type: null });
        break;
      case 'text':
        setLessonDetails({
          ...lessonDetails,
          type,
          content: { ...lessonDetails?.content, text: data || null }
        });
        break;
      case 'video':
        setLessonDetails({
          ...lessonDetails,
          type,
          content: { ...lessonDetails?.content, video: data || null }
        });
        break;
      case 'html5':
        setLessonDetails({
          ...lessonDetails,
          type,
          content: {
            ...lessonDetails?.content,
            interactiveContentPath: data.path || ''
          }
        });
        break;
      default:
        break;
    }
  };

  const checkUnsavedChanged = () => {
    let hasChanges = false;
    if (!lesson?.id) {
      if (!!lessonDetails?.title?.ar) {
        hasChanges = true;
      }
      if (!!lessonDetails?.title?.en) {
        hasChanges = true;
      }
      if (!!lessonDetails?.type) {
        hasChanges = true;
      }
      if (!!lessonDetails?.content) {
        hasChanges = true;
      }
    } else {
      if (lessonDetails?.title?.ar !== initLessonValue?.title?.ar) {
        hasChanges = true;
      }
      if (lessonDetails?.title?.en !== initLessonValue?.title?.en) {
        hasChanges = true;
      }
      if (lessonDetails?.type !== initLessonValue?.type) {
        hasChanges = true;
      }
      if (
        JSON.stringify(lessonDetails?.content) !==
        JSON.stringify(initLessonValue?.content)
      ) {
        hasChanges = true;
      }
    }

    setHasUnsavedChanges(hasChanges);
  };

  const handleCancel = () => {
    if (!hasUnsavedChanges) {
      close(false);
      return;
    }
    setConfirmModalProps({
      isOpen: true,
      onClose: () => setConfirmModalProps(null),
      onConfirm: () => close(false),
      message: t('common:message.confirmDiscardMessage'),
      header: t('common:message.confirmDiscardTitle'),
      actionButtonColor: 'danger',
      actionButtonText: t('common:button.discard')
    });
  };

  const validateLesson = () => {
    let hasError = false;
    let errorsObj = {};
    if (!lessonDetails?.title?.ar) {
      if (!errorsObj['title']) {
        errorsObj['title'] = { ar: true };
        hasError = true;
      } else {
        errorsObj['title']['ar'] = true;
        hasError = true;
      }
    }
    if (!lessonDetails?.title?.en) {
      if (!errorsObj['title']) {
        errorsObj['title'] = { en: true };
        hasError = true;
      } else {
        errorsObj['title']['en'] = true;
        hasError = true;
      }
    }
    if (!lessonDetails?.type) {
      errorsObj['type'] = true;
      hasError = true;
    }
    // if (!lessonDetails?.content) {
    //   errorsObj['content'] = true;
    //   hasError = true;
    // }
    if (lessonDetails?.type === 'text') {
      if (!lessonDetails?.content?.text?.en) {
        if (!errorsObj['content']) {
          errorsObj['content'] = { en: true };
          hasError = true;
        } else {
          errorsObj['content']['en'] = true;
          hasError = true;
        }
      }
      if (!lessonDetails?.content?.text?.ar) {
        if (!errorsObj['content']) {
          errorsObj['content'] = { ar: true };
          hasError = true;
        } else {
          errorsObj['content']['ar'] = true;
          hasError = true;
        }
      }
    }
    if (
      lessonDetails?.type === 'html5' &&
      !lessonDetails?.content.interactiveContentPath
    ) {
      errorsObj['content'] = true;
      hasError = true;
    }
    if (
      lessonDetails?.type === 'video' &&
      !lessonDetails?.content?.video?.url
    ) {
      errorsObj['content'] = true;
      hasError = true;
    }
    if (
      !!lessonDetails?.title?.en &&
      !!lessonDetails?.title?.ar &&
      !!lessonDetails?.type &&
      ((lessonDetails?.type === 'text' &&
        !!lessonDetails?.content?.text?.en &&
        !!lessonDetails?.content?.text?.ar) ||
        (lessonDetails?.type === 'html5' &&
          !!lessonDetails?.content?.interactiveContentPath) ||
        (lessonDetails?.type === 'video' &&
          !!lessonDetails?.content?.video?.url))
    ) {
      errorsObj = null;
      hasError = false;
    }
    //console.log('validateLesson', lessonDetails);
    setErrors(errorsObj);
    return hasError;
  };

  const handleSubmitLesson = () => {
    const hasError = validateLesson();
    if (hasError) {
      toast.error(t('common:validation.pleaseFilloutRequiredFields'), {
        theme: 'colored',
        autoClose: false
      });
      return;
    }
    saveLesson({
      id: lessonDetails?.id,
      title: lessonDetails?.title,
      courseId: course.id,
      chapterId: chapter.id,
      type: lessonDetails?.type,
      allowPreview: lessonDetails?.allowPreview || false,
      content: {
        video: lessonDetails?.content?.video || null,
        text: lessonDetails?.content?.text || null,
        interactiveContentPath:
          lessonDetails?.content?.interactiveContentPath || ''
      }
    });
  };

  useEffect(() => {
    checkUnsavedChanged();
  }, [lessonDetails]);

  useEffect(() => {
    if (saveLessonError) {
      toast.error(getErrorMessage(t, saveLessonError), {
        theme: 'colored',
        autoClose: false
      });
    }
    if (lessonError) {
      toast.error(getErrorMessage(t, lessonError), {
        theme: 'colored',
        autoClose: false
      });
    }
  }, [saveLessonError, lessonError]);

  return (
    <Modal
      show={!!chapter?.id}
      size="xl"
      aria-labelledby="contained-modal-title-lesson"
      centered
      backdrop="static"
    >
      <Modal.Header>
        <Modal.Title id="contained-modal-title-lesson">
          {t('lesson:labels.lessonModalTitle', {
            name: !!lesson?.id
              ? lesson?.title?.[currentLanguage]
              : t('common:button.new')
          })}
        </Modal.Title>
        {/* <CloseButton
          className="btn btn-circle btn-sm transition-base p-0"
          onClick={() => close(false)}
        /> */}
      </Modal.Header>
      <Modal.Body>
        {lessonLoading && (
          <LoaderWithMessage message={t('lesson:message.lessonLoading')} />
        )}
        {!lessonLoading && (
          <Form.Group controlId="newChapter" className="position-relative">
            <Flex
              direction="column"
              alignItems="start"
              justifyContent="center"
              gap="1"
            >
              <Row className="w-100 gx-0 gy-1">
                <Col md={6} className="mt-1 g-1">
                  <Form.Label>
                    {`${t('common:labels.title')} (${t(
                      'common:language.ar.name'
                    )})`}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lessonTitleAr"
                    required
                    value={lessonDetails?.title?.ar || ''}
                    className="pe-4"
                    onChange={e =>
                      setLessonDetails({
                        ...lessonDetails,
                        title: { ...lessonDetails?.title, ar: e.target.value }
                      })
                    }
                    placeholder={t('common:placeholder.titleAr')}
                    isInvalid={!!errors?.title?.ar}
                    disabled={disableEditing}
                  />
                </Col>
                <Col md={6} className="mt-1 g-1">
                  <Form.Label>
                    {`${t('common:labels.title')} (${t(
                      'common:language.en.name'
                    )})`}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="lessonTitleEn"
                    required
                    value={lessonDetails?.title?.en || ''}
                    className="pe-4"
                    onChange={e =>
                      setLessonDetails({
                        ...lessonDetails,
                        title: { ...lessonDetails?.title, en: e.target.value }
                      })
                    }
                    placeholder={t('common:placeholder.titleEn')}
                    isInvalid={!!errors?.title?.en}
                    disabled={disableEditing}
                  />
                </Col>
              </Row>
              <Row>
                <Col md={12} className="mt-1">
                  {/* <Form.Label>
                    {`${t('lesson:labels.allowPreview')}`}
                    <span className="text-danger">*</span>
                  </Form.Label> */}
                  <Form.Check
                    type="switch"
                    id="defaultSwitch"
                    size={50}
                    label={t('lesson:labels.allowPreview')}
                    onChange={e => {
                      setLessonDetails({
                        ...lessonDetails,
                        allowPreview: e.target.checked || false
                      });
                    }}
                    disabled={disableEditing}
                    checked={lessonDetails?.allowPreview || false}
                  />
                </Col>
              </Row>
              <Row className="w-100 gx-0 gy-1 mt-1">
                <Col md={12} className="mt-1 g-1">
                  <Form.Label>
                    {`${t('lesson:labels.lessonType')}`}
                    <span className="text-danger">*</span>
                  </Form.Label>
                  <Form.Select
                    placeholder={t('lesson:placeholders.selectLessonType')}
                    onChange={e => {
                      if (e.target.value) {
                        setErrors({ type: false });
                      }
                      setLessonDetails({
                        ...lessonDetails,
                        type: e.target.value || null
                      });
                    }}
                    disabled={disableEditing}
                    isInvalid={errors?.type}
                    value={lessonDetails?.type || ''}
                  >
                    <option value="">
                      {t('lesson:placeholders.selectLessonType')}
                    </option>
                    <option value="text">{t('lesson:type.article')}</option>
                    <option value="video">{t('lesson:type.video')}</option>
                    {/* <option value="html5">{t('lesson:type.html5')}</option> */}
                  </Form.Select>
                </Col>
              </Row>
              {!!lessonDetails?.type && (
                <Row className="w-100 gx-0 gy-1 mt-1">
                  <Col md={12} className="mt-1 g-1">
                    <Form.Label>
                      {`${t('lesson:labels.lessonContent')}`}
                      <span className="text-danger">*</span>
                    </Form.Label>
                    <LessonContent
                      lesson={lessonDetails}
                      onChange={({ type, data }) =>
                        handleLessonContentChange({ type, data })
                      }
                      disableAll={status => setDisableEditing(status)}
                      disabled={disableEditing}
                      errors={errors}
                    />
                  </Col>
                </Row>
              )}
            </Flex>
          </Form.Group>
        )}
        <ConfirmModal {...confirmModalProps} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={saveLessonLoading || disableEditing}
          onClick={handleSubmitLesson}
          className="mt-1"
        >
          {saveLessonLoading && <Spinner size="sm" />}
          {!saveLessonLoading && t('common:button.save')}
        </Button>
        <Button
          onClick={handleCancel}
          variant="secondary"
          disabled={saveLessonLoading || disableEditing}
        >
          {t('common:button.cancel')}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default LessonModal;
