import http from 'http/interceptor';
import { getItemFromStore } from 'helpers/utils';
const currentLocale =
  getItemFromStore('preferredLanguage', 'en') ||
  process.env.REACT_APP_DEFAULT_LANGUAGE;
const BASE_API_URL = `${process.env.REACT_APP_API_BASE_URL}/api`;
const LIST_PLANS_URL = `https://admin.api.360realize.com/api/plans/List`;
const GET_PLAN_URL = `https://admin.api.360realize.com/api/plans/GetPlanById`;
const CREATE_PLAN_URL = `https://admin.api.360realize.com/api/plans/Create`;
const UPDATE_PLAN_URL = `https://admin.api.360realize.com/api/plans/Update`;
const DELETE_PLAN_URL = `https://admin.api.360realize.com/api/plans/Delete`;
const LIST_SERVICE_TYPES_URL = `https://admin.api.360realize.com/api/ServiceTypes/List`;


const PlanService = {
  list: data => {
    return http.post(
      LIST_PLANS_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  get: id => {
    return http.get(`${GET_PLAN_URL}?id=${id}`, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  create: data => {
    return http.post(
      CREATE_PLAN_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  update: data => {
    return http.post(
      UPDATE_PLAN_URL,
      { ...data },
      {
        headers: { 'Content-Type': 'Application/Json' }
      }
    );
  },
  delete: data => {
    return http.delete(`${DELETE_PLAN_URL}?id=${data}`, data, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
  listServiceTypes: filters => {
    const url = LIST_SERVICE_TYPES_URL;
    return http.post(url, filters, {
      headers: { 'Content-Type': 'Application/Json' }
    });
  },
};

export default PlanService;
