import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Alert, CloseButton } from 'react-bootstrap';
import classNames from 'classnames';
import { faFileAlt, faRocket } from '@fortawesome/free-solid-svg-icons';

const IconAlert = ({
  variant,
  dismissible,
  children,
  className,
  onClose,
  ...rest
}) => {
  const icon = {
    success: 'check-circle',
    info: 'info-circle',
    warning: 'exclamation-circle',
    danger: 'times-circle',
    primary: faRocket,
    secondary: faFileAlt
  };
  return (
    <Alert
      variant={variant}
      className={classNames(className, 'd-flex align-items-center')}
      {...rest}
    >
      <div className={`bg-${variant} me-3 icon-item`}>
        <FontAwesomeIcon
          size="lg"
          icon={icon[variant]}
          className="text-white "
        />
      </div>
      <div className="flex-1">{children}</div>
      {dismissible && <CloseButton onClick={onClose} />}
    </Alert>
  );
};

export default IconAlert;
